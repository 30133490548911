import axios from "axios";

export const getFabricAvailability = async (data, setLoading) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_URL}/api/order/check-inventory`,
      data,
      config
    );
    return res?.data;
  } catch (error) {
    console.log(error?.response?.status);
    if (error?.response?.status !== 400) {
      setLoading(false);
    }
    return error?.response?.data;
  }
};
