import axios from "axios";

export const updateAddOns = async (item, id) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const addOns_data = {
      addons_name: item.addons_name,
      addons_rate: item.addons_rate,
    };

    const data = await axios.post(
      `${process.env.REACT_APP_URL}/api/addons-master/update-addOn-master?id=${id}`,
      addOns_data,
      config
    );
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
