import {
  ALL_COSTING_MASTER_SUCCESS,
  ALL_PRODUCT_MASTER_SUCCESS,
  ALL_SUPPLIER_MASTER_SUCCESS,
  LOAD_COSTING_MASTER_FAIL,
  LOAD_COSTING_MASTER_REQUEST,
  LOAD_PRODUCT_MASTER_FAIL,
  LOAD_PRODUCT_MASTER_REQUEST,
  ALL_ADD_ON_MASTER_FAIL,
  ALL_ADD_ON_MASTER_REQUEST,
  ALL_ADD_ON_MASTER_SUCCESS,
  ALL_FACTORY_MASTER_FAIL,
  ALL_FACTORY_MASTER_REQUEST,
  ALL_FACTORY_MASTER_SUCCESS,
  ALL_PORT_MASTER_FAIL,
  ALL_PORT_MASTER_REQUEST,
  ALL_PORT_MASTER_SUCCESS,
  ALL_UNIT_MASTER_FAIL,
  ALL_UNIT_MASTER_REQUEST,
  ALL_UNIT_MASTER_SUCCESS,
  LOAD_SUPPLIER_MASTER_FAIL,
  LOAD_SUPPLIER_MASTER_REQUEST,
  ALL_FABRIC_MASTER_FAIL,
  ALL_FABRIC_MASTER_SUCCESS,
  ALL_FABRIC_MASTER_REQUEST,
  ALL_GST_MASTER_REQUEST,
  ALL_GST_MASTER_SUCCESS,
  ALL_GST_MASTER_FAIL,
  ALL_FABRIC_COLOR_MASTER_REQUEST,
  ALL_FABRIC_COLOR_MASTER_SUCCESS,
  ALL_FABRIC_COLOR_MASTER_FAIL,
} from "app/utils/constants/masterConstants";
import axios from "axios";

export const getAllSuppliers = (search_value, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }
    dispatch({ type: LOAD_SUPPLIER_MASTER_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/supplier-master/list-supplier-master?search=${search_value}&page=${page}`,
      config
    );
    // console.log(data);
    dispatch({
      type: ALL_SUPPLIER_MASTER_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: LOAD_SUPPLIER_MASTER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getAllCosting = (search_value, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }
    dispatch({ type: LOAD_COSTING_MASTER_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/costing-master/list-costing-master?search=${search_value}&page=${page}`,
      config
    );
    // console.log(data);
    dispatch({
      type: ALL_COSTING_MASTER_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: LOAD_COSTING_MASTER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//port master
export const getAllPorts = (search_value, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }
    dispatch({ type: ALL_PORT_MASTER_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/port-master/list-port-master?search=${search_value}&page=${page}`,
      config
    );
    // console.log(data);
    dispatch({
      type: ALL_PORT_MASTER_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: ALL_PORT_MASTER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getMaleProduct = (search_value, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }
    dispatch({ type: LOAD_PRODUCT_MASTER_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/product-master/list-male-product-master?search=${search_value}&page=${page}`,
      config
    );
    // console.log(data);
    dispatch({
      type: ALL_PRODUCT_MASTER_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: LOAD_PRODUCT_MASTER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getFemaleProduct =
  (search_value, input, page) => async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }
      dispatch({ type: LOAD_PRODUCT_MASTER_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/product-master/list-female-product-master?search=${search_value}&page=${page}`,
        config
      );
      // console.log(data);
      dispatch({
        type: ALL_PRODUCT_MASTER_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: LOAD_PRODUCT_MASTER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//add-on master
export const getAllAddOns = (search_value, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }
    dispatch({ type: ALL_ADD_ON_MASTER_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/addons-master/list-addOn-master?search=${search_value}&page=${page}`,
      config
    );

    dispatch({
      type: ALL_ADD_ON_MASTER_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: ALL_ADD_ON_MASTER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//unit master
export const getAllUnit = (search_value, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }
    dispatch({ type: ALL_UNIT_MASTER_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/unit-master/list-unit-master?search=${search_value}&page=${page}`,
      config
    );

    dispatch({
      type: ALL_UNIT_MASTER_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: ALL_UNIT_MASTER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//gst master
export const getAllGst = (search_value, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }
    dispatch({ type: ALL_GST_MASTER_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/gst-master/list-gst-master?search=${search_value}&page=${page}`,
      config
    );

    dispatch({
      type: ALL_GST_MASTER_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: ALL_GST_MASTER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//fabricColor master
export const getAllFabricColor = (search_value, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }
    dispatch({ type: ALL_FABRIC_COLOR_MASTER_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/fabric-color-master/list-fabric-color-master?search=${search_value}&page=${page}`,
      config
    );

    dispatch({
      type: ALL_FABRIC_COLOR_MASTER_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: ALL_FABRIC_COLOR_MASTER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//factory master
export const getAllFactory = (search_value, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }
    dispatch({ type: ALL_FACTORY_MASTER_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/factory-master/list-factory-master?search=${search_value}&page=${page}`,
      config
    );

    dispatch({
      type: ALL_FACTORY_MASTER_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: ALL_FACTORY_MASTER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//fabric master
export const getAllFabric = (search_value, color, page) => async (dispatch) => {
  try {
    // console.log(search_value,color)
    if (!search_value) {
      search_value = "";
    } else if (!color) {
      color = "";
    }
    dispatch({ type: ALL_FABRIC_MASTER_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/fabric-master/list-fabric-master?search=${search_value}&fabric_color=${color}&page=${page}`,
      config
    );

    dispatch({
      type: ALL_FABRIC_MASTER_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: ALL_FABRIC_MASTER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
