import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../../../../pages/css/swal.css";
import Swal from "sweetalert2";
import {  updateItemFreight } from "app/services/apis/freight";
import { ErrorMessage, Form, Formik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dateFun } from "app/utils/constants/functions";

export const UpdateFreightModal = ({ open, onClose, setIsUpdateFreightModalOpen,itemDetails }) => {

  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);

  const freightInitialValue = {
    item_id:itemDetails?.id,
    item_freight_value: "",
    item_freight_order_date: "",
  };
  const validationSchema = yup.object({
    item_freight_value: yup.number("Enter Freight Value").required("Freight Value is required."),
    item_freight_order_date: yup.string().required("Order Date is required"),
  });

  const onUserSave = async (values) => {
    let newData = { ...values };
    newData.item_freight_order_date = dateFun(newData.item_freight_order_date);
    console.log(newData,"40");
    setSubmitting(true);
    const data = await updateItemFreight(newData);
    if (data?.status == 200) {
      Swal.fire({
        icon: "success",
        title: "Item Freight Updated Successfully",
        text: "",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: data?.message,
        text: "",
      });
    }
    setSubmitting(false);
    setIsUpdateFreightModalOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <DialogTitle sx={{ fontWeight: "700" }}>Update Freight Value</DialogTitle>
      <DialogContent>
        <Formik
          validateOnChange={true}
          initialValues={freightInitialValue}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values, errors }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 0 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    columnGap: 2,
                  }}
                >
                  <Div>
                    <Typography variant="h5">Freight Value*</Typography>
                    <TextField
                      size="small"
                      type="number"
                      name="item_freight_value"
                      label="Freight Value"
                      onChange={(e) => setFieldValue("item_freight_value", Number(e.target.value))}
                      error={errors?.item_freight_value || false}
                      helperText={errors?.item_freight_value}
                    />
                  </Div>
                  <Div>
                    <Typography variant="h5">Date*</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        value={values?.item_freight_order_date != "" ? dayjs(values?.item_freight_order_date, "DD-MM-YYYY") : null}
                        onChange={(date) => {
                          setFieldValue("item_freight_order_date", date);
                        }}
                        format="DD/MM/YYYY"
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage name="item_freight_order_date" component="div" style={{ color: "red" }} />
                      </Div>
                    </LocalizationProvider>
                  </Div>
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 0,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setIsUpdateFreightModalOpen(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton variant="contained" type="submit" sx={{ width: "100px" }} loading={isSubmitting}>
                    Update
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
