import Div from "@jumbo/shared/Div";
import {
  Button,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { displayDateFun } from "app/utils/constants/functions";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import Swal from "sweetalert2";
import { useEffect } from "react";
import QcImagesModal from "../../List/StartQ/qcImagesModal";
import { sendAlteration } from "app/services/apis/sendAlteration";

const AlterationTable = () => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [qcData, setQCData] = useState([]);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [type, setType] = useState(null);
  const [inputs, setInputs] = useState({
    order_items_id: state.order_items_id,
    qc_status: "",
    qc_confirm_by: state.qc_confirm_by ? state.qc_confirm_by : "",
    remarks_by_qc: state.remarks_by_qc ? state.remarks_by_qc : "",
    qc_contact_person_name: state.qc_contact_person_name
      ? state.qc_contact_person_name
      : "",
    qc_contact_person_phone_no: state.qc_contact_person_phone_no
      ? state.qc_contact_person_phone_no
      : "",
    qc_details_id: state.qc_details_id ? state.qc_details_id : "",
  });
  const navigate = useNavigate();

  const onSave = async () => {
    const body = {
      qc_details_id: inputs.qc_details_id,
      send_for_alteration_contact_person_name: inputs.qc_contact_person_name,
      send_for_alteration_contact_person_phone_no:
        inputs.qc_contact_person_phone_no,
      send_for_alteration_est_delivery_date:
        inputs.send_for_alteration_est_delivery_date,
    };
    setIsLoading(true);
    const data = await sendAlteration(body);
    // console.log(data);
    if (data?.data?.status !== true) {
      Swal.fire("Error", data.message, "error");
      setIsLoading(false);
      navigate("/dashboard/order/qc");
    } else {
      setIsLoading(false);
      Swal.fire("Challan Generated", data.message, "success");
      navigate("/dashboard/order/qc");
    }
  };

  const handleInfoIconClick = (rowData, type) => {
    setSelectedRowData(rowData);
    setInfoModalOpen(true);
    setType(type);
  };

  useEffect(async () => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api/qc/get-qc?order_items_id=${state?.order_items_id}`,
        config
      );
      if (res?.status == 200) {
        // console.log(res.data.result[0].remarks_by_qc);
        setQCData(res.data.result[0].qc_measurements);
        setInputs({
          ...inputs,
          qc_status: res.data.result[0].qc_status,
          qc_confirm_by: res.data.result[0].qc_confirm_by,
          qc_details_id: res.data.result[0].qc_details_id,
          remarks_by_qc: res.data.result[0].remarks_by_qc,
        });
      }
    } catch (error) {
      alert("Something Went Wrong");
    }
  }, []);

  useEffect(() => {
    const initialobservedData = Object?.entries(
      state?.order_measurement?.filter(
        (item) => item?.item_master_item_name == state?.order_sub_item_name
      )[0]?.item_master_measurement
    )?.map(([label, value], i) => ({
      observed_measurements: {
        name: label,
        value: null,
      },
    }));
    setQCData(initialobservedData);
  }, []);

  return (
    <Div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order No.
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Type
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "140px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Port Number & Name
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Exp. Delivery Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{state?.order_no}</TableCell>
              <TableCell>{state?.order_type}</TableCell>
              <TableCell>{state?.order_port_no_and_name}</TableCell>
              <TableCell>{displayDateFun(state?.order_date)}</TableCell>
              <TableCell>
                {displayDateFun(state?.order_est_delivery_date)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 3,
          mb: 1,
        }}
      >
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Item No:-
          </Typography>
          <Typography variant="h3">{state?.order_item_no}</Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Fabric:-
          </Typography>
          <Typography variant="h3">
            {state?.order_item_kc_fabric_code}
          </Typography>
        </Div>

        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Item:-
          </Typography>
          <Typography variant="h3">{state?.order_item_name}</Typography>
        </Div>

        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            SubItem:-
          </Typography>
          <Typography variant="h3">
            {state?.order_sub_item_name ? state?.order_sub_item_name : "-"}
          </Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Client Name:-
          </Typography>
          <Typography variant="h3">{state?.order_client_name}</Typography>
        </Div>
        <Div>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={() => handleInfoIconClick(state, "image")}
          >
            View Images
          </Button>
          <Button
            variant="contained"
            onClick={() => handleInfoIconClick(state, "catalogue")}
          >
            View Catalogue Images
          </Button>
        </Div>
      </Div>
      {state?.order_remarks && (
        <Div sx={{ mb: 4, border: "1px solid", p: 1, borderRadius: "5px" }}>
          <Typography variant="h4" sx={{ fontWeight: "600" }}>
            Order Remark:-
          </Typography>
          <Div>
            {state?.order_remarks.split("\n").map((line, index) => (
              <p key={index}>{line}</p>
            ))}

            {}
          </Div>
        </Div>
      )}
      {state?.order_item_remarks && (
        <Div sx={{ mb: 4, border: "1px solid", p: 1, borderRadius: "5px" }}>
          <Typography variant="h4" sx={{ fontWeight: "600" }}>
            Item Remark:-
          </Typography>
          <Div>{state?.order_item_remarks}</Div>
        </Div>
      )}

      <Div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Actual Measurement
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Observe Measurement
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "140px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Fail / Pass
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Comment
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object?.entries(
                state?.order_measurement?.filter(
                  (item) =>
                    item?.item_master_item_name == state?.order_sub_item_name
                )[0]?.item_master_measurement
              )?.map(([label, value], i) => (
                <>
                  <TableRow key={i}>
                    <TableCell>
                      <Typography>{label}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{value}</Typography>
                    </TableCell>
                    <TableCell>
                      {/* {console.log(qcData[i]?.observed_measurements)} */}
                      <TextField
                        disabled
                        value={qcData[i]?.observed_measurements?.value}
                        size="small"
                        name={label}
                        // onChange={(e) => {
                        //   handleObserveMeasurement(e, i);
                        // }}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        disabled
                        // onChange={(e) => handleObserveMeasurement(e, i)}
                        name="status"
                        checked={qcData[i]?.status}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled
                        value={qcData[i]?.comments}
                        name="comments"
                        size="small"
                        // onChange={(e) => {
                        //   handleObserveMeasurement(e, i);
                        // }}
                      ></TextField>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Div>
      <Div sx={{ mt: 3 }}>
        <Div sx={{ mb: 2 }}>
          <Typography variant="h4">Remark by QC</Typography>
          <TextField
            disabled
            fullWidth
            multiline
            size="medium"
            value={inputs?.remarks_by_qc}
            // onChange={(e) =>
            //   setInputs({ ...inputs, remarks_by_qc: e.target.value })
            // }
          >
            {/* {console.log(inputs)} */}
          </TextField>
        </Div>
        <Div sx={{ display: "flex", columnGap: 5, mb: 2 }}>
          <Div sx={{ width: "15%" }}>
            <Typography variant="h4">QC Status</Typography>
            <Select
              disabled
              value={inputs?.qc_status}
              // onChange={(e) =>
              //   setInputs({ ...inputs, qc_status: e.target.value })
              // }
              sx={{ width: "100%" }}
              size="small"
            >
              <MenuItem value={"Pass"}>Pass</MenuItem>
              <MenuItem value={"Fail"}>Fail</MenuItem>
            </Select>
          </Div>
          <Div sx={{ width: "25%" }}>
            <Typography variant="h4">Confirm By</Typography>
            <TextField
              disabled
              size="small"
              value={inputs?.qc_confirm_by}
              // onChange={(e) =>
              //   setInputs({ ...inputs, qc_confirm_by: e.target.value })
              // }
              sx={{ width: "100%" }}
            ></TextField>
          </Div>
          <Div sx={{ width: "25%" }}>
            <Typography variant="h4">Contact Person Name</Typography>
            <TextField
              size="small"
              value={inputs?.qc_contact_person_name}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  qc_contact_person_name: e.target.value,
                })
              }
              sx={{ width: "100%" }}
            ></TextField>
          </Div>

          <Div sx={{ width: "25%" }}>
            <Typography variant="h4">Contact Person No</Typography>
            <TextField
              type="number"
              size="small"
              value={inputs?.qc_contact_person_phone_no}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  qc_contact_person_phone_no: e.target.value,
                })
              }
              sx={{ width: "100%" }}
            ></TextField>
          </Div>
          <Div>
            <Typography variant="h5" sx={{ fontWeight: "600", mr: 1 }}>
              Est Delivery Date
            </Typography>

            <TextField
              size="small"
              id="datetime-local"
              type="datetime-local"
              sx={{ width: 250 }}
              onChange={(e) => {
                setInputs({
                  ...inputs,
                  send_for_alteration_est_delivery_date: e.target.value,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Div>
        </Div>
      </Div>
      <QcImagesModal
        open={isInfoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        rowData={selectedRowData}
        type={type}
      />
      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 4,
        }}
      >
        <LoadingButton
          variant="contained"
          sx={{ width: "200px" }}
          onClick={() => navigate(-1)}
          loading={isLoader}
        >
          Cancel
        </LoadingButton>
        <LoadingButton loading={isLoading} variant="outlined" onClick={onSave}>
          Generate Challan
        </LoadingButton>
      </Div>
    </Div>
  );
};

export default AlterationTable;
