import {
  ALL_DRAFT_FAIL,
  ALL_DRAFT_REQUEST,
  ALL_DRAFT_SUCCESS,
  ALL_REPORT_FAIL,
  ALL_REPORT_REQUEST,
  ALL_REPORT_SUCCESS,
} from "app/utils/constants/draftConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  draftList: [],
  reportList: [],
  TotalPage: null,
};

export const draftReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //draftlist
    case ALL_DRAFT_REQUEST:
      return {
        loading: true,
      };
    case ALL_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        draftList: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_DRAFT_FAIL:
      return {
        loading: false,
        draftList: [],
        error: action.payload,
      };
    case ALL_REPORT_REQUEST:
      return {
        loading: true,
      };
    case ALL_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        reportList: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_REPORT_FAIL:
      return {
        loading: false,
        reportList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
