import Div from "@jumbo/shared/Div/Div";
import React, { Suspense, lazy, useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllUsers } from "app/redux/actions/userAction";
import ListUserTable from "./usertable";

export default function ListUser() {
  const [searchTerm, setSearchTerm] = useState("");
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllUsers(value, 1));
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">User Management</Typography>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        <Div>
          <Button
            variant="contained"
            sx={{
              mr: 2,
              p: 1,
              pl: 4,
              pr: 4,
            }}
            onClick={() => navigate("/dashboard/user/log")}
          >
            Log
          </Button>
          {permissions?.user_create == true && (
            <Button
              variant="contained"
              sx={{ p: 1, pl: 4, pr: 4 }}
              onClick={() => navigate("/dashboard/adduser")}
            >
              Add User
            </Button>
          )}
        </Div>
      </Div>
      <Suspense fallback={<div>Loading...</div>}>
        <ListUserTable searchTerm={searchTerm} page={page} setPage={setPage} />
      </Suspense>
    </Div>
  );
}
