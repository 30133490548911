import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const SalesRouteMiddleware = ({ fallbackPath }) => {
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  if (permissions.sales_view === true || permissions.sales_edit === true) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};

export default SalesRouteMiddleware;
