import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";

export default function DispatchDetailTable({ itemDetails }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "10%",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Box No.
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "10%",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Weight
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "10%",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Qty
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                maxWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Person Name
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Person No
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                textAlign: "left",
                pl: 2,
              }}
            >
              {itemDetails?.dispatch_box_no}
            </TableCell>
            <TableCell sx={{ textAlign: "left" }}>
              {itemDetails?.dispatch_weight}
            </TableCell>
            <TableCell sx={{ textAlign: "left" }}>
              {itemDetails?.dispatch_quantity}
            </TableCell>
            <TableCell sx={{ textAlign: "left" }}>
              {itemDetails?.dispatch_contact_person_name
                ? itemDetails?.dispatch_contact_person_name
                : "-"}
            </TableCell>
            <TableCell sx={{ textAlign: "left" }}>
              {itemDetails?.dispatch_contact_person_phone_no
                ? itemDetails?.dispatch_contact_person_phone_no
                : "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
