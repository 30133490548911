import Div from '@jumbo/shared/Div'
import { Typography } from '@mui/material'
import React from 'react'
import StartqcTable from './startqcTable'

const Startqc = () => {
  return (
   <Div>
    <Typography variant="h1" sx={{ mt: -4,mb:4 }}>
        Start QC
      </Typography>
      <StartqcTable/>
   </Div>
  )
}

export default Startqc