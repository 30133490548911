import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { roleReducer } from "./roleReducer";
import { masterReducer } from "./masterReducer";
import { purchaseReducer } from "./purchaseReducer";
import { draftReducer } from "./draftReducer";
import { inventoryReducer } from "./inventoryReducer";
import { orderReducer } from "./orderReducer";
import { salesReducer } from "./salesReducer";
import { reportReducer } from "./reportReducer";

const exportReducers = (history) => {
  return combineReducers({
    userReducer: userReducer,
    roleReducer: roleReducer,
    masterReducer: masterReducer,
    purchaseReducer: purchaseReducer,
    draftReducer: draftReducer,
    inventoryReducer: inventoryReducer,
    orderReducer: orderReducer,
    salesReducer: salesReducer,
    reportReducer: reportReducer,
  });
};

export default exportReducers;
