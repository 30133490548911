import React, { useEffect } from "react";

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelectCheckmarks = ({
  names,
  setMeasurement,
  measurement,
  gender,
  setSelectedDropdown,
}) => {
  const [itemName, setItemName] = React.useState([]);
  const { pathname } = useLocation();

  function findUncommonObjects(arr1, arr2) {
    const names1 = new Set(arr1?.map((item) => item.item_master_item_name));

    const uncommonObjects = arr2?.filter(
      (item) => !names1.has(item.item_master_item_name)
    );

    // console.log(uncommonObjects);
    return uncommonObjects;
  }

  function findMissingItemIndices(array1, array2) {
    // console.log(array1, array2);
    // const itemNamesArray2 = new Set(
    //   array2.map((item) => item.item_master_item_name)
    // );
    // console.log(itemNamesArray2);
    const arr2 = array2.map((item) => item.item_master_item_name);
    const findDuplicates = arr2?.filter(
      (value, index, self) => self.indexOf(value) !== index
    );
    // console.log(findDuplicates);
    const findIndex = array1.findIndex(
      (item) => item.item_master_item_name == findDuplicates[0]
    );
    // console.log(findIndex);
    return findIndex;
    // const missingItemIndices = array1
    //   .map((item, index) =>
    //     itemNamesArray2.has(item.item_master_item_name) ? null : index
    //   )
    //   .filter((index) => index !== null);

    // console.log(missingItemIndices, itemNamesArray2);
    // return missingItemIndices;
  }

  const handleChange = (event) => {
    // const {
    //   target: { value },
    // } = event;
    if (measurement?.length == 0) {
      // console.log("111111111111111111111111");
      const finalArr = event.target.value?.map((item) => {
        return {
          item_master_item_name: item.item_master_item_name,
          item_master_measurement: item.item_master_measurement,
          order_item_no: item.order_item_no,
        };
      });

      setMeasurement(finalArr);
    } else {
      // console.log(measurement, event.target.value);
      let uncommonObjects = findUncommonObjects(
        measurement,
        event.target.value
      );
      // console.log(uncommonObjects);
      if (uncommonObjects?.length > 0) {
        // console.log("2222222222222222222222222");
        const finalArr = uncommonObjects?.map((item) => {
          return {
            item_master_item_name: item.item_master_item_name,
            item_master_measurement: item.item_master_measurement,
            order_item_no: item.order_item_no,
          };
        });
        setMeasurement([...measurement, finalArr[0]]);
        uncommonObjects = [];
      } else {
        // console.log("EEEEEEEEEEEEEEEE");
        // console.log(measurement, event.target.value);
        const isItemExists = findMissingItemIndices(
          measurement,
          event.target.value
        );

        if (isItemExists >= 0) {
          const data = [...measurement];
          data.splice(isItemExists, 1);
          setMeasurement(data);
        }
      }
    }
    setItemName(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  useEffect(() => {
    if (pathname == "/dashboard/order/editOrder") {
      // console.log(measurement);
      setItemName(measurement?.map((item) => item));
    } else {
      setItemName([]);
    }
  }, [gender]);

  useEffect(() => {
    setSelectedDropdown(itemName);
  }, [itemName]);

  useEffect(() => {
    setItemName(measurement?.map((item) => item));
  }, [measurement]);

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      {/* <InputLabel id="demo-multiple-checkbox-label">Select Item</InputLabel> */}
      <Typography variant="h5">Select Items:</Typography>
      <Select
        size="small"
        multiple
        value={itemName}
        onChange={(e) => {
          handleChange(e);
        }}
        input={<OutlinedInput />}
        renderValue={(selected) =>
          selected.map((item) => item.item_master_item_name).join(", ")
        }
      >
        {names.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox
              checked={itemName.some(
                (selectedItem) =>
                  selectedItem.item_master_item_name ===
                  name.item_master_item_name
              )}
            />
            <ListItemText primary={name.item_master_item_name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectCheckmarks;
