import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getPackingOrderList,
  getShopPackingOrderList,
} from "app/redux/actions/orderAction";
import { getSizeList } from "app/services/apis/ListApi/SizeList";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";
import { getFinishItemNameList } from "app/services/apis/ListApi/itemNameList";
import { getjobWorkIdList } from "app/services/apis/ListApi/jobWorkIdList";
import { getOrderNoList } from "app/services/apis/ListApi/orderNoList";
import { getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import { downloadPackingDoneReport } from "app/services/apis/ReportApis/Packing/PackingDoneReports";
import { downloadPackingPendingReport } from "app/services/apis/ReportApis/Packing/PackingPendingReports";
import { downloadShopPackingDoneReport } from "app/services/apis/ReportApis/Packing/ShopPackingDoneReports";
import { downloadShopPackingPendingReport } from "app/services/apis/ReportApis/Packing/ShopPackingPendingReports";
import { dateFun } from "app/utils/constants/functions";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ShopPakingDoneListTable from "../shopPacking/packingDoneTable";
import ShopPackingPendingListTable from "../shopPacking/packingPendingListTable";
import PakingDoneListTable from "./normalPacking/packingDoneTable";
import PackingPendingListTable from "./normalPacking/packingPendingListTable";

const PackingOrderList = () => {
  const [inputs, setInputs] = useState({});
  console.log(inputs, "inputs");
  const [searchTerm, setSearchTerm] = useState("");
  const [nav, setNav] = useState("Packing Pending");
  const [value, setValue] = React.useState(0);
  const [portNoNameList, setPortNoNameList] = useState([]);
  const [orderNoList, setOrderNoList] = useState([]);
  const [jobWorkidList, setJobWorkidList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [orderType, setOrderType] = useState("Normal Order");
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const [page, setPage] = useState(1);
  const [itemNameList, setItemNameList] = useState([]);
  const [sizeList, setSizeList] = useState([]);

  const dispatch = useDispatch();

  const handleFilter = () => {
    setPage(1);
    if (nav == "Packing Pending") {
      dispatch(getPackingOrderList("", inputs, 1, "packingPending"));
    } else if (nav == "Packing Done") {
      dispatch(getPackingOrderList("", inputs, 1, "packingDone"));
    } else if (nav == "Packing Pending - Shop Order") {
      dispatch(getShopPackingOrderList("", inputs, 1, "shopPackingPending"));
    } else if (nav == "Packing Done - Shop Order") {
      dispatch(getShopPackingOrderList("", inputs, 1, "shopPackingDone"));
    }
  };

  const handleClear = () => {
    setPage(1);
    if (nav == "Packing Pending") {
      dispatch(getPackingOrderList("", "", 1, "packingPending"));
    } else if (nav == "Packing Done") {
      dispatch(getPackingOrderList("", "", 1, "packingDone"));
    } else if (nav == "Packing Pending - Shop Order") {
      dispatch(getShopPackingOrderList("", "", 1, "shopPackingPending"));
    } else if (nav == "Packing Done - Shop Order") {
      dispatch(getShopPackingOrderList("", "", 1, "shopPackingDone"));
    }

    setInputs({
      order_port_no_and_name: null,
      order_no: null,
      order_item_status: null,
      order_item_job_work_id: null,
      order_item_factory_name: null,
      order_item_qc_status: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
      packing_create_date: null,
      shop_order_item_size: null,
      shop_order_item_name: null,
    });
    setResetAutocomplete((prev) => !prev);
  };

  const handleSearch = (value) => {
    if (nav == "Packing Pending") {
      dispatch(getPackingOrderList(value, "", "", "packingPending"));
    } else if (nav == "Packing Done") {
      dispatch(getPackingOrderList(value, "", "", "packingDone"));
    } else if (nav == "Packing Pending - Shop Order") {
      dispatch(getShopPackingOrderList(value, "", "", "shopPackingPending"));
    } else if (nav == "Packing Done - Shop Order") {
      dispatch(getShopPackingOrderList(value, "", "", "shopPackingDone"));
    }
  };

  useEffect(() => {
    if (nav == "Packing Pending") {
      dispatch(getPackingOrderList("", "", 1, "packingPending"));
    } else if (nav == "Packing Done") {
      dispatch(getPackingOrderList("", "", 1, "packingDone"));
    } else if (nav == "Packing Pending - Shop Order") {
      dispatch(getShopPackingOrderList("", "", 1, "shopPackingPending"));
    } else if (nav == "Packing Done - Shop Order") {
      dispatch(getShopPackingOrderList("", "", 1, "shopPackingDone"));
    }

    setInputs({
      order_no: null,
      order_item_status: null,
      shop_order_item_size: null,
      shop_order_item_name: null,
      order_port_no_and_name: null,
      order_item_job_work_id: null,
      order_item_factory_name: null,
      order_item_qc_status: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
      packing_create_date: null,
    });
    setResetAutocomplete((prev) => !prev);
  }, [nav]);

  // navs and tab functionality
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    setPage(1);
    setValue(newValue);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(async () => {
    if (orderType == "Normal Order") {
      setOrderNoList(await getOrderNoList());
    } else {
      setOrderNoList(await getOrderNoList("shop"));
    }
    setPortNoNameList(await getPortListNoPerm());
    setJobWorkidList(await getjobWorkIdList());
    setFactoryList(await getFactoryNameList());
    setItemNameList(await getFinishItemNameList());
    setSizeList(await getSizeList());
  }, [orderType]);
  return (
    <Div>
      <Typography variant="h1" sx={{ mt: -4 }}>
        Packing Order
      </Typography>

      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Port No & Name</Typography>
          <Autocomplete
            freeSolo
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={portNoNameList || []}
            value={inputs.order_port_no_and_name}
            getOptionLabel={(option) => option}
            onInputChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_port_no_and_name: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Order No</Typography>
          <Autocomplete
            freeSolo
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={orderNoList || []}
            value={inputs.order_no}
            getOptionLabel={(option) => option}
            onInputChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_no: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        {orderType != "Shop Order" && (
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Item Status</Typography>
            <Select
              size="small"
              sx={{ width: "100%" }}
              value={inputs.order_item_status || ""}
              name="order_item_status"
              onChange={handleChange}
            >
 
              <MenuItem value={"QC Pass"}>QC Pass</MenuItem>

              <MenuItem value={"Dispatch"}>Dispatch</MenuItem>
              <MenuItem value={"Delivered"}>Delivered</MenuItem>
            </Select>
          </Div>
        )}
        {orderType != "Shop Order" && (
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Job Work ID</Typography>
            <Autocomplete
              freeSolo
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={jobWorkidList || []}
              value={inputs.order_item_job_work_id}
              getOptionLabel={(option) => option}
              onInputChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_item_job_work_id: newValue ? newValue : null,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>
        )}
        {orderType == "Shop Order" && (
          <Div sx={{ width: "25%" }}>
            <Typography variant="h5">Item Name</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={itemNameList || []}
              // value={}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) =>
                setInputs((values) => ({
                  ...values,
                  shop_order_item_name: newValue ? newValue : null,
                }))
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  // placeholder="KC Fabric Code"
                />
              )}
            />
          </Div>
        )}
        {orderType == "Shop Order" && (
          <Div sx={{ width: "25%" }}>
            <Typography variant="h5">Size</Typography>

            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={sizeList || []}
              // value={}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) =>
                setInputs((values) => ({
                  ...values,
                  shop_order_item_size: newValue ? newValue : null,
                }))
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  // placeholder="KC Fabric Code"
                />
              )}
            />
          </Div>
        )}
        {orderType != "Shop Order" && (
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Factory</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={factoryList || []}
              defaultValue={inputs?.order_item_factory_name}
              getOptionLabel={(option) => option?.factory_name}
              onChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_item_factory_name: newValue?.factory_name
                    ? newValue?.factory_name
                    : null,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.factory_name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>
        )}
        {/* {nav == "Packing Pending - Shop Order" ||
          (nav == "Packing Pending" && (
            <Div>
              <Typography variant="h5">Delivery Date Range</Typography>
              <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Div sx={{ width: "45%" }}>
                    <DatePicker
                      maxDate={dayjs()}
                      format="DD/MM/YY"
                      value={inputs?.order_est_delivery_date_from}
                      error={!inputs?.order_est_delivery_date_from}
                      onChange={(newDate) =>
                        (inputs.order_est_delivery_date_from = dateFun(newDate))
                      }
                      sx={{
                        width: "100%",
                        "&.MuiTextField-root": {
                          height: "39px",
                          flexDirection: "unset",
                        },
                      }}
                    />
                  </Div>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Div sx={{ width: "45%" }}>
                    <DatePicker
                      maxDate={dayjs()}
                      format="DD/MM/YY"
                      value={inputs.order_est_delivery_date_to}
                      error={!inputs.order_est_delivery_date_to}
                      onChange={(newDate) =>
                        (inputs.order_est_delivery_date_to = dateFun(newDate))
                      }
                      sx={{
                        width: "100%",
                        "&.MuiTextField-root": {
                          height: "39px",
                          flexDirection: "unset",
                        },
                      }}
                    />
                  </Div>
                </LocalizationProvider>
              </Div>
            </Div>
          ))} */}
        {(nav === "Packing Done - Shop Order" || nav === "Packing Done") && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Packing Date</Typography>
              <DatePicker
                key={resetAutocomplete ? "reset1" : "normal1"}
                format="DD/MM/YYYY"
                value={inputs?.packing_create_date}
                error={!inputs?.packing_create_date}
                onChange={(newDate) =>
                  (inputs.packing_create_date = dateFun(newDate))
                }
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": {
                    padding: 1,
                  },
                }}
              />
            </Div>
          </LocalizationProvider>
        )}
      </Div>

      <Div sx={{ display: "flex", alignItems: "center", mt: 4 }}>
        <Button
          size="small"
          variant="outlined"
          sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }}
          onClick={handleFilter}
        >
          Apply
        </Button>
        <Button
          size="small"
          variant="outlined"
          sx={{ p: 1, pl: 4, pr: 4 }}
          onClick={handleClear}
        >
          Clear
        </Button>
      </Div>

      {/* search */}
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          mt: 3.5,
        }}
      >
        <Div sx={{ display: "flex", columnGap: 1 }}>
          <Div>
            <TextField
              width="23px"
              size="small"
              id="search"
              type="search"
              label="Search"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handleSearch(e.target.value);
              }}
              sx={{ width: 300, mt: "auto" }}
              InputProps={{
                endAdornment: (
                  <Div sx={{ cursor: "pointer" }}>
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  </Div>
                ),
              }}
            />
          </Div>
          <Div sx={{ width: "50%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Packing Type
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  setOrderType(e.target.value);
                  if (e.target.value == "Normal Order") {
                    setNav("Packing Pending");
                    setValue(0);
                  } else {
                    setNav("Packing Pending - Shop Order");
                    setValue(0);
                  }
                }}
                value={orderType}
                label="Packing Type"
                size="small"
              >
                <MenuItem value={"Normal Order"}>{"Normal Order"}</MenuItem>
                <MenuItem value={"Shop Order"}>{"Shop Order"}</MenuItem>
              </Select>
            </FormControl>
          </Div>
        </Div>
        {orderType == "Normal Order" ? (
          <Div sx={{ display: "flex", gap: 2 }}>
            {(nav == "Packing Pending" || nav == "Packing Done") && (
              <Button
                variant="contained"
                onClick={async () => {
                  if (nav == "Packing Pending") {
                    const data = await downloadPackingPendingReport(inputs);
                    window.open(data);
                  }
                  if (nav == "Packing Done") {
                    const data = await downloadPackingDoneReport(inputs);
                    window.open(data);
                  }
                }}
              >
                Export CSV
              </Button>
            )}
          </Div>
        ) : (
          <Div sx={{ display: "flex", gap: 2 }}>
            {(nav == "Packing Pending - Shop Order" ||
              nav == "Packing Done - Shop Order") && (
              <Button
                variant="contained"
                onClick={async () => {
                  if (nav == "Packing Pending - Shop Order") {
                    const data = await downloadShopPackingPendingReport(inputs);
                    window.open(data);
                  }

                  if (nav == "Packing Done - Shop Order") {
                    const data = await downloadShopPackingDoneReport(inputs);
                    window.open(data);
                  }
                }}
              >
                Export CSV
              </Button>
            )}
          </Div>
        )}
      </Div>
      {orderType == "Normal Order" ? (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab
                label="Packing Pending"
                {...a11yProps(0)}
                onClick={() => setNav("Packing Pending")}
              />
              <Tab
                label="Packing Done"
                {...a11yProps(1)}
                onClick={() => setNav("Packing Done")}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <PackingPendingListTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
              inputs={inputs}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <PakingDoneListTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
              inputs={inputs}
            />
          </CustomTabPanel>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab
                label="Packing Pending - Shop Order"
                {...a11yProps(0)}
                onClick={() => setNav("Packing Pending - Shop Order")}
              />
              <Tab
                label="Packing Done - Shop Order"
                {...a11yProps(1)}
                onClick={() => setNav("Packing Done - Shop Order")}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <ShopPackingPendingListTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
              inputs={inputs}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ShopPakingDoneListTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
              inputs={inputs}
            />
          </CustomTabPanel>
        </Box>
      )}
    </Div>
  );
};

export default PackingOrderList;
