import Div from "@jumbo/shared/Div";
import { Button, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { dateFun } from "app/utils/constants/functions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getQCReport } from "app/redux/actions/reportAction";
import { downloadQCAllReport } from "app/services/apis/ReportApis/AllReport/qc";

const QCReportsFilter = () => {
  const [inputs, setInputs] = useState({});
  const { newOrderReport } = useSelector((state) => state.reportReducer);
  const dispatch = useDispatch();

  const handleFilter = () => {
    dispatch(getQCReport("", inputs, ""));
  };

  const handleClear = () => {
    setInputs({});
    dispatch(getQCReport("", {}));
  };

  return (
    <>
      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        <Div sx={{ width: "50%" }}>
          <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ flex: "1 1 40%" }}>
                <Typography variant="h5">From</Typography>
                <DatePicker
                  format="DD/MM/YY"
                  value={inputs?.from || null}
                  error={!inputs?.from}
                  onChange={(newDate) => (inputs.from = dateFun(newDate))}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ flex: "1 1 40%" }}>
                <Typography variant="h5">To</Typography>
                <DatePicker
                  format="DD/MM/YY"
                  value={inputs?.to || null}
                  error={!inputs?.to}
                  onChange={(newDate) => (inputs.to = dateFun(newDate))}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
          </Div>
        </Div>
      </Div>

      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          justifyContent: "space-between",
        }}
      >
        <Div>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }}
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Div>
        <Button
          disabled={newOrderReport?.length == 0}
          variant="contained"
          onClick={async () => {
            const data = await downloadQCAllReport(inputs);
            window.open(data);
          }}
        >
          Export CSV
        </Button>
      </Div>
    </>
  );
};

export default QCReportsFilter;
