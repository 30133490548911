import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
} from "@mui/material";
import "./factory.css";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllFactory } from "app/redux/actions/masterAction";
import FullScreenLoader from "app/components/ListingPageLoader";
export default function ListFactoryTable({ searchTerm, setPage, page }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  const { factoryMaster, TotalPage,loading } = useSelector(
    (state) => state.masterReducer
  );
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = factoryMaster?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "factory_name") {
      aValue = a.factory_name.toLowerCase();
      bValue = b.factory_name.toLowerCase();
    } else if (orderBy == "factory_pan_no") {
      aValue = a.factory_pan_no.toLowerCase();
      bValue = b.factory_pan_no.toLowerCase();
    } else if (orderBy == "factory_gst_no") {
      aValue = a.factory_gst_no.toLowerCase();
      bValue = b.factory_gst_no.toLowerCase();
    } else if (orderBy == "factory_address") {
      aValue = a.factory_address.toLowerCase();
      bValue = b.factory_address.toLowerCase();
    } else if (orderBy == "factory_city") {
      aValue = a.factory_city.toLowerCase();
      bValue = b.factory_city.toLowerCase();
    } else if (orderBy == "factory_state") {
      aValue = a.factory_state.toLowerCase();
      bValue = b.factory_state.toLowerCase();
    } else if (orderBy == "factory_contact_person_name") {
      aValue = a.factory_contact_person_name.toLowerCase();
      bValue = b.factory_contact_person_name.toLowerCase();
    } else if (orderBy == "factory_contact_person_email_id") {
      aValue = a.factory_contact_person_email_id.toLowerCase();
      bValue = b.factory_contact_person_email_id.toLowerCase();
    } else if (orderBy === "factory_no") {
      // Assuming factory_no is a numerical value
      aValue = parseInt(a.factory_no, 10);
      bValue = parseInt(b.factory_no, 10);
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/master/factory/edit", { state: menuItem?.data });
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllFactory(searchTerm, newPage));
  };

  return (
    <>      {loading && <FullScreenLoader />}
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                // minWidth: "70px",
                verticalAlign: "middle",
                maxWidth: "100px",
              }}
            >
              <TableSortLabel
                active={orderBy === "factory_no"}
                direction={order}
                onClick={() => handleSort("factory_no")}
                sx={{
                  //   maxWidth: "70px",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Factory No
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "factory_name"}
                direction={order}
                onClick={() => handleSort("factory_name")}
                sx={{
                  //   maxWidth: "70px",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Factory Name
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === "factory_pan_no"}
                direction={order}
                onClick={() => handleSort("factory_pan_no")}
                sx={{
                  //   maxWidth: "70px",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                PAN
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === "factory_gst_no"}
                direction={order}
                onClick={() => handleSort("factory_gst_no")}
                sx={{
                  //   maxWidth: "70px",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                GST
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === "factory_address"}
                direction={order}
                onClick={() => handleSort("factory_address")}
                sx={{
                  //   maxWidth: "70px",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Address
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",
                minWidth: "100px",
              }}
            >
              <TableSortLabel
                active={orderBy === "factory_city"}
                direction={order}
                onClick={() => handleSort("factory_city")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                City
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "100px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "factory_state"}
                direction={order}
                onClick={() => handleSort("factory_state")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                State
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "factory_pincode"}
                direction={order}
                onClick={() => handleSort("factory_pincode")}
                sx={{
                  maxWidth: "50px",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Pincode
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",

                maxWidth: "180px",
              }}
            >
              <TableSortLabel
                active={orderBy === "factory_contact_person_name"}
                direction={order}
                onClick={() => handleSort("factory_contact_person_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                  maxWidth: "180px",
                }}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",
                color: "white",
                maxWidth: "150px",
              }}
            >
              Contact No.
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === "factory_contact_person_email_id"}
                direction={order}
                onClick={() => handleSort("factory_contact_person_email_id")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                  maxWidth: "180px",
                }}
              >
                Email ID
              </TableSortLabel>
            </TableCell>
            {permissions?.factory_master_edit == true && (
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>{row.factory_no}</TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.factory_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.factory_pan_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.factory_gst_no}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "180px",
                  wordWrap: "break-word",
                }}
              >
                {row.factory_address}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {row.factory_city}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.factory_state}
              </TableCell>
              <TableCell>{row.factory_pincode}</TableCell>

              <TableCell sx={{ textAlign: "left" }}>
                {row.factory_contact_person_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.factory_contact_person_country_code}{" "}
                {row.factory_contact_person_phone_no}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "120px",
                  wordWrap: "break-word",
                }}
              >
                {row.factory_contact_person_email_id}
              </TableCell>
              {permissions?.factory_master_edit == true && (
                <TableCell sx={{ textAlign: "left" }}>
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={[
                      {
                        icon: <EditIcon />,
                        title: "Edit Factory Details",
                        action: "edit",
                        data: row,
                      },
                    ]}
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1}
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
    </TableContainer>
    </>
  );
}
