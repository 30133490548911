import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { updateCosting } from "app/services/apis/updateCosting";
import { addCosting } from "app/services/apis/addCosting";
import { supplierList } from "app/services/apis/supplierList";
import { getFabricDropdown } from "app/services/apis/ListApi/fabricList";
import { getLatestKFC } from "app/services/apis/getLatestKFC";
import { LoadingButton } from "@mui/lab";

export default function AddCosting() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [fabricList, setFabricList] = useState([]);
  const [fabricType, setFabricType] = useState("");

  const factory = {
    supplier_master_id: state?.supplier_master?.id
      ? state.supplier_master.id
      : "",
    fabric_master_id: state?.fabric_master?.id ? state?.fabric_master?.id : "",
    mrp: state?.mrp ? state?.mrp : "",
    cost: state?.cost ? state?.cost : "",
    job_work_cost: state?.job_work_cost ? state?.job_work_cost : "",
    freight: state?.freight ? state?.freight : "",
    profit_percentage: state?.profit_percentage ? state?.profit_percentage : "",
    usd_rate: state?.usd_rate ? state?.usd_rate : "",
    usd_sale_price: state?.usd_sale_price ? state?.usd_sale_price : "",
    kc_fabric_code: state?.kc_fabric_code ? state.kc_fabric_code : "",
  };
  const validationSchema = yup.object({
    supplier_master_id: yup
      .string("Enter Supplier Name")
      .required("Supplier Name is required"),
    fabric_master_id: yup
      .string("Enter Supplier Fabric Code")
      .required("Supplier Fabric Code is required"),
    mrp: yup
      .number()
      .typeError("MRP must be a number")
      .required("MRP is Required"),
    cost: yup
      .number()
      .typeError("Cost must be a number")
      .required("Cost is Required"),
    job_work_cost: yup
      .number()
      .typeError("Job Work Cost must be a number")
      .required("Job Work Cost is Required"),
    freight: yup
      .number()
      .typeError("Freight must be a number")
      .required("Freight is Required"),
    profit_percentage: yup
      .number()
      .typeError("Profit Percentage must be a number")
      .required("Profit Percentage is Required"),
    usd_rate: yup
      .number()
      .typeError("USD Rate must be a number")
      .required("USD Rate is Required"),
    usd_sale_price: yup
      .number()
      .typeError("USD Sale Price must be a number")
      .required("USD Sale Price is Required"),
  });

  useEffect(async () => {
    const data = await supplierList();
    if (data?.status == 200) {
      setSupplierNameList(data.data.result);
    }
  }, []);

  const handleSupplier = async (id) => {
    setFabricList(await getFabricDropdown(id));
  };

  const handleFabricChange = async (id) => {
    const data = await getLatestKFC(id);
    setFabricType(data?.fabricType);
    return data.kc_fabric_code;
  };

  const onUserSave = async (values) => {
    setSubmitting(true);
    const formval = {
      ...values,
      kc_fabric_code:
        pathname == "/master/costing/edit"
          ? values.kc_fabric_code
          : `${values.kc_fabric_code}-${values.usd_sale_price}`,
    };
    if (pathname == "/master/costing/edit") {
      const data = await updateCosting(formval, state.id);

      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Costing Edited Successfully",
          text: "",
        });
        navigate("/dashboard/master/costing");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    } else {
      const data = await addCosting(formval);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Costing Added Successfully",
          text: "",
        });
        navigate("/dashboard/master/costing");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  const handleUSDRate = (values, setFieldValue) => {
    const cost = parseInt(values?.mrp) * (fabricType == "SH" ? 1.8 : 3.5);
    // console.log(cost);
    if (cost !== NaN) {
      setFieldValue("cost", cost.toFixed(2));
    }
    // console.log(cost);
    const totalCost =
      parseInt(values.freight) +
      parseInt(values.job_work_cost) +
      parseInt(values.cost);
    const profitAmount = (totalCost * parseInt(values.profit_percentage)) / 100;
    const totalSP = profitAmount + totalCost;
    const usdSP = parseInt(totalSP) / parseInt(values.usd_rate);

    if (usdSP != NaN || usdSP != undefined) {
      setFieldValue("usd_sale_price", parseFloat(usdSP.toFixed(2)));
    }
  };

  useEffect(async () => {
    setFabricList(await getFabricDropdown(state?.supplier_master?.id));
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/master/costing/add" ? "Add New Costing" : "Edit Costing"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={factory}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
          // onSubmit={(values) => console.log(values)}
        >
          {({ setFieldValue, values }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div sx={{ display: "flex", columnGap: 5 }}>
                  {console.log(values, "dgfyugyudgfusdgbjgsjgv")}
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Supplier Name</Typography>

                    <Autocomplete
                      size="small"
                      sx={{ width: "100%" }}
                      disabled={
                        pathname == "/master/costing/edit" ? true : false
                      }
                      value={
                        supplierNameList?.find(
                          (item) => item.id === values?.supplier_master_id
                        ) || null
                      }
                      name="supplier_master_id"
                      onChange={async (e, value) => {
                        setFieldValue("supplier_master_id", value?.id || "");
                        handleSupplier(value?.id);
                      }}
                      options={supplierNameList || []}
                      getOptionLabel={(option) => option.supplier_name || ""}
                      renderInput={(params) => (
                        <TextField {...params} label="Select" />
                      )}
                    />
                    <ErrorMessage
                      name="supplier_master_id"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Supplier Fabric Code</Typography>
                    {/* <Select
                      size="small"
                      sx={{ width: "100%" }}
                      disabled={
                        pathname == "/master/costing/edit" ? true : false
                      }
                      name="fabric_master_id"
                      onChange={async (e) => {
                        setFieldValue("fabric_master_id", e.target.value);
                        setFieldValue(
                          "kc_fabric_code",
                          await handleFabricChange(e.target.value)
                        );
                      }}
                      value={values?.fabric_master_id}
                    >
                      {fabricList?.map((item) => {
                        return (
                          <MenuItem value={item.id}>
                            {item?.supplier_fabric_code}
                          </MenuItem>
                        );
                      })}
                    </Select> */}

                    <Autocomplete
                      size="small"
                      sx={{ width: "100%" }}
                      disabled={
                        pathname == "/master/costing/edit" ? true : false
                      }
                      name="fabric_master_id"
                      onChange={async (e, value) => {
                        setFieldValue("fabric_master_id", value?.id || "");
                        setFieldValue(
                          "kc_fabric_code",
                          value ? await handleFabricChange(value.id) : ""
                        );
                      }}
                      value={
                        fabricList?.find(
                          (item) => item.id === values?.fabric_master_id
                        ) || null
                      }
                      options={fabricList || []}
                      getOptionLabel={(option) =>
                        option.supplier_fabric_code || ""
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Select" />
                      )}
                    />
                    <ErrorMessage
                      name="fabric_master_id"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                </Div>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                    rowGap: 2,
                    mt: 2,
                  }}
                >
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">MRP</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      name="mrp"
                      value={values.mrp}
                      onChange={(e) => {
                        const newMrpValue = e.target.value;
                        setFieldValue("mrp", newMrpValue);

                        // Update the 'values' object with the latest 'mrp' value
                        const newValues = {
                          ...values,
                          mrp: newMrpValue,
                        };
                        handleUSDRate(newValues, setFieldValue);
                      }}
                    />
                    <ErrorMessage
                      name="mrp"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Cost</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      name="cost"
                      disabled
                      value={values.cost}
                    />
                  </Div>

                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Job Work Cost</Typography>
                    <TextField
                      name="job_work_cost"
                      size="small"
                      fullWidth
                      value={values.job_work_cost}
                      onChange={(e) => {
                        const jobworkcost = e.target.value;
                        setFieldValue("job_work_cost", jobworkcost);

                        // Update the 'values' object with the latest 'mrp' value
                        const newValues = {
                          ...values,
                          job_work_cost: jobworkcost,
                        };
                        handleUSDRate(newValues, setFieldValue);
                      }}
                    />
                    <ErrorMessage
                      name="job_work_cost"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Freight</Typography>
                    <TextField
                      size="small"
                      fullWidth
                      name="freight"
                      value={values.freight}
                      onChange={(e) => {
                        const freightValue = e.target.value;
                        setFieldValue("freight", freightValue);
                        // Update the 'values' object with the latest 'mrp' value
                        const newValues = {
                          ...values,
                          freight: freightValue,
                        };
                        handleUSDRate(newValues, setFieldValue);
                      }}
                    />
                    <ErrorMessage
                      name="freight"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Profit Percentage</Typography>
                    <TextField
                      name="profit_percentage"
                      size="small"
                      fullWidth
                      value={values.profit_percentage}
                      onChange={(e) => {
                        const profitPer = e.target.value;
                        setFieldValue("profit_percentage", profitPer);

                        // Update the 'values' object with the latest 'mrp' value
                        const newValues = {
                          ...values,
                          profit_percentage: profitPer,
                        };
                        handleUSDRate(newValues, setFieldValue);
                      }}
                    />
                    <ErrorMessage
                      name="profit_percentage"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">USD Rate</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      name="usd_rate"
                      value={values.usd_rate}
                      onChange={(e) => {
                        const usdRate = e.target.value;
                        setFieldValue("usd_rate", usdRate);

                        // Update the 'values' object with the latest 'mrp' value
                        const newValues = {
                          ...values,
                          usd_rate: usdRate,
                        };
                        handleUSDRate(newValues, setFieldValue);
                      }}
                    />
                    <ErrorMessage
                      name="usd_rate"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">USD Sale Price</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      name="usd_sale_price"
                      value={values.usd_sale_price}
                      onChange={(e) => {
                        setFieldValue("usd_sale_price", e.target.value);
                      }}
                      status={pathname == "/master/costing/edit" && true}
                    />
                  </Div>

                  {/* <FormTextField name="color_code" label="Color Code" /> */}
                  {pathname == "/master/costing/edit" ? (
                    <Typography variant="h5" sx={{ mt: 5, fontWeight: "600" }}>
                      Kenny's Fabric Code: {values.kc_fabric_code}
                    </Typography>
                  ) : (
                    <Typography variant="h5" sx={{ mt: 5, fontWeight: "600" }}>
                      Kenny's Fabric Code: {values.kc_fabric_code}-
                      {values.usd_sale_price == NaN ? 0 : values.usd_sale_price}
                    </Typography>
                  )}
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/master/costing");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
