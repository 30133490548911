import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  clearPurchaseItemsByPONo,
  getPurchaseItemsByPONo,
} from "app/redux/actions/inventoryAction";
import { currentdate, dateFun } from "app/utils/constants/functions";
import dayjs from "dayjs";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoIcon from "@mui/icons-material/Info";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ItemDetails from "../ActionComponent/itemDetails";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import Swal from "sweetalert2";
import { getGstList } from "app/services/apis/ListApi/gstList";
import { supplierList } from "app/services/apis/supplierList";
import { getGstListNoPem } from "app/services/apis/ListApi/gst";

const AddFinishedGoods = () => {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("supplier_name");
  const [poDetails, setPODetails] = useState({});
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [gstList, setGstList] = useState([]);
  const [poList, setPOList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [resetAutocomplete, setResetAutocomplete] = useState(false);

  const [inputs, setInputs] = useState({
    purchase_order_no: "",
    finished_invoice_no: "",
    finished_invoice_date: dateFun(currentdate),
    finished_discount: "",
    finished_discount_value: "",
    finished_invoice_value: "",
    finished_igst: null,
    finished_sgst: null,
    finished_cgst: null,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { purchase_items, error } = useSelector(
    (state) => state.inventoryReducer
  );
  // console.log(purchase_items);
  // Replace this with your actual data

  const headings = [
    "Supplier Name",
    "Supplier Address",
    "Supplier City Pincode",
    "Supplier GST",
    "Contact Person Name",
    "Contact Person Phone No",
    "Contact Person Email ID",
  ];

  const itemDetailsheadings = [
    "Item Name",
    "Size",
    "Qty",
    "Units",
    "Rate per Unit",
    "Amount",
    "GST%",
    "GST",
    "Total Amount",
    "Received Qty Status",
    "Action",
  ];

  const sortedData = poDetails?.purchase_items_details?.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handlePurchaseOrderNo = async (newvalue) => {
    inputs.purchase_order_no = newvalue ? newvalue : "";
    if (newvalue != null) {
      dispatch(getPurchaseItemsByPONo(newvalue));
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name == "finished_discount") {
      const discountValue = (inputs.assessible * (Number(value) / 100)).toFixed(
        2
      );
      setInputs((values) => ({
        ...values,
        finished_discount_value: discountValue,
      }));
    } else if (name == "gst") {
      let equalValue =
        poDetails?.supplier_master?.supplier_state == "Maharashtra"
          ? (value / 2).toFixed(2)
          : 0;
      const gstValue =
        (inputs.assessible - inputs.finished_discount_value) *
        (Number(value) / 100);
      const invoiceValue =
        inputs.assessible - inputs.finished_discount_value + gstValue;
      setInputs((values) => ({
        ...values,
        finished_invoice_value: Math.round(invoiceValue),
        gst_value: Number(gstValue).toFixed(2),
        finished_sgst: equalValue > 0 ? equalValue : 0,
        finished_cgst: equalValue > 0 ? equalValue : 0,
        finished_igst: equalValue == 0 ? value : 0,
      }));
    }
    setInputs((values) => ({
      ...values,
      [name]:
        name === "finished_invoice_no" || name === "purchase_order_no"
          ? value
          : Number(value),
    }));
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "itemDetails":
        setInfoModalOpen(true);
        setSelectedRowData(menuItem.data);
        break;
    }
  };
  // console.log(purchase_items);
  const submitForm = async () => {
    try {
      const itemData = [];
      for (let i of purchase_items.purchase_items_details) {
        if (JSON.parse(localStorage.getItem(i.id))) {
          itemData.push(...JSON.parse(localStorage.getItem(i.id)));
        }
      }
      for (let key in inputs) {
        if (inputs[key] === "") {
          let error = "";
          if (key == "finished_invoice_no") {
            error = "Invoice Number is Required";
          } else if (key == "finished_invoice_date") {
            error = "Invoice Date is Required";
          } else if (key == "finished_discount") {
            error = "Discount is Required";
          } else if (key == "finished_discount_value") {
            error = "Discount Value is Required";
          } else if (key == "finished_invoice_value") {
            error = "Invoice Value is Required";
          } else if (key == "purchase_order_no") {
            error = "Purchase Order No is Required";
          }
          return Swal.fire({
            title: error,
            icon: "error",
          });
        }
      }
      setLoading(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/api/finished-good-inventory/add-finished-good-inventory`,
        {
          purchase_order_id: purchase_items?.id,
          finished_item_details: itemData.length > 0 ? itemData : [],
          ...inputs,
          finished_assessible: inputs.assessible,
          finished_gst: Number(inputs.gst_value),
          finished_gst_percentage: inputs.gst,
        },
        config
      );
      // console.log(data);
      if (data?.status == 200) {
        localStorage.clear();
        Swal.fire({
          icon: "success",
          title: "Finished Goods Added Successfully",
        });
        navigate("/dashboard/inventory");
      }
    } catch (error) {
      if (error?.response) {
        Swal.fire({
          icon: "error",
          title: error?.response?.data?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const calculateAssessible = () => {
    if (purchase_items?.purchase_items_details?.length > 0) {
      const itemData = [];
      for (let i of purchase_items?.purchase_items_details) {
        if (JSON.parse(localStorage.getItem(i.id))) {
          itemData.push(...JSON.parse(localStorage.getItem(i.id)));
        }
      }
      const totalTakaReceived = {};
      for (const item of itemData) {
        const kcFabricCode = item.po_item_details_id;
        const takaReceivedQuantity = item.finished_item_recieved_quantity;

        if (totalTakaReceived[kcFabricCode]) {
          totalTakaReceived[kcFabricCode] += takaReceivedQuantity;
        } else {
          totalTakaReceived[kcFabricCode] = takaReceivedQuantity;
        }
      }
      let final_assessible = [];
      for (let i in totalTakaReceived) {
        const calculatedData = purchase_items?.purchase_items_details.filter(
          (item) => {
            return item.id == i;
          }
        );
        // console.log(calculatedData);
        final_assessible.push(
          Number(totalTakaReceived[i]) * Number(calculatedData[0].rate_per_unit)
        );
      }
      const assessible_value = final_assessible.reduce((a, item) => {
        return a + item;
      }, 0);
      // console.log(assessible_value);
      setInputs((values) => ({
        ...values,
        assessible: assessible_value,
      }));
    }
  };

  const handleSupplier = async (newvalue) => {
    setResetAutocomplete((prev) => !prev);
    setPOList([]);
    inputs.supplier_name = newvalue?.supplier_name
      ? newvalue?.supplier_name
      : "";
    if (newvalue != null) {
      try {
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const data = await axios.get(
          `${
            process.env.REACT_APP_URL
          }/api/inventory/list/get-order-no-based-on-supplier?id=${
            newvalue.id
          }&purchase_order_type=${"Finished Goods".replace(/ /g, "+")}`,
          config
        );
        if (data?.status == 200) {
          setPOList(data?.data?.result);
        }
      } catch (error) {
        // console.log(error);
        return error?.response?.data?.result;
      }
    }
  };

  useEffect(async () => {
    dispatch(clearPurchaseItemsByPONo());
    setGstList(await getGstListNoPem());
    const data = await supplierList();
    if (data?.status == 200) {
      setSupplierNameList(data.data.result);
    }
  }, []);

  useEffect(async () => {
    setPODetails(purchase_items);
    if (purchase_items?.purchase_finished_goods_inventory_id != undefined) {
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/finished-good-inventory/get-finished-inventory?id=${purchase_items?.purchase_finished_goods_inventory_id}`
      );
      if (data?.status == 200) {
        const res = data?.data?.result;
        const apiRes = {
          finished_invoice_no:
            res.finished_invoice_no == null ? "" : res.finished_invoice_no,
          finished_invoice_date:
            res.finished_invoice_date == null
              ? ""
              : dateFun(res.finished_invoice_date),
          finished_discount:
            res.finished_discount == null ? "" : res.finished_discount,
          finished_discount_value:
            res.finished_discount_value == null
              ? ""
              : res.finished_discount_value,
          finished_invoice_value:
            res.finished_invoice_value == null
              ? ""
              : res.finished_invoice_value,
        };
        setInputs(apiRes);
      }
    }
  }, [purchase_items]);

  return (
    <Div>
      <Div
        sx={{ display: "flex", flexWrap: "wrap", width: "100%", gap: 5, mb: 3 }}
      >
        <Div sx={{ width: "45%" }}>
          <Typography variant="h5">Supplier Name</Typography>
          <Autocomplete
            size="small"
            id="tags-standard"
            options={supplierNameList || []}
            getOptionLabel={(option) => option?.supplier_name}
            onChange={(e, newValue) => handleSupplier(newValue)}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.supplier_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "45%" }}>
          <Typography variant="h5">Purchase Order No</Typography>
          <Autocomplete
            size="small"
            id="tags-standard"
            key={resetAutocomplete ? "reset1" : "normal1"}
            options={poList || []}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => handlePurchaseOrderNo(newValue)}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
      </Div>

      {/* purchase order table */}
      <Div sx={{ mt: 4 }}>
        <Typography variant="h5">Purchase Order Details:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                {headings.map((heading) => (
                  <TableCell
                    key={heading}
                    sx={{
                      textAlign: "left",
                      color: "white",
                      minWidth:
                        heading === "Supplier Address" ? "190px" : "140px", // Adjust the width for "Supplier Address" column
                      verticalAlign: "middle",
                    }}
                  >
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_address}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_pincode}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_gst_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_phone_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_email_id}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Div>
      <Div sx={{ mt: 4 }}>
        <Typography variant="h5">Item Details:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                {itemDetailsheadings.map((heading) => (
                  <TableCell
                    key={heading}
                    sx={{
                      textAlign: "left",
                      minWidth: "140px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData?.map((row, i) => (
                <TableRow key={i}>
                  {/* Render your data here based on the new headings */}
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.purchase_item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.purchase_size ? row?.purchase_size : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.quantity}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.unit_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.rate_per_unit}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>{row.amount}</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.gst_percentage}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>{row.gst}</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.total_amount}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.po_item_status}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <InfoIcon />,
                          title: "Item Details",
                          action: "itemDetails",
                          data: row,
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Div>
      <Div sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Button variant="contained" size="small" onClick={calculateAssessible}>
          Calculate Assessible
        </Button>
      </Div>
      <Div
        sx={{
          display: "flex",
          flexWrap: "wrap",
          mt: 5,
          width: "100%",
          columnGap: 3,
          rowGap: 1,
        }}
      >
        <Div sx={{ width: "8%" }}>
          <Typography>Invoice Number</Typography>
          <TextField
            size="small"
            name="finished_invoice_no"
            value={inputs.finished_invoice_no}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>
        <Div sx={{ width: "8%" }}>
          <Typography>Invoice Date</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                width: "100%",
                "&.MuiTextField-root": {
                  height: "39px",
                  flexDirection: "unset",
                },
              }}
              format="DD/MM/YY"
              value={
                inputs.finished_invoice_date
                  ? dayjs(inputs.finished_invoice_date)
                  : dayjs(currentdate)
              }
              onChange={(date) => {
                setInputs({ ...inputs, finished_invoice_date: dateFun(date) });
              }}
            />
          </LocalizationProvider>
        </Div>
        <Div sx={{ width: "8%" }}>
          <Typography>Assessible</Typography>
          <TextField
            size="small"
            name="assessible"
            value={inputs.assessible}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>
        <Div sx={{ width: "8%" }}>
          <Typography>Discount(%)</Typography>
          <TextField
            size="small"
            type="number"
            name="finished_discount"
            value={inputs.finished_discount}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>
        <Div sx={{ width: "8%" }}>
          <Typography>Discount Value</Typography>
          <TextField
            size="small"
            name="finished_discount_value"
            value={inputs.finished_discount_value}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>
        <Div sx={{ width: "8%" }}>
          <Typography>GST%</Typography>
          <Select
            name="gst"
            value={inputs?.gst}
            onChange={handleChange}
            fullWidth
            size="small"
          >
            {gstList?.map((gst) => {
              return <MenuItem value={gst}>{gst}%</MenuItem>;
            })}
          </Select>
        </Div>
        {poDetails?.supplier_master?.supplier_state == "Maharashtra" ? (
          <>
            <Div sx={{ width: "8%" }}>
              <Typography>CGST%</Typography>

              <TextField
                size="small"
                sx={{ width: "100%" }}
                name="cgst"
                disabled={true}
                value={inputs?.finished_cgst}
              />
            </Div>

            <Div sx={{ width: "8%" }}>
              <Typography>SGST%</Typography>
              <TextField
                size="small"
                sx={{ width: "100%" }}
                name="sgst"
                disabled={true}
                value={inputs?.finished_sgst}
              />
            </Div>
          </>
        ) : (
          <Div sx={{ width: "8%" }}>
            <Typography>IGST%</Typography>
            <TextField
              size="small"
              sx={{ width: "100%" }}
              name="igst"
              disabled={true}
              value={inputs?.finished_igst}
            />
          </Div>
        )}
        <Div sx={{ width: "8%" }}>
          <Typography>GST Value</Typography>
          <TextField
            size="small"
            name="gst_value"
            disabled
            value={inputs.gst_value}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>

        <Div sx={{ width: "8%" }}>
          <Typography>Invoice Value</Typography>
          <TextField
            size="small"
            name="finished_invoice_value"
            value={inputs.finished_invoice_value}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>

        <ItemDetails
          open={isInfoModalOpen}
          onClose={() => setInfoModalOpen(false)}
          rowData={selectedRowData}
          purchaseNo={poDetails?.id}
          type="finishedGoods"
        />
      </Div>

      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 4,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to cancel?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboard/inventory");
              }
            });
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          sx={{ width: "100px" }}
          onClick={submitForm}
          loading={isLoading}
        >
          Save
        </LoadingButton>
      </Div>
    </Div>
  );
};

export default AddFinishedGoods;
