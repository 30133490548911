import {
  ALL_NEW_ORDER_REPORT_FAIL,
  ALL_NEW_ORDER_REPORT_REQUEST,
  ALL_NEW_ORDER_REPORT_SUCCESS,
  ALL_CUTTING_CHALLAN_GENERATED_REPORT_FAIL,
  ALL_CUTTING_CHALLAN_GENERATED_REPORT_REQUEST,
  ALL_CUTTING_CHALLAN_GENERATED_REPORT_SUCCESS,
  ALL_QC_REPORT_FAIL,
  ALL_QC_REPORT_REQUEST,
  ALL_QC_REPORT_SUCCESS,
  ALL_DRAFT_REPORT_FAIL,
  ALL_DRAFT_REPORT_REQUEST,
  ALL_DRAFT_REPORT_SUCCESS,
  ALL_DISPATCH_REPORT_FAIL,
  ALL_DISPATCH_REPORT_REQUEST,
  ALL_DISPATCH_REPORT_SUCCESS,
  ALL_REPLACEMENT_REPORT_FAIL,
  ALL_REPLACEMENT_REPORT_REQUEST,
  ALL_REPLACEMENT_REPORT_SUCCESS,
  ALL_SALES_REPORT_FAIL,
  ALL_SALES_REPORT_REQUEST,
  ALL_SALES_REPORT_SUCCESS,
  ALL_FACTORY_RECEIVE_PENDING_REPORT_FAIL,
  ALL_FACTORY_RECEIVE_PENDING_REPORT_REQUEST,
  ALL_FACTORY_RECEIVE_PENDING_REPORT_SUCCESS,
} from "app/utils/constants/reportConstant.js";

const INIT_STATE = {
  loading: false,
  error: null,
  newOrderReport: [],
  cuttingChallanGeneratedReport: [],
  qc: [],
  allDispatch: [],
  draft: [],
  replacement: [],
  factoryReceivingPending: [],
  sales: [],
};

export const reportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //NEW_ORDER
    case ALL_NEW_ORDER_REPORT_REQUEST:
      return {
        loading: true,
      };
    case ALL_NEW_ORDER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        newOrderReport: action.payload.data,
      };

    case ALL_NEW_ORDER_REPORT_FAIL:
      return {
        loading: false,
        cuttingChallanGeneratedReport: [],
        error: action.payload,
      };

    //CUTTING_CHALLAN_GENERATED
    case ALL_CUTTING_CHALLAN_GENERATED_REPORT_REQUEST:
      return {
        loading: true,
      };
    case ALL_CUTTING_CHALLAN_GENERATED_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        cuttingChallanGeneratedReport: action.payload.data,
      };

    case ALL_CUTTING_CHALLAN_GENERATED_REPORT_FAIL:
      return {
        loading: false,
        qc: [],
        error: action.payload,
      };
    //QC
    case ALL_QC_REPORT_REQUEST:
      return {
        loading: true,
      };
    case ALL_QC_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        qc: action.payload.data,
      };

    case ALL_QC_REPORT_FAIL:
      return {
        loading: false,
        qc: [],
        error: action.payload,
      };

    //DISPATCH
    case ALL_DISPATCH_REPORT_REQUEST:
      return {
        loading: true,
      };
    case ALL_DISPATCH_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        allDispatch: action.payload.data,
      };

    case ALL_DISPATCH_REPORT_FAIL:
      return {
        loading: false,
        draft: [],
        error: action.payload,
      };
    //draft
    case ALL_DRAFT_REPORT_REQUEST:
      return {
        loading: true,
      };
    case ALL_DRAFT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        draft: action.payload.data,
      };

    case ALL_DRAFT_REPORT_FAIL:
      return {
        loading: false,
        draft: [],
        error: action.payload,
      };
    //Replacement
    case ALL_REPLACEMENT_REPORT_REQUEST:
      return {
        loading: true,
      };
    case ALL_REPLACEMENT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        replacement: action.payload.data,
      };

    case ALL_REPLACEMENT_REPORT_FAIL:
      return {
        loading: false,
        replacement: [],
        error: action.payload,
      };

    //Factory Receiving Pending
    case ALL_FACTORY_RECEIVE_PENDING_REPORT_REQUEST:
      return {
        loading: true,
      };
    case ALL_FACTORY_RECEIVE_PENDING_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        factoryReceivingPending: action.payload.data,
      };

    case ALL_FACTORY_RECEIVE_PENDING_REPORT_FAIL:
      return {
        loading: false,
        factoryReceivingPending: [],
        error: action.payload,
      };

    //SALES
    case ALL_SALES_REPORT_REQUEST:
      return {
        loading: true,
      };
    case ALL_SALES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        sales: action.payload.data,
      };

    case ALL_SALES_REPORT_FAIL:
      return {
        loading: false,
        sales: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
