import Div from "@jumbo/shared/Div";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useParams } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import axios from "axios";

const AddCatalogueImagesModal = ({ open, onClose, runApi, setRunApi }) => {
  const { name } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);

  const validationSchema = yup.object({
    image: yup
      .array()
      .min(1, "At least one image is required") // Minimum length of 1
      .of(
        yup
          .mixed()
          .test("fileType", "Invalid file type", (value) => {
            // Check if a file is selected
            if (!value) {
              return true; // Pass validation if no file is selected
            }
            // Get the file type
            const fileType = value.type;
            // Define the allowed image file types
            const allowedImageTypes = [
              "image/jpeg",
              "image/jpg",
              "image/png",
              "image/gif",
            ];
            // Check if the file type is allowed
            return allowedImageTypes.includes(fileType);
          })
          .required("Image file is required")
      ),
  });

  const onUserSave = async (values) => {
    setSubmitting(true);
    const imageFormData = new FormData();
    for (let j = 0; j < values.image.length; j++) {
      // Append each file to the FormData
      imageFormData.append("image", values.image[j]);
    }
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/api/catalogue-master/add-catalogue-image?folder_name=${name}`,
        imageFormData,
        config
      );
      if (data?.status == 200) {
        Swal.fire({ icon: "success", title: "Images Added" });
        onClose();
        setRunApi(!runApi);
      }
    } catch (error) {
      if (error) {
        Swal.fire({ icon: "error", title: "Images Not Added" });
        onClose();
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={200}
      sx={{ width: "42%", mx: "auto" }}
    >
      <DialogTitle
        sx={{ fontWeight: "700" }}
      >{`Add Images in ${name} Catalogue`}</DialogTitle>
      <DialogContent>
        <Formik
          validateOnChange={true}
          initialValues={{ image: [] }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Div>
                <Div sx={{ mb: 3 }}>
                  <Typography variant="h5" sx={{ fontWeight: 700 }}>
                    Select Images
                  </Typography>
                  <Div>
                    <input
                      type="file"
                      multiple
                      id="image"
                      name={"image"}
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => {
                        // Set the "image" field with the selected files
                        setFieldValue("image", Array.from(e.target.files));
                      }}
                      sx={{
                        width: "100%",
                        fontSize: "1rem",
                      }}
                    />
                    <ErrorMessage
                      name={"image"}
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                </Div>

                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 0,
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Add
                  </LoadingButton>
                  <Button onClick={onClose} color="error" variant="contained">
                    Close
                  </Button>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddCatalogueImagesModal;
