import Div from "@jumbo/shared/Div";
import { TextField, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import EditRawMaterial from "./editRawMaterial";
import EditFinishedGoods from "./editFinishedGoods";


export default function ReturnPurchaseForm() {
  const { state } = useLocation();
  console.log(state, "state");
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1" sx={{ mb: 4 }}>
        Purchase Return
      </Typography>
      <Div sx={{ mb: 2 }}>
        <Typography variant="h5">Inward Type</Typography>
        <TextField
          size="small"
          variant="outlined"
          value={state?.purchase_order_type}
          InputProps={{
            readOnly: true,
          }}
          sx={{ width: "45%" }}
        />
      </Div>

      {state?.purchase_order_type == "Raw Material" && (
        <EditRawMaterial data={state} />
      )}
      {state?.purchase_order_type == "Finished Goods" && <EditFinishedGoods data={state} />}
    </Div>
  );
}
