import { useBarcode } from "@createnextapp/react-barcode";
import Div from "@jumbo/shared/Div";
import { data } from "app/shared/metrics/SalesStatistics/data";
import React from "react";
import { useLocation } from "react-router-dom";

export default function PrintTag() {
  const { state } = useLocation();
  console.log(state);
  let fabricCode = state?.data?.kc_fabric_code + state?.data?.taka_no;
  if (fabricCode) {
    fabricCode = fabricCode.replace(/-/g, "");
    console.log(fabricCode);
  }

  const { inputRef: inputRefBarcode1 } = useBarcode({
    value: fabricCode,
    options: {
      height: 30,
      width: 2,
      displayValue: false,
    },
  });

  const { inputRef: inputRefBarcode2 } = useBarcode({
    value: fabricCode,
    options: {
      height: 30,
      width: 2,
      displayValue: false,
    },
  });
  const text = state?.data?.supplier_fabric_code;
  const textLength = text.length;
  const baseFontSize = 10; // Adjust this value as needed

  // Calculate the font size dynamically based on the length of the text
  const fontSize = Math.max(baseFontSize - textLength * 0.2, 8);

  return (
    //  <Div sx={{width:"188.9764px",bgcolor:"red"}}>
    <Div sx={{ display: "flex" }}>
      <Div sx={{ width: "188.9764px", paddingLeft: "10px" }}>
        {/* <img width={188.9764/2} ref={inputRef} /> */}
        <Div>
          <img width={"90%"} height={"30px"} ref={inputRefBarcode1} />
        </Div>
        <Div sx={{ pl: 0.4 }}>
          <Div sx={{ mt: -1.5 }}>
            <b style={{ fontSize: "12px" }}>{state?.data?.kc_fabric_code}</b>
          </Div>
          <Div sx={{ mt: -1.3 }}>
            <p
              style={{
                fontSize: `${fontSize}px`,
                fontWeight: "600",
                lineHeight: "1",
              }}
            >
              {state?.data?.supplier_fabric_code}
            </p>
          </Div>

          <Div sx={{ mt: -1.4 }}>
            <b style={{ fontSize: "10px" }}>
              RC: {state?.data?.raw_rack_details}
            </b>
          </Div>

          <Div style={{ display: "flex", mt: -1 }}>
            <Div sx={{ flex: 4, mt: -1.2 }}>
              <b style={{ fontSize: "10px" }}>
                Mtr:{state?.data?.taka_recieved_quantity}
              </b>
            </Div>

            <Div sx={{ flex: 4, mt: -1.2 }}>
              <b style={{ fontSize: "10px" }}>Taka:{state?.data?.taka_no}</b>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div sx={{ width: "188.9764px", paddingLeft: "10px" }}>
        {/* <img width={188.9764/2} ref={inputRef} /> */}
        <Div>
          <img width={"90%"} height={"30px"} ref={inputRefBarcode2} />
        </Div>
        <Div sx={{ pl: 0.4 }}>
          <Div sx={{ mt: -1.5 }}>
            <b style={{ fontSize: "12px" }}>{state?.data?.kc_fabric_code}</b>
          </Div>
          <Div sx={{ mt: -1.3 }}>
            <p style={{ fontSize: `${fontSize}px`, lineHeight: "1" }}>
              <b> {state?.data?.supplier_fabric_code}</b>
            </p>
          </Div>

          <Div sx={{ mt: -1.4 }}>
            <b style={{ fontSize: "10px" }}>
              RC: {state?.data?.raw_rack_details}
            </b>
          </Div>

          <Div style={{ display: "flex", mt: -1 }}>
            <Div sx={{ flex: 4, mt: -1.2 }}>
              <b style={{ fontSize: "10px" }}>
                Mtr:{state?.data?.taka_recieved_quantity}
              </b>
            </Div>

            <Div sx={{ flex: 4, mt: -1.2 }}>
              <b style={{ fontSize: "10px" }}>Taka:{state?.data?.taka_no}</b>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}
