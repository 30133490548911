import Div from "@jumbo/shared/Div";
import { Button, TextField, TextareaAutosize, Typography } from "@mui/material";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChallanItemTable } from "./challanItemTable";
import { ChallanMeasurement } from "./challanMeasurement";
import { LoadingButton } from "@mui/lab";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import axios from "axios";
import Swal from "sweetalert2";
import QcImagesModal from "../../QC/List/StartQ/qcImagesModal";

export const GenerateChallan = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [type, setType] = useState(null);

  const [input, setInput] = useState({
    challan_factory_no: null,
    challan_factory_name: null,
    challan_factory_address: null,
    challan_factory_pan_no: null,
    challan_factory_gst_no: null,
    challan_factory_city: null,
    challan_factory_state: null,
    challan_factory_country: null,
    challan_factory_pincode: null,
    challan_factory_contact_person_name: null,
    challan_factory_contact_person_country_code: null,
    challan_factory_contact_person_phone_no: null,
    challan_factory_contact_person_email_id: null,
    order_items_id: state?.item?.id || state?.order_items_id,
    pickup_person_name: null,
    pickup_person_phone_no: null,
    challan_est_delivery_date: null,
    pickup_date: null,
  });

  const submitForm = async () => {
    try {
      setIsLoading(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/api/challan/add-challan`,
        input,
        config
      );
      if (data?.data?.status == true) {
        Swal.fire({
          icon: "success",
          title: "",
          text: "Challan Generated",
        });
        window.history.back();
      } else {
        Swal.fire({
          icon: "error",
          title: "",
          text: "Challan Not Generated",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInfoIconClick = (rowData, type) => {
    setSelectedRowData(
      rowData?.item
        ? rowData.item
        : {
            order_item_catalogue_image: rowData.order_item_catalogue_image,
            order_item_image: rowData.order_item_image,
          }
    );
    setInfoModalOpen(true);
    setType(type);
  };

  useEffect(async () => {
    const data = await getFactoryNameList(
      state?.item?.factory_master_id || state?.factory_master_id
    );
    setInput({
      ...input,
      challan_factory_no: data?.factory_no,
      challan_factory_name: data?.factory_name,
      challan_factory_address: data?.factory_address,
      challan_factory_pan_no: data?.factory_pan_no,
      challan_factory_gst_no: data?.factory_gst_no,
      challan_factory_city: data?.factory_city,
      challan_factory_state: data?.factory_state,
      challan_factory_country: data?.factory_country,
      challan_factory_pincode: data?.factory_pincode,
      challan_factory_contact_person_name: data?.factory_contact_person_name,
      challan_factory_contact_person_country_code:
        data?.factory_contact_person_country_code,
      challan_factory_contact_person_phone_no:
        data?.factory_contact_person_phone_no,
      challan_factory_contact_person_email_id:
        data?.factory_contact_person_email_id,
    });
  }, []);
  // console.log(state);
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1" sx={{ mb: 2 }}>
        Generate Challan
      </Typography>

      <Div sx={{ display: "flex", flexWrap: "wrap", columnGap: 4 }}>
        <Div sx={{ display: "flex", alignItems: "center", width: "45%" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Factory Name:-
          </Typography>
          <Typography variant="h3">{input.challan_factory_name}</Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center", width: "45%" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Factory Address:-
          </Typography>
          <Typography variant="h3">{input.challan_factory_address}</Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center", width: "45%" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Factory PAN No:-
          </Typography>
          <Typography variant="h3">{input.challan_factory_pan_no}</Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center", width: "45%" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Factory GST No:-
          </Typography>
          <Typography variant="h3">{input.challan_factory_gst_no}</Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center", width: "45%" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Factory Country:-
          </Typography>
          <Typography variant="h3">{input.challan_factory_country}</Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center", width: "45%" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Factory State:-
          </Typography>
          <Typography variant="h3">{input.challan_factory_state}</Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center", width: "45%" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Factory City:-
          </Typography>
          <Typography variant="h3">{input.challan_factory_city}</Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center", width: "45%" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Factory Person Name:-
          </Typography>
          <Typography variant="h3">
            {input.challan_factory_contact_person_name}
          </Typography>
        </Div>

        <Div sx={{ display: "flex", alignItems: "center", width: "45%" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Factory Person Email ID:-
          </Typography>
          <Typography variant="h3">
            {input.challan_factory_contact_person_email_id}
          </Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center", width: "45%" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Factory Contact No:-
          </Typography>
          <Typography variant="h3">
            {input.challan_factory_contact_person_country_code}{" "}
            {input.challan_factory_contact_person_phone_no}
          </Typography>
        </Div>
        <QcImagesModal
          open={isInfoModalOpen}
          onClose={() => setInfoModalOpen(false)}
          rowData={selectedRowData}
          type={type}
        />
      </Div>
      <Div sx={{ float: "right" }}>
        <Button
          sx={{ mr: 2 }}
          variant="contained"
          onClick={() => handleInfoIconClick(state, "image")}
        >
          View Images
        </Button>
        <Button
          variant="contained"
          onClick={() => handleInfoIconClick(state, "catalogue")}
        >
          View Catalogue Images
        </Button>
      </Div>
      <ChallanItemTable rowData={state} order_no={state.order_no} />
      <Div sx={{ display: "flex", alignItems: "center", width: "45%", mt: 2 }}>
        <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
          Item Remarks:-
        </Typography>
        <TextareaAutosize
          readOnly
          style={{ resize: "none", width: "100%", fontSize: "16px" }}
        >
          {state?.item?.order_item_remarks != undefined
            ? state?.item?.order_item_remarks
            : state?.order_item_remarks}
        </TextareaAutosize>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center", width: "45%", mt: 2 }}>
        <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
          Client Name:-
        </Typography>
        <Typography variant="h3">{state.order_client_name}</Typography>
      </Div>
      <Div sx={{ mt: 2 }}>
        <ChallanMeasurement data={state} />
      </Div>
      <Div sx={{ display: "flex", flexWrap: "wrap", columnGap: 12, mt: 3 }}>
        <Div>
          <Typography variant="h5" sx={{ fontWeight: "600", mr: 1 }}>
            Pickup Person Name:-
          </Typography>
          <TextField
            size="small"
            value={input.pickup_person_name}
            onChange={(e) => {
              setInput({ ...input, pickup_person_name: e.target.value });
            }}
          />
        </Div>
        <Div>
          <Typography variant="h5" sx={{ fontWeight: "600", mr: 1 }}>
            Pickup Person Phone No:-
          </Typography>
          <TextField
            size="small"
            value={input.pickup_person_phone_no}
            onChange={(e) => {
              setInput({ ...input, pickup_person_phone_no: e.target.value });
            }}
          />
        </Div>
        <Div>
          <Typography variant="h5" sx={{ fontWeight: "600", mr: 1 }}>
            Pickup Person Date & Time:-
          </Typography>

          <TextField
            size="small"
            id="datetime-local"
            type="datetime-local"
            sx={{ width: 250 }}
            onChange={(e) => {
              setInput({ ...input, pickup_date: e.target.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Div>
        <Div>
          <Typography variant="h5" sx={{ fontWeight: "600", mr: 1 }}>
            Est Delivery Date
          </Typography>

          <TextField
            size="small"
            id="datetime-local"
            type="datetime-local"
            sx={{ width: 250 }}
            onChange={(e) => {
              setInput({ ...input, challan_est_delivery_date: e.target.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Div>
      </Div>
      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 4,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to cancel?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                window.history.back();
              }
            });
          }}
        >
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          sx={{ width: "270px" }}
          onClick={submitForm}
          loading={isLoading}
        >
          Save & Generate Challan
        </LoadingButton>
      </Div>
    </Div>
  );
};
