export const LOAD_SUPPLIER_MASTER_REQUEST = "LOAD_SUPPLIER_MASTER_REQUEST";
export const LOAD_SUPPLIER_MASTER_SUCCESS = "LOAD_SUPPLIER_MASTER_SUCCESS";
export const LOAD_SUPPLIER_MASTER_FAIL = "LOAD_SUPPLIER_MASTER_FAIL";

export const ALL_SUPPLIER_MASTER_REQUEST = "ALL_SUPPLIER_MASTER_REQUEST";
export const ALL_SUPPLIER_MASTER_SUCCESS = "ALL_SUPPLIER_MASTER_SUCCESS";
export const ALL_SUPPLIER_MASTER_FAIL = "ALL_SUPPLIER_MASTER_FAIL";

export const LOAD_COSTING_MASTER_REQUEST = "LOAD_COSTING_MASTER_REQUEST";
export const LOAD_COSTING_MASTER_SUCCESS = "LOAD_COSTING_MASTER_SUCCESS";
export const LOAD_COSTING_MASTER_FAIL = "LOAD_COSTING_MASTER_FAIL";

export const ALL_COSTING_MASTER_REQUEST = "ALL_COSTING_MASTER_REQUEST";
export const ALL_COSTING_MASTER_SUCCESS = "ALL_COSTING_MASTER_SUCCESS";
export const ALL_COSTING_MASTER_FAIL = "ALL_COSTING_MASTER_FAIL";

export const LOAD_PRODUCT_MASTER_REQUEST = "LOAD_PRODUCT_MASTER_REQUEST";
export const LOAD_PRODUCT_MASTER_SUCCESS = "LOAD_PRODUCT_MASTER_SUCCESS";
export const LOAD_PRODUCT_MASTER_FAIL = "LOAD_PRODUCT_MASTER_FAIL";

export const ALL_PRODUCT_MASTER_REQUEST = "ALL_PRODUCT_MASTER_REQUEST";
export const ALL_PRODUCT_MASTER_SUCCESS = "ALL_PRODUCT_MASTER_SUCCESS";
export const ALL_PRODUCT_MASTER_FAIL = "ALL_PRODUCT_MASTER_FAIL";

//port master
export const ALL_PORT_MASTER_REQUEST = "ALL_PORT_MASTER_REQUEST";
export const ALL_PORT_MASTER_SUCCESS = "ALL_PORT_MASTER_SUCCESS";
export const ALL_PORT_MASTER_FAIL = "ALL_PORT_MASTER_FAIL";

//gst master
export const ALL_GST_MASTER_REQUEST = "ALL_GST_MASTER_REQUEST";
export const ALL_GST_MASTER_SUCCESS = "ALL_GST_MASTER_SUCCESS";
export const ALL_GST_MASTER_FAIL = "ALL_GST_MASTER_FAIL";

//fabricColor master
export const ALL_FABRIC_COLOR_MASTER_REQUEST =
  "ALL_FABRIC_COLOR_MASTER_REQUEST";
export const ALL_FABRIC_COLOR_MASTER_SUCCESS =
  "ALL_FABRIC_COLOR_MASTER_SUCCESS";
export const ALL_FABRIC_COLOR_MASTER_FAIL = "ALL_FABRIC_COLOR_MASTER_FAIL";

//addon master
export const ALL_ADD_ON_MASTER_REQUEST = "ALL_ADD_ON_MASTER_REQUEST";
export const ALL_ADD_ON_MASTER_SUCCESS = "ALL_ADD_ON_MASTER_SUCCESS";
export const ALL_ADD_ON_MASTER_FAIL = "ALL_ADD_ON_MASTER_FAIL";

//unit master
export const ALL_UNIT_MASTER_REQUEST = "ALL_UNIT_MASTER_REQUEST";
export const ALL_UNIT_MASTER_SUCCESS = "ALL_UNIT_MASTER_SUCCESS";
export const ALL_UNIT_MASTER_FAIL = "ALL_UNIT_MASTER_FAIL";

//factory master
export const ALL_FACTORY_MASTER_REQUEST = "ALL_FACTORY_MASTER_REQUEST";
export const ALL_FACTORY_MASTER_SUCCESS = "ALL_FACTORY_MASTER_SUCCESS";
export const ALL_FACTORY_MASTER_FAIL = "ALL_FACTORY_MASTER_FAIL";

//fabric master
export const ALL_FABRIC_MASTER_REQUEST = "ALL_FABRIC_MASTER_REQUEST";
export const ALL_FABRIC_MASTER_SUCCESS = "ALL_FABRIC_MASTER_SUCCESS";
export const ALL_FABRIC_MASTER_FAIL = "ALL_FABRIC_MASTER_FAIL";
