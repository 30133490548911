import {
  ALL_SALES_FAIL,
  ALL_SALES_REQUEST,
  ALL_SALES_SUCCESS,
} from "app/utils/constants/salesConstants";
import axios from "axios";

export const getAllSales = (search_value, inputs, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }
    const filter = {
      order_port_no_and_name: inputs.order_port_no_and_name
        ? inputs.order_port_no_and_name
        : "",
      order_no: inputs.order_no ? inputs.order_no : "",
      order_item_name: inputs.order_item_name ? inputs.order_item_name : "",
    };
    dispatch({ type: ALL_SALES_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/sales/list-sales?search=${search_value}&order_port_no_and_name=${filter.order_port_no_and_name}&order_no=${filter.order_no}&order_item_name=${filter.order_item_name}&page=${page}`,
      config
    );
    // console.log(data);
    dispatch({
      type: ALL_SALES_SUCCESS,
      payload: {
        data: data?.data?.result,
        totalPage: data?.data?.totalPages,
      },
    });
  } catch (error) {
    dispatch({
      type: ALL_SALES_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
