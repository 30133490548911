import Page from "@jumbo/shared/Page";
import AddAddons from "app/pages/Master/AddonsMaster/AddAddons";
import ListAddons from "app/pages/Master/AddonsMaster/ListAddons";
import AddCosting from "app/pages/Master/CostingMaster/AddCosting";
import ListCosting from "app/pages/Master/CostingMaster/ListCosting";
import AddFabric from "app/pages/Master/FabricMaster/AddFabric";
import ListFabric from "app/pages/Master/FabricMaster/ListFabric";
import AddFactory from "app/pages/Master/FactoryMaster/AddFactory";
import ListFactory from "app/pages/Master/FactoryMaster/ListFactory";
import AddGst from "app/pages/Master/GST Master/AddGst";
import ListGst from "app/pages/Master/GST Master/List Gst";
import AddPort from "app/pages/Master/PortMaster/AddPort";
import ListPort from "app/pages/Master/PortMaster/ListPort";
import AddProduct from "app/pages/Master/ProductMaster/AddProduct";
import ListProduct from "app/pages/Master/ProductMaster/ListProduct";
import AddSupplier from "app/pages/Master/SupplierMaster/AddSupplier";
import ListSupplier from "app/pages/Master/SupplierMaster/ListSupplier";
import AddUnit from "app/pages/Master/UnitMaster/AddUnit";
import ListUnit from "app/pages/Master/UnitMaster/ListUnit";
import SupplierRouteMiddleware from "./middleware/auth/supplierMasterValidRoute";
import FabricRouteMiddleware from "./middleware/auth/fabricValidRoute";
import CostingRouteMiddleware from "./middleware/auth/costingValidRoute";
import PortRouteMiddleware from "./middleware/auth/portValidRoute";
import AddOnsRouteMiddleware from "./middleware/auth/addonsValidRoute";
import UnitRouteMiddleware from "./middleware/auth/unitValidRoute";
import GstRouteMiddleware from "./middleware/auth/gstValidRoute";
import FactoryRouteMiddleware from "./middleware/auth/factoryValidRoute";
import ProductRouteMiddleware from "./middleware/auth/productValidRoute";
import ListFabricColor from "app/pages/Master/FabricColor Master/ListFabricColor";
import AddFabricColor from "app/pages/Master/FabricColor Master/AddFabricColor";
import FabricColorRouteMiddleware from "./middleware/auth/fabricColorRoute";
import ListCatalogue from "app/pages/Master/CatalogueMaster/ListCatalogue";
import AddCatalogue from "app/pages/Master/CatalogueMaster/AddCatalogue";
import CatalogueRouteMiddleware from "./middleware/auth/catalogueValidRoute";
import CatalogueFolderImages from "app/pages/Master/CatalogueMaster/ListCatalogue/catalogueImagesList";
import ItemsRouteMiddleware from "./middleware/itemMasterValidRoute";
import ListItems from "app/pages/Master/Item Master/ListItems";
import PrintKCTag from "app/pages/Master/CostingMaster/PrintKC";

export const masterRoutes = [
  {
    middleware: [
      {
        element: SupplierRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/supplier",
        element: <Page component={ListSupplier} layout={"vertical-default"} />,
      },
      {
        path: "/master/supplier/add",
        element: <Page component={AddSupplier} layout={"vertical-default"} />,
      },
      {
        path: "/master/supplier/edit",
        element: <Page component={AddSupplier} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: FabricRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/fabric",
        element: <Page component={ListFabric} layout={"vertical-default"} />,
      },
      {
        path: "/master/fabric/add",
        element: <Page component={AddFabric} layout={"vertical-default"} />,
      },
      {
        path: "/master/fabric/edit",
        element: <Page component={AddFabric} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: CostingRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/costing",
        element: <Page component={ListCosting} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/master/costing/printtag",
        element: <Page component={PrintKCTag} layout={"solo-page"} />,
      },
      {
        path: "/master/costing/add",
        element: <Page component={AddCosting} layout={"vertical-default"} />,
      },
      {
        path: "/master/costing/edit",
        element: <Page component={AddCosting} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: PortRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/port",
        element: <Page component={ListPort} layout={"vertical-default"} />,
      },
      {
        path: "/master/port/add",
        element: <Page component={AddPort} layout={"vertical-default"} />,
      },
      {
        path: "/master/port/edit",
        element: <Page component={AddPort} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: ItemsRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/items",
        element: <Page component={ListItems} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: AddOnsRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/addons",
        element: <Page component={ListAddons} layout={"vertical-default"} />,
      },
      {
        path: "/master/addons/add",
        element: <Page component={AddAddons} layout={"vertical-default"} />,
      },
      {
        path: "/master/addons/edit",
        element: <Page component={AddAddons} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: UnitRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/unit",
        element: <Page component={ListUnit} layout={"vertical-default"} />,
      },
      {
        path: "/master/unit/add",
        element: <Page component={AddUnit} layout={"vertical-default"} />,
      },
      {
        path: "/master/unit/edit",
        element: <Page component={AddUnit} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: GstRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/gst",
        element: <Page component={ListGst} layout={"vertical-default"} />,
      },
      {
        path: "/master/gst/add",
        element: <Page component={AddGst} layout={"vertical-default"} />,
      },
      {
        path: "/master/gst/edit",
        element: <Page component={AddGst} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: FabricColorRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/fabric-color",
        element: (
          <Page component={ListFabricColor} layout={"vertical-default"} />
        ),
      },
      {
        path: "/master/fabric-color/add",
        element: (
          <Page component={AddFabricColor} layout={"vertical-default"} />
        ),
      },
      {
        path: "/master/fabric-color/edit",
        element: (
          <Page component={AddFabricColor} layout={"vertical-default"} />
        ),
      },
    ],
  },

  // {
  //   path: "/dashboard/master/gst",
  //   element: <Page component={ListGst} layout={"vertical-default"} />,
  // },
  // {
  //   path: "/master/gst/add",
  //   element: <Page component={AddGst} layout={"vertical-default"} />,
  // },
  // {
  //   path: "/master/gst/edit",
  //   element: <Page component={AddGst} layout={"vertical-default"} />,
  // },
  {
    middleware: [
      {
        element: FactoryRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/factory",
        element: <Page component={ListFactory} layout={"vertical-default"} />,
      },
      {
        path: "/master/factory/add",
        element: <Page component={AddFactory} layout={"vertical-default"} />,
      },
      {
        path: "/master/factory/edit",
        element: <Page component={AddFactory} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: ProductRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/product",
        element: <Page component={ListProduct} layout={"vertical-default"} />,
      },
      {
        path: "/master/product/add",
        element: <Page component={AddProduct} layout={"vertical-default"} />,
      },
      {
        path: "/master/product/edit",
        element: <Page component={AddProduct} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: CatalogueRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/catalogue",
        element: <Page component={ListCatalogue} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/master/catalogue/add",
        element: <Page component={AddCatalogue} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/master/catalogue/edit",
        element: <Page component={AddCatalogue} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/master/catalogue/:name",
        element: (
          <Page component={CatalogueFolderImages} layout={"vertical-default"} />
        ),
      },
    ],
  },
  // {
  //   path: "/master/costing/add",
  //   element: <Page component={AddCosting} layout={"vertical-default"} />,
  // },
  // {
  //   path: "/master/costing/edit",
  //   element: <Page component={AddCosting} layout={"vertical-default"} />,
  // },
];
