import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const CuttingOrderRouteMiddleware = ({ fallbackPath }) => {
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  if (
    permissions.cutting_order_create === true ||
    permissions.cutting_order_edit === true ||
    permissions.cutting_order_view === true
  ) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};

export default CuttingOrderRouteMiddleware;
