import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dateFun } from "app/utils/constants/functions";
import SearchIcon from "@mui/icons-material/Search";

import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersCreated } from "app/redux/actions/orderAction";

import { getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import { getOrderNoList } from "app/services/apis/ListApi/orderNoList";

import { employeeIDListApi } from "app/services/apis/ListApi/employeeIDList";
import axios from "axios";
import { downloadAllOrderReport } from "app/services/apis/ReportApis/AllOrderReport";

const AllOrders = () => {
  const [inputs, setInputs] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState("");

  const [orderBy, setOrderBy] = useState("fabric_code");

  const [orderNoList, setOrderNoList] = useState([]);

  const [portNoNameList, setPortNoNameList] = useState([]);
  const [employeeIDList, setEmployeeIDList] = useState([]);

  const [page, setPage] = useState(1);
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderCreatedList, TotalPage, error } = useSelector(
    (state) => state.orderReducer
  );

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = orderCreatedList?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);

    aValue = a[orderBy];
    bValue = b[orderBy];

    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllOrdersCreated(searchTerm, inputs, newPage));
    // Replace this with your actual pagination logic
  };

  const handleFilter = () => {
    console.log(inputs);
    dispatch(getAllOrdersCreated("", inputs, ""));
  };

  const handleClear = () => {
    dispatch(getAllOrdersCreated("", "", 1));
    setInputs({
      order_no: null,
      order_port_no_and_name: null,
      order_date: null,
      employee_id: null,
    });
    setResetAutocomplete((prev) => !prev);
  };

  const handleSearch = (value) => {
    dispatch(getAllOrdersCreated(value, "", page));
  };

  const handlePdf = async (data) => {
    console.log(data);
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/api/order/created-order-pdf?id=${data.orders_id}`,
        config
      );

      if (res) {
        window.open(res.data.result);
      }
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(getAllOrdersCreated("", "", page));
  }, []);

  useEffect(async () => {
    setPortNoNameList(await getPortListNoPerm());
    setOrderNoList(await getOrderNoList());
    setEmployeeIDList(await employeeIDListApi());
  }, []);

  return (
    <Div>
      <Typography variant="h1" sx={{ mt: -4 }}>
        All Created Orders
      </Typography>
      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        <Div sx={{ width: "14%" }}>
          <Typography variant="h5">Employee ID</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={employeeIDList || []}
            value={inputs.employee_id}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                employee_id: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>

        <Div sx={{ width: "14%" }}>
          <Typography variant="h5">Order No</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={orderNoList || []}
            value={inputs.order_no}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_no: newValue ? newValue : null,
              }));
              // Set to null when newValue is null
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>

        <Div sx={{ width: "14%" }}>
          <Typography variant="h5">Port No./Port Name</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={portNoNameList || []}
            value={inputs.order_port_no_and_name}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_port_no_and_name: newValue ? newValue : null,
              }));
              // Set to null when newValue is null
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>

        <Div>
          <Typography variant="h5">Order Date</Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              // maxDate={dayjs()}
              format="DD/MM/YY"
              value={inputs?.order_date}
              error={!inputs?.order_date}
              onChange={(newDate) => (inputs.order_date = dateFun(newDate))}
              sx={{
                "&.MuiTextField-root": {
                  height: "39px",
                  flexDirection: "unset",
                },
              }}
            />
          </LocalizationProvider>
        </Div>
      </Div>
      {/* apply and clear */}
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          justifyContent: "space-between",
        }}
      >
        <Div>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Div>
        <Button
          disabled={sortedData?.length == 0}
          variant="contained"
          onClick={async () => {
            const data = await downloadAllOrderReport(inputs);
            window.open(data);
          }}
        >
          Export CSV
        </Button>
      </Div>
      {/* search */}
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          mt: 3,
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mt: "auto" }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
      </Div>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "65px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_no"}
                  direction={order}
                  onClick={() => handleSort("order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Employee ID
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Port No & Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Sales Person Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "65px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_no"}
                  direction={order}
                  onClick={() => handleSort("order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Order No.
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{row.employee_id}</TableCell>
                <TableCell>{row.order_port_no_and_name}</TableCell>
                <TableCell>{row.salesman_name}</TableCell>
                <TableCell>{row.order_no}</TableCell>
                <TableCell>{row.order_date}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handlePdf(row);
                    }}
                    size="small"
                    sx={{ ml: -0.5 }}
                  >
                    View PDF
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </Div>
  );
};

export default AllOrders;
