import { ALL_SALES_FAIL, ALL_SALES_REQUEST, ALL_SALES_SUCCESS } from "app/utils/constants/salesConstants";

  
  const INIT_STATE = {
    loading: false,
    error: null,
    allSales: [],
    TotalPage: null,
  };
  
  export const salesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ALL_SALES_REQUEST:
        return {
          loading: true,
        };
      case ALL_SALES_SUCCESS:
        return {
          ...state,
          loading: false,
          allSales: action.payload.data,
          TotalPage: action.payload.totalPage,
        };
  
      case ALL_SALES_FAIL:
        return {
          loading: false,
          allSales: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  