import axios from "axios";

export const downloadShopOrderOutForDeliveryReport = async (inputs) => {
  if (inputs.order_no == undefined) {
    inputs.order_no = "";
  }
  if (inputs.order_port_no_and_name == undefined) {
    inputs.order_port_no_and_name = "";
  }
  if (inputs.dispatch_id == undefined) {
    inputs.dispatch_id = "";
  }
  if (inputs.dispatch_shipment_no == undefined) {
    inputs.dispatch_shipment_no = "";
  }
  if (inputs.order_est_delivery_date_from == undefined) {
    inputs.order_est_delivery_date_from = "";
  }
  if (inputs.order_est_delivery_date_from == undefined) {
    inputs.order_est_delivery_date_from = "";
  }

  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/shop-order/list-shop-order-out_for_delivery-download-excel?shop_order_no=${inputs.order_no}&shop_order_port_no_and_name=${inputs.order_port_no_and_name}&shop_order_dispatch_id=${inputs.dispatch_id}&dispatch_shipment_no=${inputs.dispatch_shipment_no}&shop_order_dispatch_est_delivery_date_to=${inputs.order_est_delivery_date_to}&shop_order_dispatch_est_delivery_date_from=${inputs.order_est_delivery_date_from}`,
      config
    );
    return data?.data?.result;
  } catch (error) {
    console.log(error);
    return error?.response?.data?.result;
  }
};
