import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Div from "@jumbo/shared/Div";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
// import { getAllOrders } from "app/redux/actions/orderAction";
import FetchModal from "./fetchModal";
import { useLocation } from "react-router-dom";

const Measurement = ({
  setMeasurement,
  measurement,
  setSelectedGender,
  selectedDropdown,
  setRefNo
}) => {
  // console.log(measurement);
  const [orderNo, setOrderNo] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { orderItemsList } = useSelector((state) => state.orderReducer);
  const [isFetchModalOpen, setFetchModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showFetchBtn, setShowFetchBtn] = useState(false);
  const { pathname, state } = useLocation();
  const handleChange = (event, i, measurementName) => {
    const name = measurementName;
    const value = event.target.value;
    const updatedData = [...measurement];
    updatedData[i] = {
      ...updatedData[i],
      item_master_measurement: {
        ...updatedData[i].item_master_measurement,
        [name]: value,
      },
    };
    setRefNo(orderNo)
    setMeasurement(updatedData);
  };

  const getMeasurementByOrderNo = async () => {
    setLoading(true);

    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api/order/last-order_measurement?order_no=${orderNo}`,
        config
      );
      if (res?.status === 200) {
        setFetchModalOpen(true);
        setMeasurement([]);
        setShowFetchBtn(true);
        setSelectedGender(res.data.result.order_gender);
        setSelectedRowData(res.data.result);


        // setMeasurement(res.data.result.measurement);
      }
    } catch (error) {
      setShowFetchBtn(false);
      setSelectedRowData([]);
    } finally {
      setLoading(false);
    }
  };

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array?.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  return (
    <Div>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 3,
        }}
      >
        <Typography variant="h3" sx={{ mt: "auto", fontWeight: "600" }}>
          {measurement?.length > 0 && "Measurements:-"}
        </Typography>
        {pathname != "/dashboard/order/editOrder" && (
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Div sx={{ mr: 4 }}>
              <Typography variant="h5">Last Order No:</Typography>
              <TextField
                onChange={(e) => setOrderNo(e.target.value)}
                size="small"
                name="last_order_no"
                sx={{ width: "100%" }}
              ></TextField>
            </Div>

            <Div sx={{ marginTop: "auto" }}>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                size="medium"
                onClick={() => {
                  getMeasurementByOrderNo();
                }}
              >
                Fetch Measurement
              </LoadingButton>
              {showFetchBtn && (
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ ml: 3 }}
                  onClick={() => setFetchModalOpen(!isFetchModalOpen)}
                >
                  Select Measurements
                </Button>
              )}
            </Div>
          </Div>
        )}
      </Div>

      <Div>
        {measurement?.length > 0 &&
          measurement?.map((item, index) => {
            const measurementKeys = Object.keys(item?.item_master_measurement);
            const chunkedKeys = chunkArray(measurementKeys, 5);

            return (
              <Div
                sx={{
                  width: "100%",
                  mt: 3,
                  mb: 5,
                  border: "3px solid",
                  p: 2,
                  borderRadius: "5px",
                  bgcolor: "#fbfef9",
                }}
                key={index}
              >
                <Typography
                  variant="h2"
                  sx={{
                    textDecoration: "underline",
                  }}
                >
                  {item.item_master_item_name}
                </Typography>

                {chunkedKeys.map((keys, tableIndex) => (
                  <Table key={tableIndex}>
                    <TableHead>
                      <TableRow
                        sx={{
                          bgcolor: "#202020",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        {keys.map(
                          (key, i) =>
                            key && ( // Only render TableCell when key has a value
                              <TableCell
                                key={i}
                                sx={{
                                  textAlign: "left",
                                  width: `${100 / 5}%`, // Equal width for 5 keys
                                  color: "white",
                                  border: "2px solid white",
                                  borderRadius: "10px",
                                  padding: "5px",
                                  paddingLeft: "10px",
                                }}
                              >
                                {key}
                              </TableCell>
                            )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {Array.from({ length: 5 }).map((_, i) => (
                          <TableCell
                            key={i}
                            sx={{
                              width: `${100 / 5}%`, // Equal width for 5 keys
                            }}
                          >
                            {keys[i] && (
                              <TextField
                                size="small"
                                name={keys[i]}
                                value={
                                  item?.item_master_measurement[keys[i]] || ""
                                }
                                onChange={(e) =>
                                  handleChange(e, index, keys[i])
                                }
                                sx={{ width: "100%" }}
                              />
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </Div>
            );
          })}
      </Div>
      <FetchModal
        open={isFetchModalOpen}
        onClose={() => setFetchModalOpen(false)}
        rowData={selectedRowData}
        setMeasurement={setMeasurement}
        measurement={measurement}
        selectedDropdown={selectedDropdown}
        refOrderNo={orderNo}
        setRefNo={setRefNo}
      />
    </Div>
  );
};

export default Measurement;
