import Div from "@jumbo/shared/Div";
import { Box, InputAdornment, Tab, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ListMaleItemsTable from "./MaleItemsTable";
import ListFemaleItemsTable from "./femaleItemsTable";

export default function ListItems() {
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = React.useState("1");
  const [searchMensItem, setSearchMensItem] = React.useState("");
  const [searchFemaleItem, setSearchFemaleItem] = React.useState("");

  const handleSearch = (inputValue) => {
    if (value == "1") {
      setSearchMensItem(inputValue);
    } else {
      setSearchFemaleItem(inputValue);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setSearchTerm("");
    setSearchMensItem("");
    setSearchFemaleItem("");
  }, [value]);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Items Master</Typography>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
      </Div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Male Items" value="1" />
              <Tab label="Female Items" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ListMaleItemsTable MensItem={searchMensItem} />
          </TabPanel>
          <TabPanel value="2">
            <ListFemaleItemsTable FemaleItem={searchFemaleItem} />
          </TabPanel>
        </TabContext>
      </Box>
    </Div>
  );
}
