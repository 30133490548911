import {
  ALL_INVENTORY_ADDONS_FAIL,
  ALL_INVENTORY_ADDONS_REQUEST,
  ALL_INVENTORY_ADDONS_SUCCESS,
  ALL_INVENTORY_FINISHED_GOODS_FAIL,
  ALL_INVENTORY_FINISHED_GOODS_REQUEST,
  ALL_INVENTORY_FINISHED_GOODS_SUCCESS,
  ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_FAIL,
  ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_REQUEST,
  ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_SUCCESS,
  ALL_INVENTORY_PRODUCTION_FAIL,
  ALL_INVENTORY_PRODUCTION_REQUEST,
  ALL_INVENTORY_PRODUCTION_SUCCESS,
  ALL_INVENTORY_PRODUCTION_HISTORY_FAIL,
  ALL_INVENTORY_PRODUCTION_HISTORY_REQUEST,
  ALL_INVENTORY_PRODUCTION_HISTORY_SUCCESS,
  ALL_INVENTORY_RAW_MATERIAL_FAIL,
  ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_FAIL,
  ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_REQUEST,
  ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_SUCCESS,
  ALL_INVENTORY_RAW_MATERIAL_REQUEST,
  ALL_INVENTORY_RAW_MATERIAL_SUCCESS,
  ALL_INVENTORY_WASTAGE_FAIL,
  ALL_INVENTORY_WASTAGE_REQUEST,
  ALL_INVENTORY_WASTAGE_SUCCESS,
  ALL_INVENTORY_RAW_SCRAPPED_FAIL,
  ALL_INVENTORY_RAW_SCRAPPED_REQUEST,
  ALL_INVENTORY_RAW_SCRAPPED_SUCCESS,
  ALL_INVENTORY_FINISHED_SCRAPPED_FAIL,
  ALL_INVENTORY_FINISHED_SCRAPPED_REQUEST,
  ALL_INVENTORY_FINISHED_SCRAPPED_SUCCESS,
  CLEAR_INVENTORY_PURCHASE_ITEM,
  INVENTORY_PURCHASE_ITEM_FAIL,
  INVENTORY_PURCHASE_ITEM_REQUEST,
  INVENTORY_PURCHASE_ITEM_SUCCESS,
} from "app/utils/constants/inventoryConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  rawMaterial: [],
  rawMaterialManuallyIssued: [],
  finishedGoods: [],
  finishedGoodsManuallyIssued: [],
  production: [],
  productionHistory: [],
  wastage: [],
  finishedScrapped: [],
  rawScrapped: [],
  addons: [],
  purchase_items: {},
  TotalPage: null,
};

export const inventoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //raw material
    case ALL_INVENTORY_RAW_MATERIAL_REQUEST:
      return {
        loading: true,
      };
    case ALL_INVENTORY_RAW_MATERIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        rawMaterial: action.payload.data,
        TotalPage:action.payload.totalPage,
      };

    case ALL_INVENTORY_RAW_MATERIAL_FAIL:
      return {
        loading: false,
        rawMaterial: [],
        error: action.payload,
      };

    //raw material manually issued
    case ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_REQUEST:
      return {
        loading: true,
      };
    case ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_SUCCESS:
      return {
        ...state,
        loading: false,
        rawMaterialManuallyIssued: action.payload.data,
        TotalPage:action.payload.totalPage,
      };

    case ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_FAIL:
      return {
        loading: false,
        rawMaterialManuallyIssued: [],
        error: action.payload,
      };

    //finished goods
    case ALL_INVENTORY_FINISHED_GOODS_REQUEST:
      return {
        loading: true,
      };
    case ALL_INVENTORY_FINISHED_GOODS_SUCCESS:
      return {
        ...state,
        loading: false,
        finishedGoods: action.payload.data,
        TotalPage:action.payload.totalPage,
      };

    case ALL_INVENTORY_FINISHED_GOODS_FAIL:
      return {
        loading: false,
        finishedGoods: [],
        error: action.payload,
      };

    //finished goods manually issued
    case ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_REQUEST:
      return {
        loading: true,
      };
    case ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_SUCCESS:
      return {
        ...state,
        loading: false,
        finishedGoodsManuallyIssued: action.payload.data,
        TotalPage:action.payload.totalPage,
      };

    case ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_FAIL:
      return {
        loading: false,
        finishedGoodsManuallyIssued: [],
        error: action.payload,
      };

    //production
    case ALL_INVENTORY_PRODUCTION_REQUEST:
      return {
        loading: true,
      };
    case ALL_INVENTORY_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        production: action.payload.data,
        TotalPage:action.payload.totalPage,
      };

    case ALL_INVENTORY_PRODUCTION_FAIL:
      return {
        loading: false,
        production: [],
        error: action.payload,
      };

    //production history
    case ALL_INVENTORY_PRODUCTION_HISTORY_REQUEST:
      return {
        loading: true,
      };
    case ALL_INVENTORY_PRODUCTION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        productionHistory: action.payload.data,
        TotalPage:action.payload.totalPage,
      };

    case ALL_INVENTORY_PRODUCTION_HISTORY_FAIL:
      return {
        loading: false,
        productionHistory: [],
        error: action.payload,
      };

    //wastage
    case ALL_INVENTORY_WASTAGE_REQUEST:
      return {
        loading: true,
      };
    case ALL_INVENTORY_WASTAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        wastage: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_INVENTORY_WASTAGE_FAIL:
      return {
        loading: false,
        wastage: [],
        error: action.payload,
      };
    //Raw Scrapped
    case ALL_INVENTORY_RAW_SCRAPPED_REQUEST:
      return {
        loading: true,
      };
    case ALL_INVENTORY_RAW_SCRAPPED_SUCCESS:
      return {
        ...state,
        loading: false,
        rawScrapped: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_INVENTORY_RAW_SCRAPPED_FAIL:
      return {
        loading: false,
        rawScrapped: [],
        error: action.payload,
      };
    //Finished Scrapped
    case ALL_INVENTORY_FINISHED_SCRAPPED_REQUEST:
      return {
        loading: true,
      };
    case ALL_INVENTORY_FINISHED_SCRAPPED_SUCCESS:
      return {
        ...state,
        loading: false,
        finishedScrapped: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_INVENTORY_FINISHED_SCRAPPED_FAIL:
      return {
        loading: false,
        finishedScrapped: [],
        error: action.payload,
      };

    //addons
    case ALL_INVENTORY_ADDONS_REQUEST:
      return {
        loading: true,
      };
    case ALL_INVENTORY_ADDONS_SUCCESS:
      return {
        ...state,
        loading: false,
        addons: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_INVENTORY_ADDONS_FAIL:
      return {
        loading: false,
        addons: [],
        error: action.payload,
      };

    //raw items
    case INVENTORY_PURCHASE_ITEM_REQUEST:
      return {
        loading: true,
      };
    case INVENTORY_PURCHASE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        purchase_items: action.payload,
      };

    case INVENTORY_PURCHASE_ITEM_FAIL:
      return {
        loading: false,
        purchase_items: [],
        error: action.payload,
      };
    case CLEAR_INVENTORY_PURCHASE_ITEM:
      return {
        purchase_items: {},
      };

    default:
      return state;
  }
};
