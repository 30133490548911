import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import CachedIcon from "@mui/icons-material/Cached";
import FactoryIcon from "@mui/icons-material/Factory";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextareaAutosize
} from "@mui/material";
import { getAllDraft } from "app/redux/actions/draftAction";
import { displayDateFun } from "app/utils/constants/functions";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FactoryFabricModal from "./Modal/factoryFabricModal";
import "./draft.css";
import RefreshModal from "./modal";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function ListDraftTable({ searchTerm, setPage, page, inputs }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("id");

  const [isFactoryFabricModalOpen, setFactoryFabricModalOpen] = useState(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { draftList, TotalPage,loading } = useSelector((state) => state.draftReducer);
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const [remarks, setRemarks] = useState("");
  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = draftList?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_sub_item_name") {
      aValue = a.order_sub_item_name.toLowerCase();
      bValue = b.order_sub_item_name.toLowerCase();
    } else if (orderBy == "order_item_kc_fabric_code") {
      aValue = a.order_item_kc_fabric_code.toLowerCase();
      bValue = b.order_item_kc_fabric_code.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleItemAction = (menuItem, row) => {
    switch (menuItem.action) {
      case "alternative":
        navigate("/dashboard/draft/alternative", { state: menuItem.data });
        break;
      case "refresh":
        handleRefresh(menuItem.data);
        break;
      case "issuePo":
        navigate("/dashboard/purchase/add", { state: menuItem.data });
        break;
      case "factoryFabric":
        setFactoryFabricModalOpen(true);
        setSelectedRowData(menuItem.data);
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllDraft(searchTerm, inputs, newPage));
  };

  const handleRefresh = async (data) => {
    const body = {
      order_quantity: data.order_item_fabric_required,
      kc_fabric_code: data.order_item_kc_fabric_code,
      order_item_id: data.order_items_id,
    };
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/api/draft/refresh`,
        body,
        config
      );
      if (res.data.message == "Available") {
        setInfoModalOpen(true);
        setSelectedRowData(data);
      } else {
        Swal.fire(res.data.message, "Inventory Checked", "info");
      }
    } catch (error) {
      // console.log(error);
      return error.response;
    }
  };

  function countDaysUntilCurrentDate(targetDateStr) {
    const targetDate = new Date(targetDateStr);
    // console.log(targetDate);
    const currentDate = new Date();
    const timeDifference = targetDate - currentDate;
    const daysDifference = Math.abs(timeDifference) / (1000 * 60 * 60 * 24);
    return Math.round(daysDifference);
  }

  async function handleRemarks(id, value) {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/api/draft/add-draft_remark?order_items_id=${id}`,
        { draft_remark: value },
        config
      );
      // console.log(res);
    } catch (error) {
      // console.log(error);
      return error.response;
    }
  }

  return (
    <>      {loading && <FullScreenLoader />}
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",

                verticalAlign: "middle",
                color: "white",
              }}
            >
              Emp Id
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Port No & Name
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                px: 1,
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_no"}
                direction={order}
                onClick={() => handleSort("order_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Order No
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Order Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_no"}
                direction={order}
                onClick={() => handleSort("order_item_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Item No
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                px: 1,
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_name"}
                direction={order}
                onClick={() => handleSort("order_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Item Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "160px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_sub_item_name"}
                direction={order}
                onClick={() => handleSort("order_sub_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Subitem Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "140px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_kc_fabric_code"}
                direction={order}
                onClick={() => handleSort("order_item_kc_fabric_code")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Fabric Code
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "110px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "supplier_name"}
                direction={order}
                onClick={() => handleSort("supplier_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Supplier Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "110px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "supplier_fabric_code"}
                direction={order}
                onClick={() => handleSort("supplier_fabric_code")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Supplier Code
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_fabric_required"}
                direction={order}
                onClick={() => handleSort("order_item_fabric_required")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                  maxWidth: "80px",
                }}
              >
                Qty Required
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Delivery Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                px: 1,
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Draft Order Days
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "140px",
                px: 1,
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Remarks
            </TableCell>
            {permissions?.draft_order_create == true && (
              <TableCell
              sx={{
                textAlign: "left",
                minWidth: "95px",
                verticalAlign: "middle",
                color: "white",
                px: 1,
                position: "sticky",
                right: 0,
                height: "58px",
                zIndex: 1,
                bgcolor: "#202020",
              }}
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>
                {row.employee_id}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_port_no_and_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row.order_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {displayDateFun(row.order_date)}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_item_no}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  wordWrap: "break-word",
                  px: 1,
                }}
              >
                {row.order_item_name}
              </TableCell>

              <TableCell sx={{ textAlign: "left" }}>
                {row.order_sub_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {row.order_item_kc_fabric_code}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {row.supplier_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {row.supplier_fabric_code}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {row.order_item_fabric_required}
              </TableCell>

              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {displayDateFun(row.order_est_delivery_date)}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px", px: 1 }}>
                {/* {console.log(row.order_item_draft_days)} */}
                {countDaysUntilCurrentDate(`${row.order_create_date}`)}
              </TableCell>
              <TableCell sx={{ textAlign: "left", minWidth: "100px", px: 1 }}>
                <TextareaAutosize
                  style={{ resize: "none" }}
                  type="text"
                  size="small"
                  defaultValue={row.draft_remark}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                  onBlur={(e) => {
                    handleRemarks(row.order_items_id, e.target.value);
                  }}
                />
              </TableCell>
              {permissions?.draft_order_create == true && (
                <TableCell  sx={{
                  textAlign: "left",
                  px: 1,
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  bgcolor: "white",
                }}>
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={[
                      {
                        icon: <CachedIcon />,
                        title: "Refresh",
                        action: "refresh",
                        data: row,
                      },
                      {
                        icon: <AddCircleOutlineIcon />,
                        title: "Issue PO",
                        action: "issuePo",
                        data: row,
                      },
                      {
                        icon: <AltRouteIcon />,
                        title: "Alternative",
                        action: "alternative",
                        data: row,
                      },
                      {
                        icon: <FactoryIcon />,
                        title: "Factory Fabric",
                        action: "factoryFabric",
                        data: row,
                      },
                    ]}
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <RefreshModal
        open={isInfoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        rowData={selectedRowData}
        type={"refresh"}
      />
      <FactoryFabricModal
        open={isFactoryFabricModalOpen}
        onClose={() => setFactoryFabricModalOpen(false)}
        rowData={selectedRowData}
      />
      <Pagination
        count={TotalPage || 1}
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
    </TableContainer>
    </>
  );
}
