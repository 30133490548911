import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { GlassMagnifier } from "react-image-magnifiers";
import { useParams } from "react-router-dom";

const CataloguePreviewModal = ({ open, onClose, rowData }) => {
  const { name } = useParams();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: "700" }}>
        {rowData?.kc_fabric_code}
      </DialogTitle>
      <DialogContent>
        {rowData && (
          <>
            <GlassMagnifier
              height={"100%"}
              width={"100%"}
              magnifierSize={200}
              imageSrc={`${process.env.REACT_APP_URL}/${rowData}`}
              // largeImageSrc="./large-image.jpg" // Optional
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CataloguePreviewModal;
