import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getShopPackingOrderList } from "app/redux/actions/orderAction";
import { displayDateFun } from "app/utils/constants/functions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ShopPakingDoneListTable = ({ searchTerm, setPage, page, inputs }) => {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const { shopPacking, TotalPage, loading } = useSelector(
    (state) => state.orderReducer
  );

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getShopPackingOrderList(searchTerm, inputs, newPage, "shopPackingDone")
    );
  };

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };
  console.log(shopPacking, "shopPacking");

  const sortedData = shopPacking?.sort((a, b) => {
    let aValue;
    let bValue;

    if (orderBy == "shop_order_item_name") {
      aValue = a.shop_order_item_name.toLowerCase();
      bValue = b.shop_order_item_name.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  return (
    <>
      {" "}
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_port_no_and_name"}
                  direction={order}
                  onClick={() => handleSort("shop_order_port_no_and_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Port No & Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_no"}
                  direction={order}
                  onClick={() => handleSort("shop_order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Order No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_item_no"}
                  direction={order}
                  onClick={() => handleSort("shop_order_item_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_item_name"}
                  direction={order}
                  onClick={() => handleSort("shop_order_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_item_size"}
                  direction={order}
                  onClick={() => handleSort("shop_order_item_size")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Size
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_item_require_quantity"}
                  direction={order}
                  onClick={() => handleSort("shop_order_item_require_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Required Qty
                </TableSortLabel>
              </TableCell>
              {/* <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "balance_quantity"}
                  direction={order}
                  onClick={() => handleSort("balance_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Balance Qty
                </TableSortLabel>
              </TableCell> */}
              {/* <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Dispatch Details
              </TableCell> */}
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_salesman_name"}
                  direction={order}
                  onClick={() => handleSort("shop_salesman_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sales Person
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "170px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Exp. Delivery Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Packing Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.shop_order_port_no_and_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_item_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.shop_order_item_size ? row?.shop_order_item_size : "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.shop_order_item_require_quantity
                    ? row.shop_order_item_require_quantity
                    : "-"}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_salesman_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.shop_order_date)}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.shop_order_item_est_delivery_date)}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row?.shop_order_packing_create_date)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
};

export default ShopPakingDoneListTable;
