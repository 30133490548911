import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FormTextField from "app/components/InputField/FormTextField";
import Swal from "sweetalert2";
import { supplierList } from "app/services/apis/supplierList";
import { updateFabric } from "app/services/apis/updateFabric";
import { addFabric } from "app/services/apis/addFabric";
import { getFabricColorList } from "app/services/apis/ListApi/fabricColorList";
import { LoadingButton } from "@mui/lab";

export default function AddFabric() {
  const { state } = useLocation();
  const [file, setFile] = useState(null);
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [fabricColor, setFabricColor] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedColor, setSelectedColor] = useState(
    state?.fabric_color ? state?.fabric_color : []
  );
  const [imageURL, setImageURL] = useState(
    state?.image ? `${process.env.REACT_APP_URL}/${state.image}` : ""
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedColor(newValue);
  };

  const fabric = {
    supplier_master_id: state?.supplier_master.id
      ? state?.supplier_master.id
      : "Select",
    supplier_fabric_code: state?.supplier_fabric_code
      ? state?.supplier_fabric_code
      : "",
    fabric_type: state?.fabric_type ? state?.fabric_type : "Select",
    // fabric_color: selectedColor,
    // image: imageURL,
  };
  const validationSchema = yup.object({
    supplier_master_id: yup
      .string()
      .required("Supplier Name is required")
      .test(
        "supplier-not-select",
        "Please select a valid Supplier Name",
        (value) => value !== "Select"
      ),
    supplier_fabric_code: yup
      .string("Enter Supplier Fabric Code")
      .required("Supplier Fabric Code is required"),
    fabric_type: yup
      .string()
      .required("Fabric Type is required")
      .test(
        "type-not-select",
        "Please select a valid Fabric Type",
        (value) => value !== "Select"
      ),
    // fabric_color: yup
    //   .array()
    //   .min(1, "At least one fabric color is required")
    //   .required("Fabric color is required"),
    // image: yup.string("Image is required").required("Image is required"),
  });

  const onUserSave = async (values) => {
    setSubmitting(true);
    const formdata = new FormData();
    formdata.append("supplier_master_id", values.supplier_master_id);
    formdata.append("supplier_fabric_code", values.supplier_fabric_code);
    formdata.append("fabric_color", JSON.stringify(selectedColor));
    formdata.append(
      "fabric_type",
      values.fabric_type.toUpperCase().slice(0, 2)
    );
    formdata.append("image", file);

    if (pathname == "/master/fabric/edit") {
      const data = await updateFabric(formdata, state.id);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Fabric Edited Successfully",
          text: "",
        });
        navigate("/dashboard/master/fabric");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addFabric(formdata);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Fabric Added Successfully",
          text: "",
        });
        navigate("/dashboard/master/fabric");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  useEffect(async () => {
    const data = await supplierList();
    if (data?.status == 200) {
      setSupplierNameList(data?.data?.result);
      setFabricColor(await getFabricColorList());
    }
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const imageURL = URL.createObjectURL(selectedFile);
      setImageURL(imageURL);
      setFile(selectedFile);
    }
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/master/fabric/add" ? "Add New Fabric" : "Edit Fabric"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={fabric}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                    rowGap: 1,
                  }}
                >
                  {/* Supplier Name and Supplier Fabric Code */}
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Supplier Name</Typography>

                    <Autocomplete
                      size="small"
                      sx={{ width: "100%" }}
                      disabled={
                        pathname == "/master/costing/edit" ? true : false
                      }
                      value={
                        supplierNameList.find(
                          (item) => item.id === values?.supplier_master_id
                        ) || null
                      }
                      name="supplier_master_id"
                      onChange={async (e, value) => {
                        setFieldValue("supplier_master_id", value?.id || "");
                      }}
                      options={supplierNameList || []}
                      getOptionLabel={(option) => option.supplier_name || ""}
                      renderInput={(params) => (
                        <TextField {...params} label="Select" />
                      )}
                    />
                    <ErrorMessage
                      name="supplier_master_id"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <FormTextField
                    name="supplier_fabric_code"
                    label="Supplier Fabric Code"
                  />

                  {/* Autocomplete and Fabric Type */}

                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Fabric Type</Typography>
                    <Select
                      sx={{ width: "100%" }}
                      name="fabric_type"
                      size="small"
                      onChange={(e) => {
                        setFieldValue("fabric_type", e.target.value);
                      }}
                      value={values?.fabric_type}
                    >
                      <MenuItem value={"Select"}>Select</MenuItem>
                      <MenuItem value={"SU"}>Suit</MenuItem>
                      <MenuItem value={"SH"}>Shirt</MenuItem>
                    </Select>
                    <ErrorMessage
                      component={"div"}
                      name="fabric_type"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Fabric Color</Typography>
                    <Autocomplete
                      multiple
                      size="small"
                      sx={{ width: "100%" }}
                      id="tags-standard"
                      options={fabricColor}
                      getOptionLabel={(option) => option}
                      name="fabric_color"
                      value={selectedColor}
                      onChange={handleAutocompleteChange}
                      renderOption={(props, option) => (
                        <Box
                          size="small"
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Choose Fabric Colors"
                        />
                      )}
                    />
                    <ErrorMessage
                      name="fabric_color"
                      component={"div"}
                      style={{ color: "red" }}
                    />
                  </Div>
                </Div>

                <Div sx={{ mt: 2 }}>
                  {imageURL && (
                    <Card sx={{ maxWidth: 200, cursor: "pointer" }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          image={imageURL}
                          alt="Upload Image"
                          sx={{ height: "100%", objectFit: "cover" }}
                        />
                      </CardActionArea>
                    </Card>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    id="imageInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <label
                    htmlFor="imageInput"
                    style={{
                      display: "block",
                      textAlign: "left",
                      marginTop: "10px",
                      width: "151px",
                    }}
                  >
                    <Button variant="contained" component="span">
                      Upload Image
                    </Button>
                  </label>
                  <ErrorMessage
                    name="image"
                    component={"div"}
                    style={{ color: "red" }}
                  />
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: "3%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/master/fabric");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
