/* eslint-disable default-case */
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PrintIcon from "@mui/icons-material/Print";
import SwitchAccessShortcutAddIcon from "@mui/icons-material/SwitchAccessShortcutAdd";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { getAllRawMaterial } from "app/redux/actions/inventoryAction";
import { displayDateFun } from "app/utils/constants/functions";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ChangeLocation from "../ActionComponent/changeLocation";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function RawMaterialTable({ searchTerm }) {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { rawMaterial, error, TotalPage, loading } = useSelector(
    (state) => state.inventoryReducer
  );
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const navigate = useNavigate();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllRawMaterial(searchTerm, "", newPage));
    // Replace this with your actual pagination logic
  };
  const sortedData = rawMaterial?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "supplier_master.supplier_name") {
      aValue = a.supplier_master.supplier_name.toLowerCase();
      bValue = b.supplier_master.supplier_name.toLowerCase();
    } else if (orderBy == "purchase_order.purchase_order_no") {
      aValue = a.purchase_order.purchase_order_no.toLowerCase();
      bValue = b.purchase_order.purchase_order_no.toLowerCase();
    } else if (orderBy == "raw_material_inventory.raw_invoice_value") {
      aValue = a.raw_material_inventory.raw_invoice_value;
      bValue = b.raw_material_inventory.raw_invoice_value;
    } else if (orderBy == "raw_cost_details.cost_rate_per_meter") {
      aValue = a?.raw_cost_details?.cost_rate_per_meter;
      bValue = b?.raw_cost_details?.cost_rate_per_meter;
    } else if (orderBy == "raw_material_inventory.raw_invoice_no") {
      aValue = a.raw_material_inventory.raw_invoice_no.toLowerCase();
      bValue = b.raw_material_inventory.raw_invoice_no.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "addToWastage":
        addToWastage(menuItem.data.id);
        break;
      case "rawscrapped":
        addToScrap(menuItem.data.id);
        break;
      case "manuallyIssue":
        navigate("/dashboard/inventory/manuallyIssue", {
          state: {
            menuItemData: menuItem.data,
            type: "Raw Material",
          },
        });
        break;
      case "changeLocation":
        setInfoModalOpen(true);
        setSelectedRowData(menuItem.data);
        break;
      case "printTag":
        printTagBarcode(menuItem.data);
        break;
    }
  };

  const printTagBarcode = (data) => {
    navigate("/dashboard/inventory/printTag", {
      state: {
        data: data,
      },
    });
  };

  const addToWastage = (id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = {
      taka_type: "Wastage Inventory",
    };
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/taka/update-taka?id=${id}`,
        data,
        config
      )
      .then((res) => {
        if (res?.status == 200) {
          Swal.fire({ icon: "success", title: "Added To Wastage" });
          dispatch(getAllRawMaterial("", ""));
        }
      });
  };
  const addToScrap = (id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = {
      taka_type: "Raw Scrapped Inventory",
    };
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/taka/add-to-scrap_taka?id=${id}`,
        data,
        config
      )
      .then((res) => {
        if (res?.status == 200) {
          Swal.fire({ icon: "success", title: "Added To Scrap" });
          dispatch(getAllRawMaterial("", ""));
        }
      });
  };

  useEffect(() => {
    if (error == "No Raw Inventory found") {
      Swal.fire({ icon: "error", title: "No Result Found" });
    }
  }, [error]);

  return (
    <>
      {" "}
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "kc_fabric_code"}
                  direction={order}
                  onClick={() => handleSort("kc_fabric_code")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Kennys Fabric Code
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "taka_reserve_quantity"}
                  direction={order}
                  onClick={() => handleSort("taka_reserve_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Qty Rec.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "return_quantity"}
                  direction={order}
                  onClick={() => handleSort("return_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Qty Return.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "taka_reserve_quantity"}
                  direction={order}
                  onClick={() => handleSort("taka_reserve_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Qty Available
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "raw_material_inventory.raw_invoice_no"}
                  direction={order}
                  onClick={() =>
                    handleSort("raw_material_inventory.raw_invoice_no")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Invoice No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={
                    orderBy === "raw_material_inventory.raw_invoice_value"
                  }
                  direction={order}
                  onClick={() =>
                    handleSort("raw_material_inventory.raw_invoice_value")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Invoice Value
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "raw_cost_details.cost_rate_per_meter"}
                  direction={order}
                  onClick={() =>
                    handleSort("raw_cost_details.cost_rate_per_meter")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Rate Per Unit
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Invoice Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "taka_no"}
                  direction={order}
                  onClick={() => handleSort("taka_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Taka Number
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Rack Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "purchase_order.purchase_order_no"}
                  direction={order}
                  onClick={() => handleSort("purchase_order.purchase_order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  PO No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_fabric_code"}
                  direction={order}
                  onClick={() => handleSort("supplier_fabric_code")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier Fabric Code
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_master.supplier_name"}
                  direction={order}
                  onClick={() => handleSort("supplier_master.supplier_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              {permissions?.inventory_edit == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#202020",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.kc_fabric_code}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.taka_recieved_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.return_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.taka_reserve_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.raw_material_inventory.raw_invoice_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.raw_material_inventory.raw_invoice_value}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.raw_cost_details?.cost_rate_per_meter
                    ? row?.raw_cost_details?.cost_rate_per_meter
                    : "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.raw_material_inventory.raw_invoice_date == null
                    ? "N/A"
                    : displayDateFun(
                        row.raw_material_inventory.raw_invoice_date
                      )}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>{row.taka_no}</TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.raw_rack_details ? row?.raw_rack_details : "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.purchase_order.purchase_order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.supplier_fabric_code}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.supplier_master.supplier_name}
                </TableCell>
                {permissions?.inventory_edit == true && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <EditLocationIcon />,
                          title: "Change Location",
                          action: "changeLocation",
                          data: row,
                        },
                        {
                          icon: <AddCircleOutlineIcon />,
                          title: "Manually Issue",
                          action: "manuallyIssue",
                          data: row,
                        },
                        {
                          icon: <PrintIcon />,
                          title: "Print Tag",
                          action: "printTag",
                          data: row,
                        },
                        ...(row.taka_reserve_quantity <= 1
                          ? [
                              {
                                icon: <SwitchAccessShortcutAddIcon />,
                                title: "Add To Wastage",
                                action: "addToWastage",
                                data: row,
                              },
                            ]
                          : []),
                        {
                          icon: <MiscellaneousServicesIcon />,
                          title: "Add To Scrap",
                          action: "rawscrapped",
                          data: row,
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          <ChangeLocation
            open={isInfoModalOpen}
            onClose={() => setInfoModalOpen(false)}
            rowData={selectedRowData}
            type={"rawMaterial"}
          />
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
