import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllFabric } from "app/redux/actions/masterAction";
import ListFabricTable from "./fabricTable";
import { getFabricColorList } from "app/services/apis/ListApi/fabricColorList";

export default function ListFabric() {
  const [searchTerm, setSearchTerm] = useState("");
  const [fabricColor, setFabricColor] = useState([]);
  const [selectedColor, setSelectedColor] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedColor(newValue);
    newValue?.length == 0 && dispatch(getAllFabric("", "", null));
  };

  const handleSearch = (value) => {
    dispatch(getAllFabric(value));
  };

  const handleFilter = () => {
    dispatch(getAllFabric("", JSON.stringify(selectedColor)));
  };

  const handleClear = () => {
    dispatch(getAllFabric("", "", null));
    setSelectedColor([]);
  };

  useEffect(async () => {
    dispatch(getAllFabric("", "", 1));
    setFabricColor(await getFabricColorList());
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Fabric Master</Typography>
      <Div sx={{ mt: 4, display: "flex", alignItems: "center" }}>
        <Div sx={{ width: "25%", mr: 5 }}>
          <Typography variant="h5">Fabric Colors</Typography>
          <Autocomplete
            width={"50%"}
            size="small"
            multiple
            id="tags-standard"
            options={fabricColor ? fabricColor : null}
            getOptionLabel={(option) => option}
            value={selectedColor} // Use the selectedColor state for the value
            onChange={handleAutocompleteChange}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                sx={{ width: "88%" }}
                {...params}
                variant="outlined"
                placeholder="Choose Fabric Colors"
              />
            )}
          />
        </Div>
        <Div sx={{ display: "flex", alignItems: "center", mt: 3.3 }}>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Div>
      </Div>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        {permissions?.fabric_master_create == true && (
          <Div>
            <Button
              variant="contained"
              sx={{ p: 1, pl: 4, pr: 4 }}
              onClick={() => navigate("/master/fabric/add")}
            >
              Add Fabric
            </Button>
          </Div>
        )}
      </Div>
      <ListFabricTable searchTerm={searchTerm} />
    </Div>
  );
}
