import Div from "@jumbo/shared/Div";
import React from "react";
import { useLocation } from "react-router-dom";

const PrintKCTag = () => {
  const { state } = useLocation();
  console.log(state);

  const text = state?.data?.fabric_master?.supplier_fabric_code;
  const textLength = text.length;
  const baseFontSize = 12; // Adjust this value as needed

  // Calculate the font size dynamically based on the length of the text
  const fontSize = Math.max(baseFontSize - textLength * 0.2, 10);
  const fabricCode = state?.data?.kc_fabric_code;
  const splitCode = fabricCode.split("-");

  const code = splitCode[0]; // Extracting the first part of the code (before the first '-')
  console.log(code, "code");
  const numbers = splitCode.slice(1).join("-");
  console.log(numbers, "numbers");
  return (
    <Div sx={{ display: "flex", ml: 1 }}>
      <Div
        sx={{
          width: "188.9764px",

          paddingLeft: "10px",
          mt: -1,
        }}
      >
        <h3 style={{ width: "80%", lineHeight: 1.2 }}>
          {/* {state?.data?.kc_fabric_code}
           */}
          {code} <br /> {numbers}
        </h3>
        <h4
          style={{ fontSize: `${fontSize}px`, marginTop: -15, lineHeight: 1 }}
        >
          {state?.data?.fabric_master?.supplier_fabric_code}
          {/* https://www. youtube. com/watch?v= wNWyMsrpbz0 */}
        </h4>
      </Div>
      <Div
        sx={{
          width: "188.9764px",

          paddingLeft: "10px",
          mt: -1,
        }}
      >
        <h3 style={{ width: "80%", lineHeight: 1.2 }}>
          {/* {state?.data?.kc_fabric_code} */}
          {code} <br /> {numbers}
        </h3>
        <h4
          style={{ fontSize: `${fontSize}px`, marginTop: -15, lineHeight: 1 }}
        >
          {/* https://www. youtube. com/watch?v= wNWyMsrpbz0 */}
          {state?.data?.fabric_master?.supplier_fabric_code}
        </h4>
      </Div>
    </Div>
  );
};

export default PrintKCTag;
