import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Label } from "recharts";

const FetchModal = ({
  measurement,
  setMeasurement,
  open,
  onClose,
  rowData,
  selectedDropdown,
  setRefNo,
  refOrderNo
}) => {
  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState([]);

  console.log(selected);
  const navigate = useNavigate();
  const handleSelect = (e, data, rowIndex) => {
    if (e.target.checked != true) {
      // console.log("insiiiiiiiiiiiide", data);
      // console.log(rowIndex, "rooooooooooooooooooo");
      const updatedItemDetails = [...selected];

      updatedItemDetails[rowIndex] = null;

      const updatedMeasurement = measurement?.filter((item) => {
        return (
          item?.item_master_item_name !=
          selected[rowIndex]?.item_master_item_name
        );
      });
      setMeasurement(updatedMeasurement);
      setSelected(updatedItemDetails);
    } else {
      const isExist = selected.filter(
        (ele) => ele?.item_master_item_name == data?.item_master_item_name
      );

      if (isExist.length > 0) {
        var index = selected?.findIndex(
          (item) =>
            item?.item_master_item_name === isExist[0]?.item_master_item_name
        );
        // console.log(index);
        // selected?.splice(index, 1);
        const updatedItemDetails = [...selected];
        updatedItemDetails[index] = null;
        updatedItemDetails[rowIndex] = {
          item_master_item_name: data?.item_master_item_name,
          item_master_measurement: data?.item_master_measurement,
          order_item_no: data?.order_item_no,
        };
        setSelected(updatedItemDetails);
        // console.log("adnariddddddddddddddddddd");
      } else {
        // console.log("andar elseeeeeeee");
        const updatedItemDetails = [...selected];
        updatedItemDetails[rowIndex] = {
          item_master_item_name: data?.item_master_item_name,
          item_master_measurement: data?.item_master_measurement,
          order_item_no: data?.order_item_no,
        };
        setSelected(updatedItemDetails);
      }
    }
  };

  function findUncommonObjects(arr1, arr2) {
    const names1 = new Set(arr1?.map((item) => item.item_master_item_name));

    const uncommonObjects = arr2?.filter(
      (item) => !names1?.has(item?.item_master_item_name)
    );
    return uncommonObjects;
  }
  function findCommonObjects(arr1, arr2) {
    const names1 = new Set(arr1?.map((item) => item?.item_master_item_name));

    const commonObjects = arr2?.filter((item) =>
      names1?.has(item?.item_master_item_name)
    );
    return commonObjects;
  }
  useEffect(() => {
    if (selected?.length > 0) {
      const selectedMeasurement = selected?.filter((ele) => ele != null);
      const commonObj = findCommonObjects(measurement, selectedMeasurement);
      const unCommonObj = findUncommonObjects(measurement, selectedMeasurement);
      if (commonObj?.length > 0) {
        const updatedMeasurement = [...measurement];
        commonObj?.forEach((element) => {
          var index = measurement?.findIndex(
            (item) =>
              item?.item_master_item_name === element?.item_master_item_name
          );
          updatedMeasurement[index] = element;
        });
        setMeasurement(updatedMeasurement);
      }
      if (unCommonObj?.length > 0) {
        const updatedMeasurement = measurement?.filter((item) => {
          return (
            item?.item_master_item_name == unCommonObj[0]?.item_master_item_name
          );
        });
        updatedMeasurement.push(unCommonObj[0]);
        setMeasurement([...measurement, ...updatedMeasurement]);
      }
    }
  }, [selected]);

  useEffect(() => {
    const uncommon = findUncommonObjects(selectedDropdown, selected);
    const dat = uncommon.filter((item) => {
      return item != null;
    });
    // console.log(dat);
    if (dat?.length > 0) {
      // console.log(uncommon, "innnnnnnnnnnnnnnnnnnnnnnnnnnnn");
      var index = selected?.findIndex(
        (item) => item?.item_master_item_name === dat[0]?.item_master_item_name
      );
      // console.log(index);

      // const updatedItemDetails = [...selected];
      // updatedItemDetails?.splice(index, 1);
      const updatedItemDetails = [...selected];

      updatedItemDetails[index] = null;
      setSelected(updatedItemDetails);
    }
  }, [selectedDropdown]);

  useEffect(() => {
    setSelected([]);
    setMeasurement([]);
  }, [rowData]);

  useEffect(() => {
    //setRefNo
    const refNo = selected.filter((ele) => ele != null);
    if (refNo.length > 0) {
      setRefNo(refOrderNo);
    }
  }, [selected]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle sx={{ fontWeight: "700" }}>
          Select Measurements
        </DialogTitle>
      </Div>
      <DialogContent>
        <TableContainer component={Paper} sx={{ height: "50vh" }}>
          <Table sx={{ position: "relative" }}>
            <TableHead sx={{ position: "sticky", top: 0, zIndex: 2 }}>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "160px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Select
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "160px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "160px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "160px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  SubItem Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                height: "10vh",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "12px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#6b6b6b",
                  borderRadius: "2px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#d4d4d4",
                  borderRadius: "1px",
                },
              }}
            >
              {rowData?.measurement?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left" }}>
                    <Checkbox
                      {...Label}
                      checked={selected?.some(
                        (item1) =>
                          item1?.item_master_item_name ===
                            row?.item_master_item_name &&
                          item1?.order_item_no === row?.order_item_no
                      )}
                      onChange={(e) => handleSelect(e, row, i)}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.order_item_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.order_item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.item_master_item_name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Div sx={{ display: "flex", columnGap: 2 }}>
          <Button
            size="small"
            onClick={onClose}
            color="error"
            variant="contained"
          >
            Close
          </Button>
        </Div>
      </DialogActions>
    </Dialog>
  );
};

export default FetchModal;
