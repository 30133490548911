import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";
import { displayDateFun } from "app/utils/constants/functions";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddonsModal from "../AddNewOrder/addOnsModal";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import Swal from "sweetalert2";
import { getFactoryFabricDropdown } from "app/services/apis/ListApi/FactoryFabricList";
import PreviewModal from "../EditItem/PreviewOrderItem";
import "../../../../pages/css/swal.css";

export const EditOrderItem = () => {
  const [loading, setLoading] = useState(false);
  let { state } = useLocation();
  state = state.menuItemData;
  const navigate = useNavigate();
  const [kfcList, setKFCList] = useState([]);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [factoryNameList, setFactoryNameList] = useState(null);
  const [itemDetails, setItemDetails] = useState([state]);
  const [data, setData] = useState(state);

  const [open, setOpen] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  const submitPreview = async () => {
    setLoading(true);
    setOpen(true);
    const body = {
      order_item_kc_fabric_code: data?.order_item_kc_fabric_code,
      order_item_fabric_required: data?.order_item_fabric_required,
      factory_master_id: data?.factory_master_id,
      order_item_remarks: data?.order_item_remarks,
      taka_id: data?.taka_id,
      order_item_addOns: itemDetails?.undefined?.order_item_addOns,
    };
    setPreviewData(body);
  };

  const submitData = async () => {
    setLoading(true);
    const body = {
      order_item_kc_fabric_code: data?.order_item_kc_fabric_code,
      order_item_fabric_required: data?.order_item_fabric_required,
      factory_master_id: data?.factory_master_id,
      order_item_remarks: data?.order_item_remarks,
      taka_id: data?.taka_id,
      order_item_addOns: itemDetails?.undefined?.order_item_addOns,
    };
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const result = await axios.post(
        `${process.env.REACT_APP_URL}/api/order/update-order_item?order_items_id=${data?.order_items_id}`,
        body,
        config
      );

      Swal.fire({
        customClass: {
          container: "popupImportant",
        },
        position: "center",
        icon: "success",
        text: result?.data?.message,
        showConfirmButton: false,
        timer: 1500,
      });

      setLoading(false);
      navigate("/dashboard/order/admin");
    } catch (error) {
      setLoading(false);

      // Swal.fire(
      //   "Error Updating Details",
      //   error?.response?.data?.message,
      //   "error"
      // );
      Swal.fire({
        customClass: {
          container: "popupImportant",
        },
        position: "center",
        icon: "success",
        text: error?.response?.data?.message,
        showConfirmButton: false,
        timer: 1500,
      });

      // console.log(error.response);
    }
  };
  useEffect(() => {
    const initialData = async () => {
      setKFCList(await getFactoryFabricDropdown());
      setFactoryNameList(await getFactoryNameList());
    };
    initialData();
  }, []);
  return (
    <>
      <Typography variant="h1" mt={-5}>
        Edit Order Item
      </Typography>
      <Div sx={{ mt: 2 }}>
        <Div sx={{ display: "flex", justifyContent: "space-between" }}>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography variant="h3" fontWeight={600}>
              Order No:-
            </Typography>
            <Typography variant="h3">{state?.order_no}</Typography>
          </Div>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography variant="h3" fontWeight={600}>
              Client Name:-
            </Typography>
            <Typography variant="h3">{state?.order_client_name}</Typography>
          </Div>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography variant="h3" fontWeight={600}>
              Order Date:-
            </Typography>
            <Typography variant="h3">
              {displayDateFun(state?.item_order_date)}
            </Typography>
          </Div>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography variant="h3" fontWeight={600}>
              Port No & Name:-
            </Typography>
            <Typography variant="h3">
              {state?.order_port_no_and_name}
            </Typography>
          </Div>
        </Div>
        <Div>
          <Typography variant="h3" fontWeight={600}>
            Item Details:-
          </Typography>
          <Div>
            <TableContainer component={Paper} sx={{ width: "100%", mt: 3 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        color: "white",
                        width: "100px",
                      }}
                    >
                      Item No
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        color: "white",
                        width: "120px",
                      }}
                    >
                      Item Name
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "left",
                        color: "white",
                        width: "120px",
                      }}
                    >
                      Sub Item
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "left",
                        color: "white",
                        width: "260px",
                      }}
                    >
                      Fabric Code
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        color: "white",
                        width: "200px",
                      }}
                    >
                      Fabric Required
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        color: "white",
                        width: "120px",
                      }}
                    >
                      Add Addons
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        color: "white",
                        width: "200px",
                      }}
                    >
                      Factory
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        color: "white",
                      }}
                    >
                      Remark
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {data?.order_item_no}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {data?.order_item_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {data?.order_sub_item_name}
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      style={{ width: "190px" }}
                      size="small"
                      options={kfcList}
                      value={data?.order_item_kc_fabric_code}
                      getOptionLabel={(option) =>
                        option.kc_fabric_code ? option.kc_fabric_code : option
                      }
                      onChange={(e, newValue) => {
                        setData({
                          ...data,
                          order_item_kc_fabric_code:
                            newValue == null ? "" : newValue.kc_fabric_code,
                        });
                        // const updated_details = [...itemDetails];
                        // updated_details[i] = {
                        //   ...updated_details[i],
                        //   order_item_kc_fabric_code:
                        //     newValue == null ? "" : newValue.kc_fabric_code,
                        // };
                        // setItemDetails(updated_details);
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option?.kc_fabric_code}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      style={{ width: "90px" }}
                      inputProps={{ min: 1 }}
                      type="number"
                      size="small"
                      name="order_item_fabric_required"
                      value={data?.order_item_fabric_required}
                      onChange={(e) => {
                        setData({
                          ...data,
                          order_item_fabric_required: Number(e.target.value),
                        });
                      }}
                    ></TextField>
                  </TableCell>
                  <TableCell>
                    <Div sx={{ display: "flex", justifyContent: "center" }}>
                      <AddCircleOutlineOutlined
                        sx={{ cursor: "pointer" }}
                        onClick={() => setInfoModalOpen(true)}
                      />
                    </Div>
                  </TableCell>
                  {/* <TableCell>{data.order_item_factory_name}</TableCell> */}
                  {/* <TableCell>
                    <Select
                      size="small"
                      sx={{ width: "150px" }}
                      name="factory_master_id"
                      value={data?.factory_master_id}
                      onChange={(e) =>
                        setData({
                          ...data,
                          factory_master_id: e.target.value,
                        })
                      }
                    >
                      {factoryNameList &&
                        factoryNameList.length &&
                        factoryNameList?.map((item) => {
                          return (
                            <MenuItem value={item.id}>
                              {item?.factory_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </TableCell> */}
                  <TableCell>
                    <Select
                      size="small"
                      sx={{ width: "150px" }}
                      name="factory_master_id"
                      value={data?.factory_master_id || ""}
                      // onChange={(e) => {
                      //   if (e && e.target && e.target.value !== undefined) {
                      //     setData({
                      //       ...data,
                      //       factory_master_id: e.target.value,
                      //     });
                      //   } else {
                      //     console.error("Invalid event or value");
                      //   }
                      // }}
                      onChange={(e) =>
                        setData({
                          ...data,
                          factory_master_id: e.target.value,
                        })
                      }
                    >
                      {factoryNameList &&
                        factoryNameList.length &&
                        factoryNameList?.map((item) => (
                          <MenuItem key={item?.id} value={item.id}>
                            {item?.factory_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </TableCell>

                  <TableCell>
                    <TextField
                      sx={{ width: "300px" }}
                      multiline
                      size="small"
                      name={"order_item_remarks"}
                      value={data?.order_item_remarks}
                      onChange={(e) => {
                        setData({
                          ...data,
                          order_item_remarks: e.target.value,
                        });
                      }}
                    />
                  </TableCell>
                </TableBody>
              </Table>

              <AddonsModal
                open={isInfoModalOpen}
                onClose={() => setInfoModalOpen(false)}
                rowData={data}
                setItemDetails={setItemDetails}
                itemDetails={itemDetails}
              />
            </TableContainer>

            {open && (
              <PreviewModal
                open={open}
                setOpen={setOpen}
                rowData={data}
                previewData={previewData}
              />
            )}
          </Div>
        </Div>
        <Div sx={{ display: "flex", justifyContent: "center", mt: 4, gap: 2 }}>
          {/* <LoadingButton
            variant="contained"
            onClick={() => {
              navigate("/dashboard/order/admin");
            }}
          >
            Cancel
          </LoadingButton> */}
          {data?.factory_master_id === null ? (
            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              onClick={() => submitData()}
            >
              Update
            </LoadingButton>
          ) : (
            <Button
              disabled={data?.factory_master_id === null}
              // loading={loading}
              variant="contained"
              onClick={() => submitPreview()}
            >
              Preview
            </Button>
          )}
        </Div>
      </Div>
    </>
  );
};
