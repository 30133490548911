import axios from "axios";

export const changeTaka = async (inputs) => {
  try {
    console.log(inputs);
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/api/order/update-taka-details`,
      inputs,
      config
    );
    // console.log(data);
    return data;
  } catch (error) {
    // console.log(error);
    return error.response;
  }
};
