import Div from "@jumbo/shared/Div/Div";
import {
  Button,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import ListOptions from "app/components/Dropdown/ListOptions";
import FormTextField from "app/components/InputField/FormTextField";
import {
  codeList,
  genderList,
  outerDiv,
} from "app/utils/constants/dropdowns.js";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { dateFun, formattedDate } from "app/utils/constants/functions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateUserDetailsAdmin } from "app/services/apis/updateUserDetailsAdmin";
import Swal from "sweetalert2";
import { addUser } from "app/services/apis/addUser";
import { getRoles } from "app/services/apis/getRoles";
import { LoadingButton } from "@mui/lab";

export default function AddUser() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [roles, setRoles] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const { pathname } = useLocation();
  const data = state;
  const user = {
    employee_id: data?.employee_id ? data.employee_id : "",
    first_name: data?.first_name ? data?.first_name : "",
    last_name: data?.last_name ? data?.last_name : "",
    email_id: data?.email_id ? data?.email_id : "",
    country_code: data?.country_code ? data?.country_code : "+91",
    phone: data?.phone ? data?.phone : null,
    gender: data?.gender ? data?.gender : "Select",
    date_of_birth: data?.date_of_birth ? data?.date_of_birth : formattedDate,
    role_name: data?.role ? data?.role : "Select",
    user_status:
      data?.user_status == 0 || data?.user_status == 1 ? data?.user_status : 1,
  };
  const validationSchema = yup.object({
    employee_id: yup
      .string("Enter Employee ID")
      .required("Employee ID is required")
      .matches(/^[0-9]+$/, "Employee ID must be a number"),
    first_name: yup
      .string("Enter First Name")
      .required("First Name is required")
      .matches(
        /^[A-Za-z]+$/,
        "First Name must contain only alphabetic characters"
      ),
    last_name: yup
      .string("Enter Last Name")
      .required("Last Name is required")
      .matches(
        /^[A-Za-z]+$/,
        "Last Name must contain only alphabetic characters"
      ),
    email_id: yup
      .string("Enter your Email ID")
      .email("Enter a valid Email ID")
      .required("Email is required"),
    phone: yup
      .number()
      .typeError("Phone number must be a number")
      .required("Phone Number is Required"),
    gender: yup
      .string()
      .required("Gender is required")
      .test(
        "gender-not-select",
        "Please select a valid Gender",
        (value) => value !== "Select"
      ),
    date_of_birth: yup
      .date()
      .test("not-current-date", "Enter Valid Date of Birth", function (value) {
        if (!value) {
          // Handle case where value is not provided
          return false;
        }

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set time to midnight

        return value < currentDate; // Change this to <= to allow the current date
      })
      .required("Date Of Birth is required"),
  });

  const onUserSave = async (values) => {
    setSubmitting(true);
    if (values.role_name.role_name == "Select") {
      return Swal.fire({
        icon: "warning",
        title: "Please Select Role",
        text: "",
      });
    }
    if (pathname == "/dashboard/edituser") {
      const data = await updateUserDetailsAdmin({
        ...values,
        date_of_birth: dateFun(values.date_of_birth),
        id: state.id,
      });
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "User Edited Successfully",
          text: "",
        });
        navigate("/dashboard/user");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addUser({
        ...values,
        date_of_birth: dateFun(values.date_of_birth),
      });

      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "User Added Successfully",
          text: "",
        });
        navigate("/dashboard/user");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  useEffect(async () => {
    setRoles(await getRoles());
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/dashboard/adduser" ? "Add New User" : "Edit User"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={user}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <FormTextField name="employee_id" label="Employee ID" />
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 4,
                  }}
                >
                  <FormTextField name="first_name" label="First Name" />
                  <FormTextField name="last_name" label="Last Name" />
                  <FormTextField name="email_id" label="Email ID" />
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Phone No*</Typography>
                    <Div sx={{ display: "flex", mt: -1 }}>
                      <ListOptions
                        options={codeList}
                        name={"country_code"}
                        sx={{ width: "80px" }}
                      />
                      <FormTextField name="phone" sx={{ width: "100%" }} />
                    </Div>
                  </Div>

                  <ListOptions
                    name="gender"
                    label="Gender"
                    options={genderList}
                  />
                  <Div sx={outerDiv}>
                    <Typography variant="h5">Date of Birth</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          "&.MuiTextField-root": {
                            height: "39px",
                            flexDirection: "unset",
                          },
                        }}
                        maxDate={dayjs()}
                        format="DD/MM/YY"
                        defaultValue={dayjs(values.date_of_birth)}
                        onChange={(date) => {
                          setFieldValue("date_of_birth", date);
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="date_of_birth"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </LocalizationProvider>
                  </Div>
                  <Div sx={outerDiv}>
                    <Typography variant="h5">Role</Typography>
                    <Select
                      labelId="role_name"
                      id="role_name"
                      value={
                        values?.role_name?.role_name
                          ? values?.role_name?.role_name
                          : "Select"
                      }
                      onChange={(event) => {
                        setFieldValue("role_name", {
                          role_name: event.target.value,
                        });
                      }}
                      sx={{
                        ".css-153xi1v-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                          { padding: 1.2 },
                      }}
                    >
                      <MenuItem value="Select">Select</MenuItem>
                      {roles?.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                  </Div>
                </Div>

                <Div sx={{ mt: 5 }}>
                  <Typography variant="h5">Status</Typography>
                  <Switch
                    onChange={(e) => {
                      setFieldValue(
                        "user_status",
                        values.user_status == 1 ? 0 : 1
                      );
                    }}
                    defaultChecked={values.user_status == 1 ? true : false}
                    sx={{
                      p: 0,
                      width: "70px",
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: values.user_status === 1 ? "green" : "red",
                        width: "90%",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor:
                            values.user_status === 1 ? "green" : "red",
                        },
                    }}
                  />
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                 >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/user");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
