import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { getQCOrderList } from "app/redux/actions/orderAction";
import {
  dateFun,
  displayDateFun
} from "app/utils/constants/functions";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import QcOrderDetailsModal from "./qcOrderModal";
import FullScreenLoader from "app/components/ListingPageLoader";
const ChallanListTable = ({ searchTerm, setPage, page, inputs }) => {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");

  const [receiveLoader, setReceiveLoader] = useState(false);
  const [alterationLoader, setAlterationLoader] = useState(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { qcOrder, TotalPage,loading } = useSelector((state) => state.orderReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // Replace this with your actual pagination logic
    dispatch(getQCOrderList(searchTerm, inputs, newPage, "challanList"));
  };

  const handleDownload = async (row) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_URL}/api/send-for-alteration/send_for_alteration-pdf?qc_details_id=${row.qc_details_id}`
        )
        .then((res) => {
          window.open(res.data.result);
        })
        .catch((err) => {
          alert(err);
        });
    } catch (error) {
      // console.log(error);
      alert("Something Went Wrong");
    } finally {
    }
  };

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = qcOrder?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_sub_item_name") {
      aValue = a.order_sub_item_name.toLowerCase();
      bValue = b.order_sub_item_name.toLowerCase();
    } else if (orderBy == "order_item_factory_name") {
      aValue = a.order_item_factory_name.toLowerCase();
      bValue = b.order_item_factory_name.toLowerCase();
    } else if (orderBy == "order_item_qc_status") {
      aValue = a.order_item_qc_status.toLowerCase();
      bValue = b.order_item_qc_status.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleAction = (status, row) => {
    // console.log(status);
    if (status == "startqc") {
      navigate("/dashboard/order/startqc", { state: row });
    } else if (status == "receive") {
      onHandleReceive(row.order_items_id);
    } else if (status == "sendAlteration") {
      // onHandleAlteration(row.order_items_id);
      navigate("/dashboard/order/qc/generateChallan", { state: row });
    }
  };

  const onHandleReceive = async (id) => {
    try {
      setReceiveLoader(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/api/qc/receive?order_items_id=${id}`,
        config
      );
      if (res?.status == 200) {
        dispatch(getQCOrderList());
      }
    } catch (error) {
      alert("Something Went Wrong");
    } finally {
      setReceiveLoader(false);
    }
  };
  const onHandleAlteration = async (id) => {
    try {
      setAlterationLoader(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/api/qc/send-for-alteration?order_items_id=${id}`,
        config
      );
      if (res?.status == 200) {
        dispatch(getQCOrderList());
      }
    } catch (error) {
      // console.log(error);
      alert("Something Went Wrong");
    } finally {
      setAlterationLoader(false);
    }
  };

  const handleCollectionDate = async (newDate, row) => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      await axios.patch(
        `${process.env.REACT_APP_URL}/api/production/edit-collection-date?order_items_id=${row.order_items_id}`,
        {
          order_item_collection_date: dateFun(newDate),
        },
        config
      );
    } catch (error) {
      alert("error");
    }
  };

  const handleInfoIconClick = (rowData) => {
    setSelectedRowData(rowData);
    setInfoModalOpen(true);
  };

  return (
    <>      {loading && <FullScreenLoader />}
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Port No & Name
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",
                px: 1,
              }}
            >
              <TableSortLabel
                active={orderBy === "order_no"}
                direction={order}
                onClick={() => handleSort("order_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Order No.
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_no"}
                direction={order}
                onClick={() => handleSort("order_item_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Item No.
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "115px",
                px: 1,
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_name"}
                direction={order}
                onClick={() => handleSort("order_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Item Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "125px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_sub_item_name"}
                direction={order}
                onClick={() => handleSort("order_sub_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Sub Item Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "100px",
                verticalAlign: "middle",
                px: 1,
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_job_work_id"}
                direction={order}
                onClick={() => handleSort("order_item_job_work_id")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Job Work ID
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: "105px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Order Details
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_factory_name"}
                direction={order}
                onClick={() => handleSort("order_item_factory_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Factory
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Status
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                width: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_status"}
                direction={order}
                onClick={() => handleSort("order_item_status")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                QC Status
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "125px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "qc_details_create_date "}
                direction={order}
                onClick={() => handleSort("qc_details_create_date ")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                QC Date
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "115px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Order Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "115px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Challan Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Challan
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_port_no_and_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row.order_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_item_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row.order_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_sub_item_name || "-"}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row.order_item_job_work_id || "-"}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                <InfoIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleInfoIconClick(row)}
                />
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_item_factory_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_item_status}
              </TableCell>

              <TableCell sx={{ textAlign: "left" }}>
                {row.order_item_qc_status != null
                  ? row.order_item_qc_status
                  : "-"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {displayDateFun(row.qc_details_create_date)}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {displayDateFun(row.order_date)}
              </TableCell>

              <TableCell sx={{ textAlign: "left" }}>
                {displayDateFun(row.send_for_alteration_create_date)}
              </TableCell>
              <TableCell sx={{ alignItems: "left", px: 1 }}>
                <Button size="small" onClick={() => handleDownload(row)}>
                  Download
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1} // Replace with the actual total number of pages
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
      <QcOrderDetailsModal
        open={isInfoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        rowData={selectedRowData}
      />
    </TableContainer>
    </>
  );
};

export default ChallanListTable;
