import axios from "axios";

export const downloadAllOrderReport = async (inputs) => {
  // console.log(inputs);

  if (inputs.order_no == undefined) {
    inputs.order_no = "";
  }
  if (inputs.employee_id == undefined) {
    inputs.employee_id = "";
  }
  if (inputs.order_port_no_and_name == undefined) {
    inputs.order_port_no_and_name = "";
  }
  if (inputs.order_date == undefined) {
    inputs.order_date = "";
  }

  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/order/created-order-download-excel?order_no=${inputs.order_no}&employee_id=${inputs.employee_id}&order_port_no_and_name=${inputs.order_port_no_and_name}&order_date=${inputs.order_date}`,
      config
    );
    return data?.data?.result;
  } catch (error) {
    console.log(error);
    return error?.response?.data?.result;
  }
};
