
import Page from "@jumbo/shared/Page";
import InventoryList from "app/pages/Inventory/List Inventory";
import AddStock from "app/pages/Inventory/AddStock";
import ManuallyIssueTable from "app/pages/Inventory/ActionComponent/manuallyIssue";
import InventoryRouteMiddleware from "./middleware/auth/inventoryValidRoute";
import PrintTag from "app/pages/Inventory/PrintTag";
import EditProduction from "app/pages/Inventory/EditProduction/EditPrduction";

export const inventoryRoute = [
  {
    middleware: [
      {
        element: InventoryRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/inventory",
        element: <Page component={InventoryList} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/inventory/add",
        element: <Page component={AddStock} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/inventory/manuallyIssue",
        element: (
          <Page component={ManuallyIssueTable} layout={"vertical-default"} />
        ),
      },
      {
        path: "/dashboard/inventory/printTag",
        element: (
          <Page component={PrintTag} layout={"solo-page"}/>
        ),
      },
      {
        path: "/dashboard/inventory/production/edit",
        element: (
          <Page component={EditProduction} layout={"vertical-default"}/>
        ),
      },
    ],
  },
];
