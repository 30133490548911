import Div from "@jumbo/shared/Div";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListCostingTable from "./costingTable";
import { getAllCosting } from "app/redux/actions/masterAction";
import { useDispatch, useSelector } from "react-redux";
import { downloadCostingMasterReport } from "app/services/apis/ReportApis/CostingmasterReport";

export default function ListCosting() {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const { costingMaster, TotalPage } = useSelector(
    (state) => state.masterReducer
  );

  const handleSearch = (value) => {
    dispatch(getAllCosting(value, 1));
  };

  useEffect(() => {
    dispatch(getAllCosting());
  }, []);
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Costing Master</Typography>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setPage(1);
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
         <Div sx={{ display: "flex", gap: 2 }}>
         <Button
            disabled={costingMaster?.length == 0}
            variant="contained"
            onClick={async () => {
              const data = await downloadCostingMasterReport();
              window.open(data);
            }}
          >
            Export CSV
          </Button>
        {permissions?.costing_master_create == true && (
          <Div>
            <Button
              variant="contained"
              sx={{ p: 1, pl: 4, pr: 4 }}
              onClick={() => navigate("/master/costing/add")}
            >
              Add New Costing
            </Button>
          </Div>
        )}
        </Div>
      </Div>
      <ListCostingTable searchTerm={searchTerm} setPage={setPage} page={page} />
    </Div>
  );
}
