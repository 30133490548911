import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { displayDateFun } from "app/utils/constants/functions";
import { getAllProductionHistory } from "app/redux/actions/inventoryAction";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function ProductionHistoryTable({ searchTerm }) {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [page, setPage] = useState(1);
  const { productionHistory, error, TotalPage, loading } = useSelector(
    (state) => state.inventoryReducer
  );
  console.log(productionHistory, "productionHistory");
  const dispatch = useDispatch();

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = productionHistory?.sort((a, b) => {
    let aValue;
    let bValue;
    if (orderBy == "order.order_item_name") {
      aValue = a.order.order_item_name.toLowerCase();
      bValue = b.order.order_item_name.toLowerCase();
    } else if (orderBy == "order.order_item_no") {
      aValue = a.order.order_item_no;
      bValue = b.order.order_item_no;
    } else if (orderBy == "order.order_no") {
      aValue = a.order.order_no;
      bValue = b.order.order_no;
    } else if (orderBy == "order.order_item_job_work_id") {
      aValue = a.order.order_item_job_work_id;
      bValue = b.order.order_item_job_work_id;
    } else if (orderBy == "order.order_item_fabric_required") {
      aValue = a.order.order_item_fabric_required;
      bValue = b.order.order_item_fabric_required;
    } else if (orderBy == "order.order_port_no_and_name") {
      aValue = a.order.order_port_no_and_name;
      bValue = b.order.order_port_no_and_name;
    } else if (orderBy == "order.order_item_status") {
      aValue = a.order.order_item_status.toLowerCase();
      bValue = b.order.order_item_status.toLowerCase();
    } else if (orderBy == "order.order_client_name") {
      aValue = a.order.order_client_name.toLowerCase();
      bValue = b.order.order_client_name.toLowerCase();
    } else if (orderBy == "order.factory_name") {
      aValue = a.order.factory_name.toLowerCase();
      bValue = b.order.factory_name.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllProductionHistory(searchTerm, "", newPage));
  };

  useEffect(() => {
    if (error == "No order found") {
      Swal.fire({ icon: "error", title: "No Result Found" });
    }
  }, [error]);

  return (
    <>
      {" "}
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order.order_item_name"}
                  direction={order}
                  onClick={() => handleSort("order.order_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  px: 1,
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order.order_item_name"}
                  direction={order}
                  onClick={() => handleSort("order.order_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sub Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  px: 1,
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order.order_item_fabric_required"}
                  direction={order}
                  onClick={() => handleSort("order.order_item_fabric_required")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Fabric Used
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order.order_item_no"}
                  direction={order}
                  onClick={() => handleSort("order.order_item_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={orderBy === "order.order_no"}
                  direction={order}
                  onClick={() => handleSort("order.order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Order No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order.order_item_job_work_id"}
                  direction={order}
                  onClick={() => handleSort("order.order_item_job_work_id")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Job Work ID
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={orderBy === "order.order_port_no_and_name"}
                  direction={order}
                  onClick={() => handleSort("order.order_port_no_and_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Port No & Name
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "125px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order.order_item_status"}
                  direction={order}
                  onClick={() => handleSort("order.order_item_status")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order.order_client_name"}
                  direction={order}
                  onClick={() => handleSort("order.order_client_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Client Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order.factory_name"}
                  direction={order}
                  onClick={() => handleSort("order.factory_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Factory
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Est. Delivery Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Supplier
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={orderBy === "order.productions_invoice_no"}
                  direction={order}
                  onClick={() => handleSort("order.productions_invoice_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Invoice No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Amount
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Extra Amount
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                GST%
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Total Amount
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Invoice Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order.order_item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.order.order_sub_item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.order.order_item_fabric_required}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order.order_item_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.order.order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order.order_item_job_work_id}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.order.order_port_no_and_name}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row.order.order_item_status}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order.order_client_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order.factory_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.order.order_est_delivery_date)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.order.supplier_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.productions_invoice_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.productions_amount}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.productions_extra_amount}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.productions_gst_percentage}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.productions_total}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.productions_invoice_date)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
