import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Button, TextField, Typography } from "@mui/material";
import FormTextField from "app/components/InputField/FormTextField";
import axios from "axios";
import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function EditSales() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rateValue, setRateValue] = useState(null);
  // console.log(state);

  const initialValues = {
    cost_price_fabric_cost: Number(state?.cost_price_fabric_cost) || 0,
    cost_price_factory_cost: Number(state?.cost_price_factory_cost) || 0,
    cost_price_addOns_cost: Number(state?.cost_price_addOns_cost) || 0,
    cost_price_suit_cover_cost: Number(state?.cost_price_suit_cover_cost) || 0,
    cost_price_other_charges: Number(state?.cost_price_other_charges) || 0,
    cost_price_freight: Number(state?.cost_price_freight) || 0,
    cost_price_freight_remark: state?.cost_price_freight_remark || "",
    cost_price_remark: state?.cost_price_remark || "",
    total_cost_price: Number(state?.total_cost_price) || 0,
    selling_price_item_price:
      Number(state?.selling_price_item_price).toFixed(2) || 0,
    usd_rate: Number(state?.usd_rate).toFixed(2) || 0,
    selling_price_item_price_in_INR:
      Number(state?.selling_price_item_price_in_INR).toFixed(2) || 0,
    selling_price_addOns_price: Number(state?.selling_price_addOns_price) || 0,
    selling_price_freight: Number(state?.selling_price_freight) || 0,
    selling_price_freight_remark: state?.selling_price_freight_remark || "",
    selling_price_other_charges:
      Number(state?.selling_price_other_charges) || 0,
    selling_price_remark: state?.selling_price_remark || "",
    total_selling_price: Number(state?.total_selling_price).toFixed(2) || 0,
    profit: Number(state?.profit).toFixed(2) || 0,
  };

  const handleSubmit = async (values) => {
    // console.log(values);
    try {
      setIsLoading(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/api/sales/update-sales?id=${state?.sales_id}`,
        values,
        config
      );
      if (res?.status == 200) {
        Swal.fire({
          icon: "success",
          title: ` ${state?.order_sub_item_name} Sale Details Updated`,
        });
        navigate("/dashboard/sales");
      }
    } catch (error) {
      // console.log(error);
      return error.response;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCostChange = (setFieldValue, values, e) => {
    console.log(values, "cost_price_suit_cover_cost");
    const {
      cost_price_fabric_cost,
      cost_price_factory_cost,
      cost_price_addOns_cost,
      cost_price_suit_cover_cost,
      cost_price_other_charges,
      cost_price_freight,
      selling_price_item_price,
      usd_rate,
      selling_price_item_price_in_INR,
      selling_price_addOns_price,
      selling_price_freight,
      selling_price_other_charges,
      total_cost_price,
      total_selling_price,
    } = values;

    // Calculate the total cost price
    const totalCostPrice =
      cost_price_fabric_cost +
      cost_price_factory_cost +
      cost_price_addOns_cost +
      cost_price_suit_cover_cost +
      cost_price_other_charges +
      cost_price_freight;

    // Update the total_cost_price field with the calculated value
    setFieldValue("total_cost_price", Math.round(Number(totalCostPrice)));

    // console.log(selling_price_freight);
    // Calculate the total cost price
    const seliing_price_in_inr =
      Number(selling_price_item_price) * Number(usd_rate);
    setFieldValue(
      "selling_price_item_price_in_INR",
      Math.round(Number(seliing_price_in_inr))
    );
    const totalSellingPrice =
      seliing_price_in_inr +
      selling_price_addOns_price +
      selling_price_freight +
      selling_price_other_charges;

    // Update the total_cost_price field with the calculated value
    setFieldValue("total_selling_price", Math.round(Number(totalSellingPrice)));
    const profit = totalSellingPrice - totalCostPrice;
    // console.log(profit);
    setFieldValue("profit", Math.round(Number(profit)));

    // console.log(values);
  };

  useEffect(async () => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/usd-rate/list-usd-rate`,
        config
      );
      if (data?.status == 200) {
        setRateValue(data.data.result?.new_usd_rate);
      }
    } catch (error) {}
  }, []);

  // console.log(initialValues);
  return (
    <Div sx={{ mt: -6 }}>
      <Formik
        validateOnChange={false}
        initialValues={initialValues}
        validateOnBlur={true}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, form, meta, errors }) => (
          <Form noValidate autoComplete="off">
            <Typography variant="h1">
              {state?.order_sub_item_name} Sale Details
            </Typography>

            <Div sx={{ display: "flex", width: "100%", columnGap: 2 }}>
              <Div sx={{ mt: 4, width: "50%" }}>
                <Typography
                  variant="h2"
                  fontWeight={600}
                  sx={{ mb: 4, textDecoration: "underline" }}
                >
                  Kennys Cost
                </Typography>
                <Div sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <TextField
                    name="cost_price_fabric_cost"
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    label={"Fabric Cost"}
                    value={values?.cost_price_fabric_cost}
                    onChange={(e) => {
                      const newValue = Number(e.target.value);
                      setFieldValue(e.target.name, newValue);
                      handleCostChange(
                        setFieldValue,
                        { ...values, cost_price_fabric_cost: newValue },
                        e
                      );
                    }}
                  />
                  <TextField
                    name="cost_price_factory_cost"
                    label={"Factory Cost"}
                    type="number"
                    value={values?.cost_price_factory_cost}
                    onChange={(e) => {
                      const newValue = Number(e.target.value);
                      setFieldValue(e.target.name, newValue);
                      handleCostChange(
                        setFieldValue,
                        { ...values, cost_price_factory_cost: newValue },
                        e
                      );
                    }}
                  />
                  <Div sx={{ display: "flex", columnGap: 2, width: "100%" }}>
                    <TextField
                      sx={{ width: "50%" }}
                      name="cost_price_addOns_cost"
                      label={"AddOns Cost"}
                      type="number"
                      value={values?.cost_price_addOns_cost}
                      onChange={(e) => {
                        const newValue = Number(e.target.value);
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, cost_price_addOns_cost: newValue },
                          e
                        );
                      }}
                    />
                    <TextField
                      sx={{ width: "50%" }}
                      name="cost_price_suit_cover_cost"
                      label={"Suit Cover Cost"}
                      type="number"
                      value={values?.cost_price_suit_cover_cost}
                      onChange={(e) => {
                        const newValue = Number(e.target.value);
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, cost_price_suit_cover_cost: newValue },
                          e
                        );
                      }}
                    />
                  </Div>
                  <Div sx={{ display: "flex", columnGap: 2, width: "100%" }}>
                    <TextField
                      sx={{ width: "30%" }}
                      name="cost_price_other_charges"
                      label={"Other Charges"}
                      type="number"
                      value={values?.cost_price_other_charges}
                      onChange={(e) => {
                        const newValue = Number(e.target.value);
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, cost_price_other_charges: newValue },
                          e
                        );
                      }}
                    />
                    <TextField
                      sx={{ width: "70%" }}
                      name="cost_price_remark"
                      label={"Remark"}
                      multiline
                      value={values?.cost_price_remark}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, cost_price_remark: newValue },
                          e
                        );
                      }}
                    />
                  </Div>
                  <Div sx={{ display: "flex", columnGap: 2, width: "100%" }}>
                    <TextField
                      sx={{ width: "30%" }}
                      name="cost_price_freight"
                      label={"Freight"}
                      type="number"
                      value={values?.cost_price_freight}
                      onChange={(e) => {
                        const newValue = Number(e.target.value);
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, cost_price_freight: newValue },
                          e
                        );
                      }}
                    />
                    <TextField
                      sx={{ width: "70%" }}
                      name="cost_price_freight_remark"
                      label={"Remark"}
                      multiline
                      value={values?.cost_price_freight_remark}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, cost_price_freight_remark: newValue },
                          e
                        );
                      }}
                    />
                  </Div>
                  <TextField
                    name="total_cost_price"
                    label={"Total Cost Price"}
                    value={values?.total_cost_price}
                  />
                </Div>
              </Div>

              <Div sx={{ mt: 4, width: "50%" }}>
                <Typography
                  variant="h2"
                  fontWeight={600}
                  sx={{ mb: 4, textDecoration: "underline" }}
                >
                  Kennys Sales
                </Typography>
                <Div sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Div sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      sx={{ width: "33.33%" }}
                      name="selling_price_item_price"
                      label={"Item Price (USD)"}
                      type="number"
                      value={values?.selling_price_item_price}
                      onChange={(e) => {
                        const newValue = Number(e.target.value);
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, selling_price_item_price: newValue },
                          e
                        );
                      }}
                    />
                    <TextField
                      sx={{ width: "33.33%" }}
                      name="usd_rate"
                      label={"USD Rate"}
                      type="number"
                      value={values?.usd_rate}
                      onChange={(e) => {
                        const newValue = Number(e.target.value);
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, usd_rate: newValue },
                          e
                        );
                      }}
                    />
                    <TextField
                      sx={{ width: "33.33%" }}
                      name="selling_price_item_price_in_INR"
                      label={"Item Price (INR)"}
                      type="number"
                      value={values?.selling_price_item_price_in_INR}
                      onChange={(e) => {
                        const newValue = Number(e.target.value);
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          {
                            ...values,
                            selling_price_item_price_in_INR: newValue,
                          },
                          e
                        );
                      }}
                    />
                  </Div>
                  <TextField
                    name="selling_price_addOns_price"
                    label={"Add Ons Price"}
                    type="number"
                    value={values?.selling_price_addOns_price}
                    onChange={(e) => {
                      const newValue = Number(e.target.value);
                      setFieldValue(e.target.name, newValue);
                      handleCostChange(
                        setFieldValue,
                        { ...values, selling_price_addOns_price: newValue },
                        e
                      );
                    }}
                  />
                  <Div sx={{ display: "flex", columnGap: 2, width: "100%" }}>
                    <TextField
                      sx={{ width: "30%" }}
                      name="selling_price_freight"
                      label={"Freight"}
                      type="number"
                      value={values?.selling_price_freight}
                      onChange={(e) => {
                        const newValue = Number(e.target.value);
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, selling_price_freight: newValue },
                          e
                        );
                      }}
                    />
                    <TextField
                      sx={{ width: "70%" }}
                      name="selling_price_freight_remark"
                      label={"Remark"}
                      multiline
                      value={values?.selling_price_freight_remark}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, selling_price_freight_remark: newValue },
                          e
                        );
                      }}
                    />
                  </Div>
                  <Div sx={{ display: "flex", columnGap: 2, width: "100%" }}>
                    <TextField
                      sx={{ width: "30%" }}
                      name="selling_price_other_charges"
                      type="number"
                      label={"Other Charges"}
                      value={values?.selling_price_other_charges}
                      onChange={(e) => {
                        const newValue = Number(e.target.value);
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, selling_price_other_charges: newValue },
                          e
                        );
                      }}
                    />{" "}
                    <TextField
                      sx={{ width: "70%" }}
                      name="selling_price_remark"
                      label={"Remark"}
                      multiline
                      value={values?.selling_price_remark}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setFieldValue(e.target.name, newValue);
                        handleCostChange(
                          setFieldValue,
                          { ...values, selling_price_remark: newValue },
                          e
                        );
                      }}
                    />{" "}
                  </Div>
                  <TextField
                    name="total_selling_price"
                    label={"Total Selling Price"}
                    value={values?.total_selling_price}
                  />{" "}
                  <TextField
                    name="profit"
                    label={"Profit"}
                    value={values?.profit}
                  />{" "}
                </Div>
              </Div>
            </Div>
            <Div
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
                mt: 4,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to cancel?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/dashboard/sales");
                    }
                  });
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ width: "100px" }}
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </Div>
          </Form>
        )}
      </Formik>
    </Div>
  );
}
