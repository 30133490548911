import Div from "@jumbo/shared/Div";
import { Button, MenuItem, Select, Typography } from "@mui/material";

import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "app/redux/actions/orderAction";

import axios from "axios";
import { downloadYearlySalesListReport } from "app/services/apis/ReportApis/Sales/YearlySalesReport";

const SalesYearlyReport = () => {
  const [inputs, setInputs] = useState({});
  const [yearList, setYearList] = useState([]);
  const [mnthList, setMnthList] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleFilter = async () => {
    let data = await downloadYearlySalesListReport(inputs);
    window.open(data);
  };

  const handleClear = () => {
    setInputs({});
  };

  useEffect(async () => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/reports/list-order-months?year=${inputs?.month_year}&gender=${inputs.gender}`,
        config
      );
      if (data?.status == 200) {
        setMnthList(data?.data?.result);
      }
    } catch (error) {
      // console.log(error);
    }
  }, [inputs.month_year]);

  useEffect(async () => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/reports/list-order-year?gender=${inputs.gender}`,
        config
      );
      if (data?.status == 200) {
        setYearList(data?.data?.result);
      }
    } catch (error) {
      // console.log(error);
    }
    // dispatch(getAllOrders("", "", page));
  }, [inputs]);

  return (
    <Div>
      <Typography variant="h1" sx={{ mt: -4 }}>
        Sales Reports
      </Typography>
      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        {/* <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Select Order Type</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            value={inputs.order_type || ""}
            name="order_type"
            onChange={handleChange}
          >
            <MenuItem value={"Admin Order"}>Admin Order</MenuItem>
            <MenuItem value={"Cutting Order"}>Cutting Order</MenuItem>
            <MenuItem value={"Receive Order"}>Receive Order</MenuItem>
            <MenuItem value={"QC Order"}>QC Order</MenuItem>
            <MenuItem value={"Dispatch Order"}>Dispatch Order</MenuItem>
          </Select>
        </Div> */}
        {/* <Div>
          <Typography variant="h5">Todays Report</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Div sx={{ width: "100%" }}>
              <DatePicker
                format="DD/MM/YY"
                value={inputs?.today}
                error={!inputs?.today}
                minDate={dayjs()}
                maxDate={dayjs()}
                onChange={(newDate) => (inputs.today = dateFun(newDate))}
                sx={{
                  width: "100%",
                  "&.MuiTextField-root": {
                    height: "39px",
                    flexDirection: "unset",
                  },
                }}
              />
            </Div>
          </LocalizationProvider>
        </Div> */}
        {/* <Div>
          <Typography variant="h5">Current Week Report</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Div sx={{ width: "100%" }}>
              <DatePicker
                format="DD/MM/YY"
                value={inputs?.week}
                error={!inputs?.week}
                minDate={dayjs()}
                maxDate={dayjs()}
                onChange={(newDate) => (inputs.week = dateFun(newDate))}
                sx={{
                  width: "100%",
                  "&.MuiTextField-root": {
                    height: "39px",
                    flexDirection: "unset",
                  },
                }}
              />
            </Div>
          </LocalizationProvider>
        </Div> */}
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Select Gender</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            value={inputs.gender || ""}
            name="gender"
            onChange={handleChange}
          >
            <MenuItem key={"male"} value={"male"}>
              Male
            </MenuItem>
            <MenuItem key={"female"} value={"female"}>
              Female
            </MenuItem>
          </Select>
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Select Year</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            value={inputs.month_year || ""}
            name="month_year"
            onChange={handleChange}
          >
            {yearList?.map((item, i) => {
              return (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </Div>
        {/* {console.log(mnthList)} */}
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Select Month</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            value={inputs.month || ""}
            name="month"
            onChange={handleChange}
          >
            {mnthList?.map((item, i) => {
              return (
                <MenuItem key={i} value={item?.month_name}>
                  {item.month_name}
                </MenuItem>
              );
            })}
          </Select>
        </Div>
        {/* <Div>
          <Typography variant="h5">From / To</Typography>
          <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "45%" }}>
                <DatePicker
                  format="DD/MM/YY"
                  value={inputs?.from}
                  error={!inputs?.from}
                  onChange={(newDate) => (inputs.from = dateFun(newDate))}
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "45%" }}>
                <DatePicker
                  format="DD/MM/YY"
                  value={inputs?.to}
                  error={!inputs?.to}
                  onChange={(newDate) => (inputs.to = dateFun(newDate))}
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
          </Div>
        </Div> */}
      </Div>
      {/* apply and clear */}
      <Div sx={{ display: "flex", alignItems: "center", mt: 4 }}>
        <Button
          disabled={inputs["month_year"] === undefined}
          size="small"
          variant="contained"
          sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
          onClick={handleFilter}
        >
          Apply & Import
        </Button>
        <Button
          size="small"
          variant="outlined"
          sx={{ p: 1, pl: 4, pr: 4 }}
          onClick={handleClear}
        >
          Clear
        </Button>
      </Div>
    </Div>
  );
};

export default SalesYearlyReport;
