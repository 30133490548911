import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  getAllPurchase,
  getAllReturnPurchase,
} from "app/redux/actions/purchaseAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PurchaseFilter from "../FilterComponent/PurchaseFilter";
import PurchaseReturnFilter from "../FilterComponent/PurchaseReturnFilter";
import PurchaseOrderTable from "../TableComponent/PurchaseOrderTable";
import PurchaseReturnTable from "../TableComponent/PurchaseReturnTable";

export default function ListPurchase() {
  const [searchTerm, setSearchTerm] = useState("");
  const [nav, setNav] = useState("Purchase");
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSearch = (value) => {
    if (nav == "Purchase") {
      value == ""
        ? dispatch(getAllPurchase("", ""))
        : dispatch(getAllPurchase(value, ""));
    } else if (nav == "Purchase Returned") {
      value == ""
        ? dispatch(getAllReturnPurchase("", ""))
        : dispatch(getAllReturnPurchase(value, ""));
    }
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    nav == "Purchase" && dispatch(getAllPurchase("", 1));
    nav == "Purchase Returned" && dispatch(getAllReturnPurchase("", 1));
  }, [nav]);
  return (
    <>
      <Div sx={{ mt: -4 }}>
        <Typography variant="h1">Purchase Order</Typography>
        {nav == "Purchase" && <PurchaseFilter />}
        {nav == "Purchase Returned" && <PurchaseReturnFilter />}

        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearch(e.target.value);
            }}
            sx={{ width: 300, mb: 5, mt: 4 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
          <Div sx={{ display: "flex", gap: 2 }}>
            {permissions?.purchase_create == true && (
              <Div>
                <Button
                  variant="contained"
                  sx={{ p: 1, pl: 4, pr: 4 }}
                  onClick={() => navigate("/dashboard/purchase/add")}
                >
                  Create Purchase Order
                </Button>
              </Div>
            )}
          </Div>
        </Div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Purchase"
                {...a11yProps(0)}
                onClick={() => setNav("Purchase")}
              />
              <Tab
                label="Purchase Returned"
                {...a11yProps(1)}
                onClick={() => setNav("Purchase Returned")}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <PurchaseOrderTable searchTerm={searchTerm} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <PurchaseReturnTable searchTerm={searchTerm} />
          </CustomTabPanel>
        </Box>
      </Div>
    </>
  );
}
