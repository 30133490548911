//NEW_ORDER_REPORT
export const ALL_NEW_ORDER_REPORT_REQUEST = "ALL_NEW_ORDER_REPORT_REQUEST";
export const ALL_NEW_ORDER_REPORT_SUCCESS = "ALL_NEW_ORDER_REPORT_SUCCESS";
export const ALL_NEW_ORDER_REPORT_FAIL = "ALL_NEW_ORDER_REPORT_FAIL";

//CUTTING_CHALLAN_GENERATED_REPORT
export const ALL_CUTTING_CHALLAN_GENERATED_REPORT_REQUEST =
  "ALL_CUTTING_CHALLAN_GENERATED_REPORT_REQUEST";
export const ALL_CUTTING_CHALLAN_GENERATED_REPORT_SUCCESS =
  "ALL_CUTTING_CHALLAN_GENERATED_REPORT_SUCCESS";
export const ALL_CUTTING_CHALLAN_GENERATED_REPORT_FAIL =
  "ALL_CUTTING_CHALLAN_GENERATED_REPORT_FAIL";

//QC_REPORT
export const ALL_QC_REPORT_REQUEST = "ALL_QC_REPORT_REQUEST";
export const ALL_QC_REPORT_SUCCESS = "ALL_QC_REPORT_SUCCESS";
export const ALL_QC_REPORT_FAIL = "ALL_QC_REPORT_FAIL";

//DISPATCH_REPORT
export const ALL_DISPATCH_REPORT_REQUEST = "ALL_DISPATCH_REPORT_REQUEST";
export const ALL_DISPATCH_REPORT_SUCCESS = "ALL_DISPATCH_REPORT_SUCCESS";
export const ALL_DISPATCH_REPORT_FAIL = "ALL_DISPATCH_REPORT_FAIL";

//DRAFT_REPORT
export const ALL_DRAFT_REPORT_REQUEST = "ALL_DRAFT_REPORT_REQUEST";
export const ALL_DRAFT_REPORT_SUCCESS = "ALL_DRAFT_REPORT_SUCCESS";
export const ALL_DRAFT_REPORT_FAIL = "ALL_DRAFT_REPORT_FAIL";

//REPLACEMENT_REPORT
export const ALL_REPLACEMENT_REPORT_REQUEST = "ALL_REPLACEMENT_REPORT_REQUEST";
export const ALL_REPLACEMENT_REPORT_SUCCESS = "ALL_REPLACEMENT_REPORT_SUCCESS";
export const ALL_REPLACEMENT_REPORT_FAIL = "ALL_REPLACEMENT_REPORT_FAIL";

//FACTORY_RECEIVE_PENDING_REPORT
export const ALL_FACTORY_RECEIVE_PENDING_REPORT_REQUEST = "ALL_FACTORY_RECEIVE_PENDING_REPORT_REQUEST";
export const ALL_FACTORY_RECEIVE_PENDING_REPORT_SUCCESS = "ALL_FACTORY_RECEIVE_PENDING_REPORT_SUCCESS";
export const ALL_FACTORY_RECEIVE_PENDING_REPORT_FAIL = "ALL_FACTORY_RECEIVE_PENDING_REPORT_FAIL";

//SALES
export const ALL_SALES_REPORT_REQUEST = "ALL_SALES_REPORT_REQUEST";
export const ALL_SALES_REPORT_SUCCESS = "ALL_SALES_REPORT_SUCCESS";
export const ALL_SALES_REPORT_FAIL = "ALL_SALES_REPORT_FAIL";
