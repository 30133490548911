import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getAllRawMaterial,
  getRawMaterialManuallyIssued,
} from "app/redux/actions/inventoryAction";
import { getKcFabricCodeList } from "app/services/apis/ListApi/kcFabricCodeList";
import { getOrderNoList } from "app/services/apis/ListApi/orderNoList";
import { getPurchaseOrderList } from "app/services/apis/ListApi/purchaseOrderList";
import { getRakeDetailsList } from "app/services/apis/ListApi/rakeDetailsList";
import { downloadRawManuallyIssuedReport } from "app/services/apis/ReportApis/InventoryManuallyIssuedReport";
import { downloadInventoryRawReport } from "app/services/apis/ReportApis/InventoryRawReport";
import { supplierList } from "app/services/apis/supplierList";
import { dateFun } from "app/utils/constants/functions";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const RawIssuedFilter = () => {
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [kcFabricCode, setKcFabricCode] = useState([]);
  const [orderNoList, setOrderNoList] = useState([]);
  const [rakeDetails, setRakeDetails] = useState([]);
  const [purchaseOrderNo, setPurchaseOrderNo] = useState([]);
  const [inputs, setInputs] = useState({});
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const { rawMaterialManuallyIssued, error, TotalPage } = useSelector(
    (state) => state.inventoryReducer
  );
  const dispatch = useDispatch();

  const handleFilter = () => {
    dispatch(getRawMaterialManuallyIssued("", inputs, ""));
  };

  const handleClear = () => {
    dispatch(getRawMaterialManuallyIssued("", "", 1));
    setInputs({});
    setResetAutocomplete((prev) => !prev);
  };
  useEffect(async () => {
    setKcFabricCode(await getKcFabricCodeList());
    setOrderNoList(await getOrderNoList());
  }, []);

  return (
    <>
      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        <Div sx={{ width: "20%" }}>
          <Typography variant="h5">Kennys Fabric Code</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={kcFabricCode || []}
            value={inputs.kc_fabric_code}
            getOptionLabel={(option) => option.kc_fabric_code}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                kc_fabric_code: newValue?.kc_fabric_code
                  ? newValue?.kc_fabric_code
                  : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.kc_fabric_code}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "20%" }}>
          <Typography variant="h5">Order No</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={orderNoList || []}
            // value={}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                raw_manually_order_no: newValue ? newValue : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "25%" }}>
          <Typography variant="h5">Issued Date</Typography>
          <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "100%" }}>
                <DatePicker
                  // maxDate={dayjs()}
                  format="DD/MM/YYYY"
                  key={resetAutocomplete ? "reset1" : "normal1"}
                  onChange={(newDate) =>
                    (inputs.raw_manually_order_date = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
          </Div>
        </Div>
      </Div>

      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          justifyContent: "space-between",
        }}
      >
        <Div>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Div>
        <Button
          disabled={rawMaterialManuallyIssued?.length == 0}
          variant="contained"
          onClick={async () => {
            const data = await downloadRawManuallyIssuedReport(inputs);
            window.open(data);
          }}
        >
          Export CSV
        </Button>
      </Div>
    </>
  );
};

export default RawIssuedFilter;
