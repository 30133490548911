import Page from "@jumbo/shared/Page";
import ListSales from "app/pages/Sales/SalesList";
import SalesRouteMiddleware from "./middleware/auth/salesValidRoute";
import EditSales from "app/pages/Sales/SalesEdit";

export const salesRoutes = [
  {
    middleware: [
      {
        element: SalesRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/sales",
        element: <Page component={ListSales} layout={"vertical-default"} />,
      },

      {
        path: "/dashboard/sales/edit",
        element: <Page component={EditSales} layout={"vertical-default"} />,
      },
    ],
  },
];
