import { MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";
import AddRawMaterial from "./addRawMaterial";
import Div from "@jumbo/shared/Div";
import AddFinishedGoods from "./addFinishedGoods";
import AddProduction from "./addProduction";

export default function AddStock() {
  const [selectedStock, setSelectedStock] = useState("Raw Material");

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1" sx={{ mb: 4 }}>
        Add Stock
      </Typography>
      <Div sx={{ mb: 2 }}>
        <Typography variant="h5">Inward Type</Typography>
        <Select
          size="small"
          value={selectedStock}
          onChange={(e) => setSelectedStock(e.target.value)}
          sx={{ width: "45%" }}
        >
          <MenuItem value="Raw Material">Raw Material</MenuItem>
          <MenuItem value="Finished Goods">Finished Goods</MenuItem>
          {/* <MenuItem value="Production">Production</MenuItem> */}
        </Select>
      </Div>

      {/* Add Stock Component */}
      {selectedStock == "Raw Material" && <AddRawMaterial />}
      {selectedStock == "Finished Goods" && <AddFinishedGoods />}
      {selectedStock == "Production" && <AddProduction />}
    </Div>
  );
}
