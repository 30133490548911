import Div from "@jumbo/shared/Div";
import { DeleteOutline } from "@mui/icons-material";
import {
  Button,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { getUnitListDropDown } from "app/services/apis/ListApi/unitList";
import { getFinishedItemList } from "app/services/apis/ShopOrderApis/getFinishedItemList";
import { getFinishedItemSize } from "app/services/apis/ShopOrderApis/getSizeByFinishItem";
import React, { useEffect, useState } from "react";

export default function ShopItemsTable({ setOrderData, orderData }) {
  const [itemDetails, setItemDetails] = useState([
    {
      shop_order_item_no: null,
      shop_order_item_name: "",
      shop_order_item_size: null,
      shop_order_item_require_quantity: null,
      shop_order_unit: "",
      shop_order_item_remarks: "",
    },
  ]);
  const [finishList, setFinishList] = useState([]);
  const [unitList, setUnitList] = useState([]);

  const addMoreItem = () => {
    setItemDetails([
      ...itemDetails,
      {
        shop_order_item_no: null,
        shop_order_item_name: "",
        shop_order_item_size: null,
        shop_order_item_require_quantity: null,
        shop_order_unit: "",
        shop_order_item_remarks: "",
      },
    ]);
  };

  const handleRemoveItem = (i) => {
    if (itemDetails.length != 1) {
      setItemDetails(
        itemDetails.filter((item, index) => {
          return index != i;
        })
      );
    }
  };

  useEffect(() => {
    setOrderData({ ...orderData, shop_items: itemDetails });
  }, [itemDetails]);

  useEffect(async () => {
    setFinishList(await getFinishedItemList());
    setUnitList(await getUnitListDropDown());
  }, []);
  return (
    <Div>
      <Typography variant="h3">Item Details</Typography>
      <TableContainer
        style={{ border: "1px solid black" }}
        sx={{ width: "100%", mt: 1 }}
      >
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
                width: "5%",
              }}
            >
              Item No
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
                width: "5%",
              }}
            >
              Item Name
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
                width: "5%",
              }}
            >
              Size
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
                width: "5%",
              }}
            >
              Required Qty.
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
                width: "10%",
              }}
            >
              Units
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
                width: "15%",
              }}
            >
              Remarks
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
                width: "10%",
              }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemDetails?.map((row, i) => (
            <>
              <TableRow key={i}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>
                  <Select
                    onChange={async (e) => {
                      itemDetails[i].shop_order_item_name = e.target.value;
                      itemDetails[i].shop_order_item_no = i + 1;
                      itemDetails[i].sizeList = await getFinishedItemSize(
                        e.target.value
                      );
                      setItemDetails([...itemDetails]);
                    }}
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    {finishList?.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    value={itemDetails[i].shop_order_item_size}
                    onChange={(e) => {
                      itemDetails[i].shop_order_item_size = e.target.value;
                      setItemDetails([...itemDetails]);
                    }}
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    {itemDetails[i]?.sizeList?.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    inputProps={{ min: 1 }}
                    type="number"
                    size="small"
                    value={itemDetails[i].shop_order_item_require_quantity}
                    onChange={(e) => {
                      itemDetails[i].shop_order_item_require_quantity =
                        e.target.value;
                      setItemDetails([...itemDetails]);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    onChange={(e) => {
                      itemDetails[i].shop_order_unit = e.target.value;
                      setItemDetails([...itemDetails]);
                    }}
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    {unitList?.map((item) => {
                      return (
                        <MenuItem value={item.unit_name}>
                          {item.unit_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    onChange={(e) => {
                      itemDetails[i].shop_order_item_remarks = e.target.value;
                      setItemDetails([...itemDetails]);
                    }}
                    size="small"
                    fullWidth
                    multiline
                  />
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color: "red",
                      cursor: "pointer",
                      width: "25px",
                    }}
                    onClick={() => {
                      handleRemoveItem(i);
                    }}
                  >
                    <DeleteOutline />
                  </Typography>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </TableContainer>
      <Button
      sx={{mt:2}}
      variant="contained"
        onClick={() => {
          addMoreItem();
        }}
      >
        + Add More Item
      </Button>
    </Div>
  );
}
