/* eslint-disable default-case */
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getAllReturnPurchase } from "app/redux/actions/purchaseAction";
import { displayDateFun } from "app/utils/constants/functions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function PurchaseReturnTable({ search }) {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { allReturnPurchase, error, TotalPage, loading } = useSelector(
    (state) => state.purchaseReducer
  );

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllReturnPurchase(search, newPage));
  };
  const sortedData = allReturnPurchase?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "supplier_master.supplier_name") {
      aValue = a.supplier_master.supplier_name.toLowerCase();
      bValue = b.supplier_master.supplier_name.toLowerCase();
    } else if (orderBy == "purchase_order_no") {
      aValue = a.purchase_order_no.toLowerCase();
      bValue = b.purchase_order_no.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Returned Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "purchase_order_no"}
                  direction={order}
                  onClick={() => handleSort("purchase_order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  PO No.
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_name"}
                  direction={order}
                  onClick={() => handleSort("supplier_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "raw_invoice_no"}
                  direction={order}
                  onClick={() => handleSort("raw_invoice_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Invoice No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "purchase_order_type"}
                  direction={order}
                  onClick={() => handleSort("purchase_order_type")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Purchase Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "kc_fabric_code"}
                  direction={order}
                  onClick={() => handleSort("kc_fabric_code")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Kennys Fabric Code
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "taka_no"}
                  direction={order}
                  onClick={() => handleSort("taka_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Taka Number
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "finished_item_name"}
                  direction={order}
                  onClick={() => handleSort("finished_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "finished_item_no"}
                  direction={order}
                  onClick={() => handleSort("finished_item_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "finished_size"}
                  direction={order}
                  onClick={() => handleSort("finished_size")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Size
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Returned Quantity
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Rate Per Unit (With Discount)
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Total Return Amount WithOut GST
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                GST &
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Total Return Amount With GST
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row?.return_purchase_order_create_date)}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.purchase_order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.supplier_name}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row?.raw_invoice_no
                    ? row.raw_invoice_no
                    : row?.finished_invoice_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.purchase_order_type}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.kc_fabric_code ?? "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.taka_no ?? "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.finished_item_name ?? "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.finished_size ?? "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.finished_item_no ?? "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.return_quantity}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row?.rate_with_discount}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row?.without_gst_total_amount}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.gst_percentage}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row?.total_amount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
