import axios from "axios";

export const getAvailableTaka = async (fabric_code, required_quantity) => {
  try {
    if (fabric_code == undefined) {
      fabric_code = "";
    }
    if (required_quantity == undefined) {
      required_quantity = 0;
    }
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/list-order/get-available-taka?kc_fabric_code=${fabric_code}&required_quantity=${required_quantity}`,
      config
    );
    console.log(data, "data");

    return data?.data?.result;
  } catch (error) {
    console.log(error);
    // return error?.response?.data?.result;
    return error?.response?.data?.result;
  }
};
