import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { getAllRawMaterial } from "app/redux/actions/inventoryAction";
import { getKcFabricCodeList } from "app/services/apis/ListApi/kcFabricCodeList";
import { getPurchaseOrderList } from "app/services/apis/ListApi/purchaseOrderList";
import { getRakeDetailsList } from "app/services/apis/ListApi/rakeDetailsList";
import { downloadInventoryRawReport } from "app/services/apis/ReportApis/InventoryRawReport";
import { supplierList } from "app/services/apis/supplierList";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RawStockModal from "../Modal/stock";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getSupplierFabricList } from "app/services/apis/ListApi/SupplierFabricList";

const RawMaterialFilter = () => {
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [kcFabricCode, setKcFabricCode] = useState([]);
  const [supplierFabricCode, setSupplierFabricCode] = useState([]);
  const [rakeDetails, setRakeDetails] = useState([]);
  const [purchaseOrderNo, setPurchaseOrderNo] = useState([]);
  const [inputs, setInputs] = useState({});
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const { rawMaterial } = useSelector((state) => state.inventoryReducer);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // State to manage modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to handle opening the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleFilter = () => {
    dispatch(getAllRawMaterial("", inputs, ""));
  };

  const handleClear = () => {
    dispatch(getAllRawMaterial("", "", 1));
    setInputs({});
    setResetAutocomplete((prev) => !prev);
  };
  useEffect(async () => {
    const data = await supplierList();
    if (data?.status == 200) {
      setSupplierNameList(data.data.result);
    }
    setPurchaseOrderNo(await getPurchaseOrderList());
    setKcFabricCode(await getKcFabricCodeList());
    setSupplierFabricCode(await getSupplierFabricList());
    setRakeDetails(await getRakeDetailsList("Raw"));
  }, []);
  const handleDownload = async () => {
    setLoading(true);
    try {
      const data = await downloadInventoryRawReport(inputs);
      window.open(data);
    } catch (error) {
      console.error("Error downloading the report:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        <Div sx={{ width: "25%" }}>
          <Typography variant="h5">Kennys Fabric Code</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={kcFabricCode || []}
            value={inputs.kc_fabric_code}
            getOptionLabel={(option) => option.kc_fabric_code}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                kc_fabric_code: newValue?.kc_fabric_code
                  ? newValue?.kc_fabric_code
                  : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.kc_fabric_code}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "25%" }}>
          <Typography variant="h5">Supplier Name</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={supplierNameList || []}
            // value={}
            getOptionLabel={(option) => option.supplier_name}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                supplier_name: newValue?.supplier_name
                  ? newValue?.supplier_name
                  : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.supplier_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "25%" }}>
          <Typography variant="h5">PO No</Typography>

          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={purchaseOrderNo || []}
            // value={}
            getOptionLabel={(option) => option.purchase_order_no}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                purchase_order_no: newValue?.purchase_order_no
                  ? newValue?.purchase_order_no
                  : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.purchase_order_no}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>

        <Div sx={{ width: "25%" }}>
          <Typography variant="h5">Rack Details</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={rakeDetails || []}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                raw_rack_details: newValue ? newValue : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "25%" }}>
          <Typography variant="h5">Supplier Fabric Code</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={supplierFabricCode || []}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                supplier_fabric_code: newValue ? newValue : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
      </Div>

      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          justifyContent: "space-between",
        }}
      >
        <Div>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Div>
        <Div
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            gap: "15px",
            width: "40%",
          }}
        >
          {/* // const data = await downloadInventoryRawReport(inputs);
              // window.open(data); */}
          <Button
            disabled={rawMaterial?.length == 0}
            variant="contained"
            onClick={handleOpenModal}
          >
            Stock Export CSV
          </Button>
          <Button
            disabled={rawMaterial?.length == 0}
            variant="contained"
            onClick={handleDownload}
            // onClick={async () => {
            //   const data = await downloadInventoryRawReport(inputs);
            //   window.open(data);
            // }}
          >
            Export CSV
          </Button>
        </Div>
        {isModalOpen && (
          <RawStockModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </Div>
    </>
  );
};

export default RawMaterialFilter;
