import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
} from "@mui/material";
import "./items.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllAddOns } from "app/redux/actions/masterAction";
import { useEffect } from "react";
import axios from "axios";
import FullScreenLoader from "app/components/ListingPageLoader";
import { UpdateFreightModal } from "./updateFreightModal";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";

export default function ListFemaleItemsTable({ FemaleItem }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(1);
  const [mensItemList, setMensItemList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState(null);
  const [isUpdateFreightModalOpen, setIsUpdateFreightModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = mensItemList?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "addons_name") {
      aValue = a.addons_name.toLowerCase();
      bValue = b.addons_name.toLowerCase();
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllAddOns("", newPage));
  };
  useEffect(async () => {
    setLoading(true);
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/item-master/list-female-item-master?page=${page}&search=${FemaleItem}`,
        config
      );
      if (data?.status == 200) {
        setLoading(false);
        setMensItemList(data.data.result);
        setTotalPage(data.data.totalPages);
      }
    } catch (error) {
      // console.log(error);
      return error?.response?.data?.result;
    }
  }, [page, FemaleItem]);

  useEffect( () => {
    const fetchData= async()=>{
      setLoading(true);
      try {
        const config = {
          withCredentials: true,
          headers: {
          withCredentials: true,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/item-master/list-female-item-master?page=${page}&search=${FemaleItem}`,
        config
      );
      if (data?.status == 200) {
        setLoading(false);
        setMensItemList(data.data.result);
        setTotalPage(data.data.totalPages);
      }
    } catch (error) {
      // console.log(error);
      return error?.response?.data?.result;
    }
  }
  if(isUpdateFreightModalOpen == false){
    fetchData()
  }
  }, [isUpdateFreightModalOpen]);

  return (
    <>      {loading && <FullScreenLoader />}
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
            >
              Items Name
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
              >
              Freight Value
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
              >
              Freight Update Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
              >
              Order Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
              >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>
                {row.item_master_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.freight_value || "--"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
              {row?.freight_update_date ? moment(row?.freight_update_date)?.format('DD-MM-YYYY') : "--"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
              {row?.order_date ? moment(row?.order_date)?.format('DD-MM-YYYY') : "--"}
              </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <EditIcon />,
                          title: "Update Freight Details",
                          action: "edit",
                          data: row,
                        },
                      ]}
                      onClickCallback={()=> {
                        setItemDetails(row)
                        setIsUpdateFreightModalOpen(true)
                      }}
                    />
                  </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={totalPage}
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
       <UpdateFreightModal
          open={isUpdateFreightModalOpen}
          onClose={() => {
            setIsUpdateFreightModalOpen(false);
          }}
          setIsUpdateFreightModalOpen={setIsUpdateFreightModalOpen}
          item_type="Female"
          itemDetails={itemDetails}
        />
    </TableContainer>
    </>
  );
}
