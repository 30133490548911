/* eslint-disable default-case */
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import { displayDateFun } from "app/utils/constants/functions";
import { useState } from "react";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { CuttingListInfoModal } from "app/pages/Order/Cutter/List/cuttingListInfoModal";
export default function SalesTable() {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const { sales, error, loading } = useSelector((state) => state.reportReducer);
  const [isItemOpen, setIsItemOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = sales?.sort((a, b) => {
    let aValue;
    let bValue;
    if (orderBy == "order_port_no_and_name") {
      aValue = a.order_port_no_and_name.toLowerCase();
      bValue = b.order_port_no_and_name.toLowerCase();
    } else if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_sub_item_name") {
      aValue = a.order_sub_item_name.toLowerCase();
      bValue = b.order_sub_item_name.toLowerCase();
    } else if (orderBy == "transport_type") {
      aValue = a.transport_type.toLowerCase();
      bValue = b.transport_type.toLowerCase();
    } else if (orderBy === "order_no") {
      aValue = a.order_no.toString().toLowerCase();
      bValue = b.order_no.toString().toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  // useEffect(() => {
  //   if (error == "No order found") {
  //     Swal.fire({ icon: "error", title: "No Result Found" });
  //   }
  // }, []);

  return (
    <>
      {" "}
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_no"}
                  direction={order}
                  onClick={() => handleSort("order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Order No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_date"}
                  direction={order}
                  onClick={() => handleSort("order_date")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Order Date
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_port_no_and_name"}
                  direction={order}
                  onClick={() => handleSort("order_port_no_and_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Port No & Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_name"}
                  direction={order}
                  onClick={() => handleSort("order_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Product Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_sub_item_name"}
                  direction={order}
                  onClick={() => handleSort("order_sub_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sub Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_kc_fabric_code"}
                  direction={order}
                  onClick={() => handleSort("order_item_kc_fabric_code")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Fabric Code
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_job_work_id"}
                  direction={order}
                  onClick={() => handleSort("order_item_job_work_id")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Job Work ID
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "factory_name"}
                  direction={order}
                  onClick={() => handleSort("factory_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Factory Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "invoice_no"}
                  direction={order}
                  onClick={() => handleSort("invoice_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Factory Bill No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "invoice_date"}
                  direction={order}
                  onClick={() => handleSort("invoice_date")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Invoice Date
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "dispatch_shipment_no"}
                  direction={order}
                  onClick={() => handleSort("dispatch_shipment_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Shipment No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "awb_no"}
                  direction={order}
                  onClick={() => handleSort("awb_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Awb No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "transport_type"}
                  direction={order}
                  onClick={() => handleSort("transport_type")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Transportation Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "center",
                  width: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Raw Manual Issue (QTY)
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Raw Manual Issue(per mtr Rate)
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Raw Manual Issue Cost
              </TableCell>
              
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Used
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Purchase Cost (per mtr)
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Cost
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Total Fabric Cost
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Jobwork Cost (without gst)
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Other Cost (job work)
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Other Charges
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Freight
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                AddOns Price
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Suit Cover Price
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                OP Cost (10%)
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Total Cost
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Handling Cost (5%)
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Grand Total Cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData && sortedData.length > 0 ? (
              sortedData.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.order_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {displayDateFun(row?.order_date)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.order_port_no_and_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.order_item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.order_sub_item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.order_item_kc_fabric_code}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.order_item_job_work_id}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.factory_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.invoice_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {displayDateFun(row?.invoice_date)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.dispatch_shipment_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.awb_bl_no ? row?.awb_bl_no : " -"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.transport_type}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", px: 1 }}>
                    <InfoIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsItemOpen(true);
                        setSelectedRowData(row);
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.raw_manually_issue_quantity
                      ? row?.raw_manually_issue_quantity
                      : 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.raw_manual_issue_rate}
                    {/* {row?.fabric_purchase_cost_per_mtr} */}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.raw_manually_issue_total_cost
                      ? row?.raw_manually_issue_total_cost
                      : 0}
                  </TableCell>
                 
                  <TableCell sx={{ textAlign: "left" }}>
                    {/* {row?.fabric_used} */}
                    {row?.fabric_used_quantity}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {/* {row?.fabric_used} */}
                    {row?.fabric_used_rate}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {/* {row?.fabric_used} */}
                    {(row?.fabric_used_total_cost).toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {/* {(row?.fabric_used_total_cost + row?.raw_manually_issue_total_cost).toFixed(2)} */}
                    {row?.total_fabric_cost}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.other_cost_job_work ? row?.other_cost_job_work : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.Jobwork_cost_without_gst
                      ? row?.Jobwork_cost_without_gst
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.cost_price_other_charges
                      ? row?.cost_price_other_charges
                      : 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.freight}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.selling_price_addOns_price
                      ? row?.selling_price_addOns_price
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.cost_price_suit_cover_cost
                      ? row?.cost_price_suit_cover_cost
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.OP_cost}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.total_cost}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.handling_cost}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.grand_total_cost}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={20}
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                >
                  Data not found
                </TableCell>
              </TableRow>
            )}
            {sortedData && sortedData.length > 0 && (
              <TableRow sx={{ fontWeight: "bold" }}>
                <TableCell
                  colSpan={14}
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  Total :
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.rawManuallyIssueQuantitySum}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {/* {sortedData[0]?.rawManuallyIssueRateTotal} */}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.rawManuallyIssueTotalCost}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.fabricUsedSum}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.fabricPurchaseCostSum}
                </TableCell>
               
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.totalFabricCostSum}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.otherCostJobWorkSum}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.jobworkCostWithoutGstSum}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.freightSum}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.addOnsPriceSum}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.suitcoverSum}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.opCostSum}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.totalCostSum}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.handlingCostSum}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.grandTotalCostSum}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <CuttingListInfoModal
            type="normalOrder"
            open={isItemOpen}
            onClose={() => {
              setIsItemOpen(false);
            }}
            rowData={selectedRowData}
          />
        </Table>
        <Pagination
          count={0}
          hidePrevButton
          hideNextButton
          hideEllipsis
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            height: "40px",
          }}
        />
      </TableContainer>
    </>
  );
}
