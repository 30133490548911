import Div from "@jumbo/shared/Div";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState } from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";

const UploadCatalogue = ({
  open,
  onClose,
  setItemDetails,
  itemDetails,
  index,
}) => {
  const [back, setBack] = useState(false);
  const [catalogueList, setCatalogueList] = useState([]);
  const [catalogueImagesList, setCatalogueImagesList] = useState([]);
  const [selectedCatalogue, setSelectedCatalogue] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [deleteImagesBtn, setDeleteImagesBtn] = useState(false);
  const [previousSelected, setPreviousSelected] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [TotalPage, setTotalPage] = useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleSelect = (e, data, rowIndex) => {
    if (!e.target.checked == true) {
      const updatedItemDetails = [...selectedImages];
      updatedItemDetails.splice(rowIndex, 1);
      setSelectedImages(updatedItemDetails);
    } else {
      const updatedItemDetails = [...selectedImages];
      updatedItemDetails[rowIndex] = data;
      setSelectedImages(updatedItemDetails);
    }
  };

  const handleDelete = (e, data, rowIndex) => {
    // console.log(e.target.checked)
    if (e.target.checked == true) {
      const updatedItemDetails = [...previousSelected];
      // updatedItemDetails.splice(rowIndex, 1);
      updatedItemDetails[rowIndex] = undefined;

      setPreviousSelected(updatedItemDetails);
    } else {
      const updatedItemDetails = [...previousSelected];
      updatedItemDetails[rowIndex] = data;
      setPreviousSelected(updatedItemDetails);
    }
  };

  const DeleteImages = () => {
    if (previousSelected.includes(undefined)) {
      const filteredArray = previousSelected?.filter(
        (value) => value !== undefined
      );
      const updatedItemDetails = [...itemDetails];
      updatedItemDetails[index] = {
        ...updatedItemDetails[index],
        order_item_catalogue_image: [...filteredArray],
      };
      setItemDetails(updatedItemDetails);
      setBack(false);
      setDeleteImagesBtn(!deleteImagesBtn);
    }
  };

  const AddImages = () => {
    const filteredArray = selectedImages?.filter(
      (value) => value !== undefined
    );
    if (filteredArray?.length > 0) {
      const updatedItemDetails = [...itemDetails];
      updatedItemDetails[index] = {
        ...updatedItemDetails[index],
        order_item_catalogue_image:
          updatedItemDetails[index]?.order_item_catalogue_image != undefined
            ? [
                ...updatedItemDetails[index]?.order_item_catalogue_image,
                ...filteredArray,
              ]
            : [...filteredArray],
      };
      // Update the state with the modified array
      setItemDetails(updatedItemDetails);
      setSelectedImages([]);
      setBack(false);
    }
  };

  useEffect(async () => {
    if (back == false) {
      try {
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const data = await axios.get(
          `${process.env.REACT_APP_URL}/api/catalogue-master/list-catalogue-master?catalogue_folder_name=${searchTerm}&page=${page}`,
          config
        );
        if (data?.status == 200) {
          setCatalogueList(data.data.result);
          setTotalPage(data.data.totalPages);
        }
      } catch (error) {
        // console.log(error.response);
      }
    } else {
      try {
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const data = await axios.get(
          `${process.env.REACT_APP_URL}/api/catalogue-master/list-catalogue-image?folder_name=${selectedCatalogue}&search=${searchTerm}`,
          config
        );
        setCatalogueImagesList(data?.data?.result);
      } catch (error) {
        // console.log(error);
        setCatalogueImagesList([]);
      }
    }
  }, [back, searchTerm, page]);

  useEffect(() => {
    setBack(false);
    setDeleteImagesBtn(false);
  }, [open]);

  useEffect(() => {
    if (
      itemDetails &&
      itemDetails[index]?.order_item_catalogue_image?.length > 0
    ) {
      setPreviousSelected(itemDetails[index].order_item_catalogue_image);
    }
  }, [itemDetails]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          fontWeight: "700",
          mt: 2,
        }}
      >
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">
            {back == false && deleteImagesBtn == false
              ? "Select Catalogue"
              : "Select Catalogue Image"}
          </Typography>

          {back == true && (
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => {
                setBack(false);
                setCatalogueImagesList([]);
              }}
            >
              Back
            </Button>
          )}
          {deleteImagesBtn == true && (
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => {
                setBack(false);
                setDeleteImagesBtn(!deleteImagesBtn);
              }}
            >
              Back
            </Button>
          )}

          {back == false && deleteImagesBtn == false && (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setDeleteImagesBtn(!deleteImagesBtn);
                setPreviousSelected(
                  itemDetails[index]?.order_item_catalogue_image
                );
              }}
            >
              Selected Images
            </Button>
          )}
        </Div>
        {back == false && deleteImagesBtn == false && (
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search Catalogues"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            sx={{ width: 300, mb: 1, mt: 2 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
        )}
        <Div>
          {back == true && (
            <TextField
              size="small"
              id="search"
              type="search"
              label="Search Catalogue Images"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              sx={{ width: 230, mt: 2 }}
              InputProps={{
                endAdornment: (
                  <Div sx={{ cursor: "pointer" }}>
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  </Div>
                ),
              }}
            />
          )}
        </Div>
      </DialogTitle>
      <DialogContent sx={{ minHeight: "500px" }}>
        <Div sx={{ mt: 1 }}>
          {back == false ? (
            deleteImagesBtn == false ? (
              // all catalogues
              <Div
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  columnGap: 2,
                  rowGap: 1,
                }}
              >
                {catalogueList?.map((catalogue) => {
                  return (
                    <Div
                      sx={{ display: "flex", flexWrap: "wrap", width: "150px" }}
                    >
                      <img
                        loading="lazy"
                        height={"200px"}
                        width={"150px"}
                        onClick={() => {
                          setBack(true);
                          setSelectedCatalogue(
                            catalogue?.catalogue_folder_name
                          );
                        }}
                        style={{ cursor: "pointer", border: "1px solid" }}
                        src={`${process.env.REACT_APP_URL}/${catalogue?.catalogue_image_icon}`}
                      />
                      <Typography
                        variant="h5"
                        sx={{ wordBreak: "break-word", mt: 1 }}
                      >
                        {catalogue?.catalogue_folder_name}
                        {/* {
                          catalogue?.catalogue_image_icon.split("/")[
                            catalogue?.catalogue_image_icon.split("/").length -
                              1
                          ]
                        } */}
                      </Typography>
                    </Div>
                  );
                })}
              </Div>
            ) : // remove images
            itemDetails[index]?.order_item_catalogue_image?.length == 0 ? (
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "500px",
                }}
              >
                <Typography variant="h3">No Images Selected</Typography>
              </Div>
            ) : (
              <Div
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  columnGap: 5,
                  rowGap: 2,
                }}
              >
                {itemDetails[index]?.order_item_catalogue_image?.map(
                  (image, i) => {
                    return (
                      <Div
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "130px",
                        }}
                      >
                        <Div
                          sx={{
                            position: "relative",
                            height: "200px",
                            width: "150px",
                          }}
                        >
                          <img
                            loading="lazy"
                            onClick={() => {}}
                            height={"200px"}
                            width={"150px"}
                            style={{ border: "1px solid", cursor: "pointer" }}
                            src={`${process.env.REACT_APP_URL}/${image}`}
                          />
                          <Div
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              height: "100%",
                              width: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.2)",
                            }}
                          >
                            <Checkbox
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)",
                                color: "white !important",
                              }}
                              {...label}
                              onChange={(e) => handleDelete(e, image, i)}
                            />
                          </Div>
                        </Div>
                      </Div>
                    );
                  }
                )}
              </Div>
            )
          ) : // catalogue images
          catalogueImagesList?.length == 0 ? (
            <Div
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "500px",
              }}
            >
              <Typography variant="h3">No Images</Typography>
            </Div>
          ) : (
            <Div
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                columnGap: 3,
                rowGap: 2,
              }}
            >
              {catalogueImagesList?.map((image, i) => (
                <Div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    width: "150px",
                  }}
                >
                  <Div
                    sx={{
                      position: "relative",
                      height: "200px",
                      width: "150px",
                    }}
                  >
                    <img
                      onClick={() => {}}
                      height={"200px"}
                      width={"150px"}
                      style={{ border: "1px solid", cursor: "pointer" }}
                      src={`${process.env.REACT_APP_URL}/${image}`}
                    />

                    <Div
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0)",
                      }}
                    >
                      <Checkbox
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          color: "white !important",
                        }}
                        {...label}
                        onChange={(e) => handleSelect(e, image, i)}
                      />
                    </Div>
                  </Div>
                  <Typography variant="h5" style={{ wordWrap: "break-word" }}>
                    {image?.split("/")[image.split("/").length - 1]}
                  </Typography>
                </Div>
              ))}
            </Div>
          )}
        </Div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Div>
          {back == true && (
            <Div sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" sx={{ mt: 3 }} onClick={AddImages}>
                Add Images
              </Button>
            </Div>
          )}
          {deleteImagesBtn == true && (
            <Div sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" sx={{ mt: 3 }} onClick={DeleteImages}>
                Remove Images
              </Button>
            </Div>
          )}
          {back == false && deleteImagesBtn == false && (
            <>
              <Pagination
                count={TotalPage || 1}
                page={page}
                onChange={handleChangePage}
                sx={{
                  bottom: 0,
                  left: 0,
                  backgroundColor: "white",
                }}
              />
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ mt: 3 }}
                  onClick={() => onClose()}
                >
                  Close
                </Button>
              </Div>
            </>
          )}
        </Div>
      </DialogActions>
    </Dialog>
  );
};

export default UploadCatalogue;
