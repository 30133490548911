import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Div from "@jumbo/shared/Div";
import { itemDetailss } from "app/services/apis/Inventory/takaDetails";

const ItemDetails = ({ open, onClose, rowData, purchaseNo }) => {
  const [isLoading, setLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState([]);
  const [balance, setBalance] = useState(0);
  const [loader, setLoader] = useState();
  const [editTaka, setEditTaka] = useState([]);
  const [addedTaka, setAddedTaka] = useState([]);

  const addEmptyRow = () => {
    const emptyRow = {
      finished_item_name: rowData?.purchase_item_name, // Set your initial values for each field here
      finished_size: rowData?.purchase_size,
      po_item_details_id: rowData?.id,
      finished_item_quantity: rowData?.quantity,
      unit_name: rowData?.unit_name,
      finished_item_recieved_quantity: 0,
      finished_rack_details: "",
      finished_item_no: "",
      finished_item_type: "Finished Goods Inventory",
    };
    setEditTaka([...editTaka, emptyRow]);
  };

  const handleRemoveItem = (index) => {
    // Create a copy of itemDetails and remove the item at the specified index
    const updatedItemDetails = [...editTaka];
    updatedItemDetails.splice(index, 1);
    setEditTaka(updatedItemDetails);
  };

  const handleTextFieldChange = (e, rowIndex) => {
    const { name, value } = e.target;
    // Create a copy of itemDetails to modify
    const updatedItemDetails = [...editTaka];

    // Update the corresponding field in the specific row
    if (name == "finished_item_no") {
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        [name]: Number(value),
      };
    } else if (name == "finished_item_recieved_quantity") {
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        [name]: Number(value),
      };
    } else {
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        [name]: value,
      };
    }

    // Update the state with the modified array
    setEditTaka(updatedItemDetails);
  };

  const handleBalanceQty = (e, i) => {
    setBalance(itemDetails[0]?.taka_quantity - e.target.value);
  };

  const handleItemDetails = async () => {
    localStorage.setItem(rowData.id, JSON.stringify(editTaka));
    onClose();
  };

  useEffect(async () => {
    setLoader(true);
    const res = await itemDetailss(purchaseNo, rowData?.id);
    if (res?.status == 200) {
      res.data.result.length > 0 && setAddedTaka(res.data.result);

      localStorage.getItem(rowData?.id)
        ? setEditTaka(
            JSON.parse(localStorage.getItem(rowData?.id)).map((ele) => {
              return {
                ...ele,
                kc_fabric_code: rowData?.kc_fabric_code,
                supplier_fabric_code: rowData?.supplier_fabric_code,
              };
            })
          )
        : setEditTaka([
            {
              finished_item_name: rowData?.purchase_item_name, // Set your initial values for each field here
              finished_size: rowData?.purchase_size,
              po_item_details_id: rowData?.id,
              finished_item_quantity: rowData?.quantity,
              unit_name: rowData?.unit_name,
              finished_item_recieved_quantity: "",
              finished_rack_details: "",
              finished_item_no: "",
              finished_item_type: "Finished Goods Inventory",
            },
          ]);
    }
    setLoader(false);
  }, [rowData]);

  useEffect(() => {
    const sumReceivedQuantity = addedTaka.reduce(
      (total, item) => total + parseInt(item.finished_item_recieved_quantity),
      0
    );
    const remainingTakaQuantity =
      editTaka[0]?.finished_item_quantity - sumReceivedQuantity;

    setBalance(remainingTakaQuantity);
  }, [editTaka]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle sx={{ fontWeight: "700" }}>Item Details</DialogTitle>
        <DialogTitle sx={{ fontWeight: "700" }}>
          {balance < 0
            ? `Balance: +${Math.abs(balance)}`
            : `Balance: ${balance}`}
        </DialogTitle>
      </Div>
      <DialogContent>
        {loader ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size="1.5rem" />
          </Box>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Item Name
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Item Size
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Units
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Received Quantity
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Rack Details
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Item No.
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editTaka?.map((row, i) => (
                    <>
                      <TableRow key={i}>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.finished_item_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.finished_size ? row?.finished_size : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.finished_item_quantity}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.unit_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          <TextField
                            size="small"
                            type="number"
                            sx={{ width: "160px" }}
                            name="finished_item_recieved_quantity"
                            value={row?.finished_item_recieved_quantity || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (
                                newValue === "" ||
                                (newValue >= 1 && !isNaN(newValue))
                              ) {
                                handleTextFieldChange(e, i);
                                handleBalanceQty(e, i);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          <TextField
                            size="small"
                            sx={{ width: "160px" }}
                            name="finished_rack_details"
                            value={row?.finished_rack_details}
                            onChange={(e) => {
                              handleTextFieldChange(e, i);
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          <TextField
                            size="small"
                            sx={{ width: "160px" }}
                            name="finished_item_no"
                            value={
                              row?.finished_item_no ? row?.finished_item_no : ""
                            }
                            onChange={(e) => {
                              handleTextFieldChange(e, i);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {i == 0 ? (
                            "N/A"
                          ) : (
                            <RemoveCircleOutlineIcon
                              sx={{ cursor: "pointer", color: "red" }}
                              onClick={() => handleRemoveItem(i)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Button
                      variant="contained"
                      // disabled={balance <= 0 ? true : false}
                      onClick={() => addEmptyRow()}
                      sx={{ cursor: "pointer" }}
                    >
                      Add More
                    </Button>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
            {addedTaka.length > 0 && (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item Size
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Units
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Received Quantity
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Return Quantity
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Rack Details
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item No.
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Invoice No.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addedTaka?.map((row, i) => (
                      <>
                        <TableRow key={i}>
                          <TableCell sx={{ textAlign: "left" }}>
                            {row?.finished_item_name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            {row?.finished_size ? row?.finished_size : "-"}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            {row?.finished_item_quantity}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            {row?.unit_name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            <TextField
                              disabled
                              size="small"
                              type="number"
                              sx={{ width: "160px" }}
                              name="finished_item_recieved_quantity"
                              value={row?.finished_item_recieved_quantity}
                              onChange={(e) => {
                                handleTextFieldChange(e, i);
                                handleBalanceQty(e, i);
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            <TextField
                              disabled
                              size="small"
                              type="number"
                              sx={{ width: "160px" }}
                              name="return_quantity"
                              value={row?.return_quantity || 0}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            <TextField
                              disabled
                              size="small"
                              sx={{ width: "160px" }}
                              name="finished_rack_details"
                              value={row?.finished_rack_details}
                              onChange={(e) => {
                                handleTextFieldChange(e, i);
                              }}
                            />
                          </TableCell>

                          <TableCell sx={{ textAlign: "left" }}>
                            <TextField
                              disabled
                              size="small"
                              sx={{ width: "160px" }}
                              name="finished_item_no"
                              value={
                                row?.finished_item_no
                                  ? row?.finished_item_no
                                  : ""
                              }
                              onChange={(e) => {
                                handleTextFieldChange(e, i);
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            {row?.finished_invoice_no}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!loader && (
          <>
            <LoadingButton
              onClick={handleItemDetails}
              color="primary"
              size="medium"
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
            <Button onClick={onClose} color="error" variant="contained">
              Close
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ItemDetails;
