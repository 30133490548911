import {
  ALL_NEW_ORDER_REPORT_FAIL,
  ALL_NEW_ORDER_REPORT_REQUEST,
  ALL_NEW_ORDER_REPORT_SUCCESS,
  ALL_QC_REPORT_FAIL,
  ALL_QC_REPORT_REQUEST,
  ALL_QC_REPORT_SUCCESS,
  ALL_DRAFT_REPORT_FAIL,
  ALL_DRAFT_REPORT_REQUEST,
  ALL_DRAFT_REPORT_SUCCESS,
  ALL_REPLACEMENT_REPORT_FAIL,
  ALL_REPLACEMENT_REPORT_REQUEST,
  ALL_REPLACEMENT_REPORT_SUCCESS,
  ALL_DISPATCH_REPORT_FAIL,
  ALL_DISPATCH_REPORT_REQUEST,
  ALL_DISPATCH_REPORT_SUCCESS,
  ALL_FACTORY_RECEIVE_PENDING_REPORT_FAIL,
  ALL_FACTORY_RECEIVE_PENDING_REPORT_REQUEST,
  ALL_FACTORY_RECEIVE_PENDING_REPORT_SUCCESS,
  ALL_CUTTING_CHALLAN_GENERATED_REPORT_FAIL,
  ALL_CUTTING_CHALLAN_GENERATED_REPORT_REQUEST,
  ALL_CUTTING_CHALLAN_GENERATED_REPORT_SUCCESS,
  ALL_SALES_REPORT_FAIL,
  ALL_SALES_REPORT_REQUEST,
  ALL_SALES_REPORT_SUCCESS,
} from "app/utils/constants/reportConstant.js";
import axios from "axios";
//   new order report
export const getNewOrderReport = (search_value, inputs) => async (dispatch) => {
  try {
    const filter = {
      from: inputs.from || "",
      to: inputs.to || "",
    };

    if (!search_value) {
      search_value = "";
    }

    dispatch({ type: ALL_NEW_ORDER_REPORT_REQUEST });

    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const urlParams = new URLSearchParams({
      search: search_value,
      from: filter.from,
      to: filter.to,
    });

    const url = `${
      process.env.REACT_APP_URL
    }/api/reports/list-new-order-report?${urlParams.toString()}`;

    const data = await axios.get(url, config);

    dispatch({
      type: ALL_NEW_ORDER_REPORT_SUCCESS,
      payload: {
        data: data?.data?.result,
      },
    });
  } catch (error) {
    dispatch({
      type: ALL_NEW_ORDER_REPORT_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getCuttingChallanGeneratedReport =
  (search_value, inputs) => async (dispatch) => {
    try {
      const filter = {
        from: inputs.from || "",
        to: inputs.to || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_CUTTING_CHALLAN_GENERATED_REPORT_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        from: filter.from,
        to: filter.to,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/reports/cutting-challan-generated-report?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_CUTTING_CHALLAN_GENERATED_REPORT_SUCCESS,
        payload: {
          data: data?.data?.result,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_CUTTING_CHALLAN_GENERATED_REPORT_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getQCReport = (search_value, inputs) => async (dispatch) => {
  try {
    const filter = {
      from: inputs.from || "",
      to: inputs.to || "",
    };

    if (!search_value) {
      search_value = "";
    }

    dispatch({ type: ALL_QC_REPORT_REQUEST });

    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const urlParams = new URLSearchParams({
      search: search_value,
      from: filter.from,
      to: filter.to,
    });

    const url = `${
      process.env.REACT_APP_URL
    }/api/reports/list-qc-all-report?${urlParams.toString()}`;

    const data = await axios.get(url, config);

    dispatch({
      type: ALL_QC_REPORT_SUCCESS,
      payload: {
        data: data?.data?.result,
      },
    });
  } catch (error) {
    dispatch({
      type: ALL_QC_REPORT_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getDispatchReport = (search_value, inputs) => async (dispatch) => {
  try {
    const filter = {
      from: inputs.from || "",
      to: inputs.to || "",
    };

    if (!search_value) {
      search_value = "";
    }

    dispatch({ type: ALL_DISPATCH_REPORT_REQUEST });

    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const urlParams = new URLSearchParams({
      search: search_value,
      from: filter.from,
      to: filter.to,
    });

    const url = `${
      process.env.REACT_APP_URL
    }/api/reports/list-dispatch-all-report?${urlParams.toString()}`;

    const data = await axios.get(url, config);

    dispatch({
      type: ALL_DISPATCH_REPORT_SUCCESS,
      payload: {
        data: data?.data?.result,
      },
    });
  } catch (error) {
    dispatch({
      type: ALL_DISPATCH_REPORT_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getDraftReport = (search_value, inputs) => async (dispatch) => {
  try {
    const filter = {
      from: inputs.from || "",
      to: inputs.to || "",
    };

    if (!search_value) {
      search_value = "";
    }

    dispatch({ type: ALL_DRAFT_REPORT_REQUEST });

    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const urlParams = new URLSearchParams({
      search: search_value,
      from: filter.from,
      to: filter.to,
    });

    const url = `${
      process.env.REACT_APP_URL
    }/api/reports/list-draft-all-report?${urlParams.toString()}`;

    const data = await axios.get(url, config);

    dispatch({
      type: ALL_DRAFT_REPORT_SUCCESS,
      payload: {
        data: data?.data?.result,
      },
    });
  } catch (error) {
    dispatch({
      type: ALL_DRAFT_REPORT_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getReplacementReport =
  (search_value, inputs) => async (dispatch) => {
    try {
      const filter = {
        from: inputs.from || "",
        to: inputs.to || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_REPLACEMENT_REPORT_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        from: filter.from,
        to: filter.to,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/reports/list-replacement-report?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_REPLACEMENT_REPORT_SUCCESS,
        payload: {
          data: data?.data?.result,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_REPLACEMENT_REPORT_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
export const getFactoryReceivingPendingReport =
  (search_value, inputs) => async (dispatch) => {
    try {
      const filter = {
        from: inputs.from || "",
        to: inputs.to || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_FACTORY_RECEIVE_PENDING_REPORT_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.replace(/ /g, "+"),
        from: filter.from,
        to: filter.to,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/reports/list-factory-receiving-pending-report?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_FACTORY_RECEIVE_PENDING_REPORT_SUCCESS,
        payload: {
          data: data?.data?.result,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_FACTORY_RECEIVE_PENDING_REPORT_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };


export const getSalesReport =
  (search_value, inputs) => async (dispatch) => {
    try {
      const filter = {
        from: inputs.from || "",
        to: inputs.to || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_SALES_REPORT_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        from: filter.from,
        to: filter.to,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/reports/list-sales-all-report?${urlParams.toString()}`;
      const data = await axios.get(url, config);
      dispatch({
        type: ALL_SALES_REPORT_SUCCESS,
        payload: {
          data: data?.data?.result,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_SALES_REPORT_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
