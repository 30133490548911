import Page from "@jumbo/shared/Page";
import AddPurchase from "app/pages/PurchaseOrder/AddPurchase";
import ListPurchase from "app/pages/PurchaseOrder/ListPurchase";
import PurchaseRouteMiddleware from "./middleware/auth/purchaseValidRoute";
import ReturnPurchaseForm from "app/pages/PurchaseOrder/ReturnPurchase";

export const purchaseRoutes = [
  {
    middleware: [
      {
        element: PurchaseRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/purchase",
        element: <Page component={ListPurchase} layout={"vertical-default"} />,
      },

      {
        path: "/dashboard/purchase/add",
        element: <Page component={AddPurchase} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/purchase/edit",
        element: <Page component={AddPurchase} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/purchase/return",
        element: <Page component={ReturnPurchaseForm} layout={"vertical-default"} />,
      },
    ],
  },
];
