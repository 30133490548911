import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Typography,
  Autocomplete,
  Box,
  TextField,
  Button,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import { displayDateFun } from "app/utils/constants/functions";
import Div from "@jumbo/shared/Div";
import { getFabricColorList } from "app/services/apis/ListApi/fabricColorList";
import axios from "axios";
import RefreshModal from "../ListDraft/modal";
import FabricImageModal from "app/pages/Master/FabricMaster/ListFabric/imageModal";
export default function AlternativeTable() {
  const navigate = useNavigate();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { state } = useLocation();
  // console.log(state);
  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };
  const [openImage, setOpenImage] = useState(false);
  const [fabricColor, setFabricColor] = useState([]);
  const [selectedColor, setSelectedColor] = useState([]);
  const [matchingItems, setMatchingItems] = useState([]);

  function countDaysUntilCurrentDate(targetDateStr) {
    // Convert the target date string to a Date object
    const targetDate = new Date(targetDateStr);

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = targetDate - currentDate;

    // Take the absolute value of the time difference
    const daysDifference = Math.abs(timeDifference) / (1000 * 60 * 60 * 24);

    // Round the number to remove decimal places
    return Math.round(daysDifference);
  }

  const handleAutocompleteChange = async (event, newValue) => {
    setSelectedColor(newValue);
    const body = {
      order_item_fabric_required: state.order_item_fabric_required,
      fabric_color: newValue,
    };
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/api/draft/alternative`,
        body,
        config
      );
      setMatchingItems(data?.data?.result);
    } catch (error) {
      return error?.response?.data?.result;
    }
  };

  const handleSelect = async (item) => {
    setInfoModalOpen(true);
    setSelectedRowData({ ...state, item });
  };

  useEffect(async () => {
    setFabricColor(await getFabricColorList());
  }, []);

  return (
    <>
      {/* First Table */}
      <TableContainer component={Paper} sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100%",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Port No & Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order No
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item No
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Subitem Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Code
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Qty Required
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Delivery Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "170px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Draft Order Days
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "170px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Image
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ textAlign: "left" }}>
                {state.order_port_no_and_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>{state.order_no}</TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {displayDateFun(state.order_date)}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {state.order_item_no}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  wordWrap: "break-word",
                }}
              >
                {state.order_item_name}
              </TableCell>

              <TableCell sx={{ textAlign: "left" }}>
                {state.order_sub_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {state.order_item_kc_fabric_code}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {state.order_item_fabric_required}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {displayDateFun(state.order_est_delivery_date)}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {countDaysUntilCurrentDate(state.order_item_draft_days)}
              </TableCell>
              <TableCell sx={{ cursor: "pointer" }}>
                <img
                  onClick={() => {
                    setOpenImage(true);
                  }}
                  width={150}
                  src={`${process.env.REACT_APP_URL}/${state.fabric_image}`}
                />
              </TableCell>
              {/* date */}
              {/* <TableCell sx={{ textAlign: "left" }}>
                {dateFun(state.create_date)}
              </TableCell> */}
            </TableRow>
            <FabricImageModal
              open={openImage}
              onClose={() => setOpenImage(false)}
              rowData={state}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Div sx={{ width: "20%" }}>
        <Typography variant="h5" sx={{ fontWeight: "400" }}>
          Enter Color Code
        </Typography>
        <Autocomplete
          size="small"
          multiple
          id="tags-standard"
          options={fabricColor}
          getOptionLabel={(option) => option}
          freeSolo={true}
          value={selectedColor} // Use the selectedColor state for the value
          onChange={handleAutocompleteChange}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              sx={{ width: "88%" }}
              {...params}
              variant="outlined"
              placeholder="Choose Fabric Colors"
            />
          )}
        />
      </Div>
      {/* Second Table */}
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Code
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Image
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Color
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Qty Available
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Taka No
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Rack Detail
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {console.log(matchingItems)} */}
            {matchingItems?.map((state, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {state.kc_fabric_code}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <img
                    width={"100px"}
                    height={"100px"}
                    src={`${process.env.REACT_APP_URL}/${state.image}`} // Replace 'image_url' with the actual field containing the image URL in your data
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                  {/* {console.log(state.fabric_color)} */}
                  {/* {state?.fabricColor?.map((ele) => console.log(ele))} */}
                  {state.fabric_color.join(",")}
                </TableCell>
                <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                  {state.taka_reserve_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                  {state.taka_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                  {state.raw_rack_details}
                </TableCell>

                <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                  <Button onClick={() => handleSelect(state)}>Select</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <RefreshModal
          open={isInfoModalOpen}
          onClose={() => setInfoModalOpen(false)}
          rowData={selectedRowData}
          type={"alternative"}
        />
      </TableContainer>
    </>
  );
}
