import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
} from "@mui/material";

import { useSelector } from "react-redux";

import { useEffect } from "react";
import Swal from "sweetalert2";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function CuttingChallanGeneratedTable({ searchTerm }) {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");

  const { cuttingChallanGeneratedReport, error, loading } = useSelector(
    (state) => state.reportReducer
  );

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = cuttingChallanGeneratedReport?.sort((a, b) => {
    let aValue;
    let bValue;
    if (orderBy == "order_port_no_and_name") {
      aValue = a.order_port_no_and_name.toLowerCase();
      bValue = b.order_port_no_and_name.toLowerCase();
    } else if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });
  // useEffect(() => {
  //   if (error == "No order found") {
  //     Swal.fire({ icon: "error", title: "No Result Found" });
  //   }
  // }, []);

  return (
    <>
      {" "}
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_name"}
                  direction={order}
                  onClick={() => handleSort("order_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Product Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_port_no_and_name"}
                  direction={order}
                  onClick={() => handleSort("order_port_no_and_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Port Name & No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "count"}
                  direction={order}
                  onClick={() => handleSort("count")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Count
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          {/* <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.order_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.order_port_no_and_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>{row?.count}</TableCell>
            </TableRow>
          ))}
        </TableBody> */}

          <TableBody>
            {sortedData && sortedData.length > 0 ? (
              sortedData.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.order_item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.order_port_no_and_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>{row?.count}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={3}
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                >
                  Data not found
                </TableCell>
              </TableRow>
            )}
            {sortedData && sortedData.length > 0 && (
              <TableRow sx={{ fontWeight: "bold" }}>
                <TableCell
                  colSpan={2}
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  Total :
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontWeight: "bold", fontSize: 15 }}
                >
                  {sortedData[0]?.totalCount}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          count={0}
          hidePrevButton
          hideNextButton
          hideEllipsis
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            height: "40px",
          }}
        />
      </TableContainer>
    </>
  );
}
