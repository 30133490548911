import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Select,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dateFun } from "app/utils/constants/functions";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import React from "react";
import { useState } from "react";
import QcListTable from "./qcListTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQCOrderList } from "app/redux/actions/orderAction";
import { getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import { getOrderNoList } from "app/services/apis/ListApi/orderNoList";
import { getjobWorkIdList } from "app/services/apis/ListApi/jobWorkIdList";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ChallanListTable from "./challanListTable";
import QcDoneListTable from "./qcDoneTable";
import { downloadQCNotDoneReport } from "app/services/apis/ReportApis/QC/QCNotDoneReports";
import { downloadQCDoneReport } from "app/services/apis/ReportApis/QC/QCDoneReports";
import { downloadQCChallanReport } from "app/services/apis/ReportApis/QC/QCChallanReports";

const QCOrderList = () => {
  const [inputs, setInputs] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [portNoNameList, setPortNoNameList] = useState([]);
  const [page, setPage] = useState(1);
  const [orderNoList, setOrderNoList] = useState([]);
  const [jobWorkidList, setJobWorkidList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const [value, setValue] = useState("1");
  const { qcOrder, TotalPage } = useSelector((state) => state.orderReducer);
  const handleTabChange = (event, newValue) => {
    setPage(1);
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFilter = () => {
    setPage(1);
    if (value == 1) {
      dispatch(getQCOrderList("", inputs, 1, "qcList"));
    }
    if (value == 2) {
      dispatch(getQCOrderList("", inputs, 1, "challanList"));
    }
    if (value == 3) {
      dispatch(getQCOrderList("", inputs, 1, "qcDone"));
    }
  };

  const handleClear = () => {
    setPage(1);
    if (value == 1) {
      dispatch(getQCOrderList("", "", 1, "qcList"));
    }
    if (value == 2) {
      dispatch(getQCOrderList("", "", 1, "challanList"));
    }
    if (value == 3) {
      dispatch(getQCOrderList("", "", 1, "qcDone"));
    }
    setInputs({
      order_port_no_and_name: null,
      order_no: null,
      order_item_job_work_id: null,
      order_item_factory_name: null,
      order_item_qc_status: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
    });
    setResetAutocomplete((prev) => !prev);
  };

  const handleSearch = (data) => {
    if (value == 1) {
      dispatch(getQCOrderList(data, "", "", "qcList"));
    }
    if (value == 2) {
      dispatch(getQCOrderList(data, "", "", "challanList"));
    }
    if (value == 3) {
      dispatch(getQCOrderList(data, "", "", "qcDone"));
    }
  };

  useEffect(async () => {
    if (value == 1) {
      dispatch(getQCOrderList("", "", "", "qcList"));
    }
    if (value == 2) {
      dispatch(getQCOrderList("", "", "", "challanList"));
    }
    if (value == 3) {
      dispatch(getQCOrderList("", "", "", "qcDone"));
    }
    setInputs({
      order_port_no_and_name: null,
      order_no: null,
      order_item_job_work_id: null,
      order_item_factory_name: null,
      order_item_qc_status: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
    });
    setResetAutocomplete((prev) => !prev);
  }, [value]);

  useEffect(async () => {
    setPortNoNameList(await getPortListNoPerm());
    setOrderNoList(await getOrderNoList());
    setJobWorkidList(await getjobWorkIdList());
    setFactoryList(await getFactoryNameList());
  }, []);

  return (
    <Div>
      <Typography variant="h1" sx={{ mt: -4 }}>
        QC Order
      </Typography>
      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Port No & Name</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={portNoNameList || []}
            // value={inputs.order_port_no_and_name}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              inputs.order_port_no_and_name = newValue ? newValue : null; // Set to null when newValue is null
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Order No</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={orderNoList || []}
            // value={inputs.order_no}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              inputs.order_no = newValue ? newValue : null; // Set to null when newValue is null
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Job Work ID</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={jobWorkidList || []}
            // value={inputs.order_item_job_work_id}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              inputs.order_item_job_work_id = newValue ? newValue : null; // Set to null when newValue is null
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Factory</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={factoryList || []}
            // value={inputs.order_item_factory_name}
            getOptionLabel={(option) => option?.factory_name}
            onChange={(e, newValue) => {
              inputs.order_item_factory_name = newValue?.factory_name
                ? newValue?.factory_name
                : null; // Set to null when newValue is null
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.factory_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Status</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            value={inputs.qc_status || ""}
            name="qc_status"
            onChange={handleChange}
          >
            <MenuItem value={"Saved"}>{"Saved"}</MenuItem>
            <MenuItem value={"Draft"}>{"Draft"}</MenuItem>
            <MenuItem value={"Pass"}>{"Pass"}</MenuItem>
            <MenuItem value={"Fail"}>{"Fail"}</MenuItem>
          </Select>
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Item Status</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            value={inputs.order_item_status || ""}
            name="order_item_status"
            onChange={handleChange}
          >
            <MenuItem value={"QC Pending"}>QC Pending</MenuItem>
            <MenuItem value={"QC Pass"}>QC Pass</MenuItem>
            <MenuItem value={"Alteration Sent"}>Alteration Sent</MenuItem>
            <MenuItem value={"Dispatch"}>Dispatch</MenuItem>
            <MenuItem value={"Delivered"}>Delivered</MenuItem>
          </Select>
        </Div>
        <Div>
          <Typography variant="h5">Delivery Date Range</Typography>
          <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "45%" }}>
                <DatePicker
                  maxDate={dayjs()}
                  format="DD/MM/YY"
                  value={inputs?.order_est_delivery_date_from}
                  error={!inputs?.order_est_delivery_date_from}
                  onChange={(newDate) =>
                    (inputs.order_est_delivery_date_from = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "45%" }}>
                <DatePicker
                  maxDate={dayjs()}
                  format="DD/MM/YY"
                  value={inputs.order_est_delivery_date_to}
                  error={!inputs.order_est_delivery_date_to}
                  onChange={(newDate) =>
                    (inputs.order_est_delivery_date_to = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
          </Div>
        </Div>
      </Div>
      {/* apply and clear */}
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          justifyContent: "space-between",
        }}
      >
        <Div>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Div>

        <Button
          disabled={qcOrder?.length == 0}
          variant="contained"
          onClick={async () => {
            if (value == "1") {
              const data = await downloadQCNotDoneReport(inputs);
              window.open(data);
            }
            if (value == "2") {
              const data = await downloadQCChallanReport(inputs);
              window.open(data);
            }
            if (value == "3") {
              const data = await downloadQCDoneReport(inputs);
              window.open(data);
            }
          }}
        >
          Export CSV
        </Button>
      </Div>

      {/* search */}
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          mt: 3.5,
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search"
          size="small"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mt: "auto" }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
      </Div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab label="Qc List" value="1" />
            <Tab label="Challan Generated" value="2" />
            <Tab label="Qc Done" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <QcListTable
            searchTerm={searchTerm}
            setPage={setPage}
            page={page}
            inputs={inputs}
          />
        </TabPanel>
        <TabPanel value="2">
          <ChallanListTable
            searchTerm={searchTerm}
            setPage={setPage}
            page={page}
            inputs={inputs}
          />
        </TabPanel>
        <TabPanel value="3">
          <QcDoneListTable
            searchTerm={searchTerm}
            setPage={setPage}
            page={page}
            inputs={inputs}
          />
        </TabPanel>
      </TabContext>
    </Div>
  );
};

export default QCOrderList;
