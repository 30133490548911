import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getFinishedManuallyIssued
} from "app/redux/actions/inventoryAction";
import { getFinishItemNameList } from "app/services/apis/ListApi/itemNameList";
import { getOrderNoList } from "app/services/apis/ListApi/orderNoList";
import { downloadFinishedManuallyIssuedReport } from "app/services/apis/ReportApis/InventoryFinishedManuallyIssuedReport";
import { dateFun } from "app/utils/constants/functions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const FinishedIssuedFilter = () => {
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [kcFabricCode, setKcFabricCode] = useState([]);
  const [orderNoList, setOrderNoList] = useState([]);
  const [rakeDetails, setRakeDetails] = useState([]);
  const [purchaseOrderNo, setPurchaseOrderNo] = useState([]);
  const [inputs, setInputs] = useState({});
  const [itemNameList, setItemNameList] = useState([]);
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const { finishedGoodsManuallyIssued, error, TotalPage } = useSelector(
    (state) => state.inventoryReducer
  );
  const dispatch = useDispatch();

  const handleFilter = () => {
    dispatch(getFinishedManuallyIssued("", inputs, ""));
  };

  const handleClear = () => {
    dispatch(getFinishedManuallyIssued("", "", 1));
    setInputs({});
    setResetAutocomplete((prev) => !prev);
  };
  useEffect(async () => {
    setOrderNoList(await getOrderNoList());
    setItemNameList(await getFinishItemNameList());
  }, []);

  return (
    <>
      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        <Div sx={{ width: "20%" }}>
          <Typography variant="h5">Item Name</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={itemNameList || []}
            // value={}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                finished_item_name: newValue ? newValue : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "20%" }}>
          <Typography variant="h5">Order No</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={orderNoList || []}
            // value={}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                finished_manually_order_no: newValue ? newValue : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "25%" }}>
          <Typography variant="h5">Issued Date</Typography>
          <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "100%" }}>
                <DatePicker
                  // maxDate={dayjs()}
                  format="DD/MM/YYYY"
                  key={resetAutocomplete ? "reset1" : "normal1"}
                  onChange={(newDate) =>
                    (inputs.finished_manually_order_date = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
          </Div>
        </Div>
      </Div>

      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          justifyContent: "space-between",
        }}
      >
        <Div>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Div>
        <Button
          disabled={finishedGoodsManuallyIssued?.length == 0}
          variant="contained"
          onClick={async () => {
            const data = await downloadFinishedManuallyIssuedReport(inputs);
            window.open(data);
          }}
        >
          Export CSV
        </Button>
      </Div>
    </>
  );
};

export default FinishedIssuedFilter;
