import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useState } from "react";
import Div from "@jumbo/shared/Div";
import { MenuItem, Select, Typography } from "@mui/material";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";
import Swal from "sweetalert2";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const RefreshModal = ({ open, onClose, rowData, type }) => {
  const [loader, setLoader] = useState(false);
  const [factoryList, setFactoryList] = useState([]);
  const [inputs, setInputs] = useState({
    factory_master_id: "",
  });
  const [alternativeInputs, setAlternativeInputs] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (type == "refresh") {
      setInputs({
        ...inputs,
        kc_fabric_code: rowData?.order_item_kc_fabric_code,
      });
    } else {
      setAlternativeInputs({
        ...alternativeInputs,
        taka_id: rowData?.item?.id,
      });
    }
  }, [rowData]);

  useEffect(async () => {
    setFactoryList(await getFactoryNameList());
  }, []);

  const handleCreateOrder = async () => {
    if (type == "refresh") {
      try {
        setLoader(true);
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const res = await axios.post(
          `${process.env.REACT_APP_URL}/api/draft/add-order-from-draft?id=${rowData?.order_items_id}`,
          inputs,
          config
        );
        if (res?.status == 200) {
          Swal.fire({ icon: "success", title: "Order Created" });
          navigate("/dashboard/order/admin");
          onClose();
        }
      } catch (error) {
        Swal.fire({ icon: "error", title: "Not Created" });
        onClose();
      } finally {
        setLoader(false);
      }
    } else {
      try {
        setLoader(true);
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const res = await axios.patch(
          `${process.env.REACT_APP_URL}/api/draft/modify-available-kfc?id=${rowData?.order_items_id}`,
          alternativeInputs,
          config
        );
        if (res?.status == 200) {
          Swal.fire({ icon: "success", title: "Order Created" });
          navigate("/dashboard/order/admin");
          onClose();
        }
      } catch (error) {
        Swal.fire({ icon: "error", title: "Not Created" });
        onClose();
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle sx={{ fontWeight: "700" }}>
          Select Factory For Order
        </DialogTitle>
      </Div>
      <DialogContent>
        <Div sx={{ width: "100%" }}>
          <Typography variant="h5">Factory</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            defaultValue={inputs.order_item_factory_name || ""}
            name="order_item_factory_name"
            onChange={(e) =>
              type == "refresh"
                ? setInputs({
                    ...inputs,
                    factory_master_id: e.target.value,
                  })
                : setAlternativeInputs({
                    ...alternativeInputs,
                    factory_master_id: e.target.value,
                  })
            }
          >
            {factoryList?.map((item) => {
              return <MenuItem value={item?.id}>{item?.factory_name}</MenuItem>;
            })}
          </Select>
        </Div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={
            type == "refresh"
              ? inputs?.factory_master_id
                ? false
                : true
              : alternativeInputs?.factory_master_id
              ? false
              : true
          }
          loading={loader}
          size="small"
          onClick={handleCreateOrder}
          variant="contained"
        >
          Create Order
        </LoadingButton>
        <Button
          size="small"
          onClick={onClose}
          color="error"
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefreshModal;
