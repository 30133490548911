import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import axios from "axios";
import { getAllDeliveredDispatch } from "app/redux/actions/orderAction";
import { displayDateFun } from "app/utils/constants/functions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CuttingListInfoModal } from "../../Cutter/List/cuttingListInfoModal";
import DispatchDetailInfoModal from "./Modals/Dispatch Detail/dispatchDetailInfoModal";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function DeliveryTable({ searchTerm, setPage, page, inputs }) {
  const dispatch = useDispatch();
  const Swal = useSwalWrapper();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [isDispatchDetailOpen, setDispatchDetailsOpen] = useState(false);
  const [isItemOpen, setIsItemOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const navigate = useNavigate();
  const permissions = JSON?.parse(sessionStorage.getItem("permissions"));

  const { dispatchDelivered, error, TotalPage, loading } = useSelector(
    (state) => state?.orderReducer
  );

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = dispatchDelivered?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "transport_type") {
      aValue = a?.transport_type.toLowerCase();
      bValue = b?.transport_type.toLowerCase();
    } else if (orderBy == "salesman_name") {
      aValue = a?.salesman_name.toLowerCase();
      bValue = b?.salesman_name.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllDeliveredDispatch(searchTerm, inputs, newPage));
  };

  const handleInfoIconClick = (rowData) => {
    setSelectedRowData(rowData);
    setDispatchDetailsOpen(true);
  };

  const handleAwbNo = async (val, row) => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/api/dispatch/update-awb-bl-no-dispatch?dispatch_main_id=${row.dispatch_main_id}`,
        { awb_bl_no: val },
        config
      );
      if (res?.status == 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "AWB / BL No. Updated Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(getAllDeliveredDispatch("", "", 1));
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      {" "}
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Port No & Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order No
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item Dispatch
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "dispatch_shipment_no"}
                  direction={order}
                  onClick={() => handleSort("dispatch_shipment_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Shipment No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "dispatch_id"}
                  direction={order}
                  onClick={() => handleSort("dispatch_id")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Dispatch ID
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "transport_type"}
                  direction={order}
                  onClick={() => handleSort("transport_type")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Transportation Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "awb_bl_no"}
                  direction={order}
                  onClick={() => handleSort("awb_bl_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  AWB/ BL No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Dispatch Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "salesman_name"}
                  direction={order}
                  onClick={() => handleSort("salesman_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sales Person
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "170px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Delivery Date
              </TableCell>
              {permissions?.dispatch_order_create == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "170px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.order_port_no_and_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.order_no}
                </TableCell>

                <TableCell sx={{ textAlign: "left", pl: "3.8%" }}>
                  <InfoIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsItemOpen(true);
                      setSelectedRowData(row);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.dispatch_shipment_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.dispatch_id}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row?.transport_type}
                </TableCell>

                {row?.transport_type == "Hand" ? (
                  <TableCell sx={{ textAlign: "center" }}>--</TableCell>
                ) : (
                  <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      defaultValue={row.awb_bl_no}
                      onBlur={(e) => {
                        handleAwbNo(e.target.value, row);
                      }}
                    />
                  </TableCell>
                )}

                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "100px",
                    pl: 7.5,
                  }}
                >
                  <InfoIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleInfoIconClick(row)}
                  />
                </TableCell>
                <TableCell>{row?.salesman_name}</TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row?.order_date)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", pl: 3.5 }}>
                  {displayDateFun(row?.order_item_delivery_date)}
                </TableCell>
                {permissions?.dispatch_order_create == true && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    <Button
                      size="small"
                      onClick={() =>
                        navigate("/dashboard/order/replaceOrder", {
                          state: {
                            order_no: row.order_no,
                            type: "ReplacementOrder",
                          },
                        })
                      }
                    >
                      Replacement
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
        <DispatchDetailInfoModal
          open={isDispatchDetailOpen}
          onClose={() => setDispatchDetailsOpen(false)}
          rowData={selectedRowData}
        />
        <CuttingListInfoModal
          type={"normalOrder"}
          open={isItemOpen}
          onClose={() => {
            setIsItemOpen(false);
          }}
          rowData={selectedRowData}
        />
      </TableContainer>
    </>
  );
}
