import {
  ALL_ROLE_SUCCESS,
  LOAD_ROLE_FAIL,
  LOAD_ROLE_REQUEST,
} from "app/utils/constants/roleConstants";
import axios from "axios";

export const getAllRoles = (search_value, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }
    dispatch({ type: LOAD_ROLE_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/role/list-role?search=${search_value}&page=${page}`,
      config
    );
    // console.log(data);
    dispatch({
      type: ALL_ROLE_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: LOAD_ROLE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
