import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getInvoiceNoByID } from "app/services/apis/Inventory/receiveInvoiceByID";
import { getGstListNoPem } from "app/services/apis/ListApi/gst";
import { getGstList } from "app/services/apis/ListApi/gstList";
import { dateFun, displayDateFun } from "app/utils/constants/functions";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AddProduction = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [addonsList, setAddonsList] = useState(
    state?.addons_items?.length > 0
      ? state?.addons_items.map((item) => {
          return {
            order_item_addons_id: item?.order_item_addons_id,
            addons_status: "no",
            addons_quantity: item?.addons_quantity,
          };
        })
      : []
  );
  {
    // console.log(state);
  }
  const [inputs, setInputs] = useState({
    order_item_job_work_id: state?.order_item_job_work_id || "",
    order_items_id: state?.order_items_id || "",
    order_no: state?.order_no || "",
    order_item_name: state?.order_item_name || "",
    order_sub_item_name: state?.order_sub_item_name || "",
    order_item_no: state?.order_item_no || "",
    order_item_status: state?.order_item_status || "",
    productions_factory_no: state?.factory_master_id || "",
    order_item_name: state?.order_item_name || "",
    order_item_factory_name: state?.order_item_factory_name || "",
    order_item_receiving_date: state?.order_item_collection_date || "",
    productions_invoice_no: "",
    productions_invoice_date: dateFun(Date.now()),
    productions_contact_person_name: "",
    productions_contact_person_number: "",
    productions_gst_value: 0,
    productions_amount: 0,
    productions_gst_percentage: 0,
    productions_extra_amount: 0,
    productions_sgst: 0,
    productions_cgst: 0,
    productions_igst: 0,
    productions_total: 0,
  });
  const [gstList, setGstList] = useState([]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name == "productions_amount") {
      const gstValue =
        (Number(value) * Number(inputs?.productions_gst_percentage)) / 100;
      setInputs((values) => ({
        ...values,
        productions_amount: Number(value),
        productions_gst_value: gstValue,
        productions_total:
          Number(value) + Number(inputs?.productions_extra_amount) + gstValue,
      }));
    } else if (name == "productions_extra_amount") {
      const gstValue =
        (Number(value) * Number(inputs?.productions_gst_percentage)) / 100;
      setInputs((values) => ({
        ...values,
        productions_extra_amount: Number(value),
        productions_gst_value: gstValue,
        productions_total:
          Number(value) + Number(inputs?.productions_amount) + gstValue,
      }));
    } else if (name == "productions_gst_percentage") {
      let equalValue =
        state?.order_item_factory_state == "Maharashtra"
          ? Number(value) / 2
          : 0;
      const gstValue =
        ((Number(inputs?.productions_amount) +
          Number(inputs?.productions_extra_amount)) *
          Number(value)) /
        100;

      setInputs((values) => ({
        ...values,
        productions_gst_percentage: Number(value),
        productions_igst: equalValue == 0 ? Number(value) : 0,
        productions_sgst: equalValue > 0 ? equalValue : 0,
        productions_cgst: equalValue > 0 ? equalValue : 0,
        productions_gst_value: gstValue,
        productions_total:
          Number(inputs?.productions_amount) +
          Number(inputs?.productions_extra_amount) +
          gstValue,
      }));
    } else {
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };
  const handleSelect = (e, data, rowIndex) => {
    // console.log(data);
    if (e.target.checked != true) {
      const updatedItemDetails = [...addonsList];
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        checked: false,
        addons_status: "no",
      };
      setAddonsList(updatedItemDetails);
    } else {
      const updatedItemDetails = [...addonsList];
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        checked: true,
        addons_status: "yes",
      };
      setAddonsList(updatedItemDetails);
    }
  };

  const headings = [
    "Item Name",
    "Sub Item Name",
    "Item Number",
    "Status",
    "Receiving Date",
    "Job Work Issued Date",
  ];

  const handleSubmit = async () => {
    if (inputs.productions_contact_person_name == "") {
      return Swal.fire({ icon: "error", title: "Person Name is Required" });
    } else if (inputs.productions_contact_person_number == "") {
      return Swal.fire({ icon: "error", title: "Contact Number is Required" });
    }
    setLoader(true);
    const details = {
      order_items_id: inputs.order_items_id,
      inward_type: "Production",
      addons_items: addonsList?.map((item) => {
        return {
          order_item_addons_id: item?.order_item_addons_id,
          addons_status: item?.addons_status,
          addons_quantity: item.addons_quantity,
        };
      }),
      productions_contact_person_name: inputs.productions_contact_person_name,
      productions_contact_person_phone_no: Number(
        inputs.productions_contact_person_number
      ),
      order_item_receiving_date: inputs.order_item_receiving_date,
      productions_gst_value: inputs.productions_gst_value,
      productions_amount: Number(inputs.productions_amount),
      productions_extra_amount: Number(inputs.productions_extra_amount),
      productions_gst_percentage: inputs.productions_gst_percentage,
      productions_sgst: inputs?.productions_sgst,
      productions_cgst: inputs?.productions_cgst,
      productions_igst: inputs?.productions_igst,
      productions_total: Number(inputs?.productions_total),
      productions_invoice_date: inputs?.productions_invoice_date,
      productions_invoice_no: inputs?.productions_invoice_no,
    };
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/api/production/add-production`,
        details,
        config
      );
      if (res?.status == 200) {
        Swal.fire({ icon: "success", title: "Item Received Successfully" });
        // navigate("/dashboard/order/receive");
        window.history.back();
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: error?.response?.data?.message });
    } finally {
      setLoader(false);
    }
  };

  useEffect(async () => {
    setGstList(await getGstListNoPem());
    let invoiceData = await getInvoiceNoByID(state?.order_items_id);
    // console.log(invoiceData);
    if (invoiceData?.length > 0) {
      invoiceData = invoiceData?.[0];
      setInputs((values) => ({
        ...values,
        productions_invoice_no: invoiceData?.productions_invoice_no,
        productions_invoice_date: dateFun(
          invoiceData?.productions_invoice_date
        ),
        productions_contact_person_name:
          invoiceData?.productions_contact_person_name,
        productions_contact_person_number:
          invoiceData?.productions_contact_person_phone_no,
        productions_gst_value: invoiceData?.productions_gst_value,
        productions_amount: invoiceData?.productions_amount,
        productions_gst_percentage: invoiceData?.productions_gst_percentage,
        productions_extra_amount: invoiceData?.productions_extra_amount,
        productions_sgst: invoiceData?.productions_sgst,
        productions_cgst: invoiceData?.productions_cgst,
        productions_igst: invoiceData?.productions_igst,
        productions_total: invoiceData?.productions_total,
      }));
    }
  }, []);

  return (
    <Div>
      <Typography variant="h1" sx={{ mt: -4, mb: 3 }}>
        Receive Order
      </Typography>
      <Div
        sx={{ display: "flex", flexWrap: "wrap", width: "100%", gap: 5, mb: 3 }}
      >
        <Div sx={{ width: "45%" }}>
          <Typography variant="h5">
            Job Work ID: {inputs?.order_item_job_work_id}
          </Typography>
        </Div>
        <Div sx={{ width: "45%" }}>
          <Typography variant="h5">
            Order Number : {inputs?.order_no}
          </Typography>
        </Div>
      </Div>
      {/* Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              {headings.map((heading) => (
                <TableCell
                  key={heading}
                  sx={{
                    textAlign: "left",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ textAlign: "left" }}>
                {inputs?.order_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {inputs?.order_sub_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {inputs?.order_item_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {inputs?.order_item_status}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Div sx={{ width: "100%" }}>
                    <DatePicker
                      defaultValue={dayjs(state?.order_item_collection_date)}
                      onChange={(newDate) =>
                        (inputs.order_item_receiving_date = dateFun(newDate))
                      }
                      sx={{
                        width: "50%",
                        "& .MuiInputBase-input": {
                          padding: 1,
                        },
                      }}
                    />
                  </Div>
                </LocalizationProvider>
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {state?.order_item_job_work_issued_date
                  ? displayDateFun(state?.order_item_job_work_issued_date)
                  : "-"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {Array.isArray(state?.addons_items) &&
        state?.addons_items?.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: 5 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Received
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Job Work ID
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Item Name
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Size
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Quantity
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Factory
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.addons_items
                  ?.filter((data) => {
                    return data.addons_status == "yes";
                  })
                  .map((row, i) => (
                    <TableRow key={i}>
                      <TableCell sx={{ textAlign: "left" }}>
                        <Checkbox
                          {...label}
                          checked={addonsList[i]?.checked}
                          onChange={(e) => handleSelect(e, row, i)}
                        />
                        {/* {console.log(row)} */}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {inputs?.order_item_job_work_id}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {row?.itemName}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {row?.item_size ? row?.item_size : "-"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {row?.addons_quantity}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {inputs?.order_item_factory_name}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      {Array.isArray(state?.order_item_addOns) &&
        state?.order_item_addOns?.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: 5 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Received
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Job Work ID
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Item Name
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Size
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Quantity
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Factory
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.order_item_addOns
                  ?.filter((data) => {
                    return data?.status == "yes";
                  })
                  .map((row, i) => (
                    <TableRow key={i}>
                      <TableCell sx={{ textAlign: "left" }}>
                        <Checkbox
                          {...label}
                          checked={addonsList[i]?.checked}
                          onChange={(e) => handleSelect(e, row, i)}
                        />
                        {/* {console.log(row)} */}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {inputs?.order_item_job_work_id}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {row?.itemName}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {row?.size ? row?.size : "-"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {row?.quantity}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {inputs?.order_item_factory_name}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <Div
        sx={{ display: "flex", flexWrap: "wrap", mt: 3, width: "100%", gap: 5 }}
      >
        <Div sx={{ width: "100%" }}>
          <Typography variant="h5">
            Factory: {inputs.order_item_factory_name}
          </Typography>
        </Div>
        <Div sx={{ width: "100px" }}>
          <Typography>Invoice No.</Typography>
          <TextField
            size="small"
            name="productions_invoice_no"
            value={inputs.productions_invoice_no}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>
        <Div sx={{ width: "200px" }}>
          <Typography>Invoice Date</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Div>
              <DatePicker
                defaultValue={dayjs(inputs.productions_invoice_date)}
                onChange={(newDate) =>
                  (inputs.productions_invoice_date = dateFun(newDate))
                }
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": {
                    padding: 1,
                  },
                }}
              />
            </Div>
          </LocalizationProvider>
        </Div>
        <Div sx={{ width: "100px" }}>
          <Typography>Amount</Typography>
          <TextField
            size="small"
            type="number"
            name="productions_amount"
            value={inputs.productions_amount}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>
        <Div sx={{ width: "100px" }}>
          <Typography>Extra Cost</Typography>
          <TextField
            size="small"
            type="number"
            name="productions_extra_amount"
            value={inputs.productions_extra_amount}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>
        <Div sx={{ width: "100px" }}>
          <Typography>GST%</Typography>
          <Select
            name="productions_gst_percentage"
            value={inputs.productions_gst_percentage}
            onChange={handleChange}
            fullWidth
            size="small"
          >
            {gstList?.map((gst) => {
              return <MenuItem value={gst}>{gst}%</MenuItem>;
            })}
          </Select>
        </Div>
        {state?.order_item_factory_state == "Maharashtra" ? (
          <>
            <Div sx={{ width: "100px" }}>
              <Typography>CGST%</Typography>

              <TextField
                size="small"
                sx={{ width: "100%" }}
                name="productions_cgst"
                disabled={true}
                value={inputs?.productions_cgst}
              />
            </Div>

            <Div sx={{ width: "100px" }}>
              <Typography>SGST%</Typography>
              <TextField
                size="small"
                sx={{ width: "100%" }}
                name="productions_sgst"
                disabled={true}
                value={inputs?.productions_sgst}
              />
            </Div>
          </>
        ) : (
          <Div sx={{ width: "100px" }}>
            <Typography>IGST%</Typography>
            <TextField
              size="small"
              sx={{ width: "100%" }}
              name="productions_igst"
              disabled={true}
              value={inputs?.productions_igst}
            />
          </Div>
        )}
        <Div sx={{ width: "100px" }}>
          <Typography>Gst Value</Typography>
          <TextField
            size="small"
            name="productions_gst_value"
            disabled
            value={inputs.productions_gst_value}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>

        <Div sx={{ width: "100px" }}>
          <Typography>Total</Typography>
          <TextField
            size="small"
            sx={{ width: "100%" }}
            name="productions_total"
            disabled={true}
            value={inputs?.productions_total}
          />
        </Div>
      </Div>
      <Div sx={{ display: "flex", mt: 2, gap: 4 }}>
        <Div sx={{ width: "200px" }}>
          <Typography>Contact Person Name</Typography>
          <TextField
            size="small"
            onChange={handleChange}
            name="productions_contact_person_name"
            sx={{ width: "100%" }}
            value={inputs?.productions_contact_person_name}
          />
        </Div>
        <Div sx={{ width: "200px" }}>
          <Typography>Contact Person Number</Typography>
          <TextField
            onChange={handleChange}
            size="small"
            name="productions_contact_person_number"
            sx={{ width: "100%" }}
            value={inputs?.productions_contact_person_number}
          />
        </Div>
      </Div>
      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 5,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to cancel?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                window.history.back();
              }
            });
          }}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={loader}
          variant="contained"
          onClick={handleSubmit}
          sx={{ width: "100px" }}
        >
          Save
        </LoadingButton>
      </Div>
    </Div>
  );
};

export default AddProduction;
