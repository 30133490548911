import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";

import Div from "@jumbo/shared/Div";

const UploadImagesModal = ({
  open,
  onClose,
  rowData,
  setItemDetails,
  itemDetails,
}) => {
  useEffect(() => {
    // console.log(rowData);
  }, [rowData, open]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle sx={{ fontWeight: "700" }}>Selected Images</DialogTitle>
      </Div>
      <DialogContent>
        {rowData?.length > 0 ? (
          <Div
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {rowData?.map((catalogue) => {
              return (
                <img
                  loading="lazy"
                  height={"200px"}
                  width={"150px"}
                  style={{ cursor: "pointer", border: "1px solid" }}
                  src={`${process.env.REACT_APP_URL}/${catalogue}`}
                />
              );
            })}
          </Div>
        ) : (
          <Typography variant="h1">No Images Selected</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <>
          <Button
            size="small"
            onClick={onClose}
            color="error"
            variant="contained"
          >
            Close
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default UploadImagesModal;
