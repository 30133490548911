import axios from "axios";

export const getTakaNoList = async (kcFabricCode) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    let data = await axios.get(
      `${process.env.REACT_APP_URL}/api/inventory/list/raw-taka-no-basedOn-kccode-list?kc_fabric_code=${kcFabricCode}`,
      config
    );
    console.log(data,'data');
    return data?.data?.result;
  } catch (error) {
    console.log(error);
    return error?.response?.data?.result;
  }
};
