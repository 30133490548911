import Div from "@jumbo/shared/Div";

import React from "react";

import SalesYearlyReport from "./Sales";

const AdminOrderList = () => {
  return (
    <Div>
      <SalesYearlyReport />
    </Div>
  );
};

export default AdminOrderList;
