import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useState } from "react";
import {
  Box,
  CircularProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import Switch from "@mui/material/Switch";
import Div from "@jumbo/shared/Div";
import { getAddonsItemNameList } from "app/services/apis/ListApi/addOnsItemNameList";
import { getFinishedItemSize } from "app/services/apis/ShopOrderApis/getSizeByFinishItem";
import { useLocation } from "react-router-dom";

const AddonsModal = ({
  open,
  onClose,
  rowData,
  index,
  flag,
  setItemDetails,
  itemDetails,
}) => {
  const [addons, setAddons] = useState([]);
  const [loader, setLoader] = useState();
  const [itemNameList, setItemNameList] = useState([]);
  const { pathname } = useLocation();
  const addEmptyRow = () => {
    const emptyRow = {
      itemName: "",
      quantity: "",
      status: "no",
      size: null,
    };
    setAddons([...addons, emptyRow]);
  };

  const handleRemoveItem = (index) => {
    const updatedItemDetails = [...addons];
    updatedItemDetails.splice(index, 1);
    setAddons(updatedItemDetails);
  };

  const handleOnChange = async (e, i) => {
    let addonsName = rowData?.order_item_addOns;
    const name = e.target.name;
    const value = e.target.value;
    if (name == "itemName") {
      addons[i].sizeList = await getFinishedItemSize(value);
      setAddons([...addons]);
    }

    let updatedDetails = [...addons];
    if (name == "status") {
      updatedDetails[i] = {
        ...updatedDetails[i],
        [name]: e.target.checked == true ? "yes" : "no",
      };
    } else if (name == "quantity") {
      updatedDetails[i] = {
        ...updatedDetails[i],
        [name]: value,
      };
    } else {
      updatedDetails[i] = {
        ...updatedDetails[i],
        [name]: value,
      };
    }
    setAddons(updatedDetails);
  };

  useEffect(async () => {
    if (open == true) {
      setItemNameList(await getAddonsItemNameList());
    }
  }, [open]);

  useEffect(() => {
    // console.log(rowData);
    if (rowData?.order_item_addOns) {
      setAddons(
        rowData?.order_item_addOns?.length > 0
          ? rowData?.order_item_addOns
          : [
              {
                itemName: "",
                quantity: "",
                status: "no",
                size: null,
              },
            ]
      );
    } else {
      setAddons(
        rowData?.addons_items?.length > 0
          ? rowData?.addons_items
          : [
              // {
              //   itemName: "",
              //   addons_quantity: "",
              //   addons_status: "no",
              //   item_size: null,
              // },
              {
                itemName: "",
                quantity: "",
                status: "no",
                size: null,
              },
            ]
      );
    }
  }, [rowData]);

  useEffect(() => {
    if (itemDetails) {
      const updated = [...itemDetails];
      updated[index] = {
        ...updated[index],
        order_item_addOns: addons?.filter(
          (item, i, arr) => item?.itemName != ""
        ),
      };
      setItemDetails(updated);
    }
  }, [addons]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle sx={{ fontWeight: "700" }}>Add Addons</DialogTitle>
      </Div>
      <DialogContent>
        {loader ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size="1.5rem" />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Size
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Send To Factory
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {console.log(addons, "addons")}
                {addons?.map((row, i) => (
                  <>
                    <TableRow key={i}>
                      <TableCell>
                        <Select
                          value={row?.itemName || ""}
                          name="itemName"
                          onChange={(e) => handleOnChange(e, i)}
                          size="small"
                          sx={{ width: "80%" }}
                        >
                          {itemNameList &&
                            itemNameList?.length > 0 &&
                            itemNameList?.map((item) => {
                              return (
                                <MenuItem value={item?.addons_name}>
                                  {item?.addons_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={row?.size ? row?.size : row?.item_size}
                          name="size"
                          onChange={(e) => handleOnChange(e, i)}
                          size="small"
                          sx={{ width: "80%" }}
                        >
                          {addons[i]?.sizeList?.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          inputProps={{ min: 1 }}
                          onKeyDown={(e) => {
                            const inputValue = parseInt(
                              e.target.value + e.key,
                              10
                            );
                            if (isNaN(inputValue) || inputValue < 1) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => handleOnChange(e, i)}
                          name="quantity"
                          size="small"
                          sx={{ width: "60%" }}
                          value={
                            row?.quantity ? row?.quantity : row?.addons_quantity
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Switch
                          disabled={!row?.order_item_addons_id ? false : true}
                          // disabled={
                          //   pathname != "/dashboard/order/editOrder"
                          //     ? false
                          //     : true
                          // }
                          name="status"
                          defaultChecked={
                            row?.status == "yes" || row?.addons_status == "yes"
                              ? true
                              : false
                          }
                          onChange={(e) => handleOnChange(e, i)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </TableCell>

                      <TableCell>
                        {!row?.order_item_addons_id ? (
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={() => handleRemoveItem(i)}
                            sx={{ cursor: "pointer" }}
                          >
                            Remove
                          </Button>
                        ) : (
                          "N/A"
                        )}
                        {/* {pathname != "/dashboard/order/editOrder" ? (
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={() => handleRemoveItem(i)}
                            sx={{ cursor: "pointer" }}
                          >
                            Remove
                          </Button>
                        ) : (
                          "N/A"
                        )} */}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
              <TableRow>
                {/* {console.log(rowData?.order_items_id)} */}
                {rowData?.order_items_id == undefined ||
                pathname == "/dashboard/order/editItem" ||
                pathname == "/dashboard/order/replaceOrder" ? (
                  <TableCell colSpan={9}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => addEmptyRow()}
                      sx={{ cursor: "pointer" }}
                    >
                      Add More
                    </Button>
                  </TableCell>
                ) : null}
              </TableRow>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        {!loader && (
          <>
            <Button
              size="small"
              onClick={onClose}
              color="error"
              variant="contained"
            >
              Close
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddonsModal;
