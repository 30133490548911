import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
} from "@mui/material";
import "./fabric.css";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllFabric } from "app/redux/actions/masterAction";
import FabricImageModal from "./imageModal";
import FullScreenLoader from "app/components/ListingPageLoader";
export default function ListFabricTable({ searchTerm }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selectedRowData, setSelectedRowData] = useState();
  const [openImage, setOpenImage] = useState(false);
  const [page, setPage] = useState(1);
  const { fabricMaster, TotalPage,loading } = useSelector(
    (state) => state.masterReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  // console.log(fabricMaster);

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  // const sortedData = fabricMaster?.sort((a, b) => {
  //   const supplierNameA = a.supplier_master.supplier_name;
  //   const supplierNameB = b.supplier_master.supplier_name;

  //   if (order === "asc") {
  //     return supplierNameA.localeCompare(supplierNameB);
  //   } else {
  //     return supplierNameB.localeCompare(supplierNameA);
  //   }
  // });

  const sortedData = fabricMaster?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "supplier_master.supplier_name") {
      aValue = a.supplier_master.supplier_name.toLowerCase();
      bValue = b.supplier_master.supplier_name.toLowerCase();
    } else if (orderBy == "supplier_fabric_code") {
      aValue = a.supplier_fabric_code.toLowerCase();
      bValue = b.supplier_fabric_code.toLowerCase();
    } else if (orderBy == "kc_fabric_code") {
      aValue = a.kc_fabric_code;
      bValue = b.kc_fabric_code;
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }

    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/master/fabric/edit", { state: menuItem.data });
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllFabric(searchTerm, "", newPage));
  };

  return (
    <>      {loading && <FullScreenLoader />}
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
              }}
            >
              <TableSortLabel
                active={orderBy === "supplier_master.supplier_name"}
                direction={order}
                onClick={() => handleSort("supplier_master.supplier_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                  minWidth: "20px",
                }}
              >
                Supplier Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === "supplier_fabric_code"}
                direction={order}
                onClick={() => handleSort("supplier_fabric_code")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                  minWidth: "20px",
                }}
              >
                Supplier Fabric Code
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === "kc_fabric_code"}
                direction={order}
                onClick={() => handleSort("kc_fabric_code")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                  minWidth: "20px",
                }}
              >
                KC Fabric Code
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
            >
              Fabric Color
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
            >
              Image
            </TableCell>
            {permissions?.fabric_master_edit == true && (
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.supplier_master?.supplier_name?.toUpperCase()}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.supplier_fabric_code?.toUpperCase()}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.kc_fabric_code ? row?.kc_fabric_code : "N/A"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.fabric_color?.map((item) => item?.toUpperCase()).join(",")}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                <img
                  onClick={() => {
                    setSelectedRowData(row);
                    setOpenImage(true);
                  }}
                  style={{ cursor: "pointer" }}
                  width={"100px"}
                  height={"100px"}
                  src={`${process.env.REACT_APP_URL}/${row?.image}`} // Replace 'image_url' with the actual field containing the image URL in your data
                />
              </TableCell>
              {permissions?.fabric_master_edit == true && (
                <TableCell sx={{ textAlign: "left" }}>
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={[
                      {
                        icon: <EditIcon />,
                        title: "Edit Fabric Details",
                        action: "edit",
                        data: row,
                      },
                    ]}
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              )}
              <FabricImageModal
                open={openImage}
                onClose={() => setOpenImage(false)}
                rowData={selectedRowData}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1}
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
    </TableContainer>
    </>
  );
}
