import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { getAllProductionHistory } from "app/redux/actions/inventoryAction";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";
import { getInvoiceNoList } from "app/services/apis/ListApi/invoiceList";
import { getItemNameList } from "app/services/apis/ListApi/itemNameList";
import { getjobWorkIdList } from "app/services/apis/ListApi/jobWorkIdList";
import { getOrderNoList } from "app/services/apis/ListApi/orderNoList";
import { getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import { getSuppliersListDropDown } from "app/services/apis/ListApi/supplierList";
import {
  downloadInventoryProductionHistoryReport,
} from "app/services/apis/ReportApis/InventoryProductionReports";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ProductionHistoryFilter = () => {
  const [itemNameList, setItemNameList] = useState([]);
  const [orderNoList, setOrderNoList] = useState([]);
  const [jobWorkidList, setJobWorkidList] = useState([]);
  const [qcStatusList, setQcStatusList] = useState([]);
  const [portNoNameList, setPortNoNameList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [inputs, setInputs] = useState({});
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const { production, error, TotalPage } = useSelector(
    (state) => state.inventoryReducer
  );
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFilter = () => {
    dispatch(getAllProductionHistory("", inputs, ""));
  };

  const handleClear = () => {
    dispatch(getAllProductionHistory("", ""));
    setInputs({});
    setResetAutocomplete((prev) => !prev);
  };

  useEffect(async () => {
    setItemNameList(await getItemNameList());
    setPortNoNameList(await getPortListNoPerm());
    setOrderNoList(await getOrderNoList());
    setJobWorkidList(await getjobWorkIdList());
    setFactoryList(await getFactoryNameList());
    setInvoiceList(await getInvoiceNoList());
    setSupplierList(await getSuppliersListDropDown());
    setQcStatusList(["Qc Pass", "Qc Pending", "Qc Fail"]);
  }, []);

  return (
    <>
      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        <Div sx={{ width: "22%" }}>
          <Typography variant="h5">Invoice No</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={invoiceList || []}
            // value={}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                productions_invoice_no: newValue ? newValue : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "22%" }}>
          <Typography variant="h5">Item Name</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={itemNameList || []}
            // value={}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                order_item_name: newValue ? newValue : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "22%" }}>
          <Typography variant="h5">Order No</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={orderNoList || []}
            // value={}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                order_no: newValue ? newValue : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "22%" }}>
          <Typography variant="h5">Job Work ID</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={jobWorkidList || []}
            // value={}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                order_item_job_work_id: newValue ? newValue : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>

        <Div sx={{ width: "22%" }}>
          <Typography variant="h5">Port No./Port Name</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={portNoNameList || []}
            // value={}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                order_port_no_and_name: newValue ? newValue : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>

        <Div sx={{ width: "22%" }}>
          <Typography variant="h5">Status</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            name="order_item_status"
            value={inputs.order_item_status || ""}
            onChange={handleChange}
          >
            {qcStatusList?.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        </Div>
        <Div sx={{ width: "22%" }}>
          <Typography variant="h5">Factory</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={factoryList || []}
            // value={}
            getOptionLabel={(option) => option.factory_name}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                order_item_factory_name: newValue?.factory_name
                  ? newValue?.factory_name
                  : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.factory_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "22%" }}>
          <Typography variant="h5">Supplier</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={supplierList || []}
            // value={}
            getOptionLabel={(option) => option.supplier_name}
            onChange={(e, newValue) =>
              setInputs((values) => ({
                ...values,
                supplier_name: newValue?.supplier_name
                  ? newValue?.supplier_name
                  : null,
              }))
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.supplier_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
      </Div>
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          justifyContent: "space-between",
        }}
      >
        <Div>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Div>
        <Button
          disabled={production?.length == 0}
          variant="contained"
          onClick={async () => {
            const data = await downloadInventoryProductionHistoryReport(inputs);
            window.open(data);
          }}
        >
          Export CSV
        </Button>
      </Div>
    </>
  );
};

export default ProductionHistoryFilter;
