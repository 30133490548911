import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import {
  getRawMaterialManuallyIssued
} from "app/redux/actions/inventoryAction";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

export default function RawManuallyIssuedTable({ searchTerm }) {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { rawMaterialManuallyIssued, error, TotalPage ,loading} = useSelector(
    (state) => state.inventoryReducer
  );

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getRawMaterialManuallyIssued(searchTerm, "", newPage));
    // Replace this with your actual pagination logic
  };
  const sortedData = rawMaterialManuallyIssued?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "supplier_master.supplier_name") {
      aValue = a.supplier_master.supplier_name.toLowerCase();
      bValue = b.supplier_master.supplier_name.toLowerCase();
    } else if (orderBy == "raw_manually_order_no") {
      aValue = a.raw_manually_order_no.toLowerCase();
      bValue = b.raw_manually_order_no.toLowerCase();
    } else if (orderBy == "raw_cost_details.cost_rate_per_meter") {
      aValue = a?.raw_cost_details?.cost_rate_per_meter;
      bValue = b?.raw_cost_details?.cost_rate_per_meter;
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  useEffect(() => {
    if (error == "No Raw Inventory found") {
      Swal.fire({ icon: "error", title: "No Result Found" });
    }
  }, [error]);

  return (
    <>      {loading && <FullScreenLoader />}
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "kc_fabric_code"}
                direction={order}
                onClick={() => handleSort("kc_fabric_code")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Kennys Fabric Code
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "raw_manually_taka_no"}
                direction={order}
                onClick={() => handleSort("raw_manually_taka_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Taka No
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "raw_manually_order_no"}
                direction={order}
                onClick={() => handleSort("raw_manually_order_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Order No
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "raw_manually_order_item_no"}
                direction={order}
                onClick={() => handleSort("raw_manually_order_item_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Item No
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "raw_manually_order_item_name"}
                direction={order}
                onClick={() => handleSort("raw_manually_order_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Item Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "raw_manually_order_sub_item_name"}
                direction={order}
                onClick={() => handleSort("raw_manually_order_sub_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Sub Item Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "raw_manually_factory_name"}
                direction={order}
                onClick={() => handleSort("raw_manually_factory_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Factory
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Issued Quantity
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "cost_rate_per_meter"}
                direction={order}
                onClick={() => handleSort("cost_rate_per_meter")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                    Rate Per Unit
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Issued Date
            </TableCell>
          </TableRow>
        </TableHead>
        {console.log(sortedData, "vvvvvvvvvvvv")}
        <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>
                {row.kc_fabric_code}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.raw_manually_taka_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.raw_manually_order_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.raw_manually_order_item_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.raw_manually_order_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.raw_manually_order_sub_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.raw_manually_factory_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.raw_manually_issue_quantity}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.cost_rate_per_meter}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.raw_manually_order_date}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1} // Replace with the actual total number of pages
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
    </TableContainer>
    </>
  );
}
