import ListDraft from "app/pages/Draft/ListDraft";
import Page from "@jumbo/shared/Page";
import DraftAlternative from "app/pages/Draft/AlternativeDraft";
import DraftOrderRouteMiddleware from "./middleware/auth/draftValidRoute";
import ReportsOrderRouteMiddleware from "./middleware/auth/reportsValidRoute";
import ListReport from "app/pages/Reports/ListReport";
import ListAllReport from "app/pages/Reports/ListAllReport/ListAll";

export const draftRoutes = [
  {
    middleware: [
      {
        element: DraftOrderRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/draft",
        element: <Page component={ListDraft} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/draft/alternative",
        element: (
          <Page component={DraftAlternative} layout={"vertical-default"} />
        ),
      },
    ],
  },

  {
    middleware: [
      {
        element: ReportsOrderRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/reports",
        element: <Page component={ListReport} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/list-reports",
        element: <Page component={ListAllReport} layout={"vertical-default"} />,
      },
    ],
  },
];
