import {
  ALL_DRAFT_FAIL,
  ALL_DRAFT_REQUEST,
  ALL_DRAFT_SUCCESS,
  ALL_REPORT_FAIL,
  ALL_REPORT_REQUEST,
  ALL_REPORT_SUCCESS,
} from "app/utils/constants/draftConstant";
import axios from "axios";

export const getAllDraft = (search_value, inputs, page) => async (dispatch) => {
  try {
    if (!search_value) {
      search_value = "";
    }

    const filter = {
      order_port_no_and_name: inputs.order_port_no_and_name || "",
      order_no: inputs.order_no || "",
      order_item_kc_fabric_code: inputs.order_item_kc_fabric_code || "",
      order_item_name: inputs.order_item_name || "",
      order_date: inputs.order_date || "",
      order_est_delivery_date_to: inputs?.order_est_delivery_date_to || "",
      order_est_delivery_date_from: inputs?.order_est_delivery_date_from || "",
      order_item_draft_days: inputs?.order_item_draft_days || "",
      supplier_name: inputs?.supplier_name || "",
    };

    dispatch({ type: ALL_DRAFT_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const urlParams = new URLSearchParams({
      search: search_value,
      order_port_no_and_name: filter.order_port_no_and_name,
      order_no: filter.order_no,
      order_item_kc_fabric_code: filter.order_item_kc_fabric_code,
      order_item_name: filter.order_item_name,
      order_date: filter.order_date,
      order_est_delivery_date_to: filter.order_est_delivery_date_to,
      order_est_delivery_date_from: filter.order_est_delivery_date_from,
      order_item_draft_days: filter.order_item_draft_days,
      supplier_name: filter.supplier_name,
      page: page,
    });

    const data = await axios.get(
      `${
        process.env.REACT_APP_URL
      }/api/draft/list-draft-order?${urlParams.toString()}`,
      config
    );

    dispatch({
      type: ALL_DRAFT_SUCCESS,
      payload: { data: data?.data?.result, totalPage: data?.data?.totalPages },
    });
  } catch (error) {
    dispatch({
      type: ALL_DRAFT_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// Reports
export const getReportList =
  (search_value, inputs, page, type) => async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }
      const filter = {
        today: inputs.today ? inputs.today : "",
        week: inputs.week ? inputs.week : "",
        months_year: inputs.month_year ? inputs.month_year : "",
        months: inputs.month ? inputs.month : "",
        from: inputs.from ? inputs.from : "",
        to: inputs.to ? inputs.to : "",
      };
      dispatch({ type: ALL_REPORT_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.get(
        `${
          process.env.REACT_APP_URL
        }/api/order/list-order?search=${search_value}&order_item_name=${
          filter.order_item_name
        }&order_no=${filter.order_no}&order_item_job_work_id=${
          filter.order_item_job_work_id
        }&order_est_delivery_date_to=${
          filter.order_est_delivery_date_to
        }&order_est_delivery_date_from=${filter.order_est_delivery_date_from}
        &order_port_no_and_name=${
          filter.order_port_no_and_name
        }&order_item_factory_name=${filter.order_item_factory_name.replace(
          / /g,
          "+"
        )}&order_item_status=${filter.order_item_status.replace(
          / /g,
          "+"
        )}&page=${page}`,
        config
      );
      // console.log(data);
      dispatch({
        type: ALL_REPORT_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_REPORT_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };
