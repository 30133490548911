import Div from "@jumbo/shared/Div";
import {
  Box,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import NewOrderTable from "../TableComponent/NewOrderTable";
import CuttingChallanGeneratedTable from "../TableComponent/CuttingChallanGeneratedTable";
import {
  getCuttingChallanGeneratedReport,
  getNewOrderReport,
  getQCReport,
  getDispatchReport,
  getDraftReport,
  getFactoryReceivingPendingReport,
  getSalesReport,
  getReplacementReport,
} from "app/redux/actions/reportAction";
import NewOrderReportsFilter from "../FilterComponent/newOrderFilter";
import CuttingChallanGeneratedReportsFilter from "../FilterComponent/cuttingFilter";
import QCReportsFilter from "../FilterComponent/qcFilter";
import QCTable from "../TableComponent/QCTable";
import DispatchReportsFilter from "../FilterComponent/dispatchFilter";
import DispatchTable from "../TableComponent/DispatchTable";
import DraftTable from "../TableComponent/DraftTable";
import DraftReportsFilter from "../FilterComponent/draftFilter";
import ReplacementTable from "../TableComponent/ReplacementTable";
import FactoryReceivingPendingTable from "../TableComponent/FactoryReceivingPendingTable";
import FactoryReceivingPendingReportsFilter from "../FilterComponent/factoryPendingFilter";
import SalesReportsFilter from "../FilterComponent/salesFilter";
import SalesTable from "../TableComponent/Sales";
import ReplacementReportsFilter from "../FilterComponent/replacementFilter";

const ListAllReport = () => {
  const [nav, setNav] = useState("New Order");
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = React.useState(0);
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSearch = (value) => {
    if (nav == "New Order") {
      value == ""
        ? dispatch(getNewOrderReport("", ""))
        : dispatch(getNewOrderReport(value, ""));
    } else if (nav == "Cutting Challan Generated") {
      value == ""
        ? dispatch(getCuttingChallanGeneratedReport("", ""))
        : dispatch(getCuttingChallanGeneratedReport(value, ""));
    } else if (nav == "QC") {
      value == ""
        ? dispatch(getQCReport("", ""))
        : dispatch(getQCReport(value, ""));
    } else if (nav == "Dispatch") {
      value == ""
        ? dispatch(getDispatchReport("", ""))
        : dispatch(getDispatchReport(value, ""));
    } else if (nav == "Draft") {
      value == ""
        ? dispatch(getDraftReport("", ""))
        : dispatch(getDraftReport(value, ""));
    } else if (nav == "Factory Receiving Pending") {
      value == ""
        ? dispatch(getFactoryReceivingPendingReport("", ""))
        : dispatch(getFactoryReceivingPendingReport(value, ""));
    } else if (nav == "Sales") {
      value == ""
        ? dispatch(getSalesReport("", ""))
        : dispatch(getSalesReport(value, ""));
    } else if (nav == "Replacement") {
      value == ""
        ? dispatch(getReplacementReport("", ""))
        : dispatch(getReplacementReport(value, ""));
    }
  };

  // navs and tab functionality
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    nav == "New Order" && dispatch(getNewOrderReport("", ""));
    nav == "Cutting Challan Generated" &&
      dispatch(getCuttingChallanGeneratedReport("", ""));
    nav == "QC" && dispatch(getQCReport("", ""));
    nav == "Dispatch" && dispatch(getDispatchReport("", ""));
    nav == "Draft" && dispatch(getDraftReport("", ""));
    nav == "Factory Receiving Pending" &&
      dispatch(getFactoryReceivingPendingReport("", ""));
    nav == "Sales" && dispatch(getSalesReport("", ""));
    nav == "Replacement" && dispatch(getReplacementReport("", ""));
  }, [nav]);

  return (
    <>
      <Typography variant="h1" sx={{ mt: -4 }}>
      Dashboard
      </Typography>
      {nav == "New Order" && <NewOrderReportsFilter />}
      {nav == "Cutting Challan Generated" && (
        <CuttingChallanGeneratedReportsFilter />
      )}
      {nav == "QC" && <QCReportsFilter />}
      {nav == "Dispatch" && <DispatchReportsFilter />}
      {nav == "Draft" && <DraftReportsFilter />}
      {nav == "Replacement" && <ReplacementReportsFilter />}
      {nav == "Factory Receiving Pending" && (
        <FactoryReceivingPendingReportsFilter />
      )}
      {nav == "Sales" && <SalesReportsFilter />}
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
      </Div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="New Order"
              {...a11yProps(0)}
              onClick={() => setNav("New Order")}
            />
            <Tab
              label="Cutting Challan Generated "
              {...a11yProps(1)}
              onClick={() => setNav("Cutting Challan Generated")}
            />
            <Tab label="QC" {...a11yProps(1)} onClick={() => setNav("QC")} />
            <Tab
              label="Dispatch"
              {...a11yProps(1)}
              onClick={() => setNav("Dispatch")}
            />
            <Tab
              label="Draft"
              {...a11yProps(1)}
              onClick={() => setNav("Draft")}
            />
            <Tab
              label="Factory Receiving Pending"
              {...a11yProps(1)}
              onClick={() => setNav("Factory Receiving Pending")}
            />
            <Tab
              label="Sales"
              {...a11yProps(1)}
              onClick={() => setNav("Sales")}
            />
            <Tab
              label="Replacement"
              {...a11yProps(1)}
              onClick={() => setNav("Replacement")}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <NewOrderTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CuttingChallanGeneratedTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <QCTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <DispatchTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <DraftTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <FactoryReceivingPendingTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <SalesTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          <ReplacementTable searchTerm={searchTerm} />
        </CustomTabPanel>
        {console.log(value, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")}
      </Box>
    </>
  );
};

export default ListAllReport;
