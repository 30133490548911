import { dateFun } from "app/utils/constants/functions";
import {
  ALL_PURCHASE_SUCCESS,
  LOAD_PURCHASE_FAIL,
  LOAD_PURCHASE_REQUEST,
  ALL_PURCHASE_RETURN_SUCCESS,
  LOAD_PURCHASE_RETURN_FAIL,
  LOAD_PURCHASE_RETURN_REQUEST,
} from "app/utils/constants/purchaseConstants";

import axios from "axios";

export const getAllPurchase =
  (
    search_value,
    page,
    po_status,
    supplier_name,
    poDate,
    estimateDate,
    item_status,
    kc_code,
    po_no
  ) =>
  async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }
      if (!po_status) {
        po_status = "";
      }
      if (!supplier_name) {
        supplier_name = "";
      }
      if (!estimateDate) {
        estimateDate = "";
      }
      if (!poDate) {
        poDate = "";
      }
      if (!item_status) {
        item_status = "";
      }
      if (!kc_code) {
        kc_code = "";
      }
      if (!po_no) {
        po_no = "";
      }

      dispatch({ type: LOAD_PURCHASE_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        page: page,
        purchase_status: po_status,
        supplier_name: supplier_name,
        purchase_estimate_delivery_date: estimateDate,
        purchase_order_date: poDate,
        purchase_order_no: po_no,
        po_item_status: item_status,
        kc_fabric_code: kc_code,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/purchase-order/list-purchase-order?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_PURCHASE_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: LOAD_PURCHASE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
export const getAllReturnPurchase =
  (search_value, page, purchase_order_type, supplier_name, kc_code, po_no,poDate) =>
  async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }
      if (!purchase_order_type) {
        purchase_order_type = "";
      }
      if (!supplier_name) {
        supplier_name = "";
      }

      if (!kc_code) {
        kc_code = "";
      }
      if (!po_no) {
        po_no = "";
      }
      if (!poDate) {
        poDate = "";
      }

      dispatch({ type: LOAD_PURCHASE_RETURN_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        page: page,
        supplier_name: supplier_name,
        purchase_order_type: purchase_order_type,
        kc_fabric_code: kc_code,
        purchase_order_no: po_no,
        return_purchase_order_create_date: poDate,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/purchase-order/list-return-purchase-order?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_PURCHASE_RETURN_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: LOAD_PURCHASE_RETURN_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
