import Div from "@jumbo/shared/Div";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { addFabricColor } from "app/services/apis/addFabricColor";
import { updateFabricColor } from "app/services/apis/updateFabricColor";

export default function AddFabricColor() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedColor, setSelectedColor] = useState(
    state?.fabric_colors ? state?.fabric_colors : []
  );

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedColor(newValue);
  };

  const onUserSave = async () => {
    // console.log({ ...values });
    // console.log(selectedColor);
    if (selectedColor == "") {
      return Swal.fire({
        icon: "error",
        title: "Fabric Color Required",
        text: "",
      });
    }
    setSubmitting(true);
    if (pathname == "/master/fabric-color/edit") {
      if (selectedColor) {
      }
      const data = await updateFabricColor(
        { fabric_colors: selectedColor },
        state.id
      );
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Fabric Color Edited Successfully",
          text: "",
        });
        navigate("/dashboard/master/fabric-color");
      } else {
        // console.log(data);
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addFabricColor({ fabric_colors: selectedColor });
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Fabric Color Added Successfully",
          text: "",
        });
        navigate("/dashboard/master/fabric-color");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/master/fabric-color/add"
          ? "Add New Fabric Color"
          : "Edit Fabric Color"}
      </Typography>
      <Div>
        <Div sx={{ mt: 4 }}>
          <Div
            sx={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              columnGap: 5,
            }}
          >
            <Div sx={{ width: "45%" }}>
              <Typography variant="h5">Fabric Color</Typography>
              {pathname == "/master/fabric-color/add" ? (
                <>
                  <Autocomplete
                    multiple
                    size="small"
                    sx={{ width: "100%" }}
                    id="tags-standard"
                    options={[]}
                    freeSolo={true}
                    getOptionLabel={(option) => option}
                    name="fabric_color"
                    value={selectedColor}
                    onChange={handleAutocompleteChange}
                    renderOption={(props, option) => (
                      <Box
                        size="small"
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Add Fabric Colors"
                      />
                    )}
                  />
                  {/* <ErrorMessage
                          name="fabric_color"
                          component={"div"}
                          style={{ color: "red" }}
                        /> */}
                </>
              ) : (
                <TextField
                  size="small"
                  name="fabric_colors"
                  onChange={(e) => setSelectedColor(e.target.value)}
                  value={selectedColor}
                />
              )}
            </Div>
          </Div>
          <Div
            sx={{
              width: "93.5%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
              mt: 5,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure you want to cancel?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/dashboard/master/fabric-color");
                  }
                });
              }}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="contained"
              onClick={onUserSave}
              sx={{ width: "100px" }}
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}
