import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Button,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { updatePurchaseStatus } from "app/services/apis/Purchase Order/updatePurchaseStatus";
import { getAllPurchase } from "app/redux/actions/purchaseAction";
import { useDispatch } from "react-redux";

export default function PurchaseFinishInfoTable({ itemDetails, itemId }) {
  const navigate = useNavigate();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  const dispatch = useDispatch();
  const allpurchase = itemDetails;
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  // console.log(allpurchase);
  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleStatus = async (purchaseId, id, body) => {
    const res = await updatePurchaseStatus(purchaseId, id, body);
    // console.log(res);
    if (res?.status == 200) {
      dispatch(getAllPurchase());
      navigate("/dashboard/purchase");
    }
  };

  // const sortedData = allpurchase?.sort((a, b) => {
  //   const aValue = a[orderBy];
  //   const bValue = b[orderBy];
  //   if (order === "desc") {
  //     return aValue < bValue ? -1 : 1;
  //   } else {
  //     return bValue < aValue ? -1 : 1;
  //   }
  // });

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/dashboard/edituser", { state: menuItem.data });
        break;
      case "download":
        navigate("/changepassword", { state: menuItem.data });
        break;
      case "cancel":
        navigate("/changepassword", { state: menuItem.data });
        break;
    }
  };
  // console.log(allpurchase);
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "10%",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Item Name
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "10%",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Size
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "10%",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Rate Per Unit
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                maxWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Quantity
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Amount
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "40px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Status
            </TableCell>
            {permissions?.purchase_edit == true && (
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {allpurchase?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>
                {row.purchase_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.purchase_size}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.rate_per_unit}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>{row.quantity}</TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.total_amount}
              </TableCell>

              <TableCell sx={{ textAlign: "left" }}>
                {row.po_item_status}
              </TableCell>
              {permissions?.purchase_edit == true && (
                <TableCell sx={{ textAlign: "left" }}>
                  {row.po_item_status == "Complete" && "N/A"}
                  {row.po_item_status == "Partial Cancelled" && "N/A"}
                  {row.po_item_status == "Cancelled" && "N/A"}
                  {row.po_item_status == "Not Received" && (
                    <Typography
                      color={"blue"}
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleStatus(itemId, row?.id, "Cancelled")}
                    >
                      Cancel
                    </Typography>
                  )}
                  {row.po_item_status == "Partial Received" && (
                    <Typography
                      color={"blue"}
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleStatus(itemId, row?.id, "Partial Cancelled")
                      }
                    >
                      Partial Cancel
                    </Typography>
                  )}
                  {row.po_item_status == "Completed" && "N/A"}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
