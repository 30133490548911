import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const RoleMngtRouteMiddleware = ({ fallbackPath }) => {
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  if (
    permissions.role_view === true ||
    permissions.role_edit === true ||
    permissions.role_create === true
  ) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};

export default RoleMngtRouteMiddleware;
