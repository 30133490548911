import Div from "@jumbo/shared/Div";
import {
  Button,
  Checkbox,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddCatalogueImagesModal from "./catalogueImagesModal";
import Swal from "sweetalert2";
import CataloguePreviewModal from "./cataloguePreviewImage";
import SearchIcon from "@mui/icons-material/Search";

const CatalogueFolderImages = () => {
  const [images, setImages] = useState([]);
  const [runApi, setRunApi] = useState(false);
  const [selectBtn, setSelectBtn] = useState(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState();
  const [openImage, setOpenImage] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [TotalPage, setTotalPage] = useState(1);
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const { name } = useParams();
  const navigate = useNavigate();

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleSelect = (e, data, rowIndex) => {
    // console.log(data);
    if (e.target.checked != true) {
      const updatedItemDetails = [...selectedImages];
      updatedItemDetails.splice(rowIndex, 1);
      setSelectedImages(updatedItemDetails);
    } else {
      const updatedItemDetails = [...selectedImages];
      updatedItemDetails[rowIndex] = data;
      setSelectedImages(updatedItemDetails);
    }
  };

  const handleDelete = async () => {
    const filteredArray = selectedImages.filter((value) => value !== undefined);
    if (filteredArray.length > 0) {
      try {
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const data = await axios.post(
          `${process.env.REACT_APP_URL}/api/catalogue-master/remove-catalogue-image?folder_name=${name}`,
          { images: filteredArray },
          config
        );
        if (data?.status == 200) {
          Swal.fire({ icon: "success", title: "Images Deleted" });
          setSelectedImages([]);
          setSelectBtn(false);
          setRunApi(!runApi);
        }
      } catch (error) {
        Swal.fire({ icon: "error", title: "Images Not Deleted" });
      }
    }
  };
  useEffect(async () => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/catalogue-master/list-catalogue-image?folder_name=${name}&search=${searchTerm}&page=${page}`,
        config
      );
      setImages(data?.data?.result);
      setTotalPage(data.data.totalPages);
    } catch (error) {}
  }, [runApi, searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRunApi(!runApi);
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">{`${name} Catalogue`}</Typography>
      <Div
        sx={{
          mb: 5,
          mt: 4,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Div sx={{ display: "flex", columnGap: 2 }}>
          {permissions.catalogue_master_edit == true && (
            <Button
              variant="contained"
              sx={{ p: 1, pl: 4, pr: 4 }}
              onClick={() => {
                setSelectBtn(!selectBtn);
                setSelectedImages([]);
              }}
            >
              Select
            </Button>
          )}

          <TextField
            size="small"
            id="search"
            type="search"
            label="Search Catalogue Images"
            value={searchTerm}
            onChange={(e) => {
              setPage(1);
              setSearchTerm(e.target.value);
            }}
            sx={{ width: 300, mt: "auto" }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />

          {selectBtn && (
            <Button
              variant="contained"
              color="error"
              sx={{ p: 1, pl: 4, pr: 4 }}
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
          )}
        </Div>
        {permissions.catalogue_master_edit == true && (
          <Button
            variant="contained"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={() => {
              setInfoModalOpen(true);
            }}
          >
            Add Catalogue Images
          </Button>
        )}
      </Div>
      <Div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Catalogue Images
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ paddingX: "3%" }}>
                  <Div
                    sx={{
                      // bgcolor: "red",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginX: "10px",
                    }}
                  >
                    {/* {console.log(images)} */}
                    {images?.map((image, i) => (
                      <Div
                        sx={{
                          flex: "0 0 calc(20% - 10px)" /* 33.33% width for 3 items per row with some spacing */,
                          marginX: "5px" /* Adjust the margin as needed */,
                          boxSizing: "border-box",
                        }}
                      >
                        <Div
                          sx={{
                            position: "relative",
                            height: "200px",
                            width: "150px",
                          }}
                        >
                          {/* {console.log(`${process.env.REACT_APP_URL}/${image}`)} */}
                          <img
                            loading="lazy"
                            onClick={() => {
                              if (!selectBtn) {
                                setSelectedRowData(image);
                                setOpenImage(true);
                              }
                            }}
                            height={"100%"}
                            width={"100%"}
                            style={{
                              border: "1px solid",
                              cursor: "pointer",
                              objectFit: "contain",
                            }}
                            src={`${process.env.REACT_APP_URL}/${image}`}
                          />
                          {selectBtn && (
                            <Div
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: "100%",
                                width: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.2)",
                              }}
                            >
                              <Checkbox
                                sx={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%,-50%)",
                                  color: "white !important",
                                }}
                                {...label}
                                onChange={(e) => handleSelect(e, image, i)}
                              />
                            </Div>
                          )}
                        </Div>
                        <Typography
                          sx={{
                            mt: 2,
                            textAlign: "left",
                            fontWeight: "600",
                            wordBreak: "break-word",
                            wordWrap: "break-word",
                            height: "50px",
                          }}
                        >
                          {image.split("/")[image.split("/").length - 1]}
                        </Typography>
                      </Div>
                    ))}
                  </Div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Pagination
            count={TotalPage || 1}
            page={page}
            onChange={handleChangePage}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
            }}
          />
        </TableContainer>
      </Div>
      <AddCatalogueImagesModal
        open={isInfoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        setRunApi={setRunApi}
        runApi={runApi}
      />
      <CataloguePreviewModal
        open={openImage}
        onClose={() => setOpenImage(false)}
        rowData={selectedRowData}
      />
      <Div sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Button
          variant="outlined"
          onClick={() => navigate("/dashboard/master/catalogue")}
        >
          Cancel
        </Button>
      </Div>
    </Div>
  );
};

export default CatalogueFolderImages;
