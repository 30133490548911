import Div from "@jumbo/shared/Div";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FabricImageModal from "app/pages/Master/FabricMaster/ListFabric/imageModal";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

export const ChallanItemTable = ({ rowData, order_no }) => {
  if (rowData.item) {
    rowData = rowData.item;
  }
  const { pathname } = useLocation();
  // console.log(pathname);
  const [openImage, setOpenImage] = useState(false);
  return (
    <Div>
      <Typography variant="h3" sx={{ mt: 3, fontWeight: 600 }}>
        Item Details
      </Typography>
      <Table border={2}>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Order No.
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Item No.
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Item Name
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                maxWidth: "140px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              SubItem Name
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                maxWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Fabric Code
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                maxWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Fabric Qty
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                maxWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Rack No
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Image
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{order_no}</TableCell>
            <TableCell>{rowData?.order_item_no}</TableCell>
            <TableCell>{rowData?.order_item_name}</TableCell>
            <TableCell>
              {rowData?.order_sub_item_name == ""
                ? "-"
                : rowData?.order_sub_item_name}
            </TableCell>
            <TableCell>{rowData?.order_item_kc_fabric_code}</TableCell>
            <TableCell>{rowData?.order_item_fabric_required}</TableCell>
            <TableCell>{rowData?.order_item_rack_details}</TableCell>

            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpenImage(true);
              }}
            >
              <img
                width={"100px"}
                height={"100px"}
                src={`${process.env.REACT_APP_URL}/${rowData?.fabric_image}`} // Replace 'image_url' with the actual field containing the image URL in your data
              />
            </TableCell>
          </TableRow>
        </TableBody>
        <FabricImageModal
          open={openImage}
          onClose={() => setOpenImage(false)}
          rowData={rowData}
        />
      </Table>

      {rowData?.order_item_addOns &&
        pathname != "/dashboard/order/generateChallan" && (
          <>
            <Typography variant="h3" sx={{ mt: 4, fontWeight: 600 }}>
              Addons:
            </Typography>
            <Table border={1}>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Send To Factory
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData?.order_item_addOns?.map((row, i) => (
                  <>
                    <TableRow key={i}>
                      <TableCell>{row?.itemName}</TableCell>
                      <TableCell>{row?.quantity}</TableCell>
                      <TableCell>{row?.status}</TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </>
        )}
    </Div>
  );
};
