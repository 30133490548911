import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Div from "@jumbo/shared/Div";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";
import { getKcFabricCodeList } from "app/services/apis/ListApi/kcFabricCodeList";
import axios from "axios";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const FactoryFabricModal = ({ open, onClose, rowData }) => {
  const [factoryList, setFactoryList] = useState([]);
  const [kcFabricCode, setKcFabricCode] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    factory_master_id: null,
    kc_fabric_code: "Factory Fabric",
  });

  const handleSave = async () => {
    try {
      setLoader(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/api/draft/factory-fabric?id=${rowData?.order_items_id}`,
        inputs,
        config
      );
      if (res?.status == 200) {
        Swal.fire({ icon: "success", title: res.data.message });
        navigate("/dashboard/order/receive");
        onClose();
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: "Not Created" });
      onClose();
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setFactoryList(await getFactoryNameList());
      setKcFabricCode(await getKcFabricCodeList());
    };
    fetchData();
  }, []);

  useEffect(() => {
    setInputs({
      factory_master_id: null,
      kc_fabric_code: "Factory Fabric",
    });
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle sx={{ fontWeight: "700" }}>
          Select Fabric From Factory
        </DialogTitle>
      </Div>
      <DialogContent>
        <Div sx={{ width: "100%" }}>
          <Typography variant="h5">Factory</Typography>
          <Autocomplete
            size="small"
            id="factory-autocomplete"
            options={factoryList || []}
            value={
              factoryList?.find((f) => f.id === inputs.factory_master_id) || null
            }
            getOptionLabel={(option) => option.factory_name}
            onChange={(e, newValue) => {
              setInputs({
                ...inputs,
                factory_master_id: newValue?.id || null,
              });
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.factory_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        {/* <Div sx={{ width: "100%", mt: 2 }}>
          <Typography variant="h5">Fabric Code</Typography>
          {console.log({ kc_fabric_code: inputs.kc_fabric_code })}
          <Autocomplete
            freeSolo
            size="small"
            id="kc-fabric-code-autocomplete"
            options={kcFabricCode || []}
            value={{ kc_fabric_code: inputs.kc_fabric_code }}
            getOptionLabel={(option) => option.kc_fabric_code}
            onChange={(e, newValue) => {
              // This will be triggered when an option is selected from the dropdown

              typeof newValue === "object"
                ? setInputs({
                    ...inputs,
                    kc_fabric_code: newValue?.kc_fabric_code || "",
                  })
                : setInputs({
                    ...inputs,
                    kc_fabric_code: newValue || "",
                  });
            }}
            onInputChange={(e, newInputValue) => {
              // This will be triggered on each user input
              setInputs({
                ...inputs,
                kc_fabric_code: newInputValue,
              });
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.kc_fabric_code}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div> */}
      </DialogContent>
      <DialogActions>
        <Div sx={{ display: "flex", columnGap: 2 }}>
          <LoadingButton
            size="small"
            loading={loader}
            onClick={handleSave}
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
          <Button
            size="small"
            onClick={onClose}
            color="error"
            variant="contained"
          >
            Close
          </Button>
        </Div>
      </DialogActions>
    </Dialog>
  );
};

export default FactoryFabricModal;
