import Div from "@jumbo/shared/Div";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { getGstListNoPem } from "app/services/apis/ListApi/gst";
import { getKcFabricCodeListBySupplierId } from "app/services/apis/ListApi/kcFabricCodeList";
import { getUnitListDropDown } from "app/services/apis/ListApi/unitList";
import { getRatePerMeter } from "app/services/apis/Purchase Order/getRatePerMeter";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const RawMaterialTable = ({ po_details }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const { state } = useLocation();
  const [grandTotal, setGrandTotal] = useState(
    state?.grand_total ? state?.grand_total : 0
  );
  const [unitList, setUnitList] = useState([]);
  const [kfcList, setKfcList] = useState([]);
  const [gstList, setGstList] = useState([]);

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };
  const getkcFabricCode = async (e, i) => {
    // console.log(e);
    const newFabricDetails = e;
    // Create a copy of itemDetails to modify
    const updatedItemDetails = [...itemDetails];
    //rateperunit
    let ratePerMeter = {};
    if (newFabricDetails?.supplier_fabric_code != "") {
      const res = await getRatePerMeter(
        newFabricDetails?.supplier_fabric_code,
        po_details.supplier_master_id,
        ""
      );
      ratePerMeter = res[0];
    }
    // console.log(ratePerMeter);
    // Update the corresponding field in the specific row
    // console.log(newFabricDetails?.image);
    updatedItemDetails[i] = {
      kc_fabric_code: newFabricDetails?.kc_fabric_code,
      image: newFabricDetails?.image,
      supplier_fabric_code: newFabricDetails?.supplier_fabric_code,
      rate_per_meter: ratePerMeter?.rate_per_meter,
      fabric_color: newFabricDetails?.fabric_color,
    };

    // Update the state with the modified array
    setItemDetails(updatedItemDetails); // Update itemDetails
  };

  const getRpmBySupplierCode = async (e, i) => {
    // Create a copy of itemDetails to modify
    const updatedItemDetails = [...itemDetails];
    //  ratepermeter api
    const res = await getRatePerMeter(
      e.target.value,
      po_details.supplier_master_id,
      ""
    );
    // console.log(res);
    if (res.length > 0) {
      // Update the corresponding field in the specific row
      updatedItemDetails[i] = {
        supplier_fabric_code: e.target.value,
        rate_per_meter: res[0]?.rate_per_meter,
        unit_name: "",
      };
      setItemDetails(updatedItemDetails); // Update itemDetails
    }
  };

  const [itemDetails, setItemDetails] = useState(
    state?.purchase_items_details
      ? state?.purchase_items_details
      : [
          {
            kc_fabric_code: "", // Set your initial values for each field here
            supplier_fabric_code: "",
            image: "",
            rate_per_meter: "",
            quantity: "",
            unit_name: "",
            amount: "",
            gst: "",
            gst_percentage: "",
            total_amount: "",
            cgst: "",
            sgst: "",
            igst: "",
          },
        ]
  );

  const addEmptyRow = () => {
    const emptyRow = {
      kc_fabric_code: "", // Set your initial values for each field here
      supplier_fabric_code: "",
      image: "",
      rate_per_meter: "",
      quantity: "",
      unit_name: "",
      amount: "",
      gst: "",
      gst_percentage: "",
      total_amount: "",
      cgst: "",
      sgst: "",
      igst: "",
    };
    setItemDetails([...itemDetails, emptyRow]);
  };

  const handleTextFieldChange = (e, rowIndex) => {
    const { name, value } = e.target;
    // Create a copy of itemDetails to modify
    const updatedItemDetails = [...itemDetails];
    updatedItemDetails[rowIndex] = {
      ...updatedItemDetails[rowIndex],
      [name]: value,
    };
    setItemDetails(updatedItemDetails);
  };

  // console.log(itemDetails);
  const handleAmount = (rate, quantity, rowIndex, e) => {
    const { name, value } = e.target;
    if (name == "gst_percentage") {
      let equalValue =
        po_details?.supplier_state == "Maharashtra" ? value / 2 : 0;
      // console.log(equalValue);
      const updatedItemDetails = [...itemDetails];
      // Update the corresponding field in the specific row
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        gst:
          (Number(updatedItemDetails[rowIndex].amount) * Number(value)) / 100,
        gst_percentage: value,
        total_amount:
          Number(updatedItemDetails[rowIndex].amount) +
          (Number(updatedItemDetails[rowIndex].amount) * Number(value)) / 100,
        sgst: equalValue > 0 ? equalValue : 0,
        cgst: equalValue > 0 ? equalValue : 0,
        igst: equalValue == 0 ? value : 0,
      };
      setItemDetails(updatedItemDetails);
      const grandTotal = updatedItemDetails?.reduce((accumulator, item) => {
        return accumulator + (parseFloat(item?.total_amount) || 0);
      }, 0);
      if (grandTotal > 0) {
        setGrandTotal(grandTotal?.toFixed(2));
      }
    } else {
      const updatedItemDetails = [...itemDetails];

      // Update the corresponding field in the specific row
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        amount: Number(rate) * Number(quantity),
        // quantity: Number(quantity),
        gst:
          (Number(Number(rate) * Number(quantity)) *
            Number(updatedItemDetails[rowIndex].gst_percentage)) /
          100,
        gst_percentage: updatedItemDetails[rowIndex].gst_percentage,
        total_amount:
          Number(Number(rate) * Number(quantity)) +
          (Number(Number(rate) * Number(quantity)) *
            Number(updatedItemDetails[rowIndex].gst_percentage)) /
            100,
        [name]: value,
      };
      setItemDetails(updatedItemDetails);
      const grandTotal = updatedItemDetails?.reduce((accumulator, item) => {
        return accumulator + (parseFloat(item?.total_amount) || 0);
      }, 0);

      if (grandTotal > 0) {
        setGrandTotal(grandTotal?.toFixed(2));
      }
    }
  };

  const handleRemoveItem = (index) => {
    // Create a copy of itemDetails and remove the item at the specified index
    const updatedItemDetails = [...itemDetails];
    updatedItemDetails.splice(index, 1);
    setItemDetails(updatedItemDetails);
    const grandTotal = updatedItemDetails?.reduce((accumulator, item) => {
      return accumulator + (parseFloat(item?.total_amount) || 0);
    }, 0);
    if (grandTotal > 0) {
      setGrandTotal(grandTotal?.toFixed(2));
    }
  };

  useEffect(async () => {
    setKfcList(
      await getKcFabricCodeListBySupplierId(po_details?.supplier_master_id)
    );
  }, [po_details]);

  useEffect(async () => {
    setUnitList(await getUnitListDropDown());
    setGstList(await getGstListNoPem());
  }, []);

  useEffect(() => {
    localStorage.setItem("tableData", JSON.stringify(itemDetails));
    localStorage.setItem("totalAmt", grandTotal);
  }, [itemDetails, handleRemoveItem]);

  return (
    <Div>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: "600" }}>
        Items Details
      </Typography>

      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "180px",
                  color: "white",
                }}
              >
                Kenny Fabric Code
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                Supplier Fabric Code
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Image
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                       Rate Per Unit
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                Quantity
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                Units
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                Amount
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                GST %
              </TableCell>
              {po_details?.supplier_state == "Maharashtra" ? (
                <>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      color: "white",
                    }}
                  >
                    CGST%
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      color: "white",
                    }}
                  >
                    SGST%
                  </TableCell>
                </>
              ) : (
                <TableCell
                  sx={{
                    textAlign: "left",
                    color: "white",
                  }}
                >
                  IGST%
                </TableCell>
              )}
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                GST Value
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                Total Amount
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {itemDetails?.map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  {/* <Select
                    size="small"
                    sx={{ width: "150px" }}
                    value={row.kc_fabric_code}
                    name="kc_fabric_code"
                    onChange={(e, options) => {
                      handleTextFieldChange(e, i);
                      getkcFabricCode(options.props.action, i);
                    }}
                  >
                    {kfcList?.map((item) => {
                      return (
                        <MenuItem
                          key={i}
                          value={item.kc_fabric_code}
                          action={item}
                        >
                          {item?.kc_fabric_code}
                        </MenuItem>
                      );
                    })}
                  </Select> */}
                  <Autocomplete
                    sx={{ width: "150px" }}
                    size="small"
                    id="tags-standard"
                    defaultValue={row ? row : null}
                    options={kfcList || []}
                    getOptionLabel={(option) => option?.kc_fabric_code}
                    onChange={(e, newValue) => {
                      handleTextFieldChange(e, i);
                      getkcFabricCode(newValue, i);
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.kc_fabric_code}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        // placeholder="KC Fabric Code"
                      />
                    )}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <TextField
                    size="small"
                    sx={{ width: "160px" }}
                    name="supplier_fabric_code"
                    value={
                      row?.supplier_fabric_code ? row?.supplier_fabric_code : ""
                    }
                    // get Rate per Meter by supplier fabric code
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                      getRpmBySupplierCode(e, i);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {/* {console.log(row?.image)} */}
                  {row?.image ? (
                    <img
                      width={"120px"}
                      height={"120px"}
                      src={`${process.env.REACT_APP_URL}/${row?.image}`} // Replace 'image_url' with the actual field containing the image URL in your data
                    />
                  ) : (
                    <Typography>No Image</Typography>
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <TextField
                    size="small"
                    sx={{ width: "100px" }}
                    name="rate_per_meter"
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                      handleAmount(e.target.value, row?.quantity, i, e);
                    }}
                    value={row?.rate_per_meter ? row?.rate_per_meter : ""}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <TextField
                    type="number"
                    size="small"
                    sx={{ width: "110px" }}
                    name="quantity"
                    value={row?.quantity || ""}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (
                        newValue === "" ||
                        // (newValue >= 1 && !isNaN(newValue))
                        !isNaN(newValue)
                      ) {
                        handleTextFieldChange(e, i);
                        handleAmount(row?.rate_per_meter, newValue, i, e);
                      }
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <Select
                    size="small"
                    sx={{ width: "100px" }}
                    name="unit_name"
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                    }}
                    value={row?.unit_name}
                  >
                    {unitList?.map((item) => {
                      return (
                        <MenuItem value={item?.unit_name}>
                          {item?.unit_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <TextField
                    size="small"
                    sx={{ width: "120px" }}
                    name="amount"
                    disabled={true}
                    value={row?.amount}
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <Select
                    name="gst_percentage"
                    value={row?.gst_percentage}
                    sx={{
                      width: "100px",
                    }}
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                      handleAmount("", "", i, e);
                    }}
                    fullWidth
                    size="small"
                  >
                    {gstList?.map((gst) => {
                      return <MenuItem value={gst}>{gst}%</MenuItem>;
                    })}
                  </Select>
                </TableCell>
                {po_details?.supplier_state == "Maharashtra" ? (
                  <>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        fontWeight: "600",
                      }}
                    >
                      <TextField
                        size="small"
                        sx={{ width: "100px" }}
                        name="cgst"
                        disabled={true}
                        value={row.cgst}
                        onChange={(e) => {
                          handleTextFieldChange(e, i);
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        fontWeight: "600",
                      }}
                    >
                      <TextField
                        size="small"
                        sx={{ width: "100px" }}
                        name="sgst"
                        disabled={true}
                        value={row?.sgst}
                        onChange={(e) => {
                          handleTextFieldChange(e, i);
                        }}
                      />
                    </TableCell>
                  </>
                ) : (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontWeight: "600",
                    }}
                  >
                    <TextField
                      size="small"
                      sx={{ width: "100px" }}
                      name="igst"
                      disabled={true}
                      value={row?.igst}
                      onChange={(e) => {
                        handleTextFieldChange(e, i);
                      }}
                    />
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <TextField
                    size="small"
                    sx={{ width: "100px" }}
                    name="gst"
                    disabled={true}
                    value={row?.gst}
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                    }}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  <TextField
                    size="small"
                    sx={{ width: "110px" }}
                    name="total_amount"
                    disabled={true}
                    value={row?.total_amount}
                  />
                </TableCell>

                <TableCell>
                  <DeleteIcon
                    sx={{ width: "50px", cursor: "pointer", color: "red" }}
                    onClick={() => handleRemoveItem(i)}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                colSpan={po_details?.supplier_state == "Maharashtra" ? 11 : 10}
                sx={{
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                Grand Total
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                {`₹ ${grandTotal}`}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h3" sx={{ mt: 2 }}>
        (Above Prices are Exclusive of Discount)
      </Typography>
      <Button variant="contained" onClick={addEmptyRow} sx={{ mt: 2 }}>
        Add More Item
      </Button>
    </Div>
  );
};

export default RawMaterialTable;
