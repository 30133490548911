import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import FabricImageModal from "app/pages/Master/FabricMaster/ListFabric/imageModal";
import { displayDateFun } from "app/utils/constants/functions";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import QcImagesModal from "./qcImagesModal";

const EditQcPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [qcData, setQCData] = useState([]);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [type, setType] = useState(null);

  const [inputs, setInputs] = useState({
    order_items_id: state?.order_items_id,
    qc_details_id: state?.qc_details_id,
    qc_status: state?.qc_status,
    qc_confirm_by: state?.qc_confirm_by,
    remarks_by_qc: state?.remarks_by_qc,
  });

  const onSave = async () => {
    const updatedQcData = Object?.entries(
      state?.order_measurement?.filter(
        (item) => item.item_master_item_name == state?.order_sub_item_name
      )[0].item_master_measurement
    ).map(([label, value], i) => ({
      ...qcData[i],
      actual_measurements: {
        name: label,
        value: value,
      },
    }));
    setQCData(updatedQcData);
    try {
      setIsLoading(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/api/qc/update-qc`,
        { ...inputs, qc_measurements: updatedQcData },
        config
      );
      if (res?.status == 200) {
        Swal.fire({ icon: "success", title: "QC update Successfully" });
        navigate("/dashboard/order/qc");
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: error?.response?.data?.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleObserveMeasurement = (e, rowIndex) => {
    const { name, value, checked } = e.target;
    const updatedItemDetails = [...qcData];
    if (name == "status") {
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        status: checked,
      };
    } else if (name == "comments") {
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        comments: value,
      };
    } else {
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        observed_measurements: {
          name: name,
          value: value,
        },
      };
    }
    setQCData(updatedItemDetails);
  };

  const handleInfoIconClick = (rowData, type) => {
    setSelectedRowData(rowData);
    setInfoModalOpen(true);
    setType(type);
  };

  useEffect(async () => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api/qc/get-qc?id=${state?.qc_details_id}`,
        config
      );
      if (res?.status == 200) {
        setQCData(res.data.result.qc_measurements);
        setInputs({
          ...inputs,
          qc_status: res.data.result.qc_status,
          qc_confirm_by: res.data.result.qc_confirm_by,
          remarks_by_qc: res.data.result.remarks_by_qc,
        });
      }
    } catch (error) {
      console.log(error, "errrrrrrrr");
      alert("Something Went Wrong");
    }
  }, []);

  useEffect(() => {
    const initialobservedData = Object?.entries(
      state?.order_measurement?.filter(
        (item) => item?.item_master_item_name == state?.order_sub_item_name
      )[0]?.item_master_measurement
    )?.map(([label, value], i) => ({
      observed_measurements: {
        name: label,
        value: null,
      },
    }));
    setQCData(initialobservedData);
  }, []);

  return (
    <Div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order No.
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Type
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Gender
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "140px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Port Number & Name
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Exp. Delivery Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Image
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{state?.order_no}</TableCell>
              <TableCell>{state?.order_type}</TableCell>
              <TableCell>{state?.order_gender}</TableCell>
              <TableCell>{state?.order_port_no_and_name}</TableCell>
              <TableCell>{displayDateFun(state?.order_date)}</TableCell>
              <TableCell>
                {displayDateFun(state?.order_est_delivery_date)}
              </TableCell>

              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenImage(true);
                }}
              >
                <img
                  width={150}
                  src={`${process.env.REACT_APP_URL}/${state.fabric_image}`} // Replace 'image_url' with the actual field containing the image URL in your data
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 3,
          mb: 1,
        }}
      >
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Item No:-
          </Typography>
          <Typography variant="h3">{state?.order_item_no}</Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Fabric:-
          </Typography>
          <Typography variant="h3">
            {state?.order_item_kc_fabric_code}
          </Typography>
        </Div>

        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Item:-
          </Typography>
          <Typography variant="h3">{state?.order_item_name}</Typography>
        </Div>

        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            SubItem:-
          </Typography>
          <Typography variant="h3">
            {state?.order_sub_item_name ? state?.order_sub_item_name : "-"}
          </Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ fontWeight: "600", mr: 1 }}>
            Client Name:-
          </Typography>
          <Typography variant="h3">{state?.order_client_name}</Typography>
        </Div>
        <Div sx={{ mt: -1 }}>
          <Button
            size="small"
            sx={{ mr: 2 }}
            variant="contained"
            onClick={() => handleInfoIconClick(state, "image")}
          >
            View Images
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => handleInfoIconClick(state, "catalogue")}
          >
            View Catalogue Images
          </Button>
        </Div>
      </Div>
      {state?.order_remarks && (
        <Div sx={{ mb: 4, border: "1px solid", p: 1, borderRadius: "5px" }}>
          <Typography variant="h4" sx={{ fontWeight: "600" }}>
            Order Remark:-
          </Typography>
          <Div>
            {state?.order_remarks.split("\n").map((line, index) => (
              <p key={index}>{line}</p>
            ))}

            {}
          </Div>
        </Div>
      )}
      {state?.order_item_remarks && (
        <Div sx={{ mb: 4, border: "1px solid", p: 1, borderRadius: "5px" }}>
          <Typography variant="h4" sx={{ fontWeight: "600" }}>
            Item Remark:-
          </Typography>
          <Div>{state?.order_item_remarks}</Div>
        </Div>
      )}

      <Div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Actual Measurement
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Observe Measurement
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "140px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Fail / Pass
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Comment
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object?.entries(
                state?.order_measurement?.filter(
                  (item) =>
                    item?.item_master_item_name == state?.order_sub_item_name
                )[0]?.item_master_measurement
              )?.map(([label, value], i) => (
                <>
                  <TableRow key={i}>
                    <TableCell>
                      <Typography>{label}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{value}</Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        autoComplete="off"
                        value={qcData[i]?.observed_measurements?.value}
                        size="small"
                        name={label}
                        onChange={(e) => {
                          handleObserveMeasurement(e, i);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        onChange={(e) => handleObserveMeasurement(e, i)}
                        name="status"
                        checked={qcData[i]?.status}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={qcData[i]?.comments}
                        name="comments"
                        size="small"
                        onChange={(e) => {
                          handleObserveMeasurement(e, i);
                        }}
                      ></TextField>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Div>
      <Div sx={{ mt: 3 }}>
        <Div sx={{ mb: 2 }}>
          <Typography variant="h4">Remark by QC</Typography>
          <TextField
            fullWidth
            multiline
            size="medium"
            value={inputs?.remarks_by_qc}
            onChange={(e) =>
              setInputs({ ...inputs, remarks_by_qc: e.target.value })
            }
          >
            {/* {console.log(inputs)} */}
          </TextField>
        </Div>
        <Div sx={{ display: "flex", columnGap: 5, mb: 2 }}>
          <Div sx={{ width: "15%" }}>
            <Typography variant="h4">QC Status</Typography>
            <Select
              value={inputs?.qc_status}
              onChange={(e) =>
                setInputs({ ...inputs, qc_status: e.target.value })
              }
              sx={{ width: "100%" }}
              size="small"
            >
              <MenuItem value={"Pass"}>Pass</MenuItem>
              <MenuItem value={"Fail"}>Fail</MenuItem>
            </Select>
          </Div>
          <Div sx={{ width: "25%" }}>
            <Typography variant="h4">Confirm By</Typography>
            <TextField
              size="small"
              value={inputs?.qc_confirm_by}
              onChange={(e) =>
                setInputs({ ...inputs, qc_confirm_by: e.target.value })
              }
              sx={{ width: "100%" }}
            ></TextField>
          </Div>
        </Div>
      </Div>
      <QcImagesModal
        open={isInfoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        rowData={selectedRowData}
        type={type}
      />
      <FabricImageModal
        open={openImage}
        onClose={() => setOpenImage(false)}
        rowData={state}
      />
      <Div
        sx={{
          width: "93.5%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to cancel?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboard/order/qc");
              }
            });
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          sx={{ width: "100px" }}
          loading={isLoading}
          onClick={onSave}
        >
          Save
        </LoadingButton>
      </Div>
    </Div>
  );
};

export default EditQcPage;
