import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PreviewIcon from "@mui/icons-material/Preview";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { getQCOrderList } from "app/redux/actions/orderAction";
import { downloadQCPrint } from "app/services/apis/DownloadPdfApis/downloadQCPrint";
import { displayDateFun } from "app/utils/constants/functions";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import QcOrderDetailsModal from "./qcOrderModal";
import FullScreenLoader from "app/components/ListingPageLoader";
const QcDoneListTable = ({ searchTerm, setPage, page, inputs }) => {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");

  const [receiveLoader, setReceiveLoader] = useState(false);
  const [alterationLoader, setAlterationLoader] = useState(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { qcOrder, TotalPage, loading } = useSelector(
    (state) => state.orderReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // Replace this with your actual pagination logic
    dispatch(getQCOrderList(searchTerm, inputs, newPage, "qcDone"));
  };

  const handleItemAction = async (menuItem) => {
    console.log(menuItem, "menuItem");
    switch (menuItem.action) {
      case "editItem":
        navigate("/dashboard/order/qc/edit", {
          state: menuItem.data,
        });
        break;
      case "printlItem":
        const data = await downloadQCPrint(menuItem?.data?.qc_details_id);
        window.open(data);
        break;
      case "viewlItem":
        handleDownload(menuItem?.data);
        break;
    }
  };
  const handleDownload = async (row) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_URL}/api/qc/qc-report-pdf?qc_details_id=${row.qc_details_id}`
        )
        .then((res) => {
          window.open(res.data.result);
        })
        .catch((err) => {
          alert(err);
        });
    } catch (error) {
      // console.log(error);
      alert("Something Went Wrong");
    } finally {
    }
  };

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = qcOrder?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_sub_item_name") {
      aValue = a.order_sub_item_name.toLowerCase();
      bValue = b.order_sub_item_name.toLowerCase();
    } else if (orderBy == "order_item_factory_name") {
      aValue = a.order_item_factory_name.toLowerCase();
      bValue = b.order_item_factory_name.toLowerCase();
    } else if (orderBy == "order_item_qc_status") {
      aValue = a.order_item_qc_status.toLowerCase();
      bValue = b.order_item_qc_status.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleInfoIconClick = (rowData) => {
    setSelectedRowData(rowData);
    setInfoModalOpen(true);
  };

  return (
    <>
      {" "}
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Port No & Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_no"}
                  direction={order}
                  onClick={() => handleSort("order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Order No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_no"}
                  direction={order}
                  onClick={() => handleSort("order_item_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  px: 1,
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_name"}
                  direction={order}
                  onClick={() => handleSort("order_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "125px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_sub_item_name"}
                  direction={order}
                  onClick={() => handleSort("order_sub_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sub Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "125px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_job_work_id"}
                  direction={order}
                  onClick={() => handleSort("order_item_job_work_id")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Job Work ID
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "105px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_factory_name"}
                  direction={order}
                  onClick={() => handleSort("order_item_factory_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Factory
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item Status
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  width: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_status"}
                  direction={order}
                  onClick={() => handleSort("order_item_status")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  QC Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "qc_details_create_date "}
                  direction={order}
                  onClick={() => handleSort("qc_details_create_date ")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  QC Date
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>

              {/* <TableCell
              sx={{
                textAlign: "left",
                width: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Report
            </TableCell> */}

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "95px",
                  verticalAlign: "middle",
                  color: "white",
                  px: 1,
                  position: "sticky",
                  right: 0,
                  height: "58px",
                  zIndex: 1,
                  bgcolor: "#202020",
                }}
              >
                Action
              </TableCell>

              {/* <TableCell
              sx={{
                textAlign: "left",
                width: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Print
            </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order_port_no_and_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order_item_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.order_item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order_sub_item_name || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.order_item_job_work_id || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <InfoIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleInfoIconClick(row)}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order_item_factory_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order_item_status}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row.qc_status != null ? row.qc_status : "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.qc_details_create_date)}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.order_date)}
                </TableCell>

                {/* <TableCell sx={{ alignItems: "left", px: 1 }}>
                <Button size="small" onClick={() => handleDownload(row)}>
                  View
                </Button>
              </TableCell> */}

                <TableCell
                  sx={{
                    textAlign: "left",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    zIndex: 1,
                    bgcolor: "white",
                  }}
                >
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={[
                      {
                        icon: <PreviewIcon />,
                        title: "View",
                        action: "viewlItem",
                        data: row,
                      },
                      {
                        icon: <PrintIcon />,
                        title: "Print",
                        action: "printlItem",
                        data: row,
                      },
                      ...((row?.order_item_status === "Qc Pass" ||
                        row?.order_item_status === "Qc Fail") &&
                      permissions.qc_order_edit == true
                        ? [
                            {
                              icon: <EditIcon />,
                              title: "Update",
                              action: "editItem",
                              data: row,
                            },
                          ]
                        : []),
                    ]}
                    onClickCallback={handleItemAction}
                  />
                </TableCell>

                {/* <TableCell sx={{ alignItems: "left", px: 1 }}>
                <Button
                  size="small"
                  onClick={async () => {
                    const data = await downloadQCPrint(row.qc_details_id);
                    window.open(data);
                  }}
                >
                  Print
                </Button>
              </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
        <QcOrderDetailsModal
          open={isInfoModalOpen}
          onClose={() => setInfoModalOpen(false)}
          rowData={selectedRowData}
        />
      </TableContainer>
    </>
  );
};

export default QcDoneListTable;
