import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useState } from "react";
import { TextField, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { changeLocation } from "app/services/apis/Inventory/changeLocation";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
  getAllFinishedGoods,
  getAllFinishedScrapped,
  getAllRawMaterial,
  getAllRawScrapped,
  getAllWastage,
} from "app/redux/actions/inventoryAction";

const ChangeLocation = ({ open, onClose, rowData, type }) => {

  const [rackDetails, setRackDetails] = useState();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleLocation = async () => {
    setLoading(true);
    const data = await changeLocation(rackDetails, rowData.id, type);
    console.log(data,'data');
    if (data?.status == 200) {
      if (type == "rawMaterial") {
        dispatch(getAllRawMaterial("", ""));
      } else if (type == "finishedGoods") {
        dispatch(getAllFinishedGoods("", ""));
      } else if (type == "wastage") {
        dispatch(getAllWastage("", ""));
      } else if (type == "RawScrapped") {
        dispatch(getAllRawScrapped("", ""));
      
      } else if (type == "FinishedScrapped") {
        dispatch(getAllFinishedScrapped("", ""));
      }
      onClose(false);
      Swal.fire({ icon: "success", title: "Location Changed Successfully" });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (type == "rawMaterial") {
      setRackDetails(rowData?.raw_rack_details);
    } else if (type == "finishedGoods") {
      setRackDetails(rowData?.finished_rack_details);
    } else if (type == "wastage") {
      setRackDetails(rowData?.raw_rack_details);
    } else if (type == "RawScrapped") {
      setRackDetails(rowData?.raw_rack_details);
    } else if (type == "FinishedScrapped") {
      setRackDetails(rowData?.raw_rack_details);
    }
  }, [rowData]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <DialogTitle sx={{ fontWeight: "700" }}>Change Location</DialogTitle>
      <DialogContent>
        <Div>
          <Typography variant="h5">Rack Detail:</Typography>
          <TextField
            defaultValue={rowData?.raw_rack_details || rowData?.finished_rack_details}
            value={rackDetails}
            onChange={(e) => setRackDetails(e.target.value)}
            sx={{ width: "100%" }}
          />
        </Div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleLocation}
          color="primary"
          size="medium"
          variant="contained"
          loading={isLoading}
        >
          Change Location
        </LoadingButton>
        <Button onClick={onClose} color="error" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeLocation;
