import axios from "axios";

export const downloadFactoryReceivingPendingReport = async (inputs) => {
  if (inputs.from == undefined) {
    inputs.from = "";
  }
  if (inputs.to == undefined) {
    inputs.to = "";
  }

  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/reports/list-factory-receiving-pending-report-excel?from=${inputs.from}&to=${inputs.to}`,
      config
    );
    return data?.data?.result;
  } catch (error) {
    console.log(error);
    return error?.response?.data?.result;
  }
};
