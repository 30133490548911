import Page from "@jumbo/shared/Page";
import AdminOrderList from "app/pages/Order/Admin/List";
import Startqc from "app/pages/Order/QC/List/StartQ/startqc";

import CutterOrderList from "app/pages/Order/Cutter/List";
import ReceiveOrderList from "app/pages/Order/Receive/List";
import QCOrderList from "app/pages/Order/QC/List";
import DispatchOrderList from "app/pages/Order/Dispatch/List";
import AddNewOrder from "app/pages/Order/Admin/AddNewOrder/addNewOrder";
import { GenerateChallan } from "app/pages/Order/Cutter/GenerateChallan";
import AddProduction from "app/pages/Inventory/AddStock/addProduction";
import CreateNewDispatch from "app/pages/Order/Dispatch/CreateDispatch/createNewDispatch";
import AdminOrderRouteMiddleware from "./middleware/auth/adminOrderValidRoute";
import CuttingOrderRouteMiddleware from "./middleware/auth/cuttingOrderValidRoute";
import ReceiveOrderRouteMiddleware from "./middleware/auth/receiveOrderValidRoute";
import QCRouteMiddleware from "./middleware/auth/qcValidRoute";
import DispatchOrderRouteMiddleware from "./middleware/auth/dispatchOrderValidRoute";
import AddNewShopOrder from "app/pages/Order/Admin/ShopOrder";
import { EditOrderItem } from "app/pages/Order/Admin/EditItem";
import AlterationChallan from "app/pages/Order/QC/Alteration/SendAlteration/AlterationChallan";
import AllOrders from "app/pages/Order/Admin/AllOrders";
import QcEdit from "app/pages/Order/QC/EditQc";
import PackingRouteMiddleware from "./middleware/auth/packingValidRoute";
import PackingOrderList from "app/pages/Order/Packing/List";

export const orderRoutes = [
  {
    middleware: [
      {
        element: AdminOrderRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/order/admin",
        element: (
          <Page component={AdminOrderList} layout={"vertical-default"} />
        ),
      },
      {
        path: "/dashboard/order/admin/allOrders",
        element: <Page component={AllOrders} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/order/newOrder",
        element: <Page component={AddNewOrder} layout={"vertical-default"} />,
      },

      {
        path: "/dashboard/order/shopOrder",
        element: (
          <Page component={AddNewShopOrder} layout={"vertical-default"} />
        ),
      },
      {
        path: "/dashboard/order/editOrder",
        element: <Page component={AddNewOrder} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/order/replaceOrder",
        element: <Page component={AddNewOrder} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/order/editItem",
        element: <Page component={EditOrderItem} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: CuttingOrderRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/order/cutter",
        element: (
          <Page component={CutterOrderList} layout={"vertical-default"} />
        ),
      },
    ],
  },

  {
    middleware: [
      {
        element: ReceiveOrderRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/order/receive",
        element: (
          <Page component={ReceiveOrderList} layout={"vertical-default"} />
        ),
      },
    ],
  },

  {
    middleware: [
      {
        element: QCRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/order/qc",
        element: <Page component={QCOrderList} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/order/qc/generateChallan",
        element: (
          <Page component={AlterationChallan} layout={"vertical-default"} />
        ),
      },
      {
        path: "/dashboard/order/qc/edit",
        element: <Page component={QcEdit} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: PackingRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/order/packing",
        element: <Page component={PackingOrderList} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: DispatchOrderRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/order/dispatch",
        element: (
          <Page component={DispatchOrderList} layout={"vertical-default"} />
        ),
      },
      {
        path: "/dashboard/order/createDispatch",
        element: (
          <Page component={CreateNewDispatch} layout={"vertical-default"} />
        ),
      },
    ],
  },

  {
    path: "/dashboard/order/receiveOrder",
    element: <Page component={AddProduction} layout={"vertical-default"} />,
  },

  {
    path: "/dashboard/order/generateChallan",
    element: <Page component={GenerateChallan} layout={"vertical-default"} />,
  },
  {
    path: "/dashboard/order/startqc",
    element: <Page component={Startqc} layout={"vertical-default"} />,
  },
];
