import {
  ALL_INVENTORY_ADDONS_FAIL,
  ALL_INVENTORY_ADDONS_REQUEST,
  ALL_INVENTORY_ADDONS_SUCCESS,
  ALL_INVENTORY_FINISHED_GOODS_FAIL,
  ALL_INVENTORY_FINISHED_GOODS_REQUEST,
  ALL_INVENTORY_FINISHED_GOODS_SUCCESS,
  ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_FAIL,
  ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_REQUEST,
  ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_SUCCESS,
  ALL_INVENTORY_PRODUCTION_FAIL,
  ALL_INVENTORY_PRODUCTION_REQUEST,
  ALL_INVENTORY_PRODUCTION_SUCCESS,
  ALL_INVENTORY_PRODUCTION_HISTORY_FAIL,
  ALL_INVENTORY_PRODUCTION_HISTORY_REQUEST,
  ALL_INVENTORY_PRODUCTION_HISTORY_SUCCESS,
  ALL_INVENTORY_RAW_MATERIAL_FAIL,
  ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_FAIL,
  ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_REQUEST,
  ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_SUCCESS,
  ALL_INVENTORY_RAW_MATERIAL_REQUEST,
  ALL_INVENTORY_RAW_MATERIAL_SUCCESS,
  ALL_INVENTORY_WASTAGE_FAIL,
  ALL_INVENTORY_WASTAGE_REQUEST,
  ALL_INVENTORY_WASTAGE_SUCCESS,
  CLEAR_INVENTORY_PURCHASE_ITEM,
  INVENTORY_PURCHASE_ITEM_FAIL,
  INVENTORY_PURCHASE_ITEM_REQUEST,
  INVENTORY_PURCHASE_ITEM_SUCCESS,
  ALL_INVENTORY_FINISHED_SCRAPPED_REQUEST,
  ALL_INVENTORY_FINISHED_SCRAPPED_FAIL,
  ALL_INVENTORY_FINISHED_SCRAPPED_SUCCESS,
  ALL_INVENTORY_RAW_SCRAPPED_FAIL,
  ALL_INVENTORY_RAW_SCRAPPED_SUCCESS,
  ALL_INVENTORY_RAW_SCRAPPED_REQUEST,
} from "app/utils/constants/inventoryConstant";
import axios from "axios";

//raw Material
export const getAllRawMaterial =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      const filter = {
        kc_fabric_code: inputs.kc_fabric_code || "",
        purchase_order_no: inputs.purchase_order_no || "",
        raw_rack_details: inputs.raw_rack_details || "",
        supplier_name: inputs.supplier_name || "",
        supplier_fabric_code: inputs.supplier_fabric_code || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_INVENTORY_RAW_MATERIAL_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        kc_fabric_code: filter.kc_fabric_code,
        purchase_order_no: filter.purchase_order_no,
        raw_rack_details: filter.raw_rack_details,
        supplier_name: filter.supplier_name,
        supplier_fabric_code: filter.supplier_fabric_code,
        page: page,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/raw-inventory/list-raw-inventory?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_INVENTORY_RAW_MATERIAL_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_INVENTORY_RAW_MATERIAL_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//finishedGoods
export const getAllFinishedGoods =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      const filter = {
        finished_item_name: inputs.finished_item_name || "",
        purchase_order_no: inputs.purchase_order_no || "",
        finished_size: inputs.finished_size || "",
        finished_rack_details: inputs.finished_rack_details || "",
        supplier_name: inputs.supplier_name || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_INVENTORY_FINISHED_GOODS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        finished_size: filter.finished_size,
        finished_item_name: filter.finished_item_name,
        finished_rack_details: filter.finished_rack_details,
        purchase_order_no: filter.purchase_order_no,
        supplier_name: filter.supplier_name,
        page: page,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/finished-good-inventory/list-finished-good-inventory?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_INVENTORY_FINISHED_GOODS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_INVENTORY_FINISHED_GOODS_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

//   Production
export const getAllProduction =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      const filter = Object.fromEntries(
        Object.entries(inputs).filter(([key, value]) => value !== "")
      );
      const queryString = Object.entries(filter)
        .filter(([key, value]) => value !== "")
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      console.log(queryString);
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_INVENTORY_PRODUCTION_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const url = `${process.env.REACT_APP_URL}/api/production/list-production`;

      const params = new URLSearchParams({
        search: search_value,
        page: page,
        ...filter, // Spread the filter object to include all key-value pairs
      });

      // const url = `${
      //   process.env.REACT_APP_URL
      // }/api/production/list-production?${urlParams.toString()}`;
      const data = await axios.get(url, {
        ...config,
        params: params, // Use params instead of concatenating to the URL
      });
      // const data = await axios.get(url, config);

      dispatch({
        type: ALL_INVENTORY_PRODUCTION_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPage,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_INVENTORY_PRODUCTION_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//Wastage
export const getAllWastage =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      const filter = {
        kc_fabric_code: inputs.kc_fabric_code || "",
        raw_rack_details: inputs.raw_rack_details || "",
        supplier_name: inputs.supplier_name || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_INVENTORY_WASTAGE_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        kc_fabric_code: filter.kc_fabric_code,
        raw_rack_details: filter.raw_rack_details,
        supplier_name: filter.supplier_name,
        page: page,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/raw-inventory/list-wastage-inventory?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_INVENTORY_WASTAGE_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_INVENTORY_WASTAGE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
//Raw Scrapped
export const getAllRawScrapped =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      const filter = {
        kc_fabric_code: inputs.kc_fabric_code || "",
        purchase_order_no: inputs.purchase_order_no || "",
        raw_rack_details: inputs.raw_rack_details || "",
        supplier_name: inputs.supplier_name || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_INVENTORY_RAW_SCRAPPED_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        kc_fabric_code: filter.kc_fabric_code,
        purchase_order_no: filter.purchase_order_no,
        raw_rack_details: filter.raw_rack_details,
        supplier_name: filter.supplier_name,
        page: page,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/raw-inventory/list-raw-scrapped-inventory?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_INVENTORY_RAW_SCRAPPED_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_INVENTORY_RAW_SCRAPPED_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
//Finished  Scrapped
export const getAllFinishedScrapped =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      const filter = {
        finished_item_name: inputs.finished_item_name || "",
        purchase_order_no: inputs.purchase_order_no || "",
        finished_size: inputs.finished_size || "",
        finished_rack_details: inputs.finished_rack_details || "",
        supplier_name: inputs.supplier_name || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_INVENTORY_FINISHED_SCRAPPED_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        finished_size: filter.finished_size,
        finished_item_name: filter.finished_item_name,
        finished_rack_details: filter.finished_rack_details,
        purchase_order_no: filter.purchase_order_no,
        supplier_name: filter.supplier_name,
        page: page,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/finished-good-inventory/list-finished-scrapped-inventory?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_INVENTORY_FINISHED_SCRAPPED_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_INVENTORY_FINISHED_SCRAPPED_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//   Addons
export const getAllAddonsInventory =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      const filter = {
        itemName: inputs.itemName || "",
        order_no: inputs.order_no || "",
        order_item_job_work_id: inputs.order_item_job_work_id || "",
        order_item_factory_name: inputs.order_item_factory_name || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_INVENTORY_ADDONS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        itemName: filter.itemName,
        order_no: filter.order_no,
        order_item_job_work_id: filter.order_item_job_work_id,
        order_item_factory_name: filter.order_item_factory_name,
        page: page,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/production/list-addons-Inventory?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_INVENTORY_ADDONS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_INVENTORY_ADDONS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getPurchaseItemsByPONo = (po_no) => async (dispatch) => {
  dispatch({
    type: INVENTORY_PURCHASE_ITEM_REQUEST,
  });
  axios
    .get(
      `${process.env.REACT_APP_URL}/api/list/purchase-order/purchase-order-no-list?purchase_order_no=${po_no}`
    )
    .then((res) => {
      // console.log(res);
      dispatch({
        type: INVENTORY_PURCHASE_ITEM_SUCCESS,
        payload: res?.data?.result,
      });
    })
    .catch((err) => {
      dispatch({
        type: INVENTORY_PURCHASE_ITEM_FAIL,
        payload: err.response.data.message,
      });
    });
};

export const clearPurchaseItemsByPONo = () => async (dispatch) => {
  dispatch({
    type: CLEAR_INVENTORY_PURCHASE_ITEM,
  });
};

export const getRawMaterialManuallyIssued =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      const filter = {
        kc_fabric_code: inputs.kc_fabric_code || "",
        raw_manually_order_date: inputs.raw_manually_order_date || "",
        raw_manually_order_no: inputs.raw_manually_order_no || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        raw_manually_order_date: filter.raw_manually_order_date,
        kc_fabric_code: filter.kc_fabric_code,
        raw_manually_order_no: filter.raw_manually_order_no,
        page: page,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/raw-inventory/raw-manual-issue-list?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getFinishedManuallyIssued =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      const filter = {
        finished_item_name: inputs.finished_item_name || "",
        finished_manually_order_date: inputs.finished_manually_order_date || "",
        finished_manually_order_no: inputs.finished_manually_order_no || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        finished_manually_order_date: filter.finished_manually_order_date,
        finished_manually_order_no: filter.finished_manually_order_no,
        finished_item_name: filter.finished_item_name,
        page: page,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/finished-good-inventory/finished-manual-issue-list?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getAllProductionHistory =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      const filter = {
        order_item_name: inputs.order_item_name || "",
        order_no: inputs.order_no || "",
        order_item_job_work_id: inputs.order_item_job_work_id || "",
        order_port_no_and_name: inputs.order_port_no_and_name || "",
        order_item_factory_name: inputs.order_item_factory_name || "",
        order_item_status: inputs.order_item_status || "",
        order_est_delivery_date_from: inputs.order_est_delivery_date_from || "",
        order_est_delivery_date_to: inputs.order_est_delivery_date_to || "",
        productions_invoice_no: inputs.productions_invoice_no || "",
        supplier_name: inputs.supplier_name || "",
      };

      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_INVENTORY_PRODUCTION_HISTORY_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        order_item_name: filter.order_item_name,
        order_no: filter.order_no,
        order_item_job_work_id: filter.order_item_job_work_id,
        order_port_no_and_name: filter.order_port_no_and_name,
        order_est_delivery_date_from: filter.order_est_delivery_date_from,
        order_est_delivery_date_to: filter.order_est_delivery_date_to,
        productions_invoice_no: filter.productions_invoice_no,
        order_item_factory_name: filter.order_item_factory_name,
        order_item_status: filter.order_item_status,
        supplier_name: filter.supplier_name,
        page: page,
      });

      const url = `${
        process.env.REACT_APP_URL
      }/api/production/list-production-history?${urlParams.toString()}`;

      const data = await axios.get(url, config);

      dispatch({
        type: ALL_INVENTORY_PRODUCTION_HISTORY_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPage,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_INVENTORY_PRODUCTION_HISTORY_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
