import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useState } from "react";
import UndoIcon from "@mui/icons-material/Undo";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getAllPurchase } from "app/redux/actions/purchaseAction";
import { downloadPurchase } from "app/services/apis/DownloadPdfApis/downloadPurchase";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PurchaseInfoModal from "../ListPurchase/purchaseinfo";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function PurchaseOrderTable({ search }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(1);
  const { allpurchase, TotalPage, loading } = useSelector(
    (state) => state.purchaseReducer
  );
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = allpurchase?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "supplier_master.supplier_name") {
      aValue = a.supplier_master.supplier_name.toLowerCase();
      bValue = b.supplier_master.supplier_name.toLowerCase();
    } else if (orderBy == "purchase_order_no") {
      aValue = a.purchase_order_no.toLowerCase();
      bValue = b.purchase_order_no.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleItemAction = async (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/dashboard/purchase/edit", { state: menuItem.data });
        break;
      case "download":
        const link = await downloadPurchase(menuItem?.data?.purchase_order_id);
        window.open(link);
        break;
      case "return":
        navigate("/dashboard/purchase/return", { state: menuItem?.data });
        break;
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllPurchase(search, newPage));
  };

  const handleInfoIconClick = (rowData) => {
    setSelectedRowData(rowData);
    setInfoModalOpen(true);
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "purchase_order_no"}
                  direction={order}
                  onClick={() => handleSort("purchase_order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  PO No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                PO Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_master.supplier_name"}
                  direction={order}
                  onClick={() => handleSort("supplier_master.supplier_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Est Delivery Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "purchase_status"}
                  direction={order}
                  onClick={() => handleSort("purchase_status")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "purchase_order_type"}
                  direction={order}
                  onClick={() => handleSort("purchase_order_type")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                View Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    pl: 2,
                  }}
                >
                  {row?.purchase_order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.purchase_order_date}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.supplier_master?.supplier_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.purchase_estimate_delivery_date}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.purchase_status}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "100px",
                  }}
                >
                  {row?.purchase_order_type}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "100px",
                    pl: 5.3,
                  }}
                >
                  <VisibilityIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleInfoIconClick(row)}
                  />
                </TableCell>
                {console.log(row, "cccccccccc")}
                <TableCell sx={{ textAlign: "left" }}>
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={
                      permissions?.purchase_edit == true
                        ? [
                            {
                              icon: <EditIcon />,
                              title: "Edit Purchase Order",
                              action: "edit",
                              data: row,
                            },
                            ...(row.exists
                              ? [
                                  {
                                    icon: <UndoIcon />,
                                    title: "Return Purchase Order",
                                    action: "return",
                                    data: row,
                                  },
                                ]
                              : []),
                            {
                              icon: <DownloadIcon />,
                              title: "Download Purchase Order",
                              action: "download",
                              data: row,
                            },
                          ]
                        : [
                            {
                              icon: <DownloadIcon />,
                              title: "Download Purchase Order",
                              action: "download",
                              data: row,
                            },
                          ]
                    }
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
        <PurchaseInfoModal
          open={isInfoModalOpen}
          onClose={() => setInfoModalOpen(false)}
          rowData={selectedRowData}
        />
      </TableContainer>
    </>
  );
}
