import Div from "@jumbo/shared/Div";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListAddOnsTable from "./addonsTable";
import { useDispatch } from "react-redux";
import { getAllAddOns } from "app/redux/actions/masterAction";
import Swal from "sweetalert2";
import axios from "axios";
import { currentdate, displayDateFun } from "app/utils/constants/functions";
import { LoadingButton } from "@mui/lab";

export default function ListAddons() {
  const [searchTerm, setSearchTerm] = useState("");
  const [rateValue, setRateValue] = useState(0);
  const [loader, setLoader] = useState(false);
  const [usdDetails, setUsdDetails] = useState({});
  const [apiRun, setApiRun] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSearch = (value) => {
    dispatch(getAllAddOns(value));
  };
  useEffect(() => {
    dispatch(getAllAddOns());
  }, []);

  useEffect(async () => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/usd-rate/list-usd-rate`,
        config
      );
      if (data?.status == 200) {
        setUsdDetails(data.data.result);
        setRateValue(data.data.result?.new_usd_rate);
      }
    } catch (error) {
      setUsdDetails({});
    }
  }, [apiRun]);

  const handleUpdateUsd = () => {
    Swal.fire({
      title: "Are you sure you want to update USD?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // console.log(usdDetails?.id);
        if (rateValue != 0) {
          try {
            setLoader(true);
            const config = {
              withCredentials: true,
              headers: {
                withCredentials: true,
              },
            };
            if (usdDetails?.id != undefined) {
              const data = await axios.post(
                `${process.env.REACT_APP_URL}/api/usd-rate/add-usd-rate`,
                { ...usdDetails, new_usd_rate: Number(rateValue) },
                config
              );
              if (data?.status == 200) {
                // console.log(data.data.result);
                Swal.fire({ icon: "success", title: "USD Rate Updated" });
                setLoader(true);
                setApiRun(!apiRun);
              }
            } else {
              const data = await axios.post(
                `${process.env.REACT_APP_URL}/api/usd-rate/add-usd-rate`,
                { new_usd_rate: Number(rateValue) },
                config
              );
              if (data?.status == 200) {
                // console.log(data.data.result);
                Swal.fire({ icon: "success", title: "USD Added" });
                setApiRun(!apiRun);
                setLoader(true);
              }
            }
          } catch (error) {
            // console.log(error?.response?.data?.;
          } finally {
            setLoader(false);
          }
        }
      }
    });
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Add Ons Master</Typography>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        {permissions?.add_on_master_create == true && (
          <Div>
            <Button
              variant="contained"
              sx={{ p: 1, pl: 4, pr: 4 }}
              onClick={() => navigate("/master/addons/add")}
            >
              Add New AddOns
            </Button>
          </Div>
        )}
      </Div>
      {permissions?.add_on_master_edit == true && (
        <Div
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Div sx={{ mb: 2, display: "flex", alignItems: "center", gap: 1 }}>
            <Div>
              <Typography sx={{ fontWeight: 600, mr: 1, fontSize: "15px" }}>
                USD Rate:
              </Typography>
              <TextField
                size="small"
                name="addons_name"
                value={rateValue}
                onChange={(e) => setRateValue(e.target.value)}
              />
            </Div>
            <Div sx={{ mt: "auto" }}>
              <LoadingButton
                variant="contained"
                loading={loader}
                onClick={handleUpdateUsd}
              >
                Update USD Rate
              </LoadingButton>
            </Div>
          </Div>
          <Div sx={{ textAlign: "left" }}>
            <Div sx={{ display: "flex" }}>
              <Typography sx={{ fontWeight: 600 }}>Old USD Rate</Typography>:
              <Typography sx={{ fontWeight: 600, ml: 0.5 }}>
                {usdDetails?.old_usd_rate ? usdDetails?.old_usd_rate : "0"}
              </Typography>
            </Div>
            <Div sx={{ display: "flex" }}>
              <Typography sx={{ fontWeight: 600 }}>USD Updated Date</Typography>
              :
              <Typography sx={{ fontWeight: 600, ml: 0.5 }}>
                {displayDateFun(
                  usdDetails?.usd_rate_update_date
                    ? usdDetails?.usd_rate_update_date
                    : currentdate
                )}
              </Typography>
            </Div>
          </Div>
        </Div>
      )}
      <ListAddOnsTable />
    </Div>
  );
}
