import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ListDraftTable from "./draftTable";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { dateFun } from "app/utils/constants/functions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getAllDraft } from "app/redux/actions/draftAction";
import { getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import { getOrderNoList } from "app/services/apis/ListApi/orderNoList";
import { getKcFabricCodeList } from "app/services/apis/ListApi/kcFabricCodeList";
import { getItemNameList } from "app/services/apis/ListApi/itemNameList";
import { getSuppliersListDropDown } from "app/services/apis/ListApi/supplierList";
import { downloadDraftReport } from "app/services/apis/ReportApis/Draft/downloadDraftReport";

export default function ListDraft() {
  const [searchTerm, setSearchTerm] = useState("");
  const [inputs, setInputs] = useState({});
  const [orderNoList, setOrderNoList] = useState([]);
  const [portNoNameList, setPortNoNameList] = useState([]);
  const [kcFabricCode, setKcFabricCode] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);
  const [draftDays, setDraftDays] = useState([]);
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const { draftList } = useSelector((state) => state.draftReducer);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const handleFilter = () => {
    setPage(1);
    dispatch(getAllDraft("", inputs, 1));
  };
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllDraft(value, "", 1));
  };

  const handleClear = () => {
    dispatch(getAllDraft("", "", 1));
    setInputs({
      order_port_no_and_name: null,
      order_no: null,
      order_item_kc_fabric_code: null,
      order_item_name: null,
      order_item_draft_days: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
      order_date: null,
      supplier_name: null,
    });
    setResetAutocomplete((prev) => !prev);
  };

  useEffect(async () => {
    dispatch(getAllDraft("", "", 1));
    setPortNoNameList(await getPortListNoPerm());
    setOrderNoList(await getOrderNoList());
    setItemNameList(await getItemNameList());
    setSupplierList(await getSuppliersListDropDown());
    setKcFabricCode(await getKcFabricCodeList());
  }, []);

  useEffect(() => {
    if (draftList?.length > 0) {
      // logic for draft days
      const draftDays = draftList.map((date) =>
        countDaysUntilCurrentDate(date.order_item_draft_days)
      );
      let uniqueSet = new Set(draftDays);
      let uniqueArr = Array.from(uniqueSet);
      setDraftDays(uniqueArr);
      // console.log(uniqueArr);
    }
  }, [draftList]);

  function countDaysUntilCurrentDate(targetDateStr) {
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const timeDifference = targetDate - currentDate;
    const daysDifference = Math.abs(timeDifference) / (1000 * 60 * 60 * 24);
    return Math.round(daysDifference);
  }

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Draft Order</Typography>
      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Port No & Name</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={portNoNameList || []}
            value={inputs.order_port_no_and_name}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_port_no_and_name: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Order No</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={orderNoList || []}
            value={inputs.order_no}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_no: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "25%" }}>
          <Typography variant="h5">Kennys Fabric Code</Typography>
          {/* {console.log(inputs.order_item_kc_fabric_code)} */}
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={kcFabricCode || []}
            value={inputs.order_item_kc_fabric_code}
            getOptionLabel={(option) => option.kc_fabric_code}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_item_kc_fabric_code: newValue?.kc_fabric_code
                  ? newValue?.kc_fabric_code
                  : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.kc_fabric_code}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Item Name</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={itemNameList || []}
            value={inputs.order_item_name}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_item_name: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Draft Order Days</Typography>
          <Autocomplete
            freeSolo
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={draftDays || []}
            value={inputs.order_item_draft_days}
            getOptionLabel={(option) => option}
            onInputChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_item_draft_days: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>

        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Supplier Name</Typography>
          <Autocomplete
            size="small"
            id="tags-standard"
            options={supplierList || []}
            key={resetAutocomplete ? "reset1" : "normal1"}
            getOptionLabel={(option) => option.supplier_name}
            onChange={(e, newValue) => {
              console.log(newValue);
              setInputs((values) => ({
                ...values,
                supplier_name: newValue.supplier_name
                  ? newValue.supplier_name
                  : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.supplier_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>

        <Div sx={{ width: "25%" }}>
          <Typography variant="h5">Delivery Date Range</Typography>
          <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "40%" }}>
                <DatePicker
                  maxDate={dayjs()}
                  format="DD/MM/YY"
                  value={inputs?.order_est_delivery_date_from}
                  error={!inputs?.order_est_delivery_date_from}
                  onChange={(newDate) =>
                    (inputs.order_est_delivery_date_from = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "40%" }}>
                <DatePicker
                  maxDate={dayjs()}
                  format="DD/MM/YY"
                  value={inputs?.order_est_delivery_date_to}
                  error={!inputs?.order_est_delivery_date_to}
                  onChange={(newDate) =>
                    (inputs.order_est_delivery_date_to = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
          </Div>
        </Div>

        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Order Date </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Div sx={{ width: "100%" }}>
              <DatePicker
                size="small"
                maxDate={dayjs()}
                format="DD/MM/YY"
                value={inputs?.order_date}
                error={!inputs?.order_date}
                onChange={(newDate) => (inputs.order_date = dateFun(newDate))}
                sx={{
                  width: "100%",
                  "&.MuiTextField-root": {
                    height: "39px",
                    flexDirection: "unset",
                  },
                }}
              />
            </Div>
          </LocalizationProvider>
        </Div>
      </Div>
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          justifyContent: "space-between",
        }}
      >
        <Div>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Div>

        <Button
          disabled={draftList?.length == 0}
          variant="contained"
          onClick={async () => {
            const data = await downloadDraftReport(inputs);
            window.open(data);
          }}
        >
          Export CSV
        </Button>
      </Div>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 3.5,
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mb: 5, mt: 2 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
      </Div>
      <ListDraftTable
        searchTerm={searchTerm}
        setPage={setPage}
        page={page}
        inputs={inputs}
      />
    </Div>
  );
}
