import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const CostModal = ({
  open,
  onClose,
  rowData,
  setCostDetails,
  indexNo,
  costDetails,
}) => {
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // setInputs((values) => ({ ...values, [name]: value }));
    inputs[name] = Number(value);
    inputs["cost_total"] = parseInt(
      inputs?.cost_rate_per_meter * inputs?.cost_quantity
    );
    inputs["cost_discount_value"] = parseInt(
      inputs?.cost_total * (inputs?.cost_discount_percentage / 100)
    );
    inputs["cost_final_value"] = parseInt(
      inputs?.cost_total - inputs?.cost_discount_value
    );
    setInputs({ ...inputs });
  };

  useEffect(() => {
    if (Object.keys(inputs).length > 0) {
      const updatedData = [...costDetails];
      updatedData[indexNo] = {
        po_item_details_id: rowData?.id,
        ...inputs,
      };
      setCostDetails(updatedData);
    }
  }, [inputs]);

  useEffect(() => {
    // console.log(indexNo, "index");
    if (costDetails[indexNo] != undefined) {
      setInputs({
        cost_rate_per_meter: costDetails[indexNo].cost_rate_per_meter,
        cost_total: costDetails[indexNo].cost_total,
        cost_discount_value: costDetails[indexNo].cost_discount_value,
        cost_final_value: costDetails[indexNo].cost_final_value,
        cost_quantity: costDetails[indexNo].cost_quantity,
        cost_discount_percentage: costDetails[indexNo].cost_discount_percentage,
      });
    } else {
      setInputs({});
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      {/* {console.log(rowData)} */}
      <DialogTitle sx={{ fontWeight: "700" }}>Cost Details</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Rate Per Unit
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Quantity
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "140px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Total (R*Q)
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Discount %
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Discount Value
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Final Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  onKeyDown={(e) => {
                    const inputValue = parseInt(e.target.value + e.key, 10);

                    if (isNaN(inputValue) || inputValue < 0) {
                      e.preventDefault();
                    }
                  }}
                  name="cost_rate_per_meter"
                  size="small"
                  onChange={handleChange}
                  value={inputs?.cost_rate_per_meter}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  onKeyDown={(e) => {
                    const inputValue = parseInt(e.target.value + e.key, 10);
                    if (isNaN(inputValue) || inputValue < 0) {
                      e.preventDefault();
                    }
                  }}
                  name="cost_quantity"
                  size="small"
                  onChange={handleChange}
                  value={inputs?.cost_quantity}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  onKeyDown={(e) => {
                    const inputValue = parseInt(e.target.value + e.key, 10);
                    if (isNaN(inputValue) || inputValue < 0) {
                      e.preventDefault();
                    }
                  }}
                  name="cost_total"
                  disabled
                  size="small"
                  onChange={handleChange}
                  value={inputs?.cost_total}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  onKeyDown={(e) => {
                    const inputValue = parseInt(e.target.value + e.key, 10);
                    if (isNaN(inputValue) || inputValue < 0) {
                      e.preventDefault();
                    }
                  }}
                  name="cost_discount_percentage"
                  size="small"
                  onChange={handleChange}
                  value={inputs?.cost_discount_percentage}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  onKeyDown={(e) => {
                    const inputValue = parseInt(e.target.value + e.key, 10);
                    if (isNaN(inputValue) || inputValue < 0) {
                      e.preventDefault();
                    }
                  }}
                  name="cost_discount_value"
                  disabled
                  size="small"
                  onChange={handleChange}
                  value={inputs?.cost_discount_value}
                />
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  type="number"
                  inputProps={{ min: 0 }}
                  onKeyDown={(e) => {
                    const inputValue = parseInt(e.target.value + e.key, 10);
                    if (isNaN(inputValue) || inputValue < 0) {
                      e.preventDefault();
                    }
                  }}
                  name="cost_final_value"
                  size="small"
                  onChange={handleChange}
                  value={inputs?.cost_final_value}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CostModal;
