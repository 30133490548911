import Div from "@jumbo/shared/Div";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ListCatalogueFolders from "./listCatalogue";

export default function ListCatalogue() {
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Div sx={{ mt: -4 }}>
      <Typography
        variant="h1"
        sx={{ mb: permissions?.catalogue_master_create == false && 4 }}
      >
        Catalogue Master
      </Typography>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        {permissions?.catalogue_master_create == true && (
          <Div sx={{ mb: 5, mt: 4, float: "right" }}>
            <Button
              variant="contained"
              sx={{ p: 1, pl: 4, pr: 4 }}
              onClick={() => navigate("/dashboard/master/catalogue/add")}
            >
              Add New Catalogue
            </Button>
          </Div>
        )}
      </Div>

      <ListCatalogueFolders searchTerm={searchTerm} />
    </Div>
  );
}
