import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Button, MenuItem, Select, Typography } from "@mui/material";
import FormTextField from "app/components/InputField/FormTextField";
import { codeList } from "app/utils/constants/dropdowns";
import ListOptions from "app/components/Dropdown/ListOptions";
import { updateFactory } from "app/services/apis/updateFactory";
import Swal from "sweetalert2";
import { addFactory } from "app/services/apis/addFactory";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Country, State, City } from "country-state-city";

export default function AddFactory() {
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const { pathname } = useLocation();
  const { state } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  // console.log(state);
  const factory = {
    factory_no: state?.factory_no ? state?.factory_no : "",
    factory_name: state?.factory_name ? state?.factory_name : "",
    factory_address: state?.factory_address ? state?.factory_address : "",
    factory_pan_no: state?.factory_pan_no ? state?.factory_pan_no : "",
    factory_gst_no: state?.factory_gst_no ? state?.factory_gst_no : "",
    factory_city: state?.factory_city ? state?.factory_city : "",
    factory_state: state?.factory_state ? state?.factory_state : "",
    factory_pincode: state?.factory_pincode ? state?.factory_pincode : "",
    factory_contact_person_name: state?.factory_contact_person_name
      ? state?.factory_contact_person_name
      : "",
    factory_contact_person_phone_no: state?.factory_contact_person_phone_no
      ? state?.factory_contact_person_phone_no
      : "",
    factory_contact_person_email_id: state?.factory_contact_person_email_id
      ? state?.factory_contact_person_email_id
      : "",
    factory_contact_person_country_code:
      state?.factory_contact_person_country_code
        ? state?.factory_contact_person_country_code
        : "+91",
    factory_country: state?.factory_country ? state?.factory_country : "",
  };

  const validationSchema = yup.object({
    factory_no: yup
      .number()
      .typeError("Factory Number must be a number")
      .required("Factory Number is Required"),
    factory_name: yup
      .string("Enter Factory Name")
      .required("Factory Name is required"),
    factory_pan_no: yup
      .string("Enter Factory PAN No")
      .required("Factory PAN No is required")
      .matches(
        /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
        "Invalid PAN No. Please enter a valid PAN No."
      ),
    factory_gst_no: yup
      .string("Enter Factory GST No")
      .required("Factory GST No is required")
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
        "Invalid GST No. Please enter a valid GST No."
      ),
    factory_address: yup
      .string("Enter Address")
      .required("Address is required"),
    factory_city: yup.string("Enter City").required("City is required"),
    factory_state: yup.string("Enter State").required("State is required"),
    factory_pincode: yup
      .string("Enter Pincode")
      .required("Pincode is required")
      .matches(
        /^\d{6}$/,
        "Invalid PIN code. Please enter a valid 6-digit PIN code."
      ),
    factory_contact_person_name: yup
      .string("Enter Contact Person Name")
      .required("Contact Person Name is required"),
    factory_contact_person_email_id: yup
      .string("Enter Contact Person Email ID")
      .email("Enter a valid Email ID")
      .required("Contact Person Email ID is required"),
    factory_contact_person_phone_no: yup
      .number()
      .typeError("Mobile number must be a number")
      .required("Mobile Number is Required"),
    factory_country: yup
      .string()
      .required("Country is required")
      .test(
        "factory_country-not-select",
        "Please select a valid Country",
        (value) => value !== "Select"
      ),
    factory_contact_person_country_code: yup
      .string()
      .required("Country Code is required")
      .test(
        "country-not-select",
        "Please select a valid Country Code",
        (value) => value !== "+0"
      ),
  });
  const onUserSave = async (values) => {
    setSubmitting(true);
    if (pathname == "/master/factory/edit") {
      const data = await updateFactory(values, state.id);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Factory Edited Successfully",
          text: "",
        });
        navigate("/dashboard/master/factory");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addFactory(values);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Factory Added Successfully",
          text: "",
        });
        navigate("/dashboard/master/factory");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };
  const filterStates = (countryIsoCode) => {
    // Use your logic to filter states based on the selected country.
    const filteredStates = State.getAllStates().filter(
      (state) => state.countryCode === countryIsoCode
    );
    setStateList(filteredStates);
  };

  const filterCities = (stateIsoCode) => {
    // Use your logic to filter cities based on the selected state.
    const filteredCities = City.getAllCities().filter(
      (city) => city.stateCode === stateIsoCode
    );
    setCityList(filteredCities);
  };
  useEffect(() => {
    if (state) {
      const country = Country.getAllCountries().filter(
        (country) => country.name === state.factory_country
      );
      const stateList = State.getAllStates().filter(
        (country) => country.name === state.factory_state
      );
      filterStates(country[0].isoCode);
      filterCities(stateList[0].isoCode);
    }
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/master/factory/add" ? "Add New Factory" : "Edit Factory"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={factory}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                  }}
                >
                  <FormTextField name="factory_no" label="Factory No" />
                  <FormTextField name="factory_name" label="Factory Name" />
                  <FormTextField name="factory_pan_no" label="Factory PAN No" />
                  <FormTextField name="factory_gst_no" label="Factory GST No" />

                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Country</Typography>
                    <Select
                      fullWidth
                      value={values?.factory_country}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue("factory_country", e?.target?.value);
                        filterStates(key.key.replace(/[.$]/g, ""));
                      }}
                    >
                      {countryList?.map((country) => {
                        return (
                          <MenuItem
                            value={country?.name}
                            key={country?.isoCode}
                          >
                            {country?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Div style={{ height: "30px" }}>
                      <ErrorMessage
                        name="factory_country"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">State</Typography>
                    <Select
                      fullWidth
                      value={values?.factory_state}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue("factory_state", e?.target?.value);
                        filterCities(key.key.replace(/[.$]/g, ""));
                      }}
                    >
                      {StateList?.map((state) => {
                        // console.log(state);
                        return (
                          <MenuItem value={state?.name} key={state?.isoCode}>
                            {state?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="factory_state"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">City</Typography>
                    <Select
                      fullWidth
                      value={values?.factory_city}
                      size="small"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFieldValue("factory_city", e.target.value);
                      }}
                    >
                      {cityList?.map((city) => {
                        return (
                          <MenuItem key={city.name} value={city.name}>
                            {city.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="factory_city"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <FormTextField name="factory_address" label="Address" />
                  <FormTextField name="factory_pincode" label="Pincode" />

                  <FormTextField
                    name="factory_contact_person_name"
                    label="Contact Person Name"
                  />
                  <FormTextField
                    name="factory_contact_person_email_id"
                    label="Contact Person Email ID"
                  />
                  <Div sx={{ width: "50%" }}>
                    <Typography variant="h5">Phone No*</Typography>
                    <Div sx={{ display: "flex", mt: -1 }}>
                      <ListOptions
                        options={codeList}
                        name={"factory_contact_person_country_code"}
                        sx={{ minWidth: "80px", maxWidth: "10%" }}
                      />
                      <FormTextField
                        name="factory_contact_person_phone_no"
                        sx={{ width: "100%" }}
                      />
                    </Div>
                  </Div>
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/master/factory");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
