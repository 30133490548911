import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { getAllFinishedScrapped } from "app/redux/actions/inventoryAction";
import { displayDateFun } from "app/utils/constants/functions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ChangeLocation from "../ActionComponent/changeLocation";
import axios from "axios";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function FinishedScrappedTable({ searchTerm }) {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [page, setPage] = useState(1);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { finishedScrapped, error, TotalPage, loading } = useSelector(
    (state) => state.inventoryReducer
  );

  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = finishedScrapped?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "supplier_master.supplier_name") {
      aValue = a.supplier_master.supplier_name.toLowerCase();
      bValue = b.supplier_master.supplier_name.toLowerCase();
    } else if (orderBy == "raw_rack_details") {
      aValue = a.raw_rack_details.toLowerCase();
      bValue = b.raw_rack_details.toLowerCase();
    } else if (orderBy == "kc_fabric_code") {
      aValue = a.kc_fabric_code.toLowerCase();
      bValue = b.kc_fabric_code.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "FinishedRevertScrapped":
        RevertScrap(menuItem.data);
        break;
      case "changeLocation":
        setInfoModalOpen(true);
        setSelectedRowData(menuItem.data);
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllFinishedScrapped(searchTerm, "", newPage));
  };
  const RevertScrap = (data) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const datas = {
      taka_type: "Finished Goods Inventory",
      taka_id: data.id,
    };
    axios
      .patch(
        `${process.env.REACT_APP_URL}/api/finished-item/revert-scrap_finished?id=${data.scrap_finished_id}`,
        datas,
        config
      )
      .then((res) => {
        if (res?.status == 200) {
          Swal.fire({ icon: "success", title: "Item Revert Successfully" });
          dispatch(getAllFinishedScrapped("", ""));
        }
      });
  };

  useEffect(() => {
    if (error == "No Finished Scrap Goods Inventory found") {
      Swal.fire({ icon: "error", title: "No Result Found" });
    }
  }, [error]);

  return (
    <>
      {" "}
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "finished_item_name"}
                  direction={order}
                  onClick={() => handleSort("finished_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={
                    orderBy === "finish_good_inventory.finished_invoice_no"
                  }
                  direction={order}
                  onClick={() =>
                    handleSort("finish_good_inventory.finished_invoice_no")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Invoice No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={
                    orderBy === "finish_good_inventory.finished_invoice_value"
                  }
                  direction={order}
                  onClick={() =>
                    handleSort("finish_good_inventory.finished_invoice_value")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Invoice Value
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Invoice Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "finished_size"}
                  direction={order}
                  onClick={() => handleSort("finished_size")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Size
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "finished_item_recieved_quantity"}
                  direction={order}
                  onClick={() => handleSort("finished_item_recieved_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Received Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "finished_item_reserve_quantity"}
                  direction={order}
                  onClick={() => handleSort("finished_item_reserve_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Scraped Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "finished_rack_details"}
                  direction={order}
                  onClick={() => handleSort("finished_rack_details")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Rack Details
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "purchase_order.purchase_order_no"}
                  direction={order}
                  onClick={() => handleSort("purchase_order.purchase_order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  PO No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_master.supplier_name"}
                  direction={order}
                  onClick={() => handleSort("supplier_master.supplier_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              {permissions?.inventory_edit == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#202020",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.finished_item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.finish_good_inventory.finished_invoice_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.finish_good_inventory.finished_invoice_value}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.finish_good_inventory.finished_invoice_date == null
                    ? "N/A"
                    : displayDateFun(
                        row?.finish_good_inventory.finished_invoice_date
                      )}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.finished_size ? row?.finished_size : "-"}
                </TableCell>
                {/* {console.log(row)} */}
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.finished_item_recieved_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.scrap_finished_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.finished_rack_details}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.purchase_order?.purchase_order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.supplier_master?.supplier_name}
                </TableCell>
                {permissions?.inventory_edit == true && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <EditLocationIcon />,
                          title: "Change Location",
                          action: "changeLocation",
                          data: row,
                        },
                        {
                          icon: <UndoIcon />,
                          title: "Revert Scrap",
                          action: "FinishedRevertScrapped",
                          data: row,
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          <ChangeLocation
            open={isInfoModalOpen}
            onClose={() => setInfoModalOpen(false)}
            rowData={selectedRowData}
            type={"FinishedScrapped"}
          />
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
