import React from "react";
import AlternativeTable from "./alternativeTable";
import { Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";

export default function DraftAlternative() {
  return (
    <>
    <Div sx={{ mt: -4 }}>
    <Typography variant="h1" sx={{mb:5}}>Draft Alternative</Typography>
    <AlternativeTable/>
    </Div>
    </>
  );
}
