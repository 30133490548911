import Div from "@jumbo/shared/Div";
import {
  Box,
  Button,
  InputAdornment,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFemaleProduct,
  getMaleProduct,
} from "app/redux/actions/masterAction";
import { useDispatch } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ListMaleProductTable from "./maleProductTable";
import ListFemaleProductTable from "./femaleProductTable";

export default function ListProduct() {
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = useState("1");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSearch = (search) => {
    setPage(1);
    if (value == 1) {
      dispatch(getMaleProduct(search, 1));
    } else {
      dispatch(getFemaleProduct(search, 1));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setPage(1);
    if (value == 1) {
      dispatch(getMaleProduct());
    } else {
      dispatch(getFemaleProduct());
    }
  }, [value]);
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Product Master</Typography>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        {permissions?.product_master_create == true && (
          <Div>
            <Button
              variant="contained"
              sx={{ p: 1, pl: 4, pr: 4 }}
              onClick={() => navigate("/master/product/add")}
            >
              Add New Product
            </Button>
          </Div>
        )}
      </Div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Male Products" value="1" />
              <Tab label="Female Products" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ListMaleProductTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
            />
          </TabPanel>
          <TabPanel value="2">
            <ListFemaleProductTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Div>
  );
}
