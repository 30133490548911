import {
  ALL_DISPATCH_COMPLETE_LIST_FAIL,
  ALL_DISPATCH_COMPLETE_LIST_REQUEST,
  ALL_DISPATCH_COMPLETE_LIST_SUCCESS,
  ALL_DISPATCH_DELIVERED_LIST_FAIL,
  ALL_DISPATCH_DELIVERED_LIST_REQUEST,
  ALL_DISPATCH_DELIVERED_LIST_SUCCESS,
  ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_FAIL,
  ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_REQUEST,
  ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_SUCCESS,
  ALL_DISPATCH_PARTIAL_LIST_FAIL,
  ALL_DISPATCH_PARTIAL_LIST_REQUEST,
  ALL_DISPATCH_PARTIAL_LIST_SUCCESS,
  ALL_ORDER_CREATED_LIST_FAIL,
  ALL_ORDER_CREATED_LIST_REQUEST,
  ALL_ORDER_CREATED_LIST_SUCCESS,
  ALL_ORDER_ITEM_LIST_FAIL,
  ALL_ORDER_ITEM_LIST_REQUEST,
  ALL_ORDER_ITEM_LIST_SUCCESS,
  ALL_ORDER_LIST_FAIL,
  ALL_ORDER_LIST_REQUEST,
  ALL_ORDER_LIST_SUCCESS,
  ALL_SHOP_ORDER_DELIVERED_LIST_FAIL,
  ALL_SHOP_ORDER_DELIVERED_LIST_REQUEST,
  ALL_SHOP_ORDER_DELIVERED_LIST_SUCCESS,
  ALL_SHOP_ORDER_LIST_FAIL,
  ALL_SHOP_ORDER_LIST_REQUEST,
  ALL_SHOP_ORDER_LIST_SUCCESS,
  ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_FAIL,
  ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_REQUEST,
  ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_SUCCESS,
  CUTTING_ORDER_LIST_FAIL,
  CUTTING_ORDER_LIST_REQUEST,
  CUTTING_ORDER_LIST_SUCCESS,
  PACKING_ORDER_LIST_FAIL,
  PACKING_ORDER_LIST_REQUEST,
  PACKING_ORDER_LIST_SUCCESS,
  SHOP_PACKING_ORDER_LIST_FAIL,
  SHOP_PACKING_ORDER_LIST_REQUEST,
  SHOP_PACKING_ORDER_LIST_SUCCESS,
  QC_ORDER_LIST_FAIL,
  QC_ORDER_LIST_REQUEST,
  QC_ORDER_LIST_SUCCESS,
  RECEIVE_ORDER_LIST_FAIL,
  RECEIVE_ORDER_LIST_REQUEST,
  RECEIVE_ORDER_LIST_SUCCESS,
} from "app/utils/constants/orderConstant";
import axios from "axios";

export const getAllOrders =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }

      const filter = {
        order_item_name: inputs.order_item_name || "",
        order_no: inputs.order_no || "",
        order_item_job_work_id: inputs.order_item_job_work_id || "",
        order_port_no_and_name: inputs.order_port_no_and_name || "",
        order_item_factory_name: inputs.order_item_factory_name || "",
        order_item_status: inputs.order_item_status || "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to || "",
        order_est_delivery_date_from:
          inputs?.order_est_delivery_date_from || "",
      };

      // console.log(filter);
      dispatch({ type: ALL_ORDER_LIST_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        order_item_name: filter.order_item_name,
        order_no: filter.order_no,
        order_item_job_work_id: filter.order_item_job_work_id,
        order_est_delivery_date_to: filter.order_est_delivery_date_to,
        order_est_delivery_date_from: filter.order_est_delivery_date_from,
        order_port_no_and_name: filter.order_port_no_and_name,
        order_item_factory_name: filter.order_item_factory_name,
        order_item_status: filter.order_item_status,
        page: page,
      });

      const data = await axios.get(
        `${
          process.env.REACT_APP_URL
        }/api/order/list-order?${urlParams.toString()}`,
        config
      );

      // console.log(data);

      dispatch({
        type: ALL_ORDER_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ORDER_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

//orders item List by order no
export const getAllOrderItems = (orderNo) => async (dispatch) => {
  try {
    dispatch({ type: ALL_ORDER_ITEM_LIST_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/order/list-single-order?order_no=${orderNo}`,
      config
    );
    dispatch({
      type: ALL_ORDER_ITEM_LIST_SUCCESS,
      payload: data?.data?.result,
    });
  } catch (error) {
    dispatch({
      type: ALL_ORDER_ITEM_LIST_FAIL,
      payload: error.response?.data?.message,
    });
  }
};

export const getAllOrderItemsReplace = (orderNo) => async (dispatch) => {
  try {
    dispatch({ type: ALL_ORDER_ITEM_LIST_REQUEST });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/order/list-replacement-order?order_no=${orderNo}`,
      config
    );
    dispatch({
      type: ALL_ORDER_ITEM_LIST_SUCCESS,
      payload: data?.data?.result,
    });
  } catch (error) {
    dispatch({
      type: ALL_ORDER_ITEM_LIST_FAIL,
      payload: error.response?.data?.message,
    });
  }
};

export const getNewOrderList =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      dispatch({
        type: CUTTING_ORDER_LIST_REQUEST,
      });

      const filter = {
        order_item_name: inputs.order_item_name || "",
        order_no: inputs.order_no || "",
        order_item_job_work_id: inputs.order_item_job_work_id || "",
        order_port_no_and_name: inputs.order_port_no_and_name || "",
        order_item_factory_name: inputs.order_item_factory_name || "",
        order_item_no: inputs.order_item_no || "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to || "",
        order_est_delivery_date_from:
          inputs?.order_est_delivery_date_from || "",
      };

      if (!search_value) {
        search_value = "";
      }
      if (!page) {
        page = 0;
      }
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        order_item_name: filter.order_item_name,
        order_est_delivery_date_to: filter.order_est_delivery_date_to,
        order_est_delivery_date_from: filter.order_est_delivery_date_from,
        order_no: filter.order_no,
        order_item_job_work_id: filter.order_item_job_work_id,
        order_port_no_and_name: filter.order_port_no_and_name,
        order_item_factory_name: filter.order_item_factory_name,
        order_item_no: filter.order_item_no,
        page: page,
      });

      const data = await axios.get(
        `${
          process.env.REACT_APP_URL
        }/api/list-order/new-order-list?${urlParams.toString()}`,
        config
      );

      dispatch({
        type: CUTTING_ORDER_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: CUTTING_ORDER_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

export const getReceiveOrderList =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      dispatch({
        type: RECEIVE_ORDER_LIST_REQUEST,
      });

      if (!search_value) {
        search_value = "";
      }
      if (!page) {
        page = 0;
      }
      console.log(inputs);
      const filter = {
        order_no: inputs.order_no ? inputs.order_no : "",
        order_item_no: inputs.order_item_no ? inputs.order_item_no : "",
        order_item_job_work_id: inputs.order_item_job_work_id
          ? inputs.order_item_job_work_id
          : "",
        order_port_no_and_name: inputs.order_port_no_and_name
          ? inputs.order_port_no_and_name
          : "",
        order_item_factory_name: inputs.order_item_factory_name
          ? inputs.order_item_factory_name
          : "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to
          ? inputs?.order_est_delivery_date_to
          : "",
        order_est_delivery_date_from: inputs?.order_est_delivery_date_from
          ? inputs?.order_est_delivery_date_from
          : "",
        order_item_job_work_issued_date_from: inputs?.job_work_issued_date_from
          ? inputs?.job_work_issued_date_from
          : "",
        order_item_job_work_issued_date_to: inputs?.job_work_issued_date_to
          ? inputs?.job_work_issued_date_to
          : "",
      };

      const params = new URLSearchParams({
        search: search_value,
        order_item_job_work_id: filter.order_item_job_work_id,
        order_no: filter.order_no,
        order_item_no: filter.order_item_no,
        order_port_no_and_name: filter.order_port_no_and_name,
        order_item_factory_name: filter.order_item_factory_name,
        order_est_delivery_date_to: filter.order_est_delivery_date_to,
        order_est_delivery_date_from: filter.order_est_delivery_date_from,
        order_item_job_work_issued_date_from:
          filter.order_item_job_work_issued_date_from,
        order_item_job_work_issued_date_to:
          filter.order_item_job_work_issued_date_to,
        page: page.toString(),
      });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.get(
        `${
          process.env.REACT_APP_URL
        }/api/list-order/job-work-issued-order-list?${params.toString()}`,
        config
      );

      dispatch({
        type: RECEIVE_ORDER_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: RECEIVE_ORDER_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

export const getReceiveOrderListForCutting =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      dispatch({
        type: RECEIVE_ORDER_LIST_REQUEST,
      });
      if (!search_value) {
        search_value = "";
      }
      if (!page) {
        page = 0;
      }

      const filter = {
        order_item_name: inputs.order_item_name || "",
        order_no: inputs.order_no || "",
        order_item_job_work_id: inputs.order_item_job_work_id || "",
        order_port_no_and_name: inputs.order_port_no_and_name || "",
        order_item_factory_name: inputs.order_item_factory_name || "",
        order_item_no: inputs.order_item_no || "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to || "",
        order_est_delivery_date_from:
          inputs?.order_est_delivery_date_from || "",
        job_work_issued_date_to: inputs?.job_work_issued_date_to || "",
        job_work_issued_date_from: inputs?.job_work_issued_date_from || "",
      };

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        order_item_name: filter.order_item_name,
        order_no: filter.order_no,
        order_item_job_work_id: filter.order_item_job_work_id,
        order_est_delivery_date_to: filter.order_est_delivery_date_to,
        order_est_delivery_date_from: filter.order_est_delivery_date_from,
        order_item_job_work_issued_date_to: filter.job_work_issued_date_to,
        order_item_job_work_issued_date_from: filter.job_work_issued_date_from,
        order_port_no_and_name: filter.order_port_no_and_name,
        order_item_factory_name: filter.order_item_factory_name,
        order_item_no: filter.order_item_no,
        page: page,
      });

      const data = await axios.get(
        `${
          process.env.REACT_APP_URL
        }/api/challan/job-work-issued-order-challan-list?${urlParams.toString()}`,
        config
      );

      dispatch({
        type: RECEIVE_ORDER_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: RECEIVE_ORDER_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

export const getQCOrderList =
  (search_value, inputs, page, type) => async (dispatch) => {
    try {
      dispatch({
        type: QC_ORDER_LIST_REQUEST,
      });

      if (!search_value) {
        search_value = "";
      }

      if (!page) {
        page = 0;
      }

      const filter = {
        order_no: inputs.order_no || "",
        order_item_job_work_id: inputs.order_item_job_work_id || "",
        order_port_no_and_name: inputs.order_port_no_and_name || "",
        order_item_factory_name: inputs.order_item_factory_name || "",
        qc_status: inputs.qc_status || "",
        order_item_status: inputs.order_item_status || "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to || "",
        order_est_delivery_date_from:
          inputs?.order_est_delivery_date_from || "",
      };

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      let data;
      const urlParams = new URLSearchParams({
        search: search_value,
        order_item_job_work_id: filter.order_item_job_work_id,
        order_no: filter.order_no,
        order_port_no_and_name: filter.order_port_no_and_name,
        order_item_factory_name: filter.order_item_factory_name,
        qc_status: filter.qc_status,
        order_item_status: filter.order_item_status,
        order_est_delivery_date_to: filter.order_est_delivery_date_to,
        order_est_delivery_date_from: filter.order_est_delivery_date_from,
        page: page,
      });

      if (type === "qcList") {
        data = await axios.get(
          `${
            process.env.REACT_APP_URL
          }/api/list-order/alteration-order-list?${urlParams.toString()}`,
          config
        );
      }
      if (type === "challanList") {
        data = await axios.get(
          `${
            process.env.REACT_APP_URL
          }/api/send-for-alteration/list-send_for_alteration?${urlParams.toString()}`,
          config
        );
      }
      if (type === "qcDone") {
        data = await axios.get(
          `${process.env.REACT_APP_URL}/api/qc/qc-list?${urlParams.toString()}`,
          config
        );
      }

      dispatch({
        type: QC_ORDER_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: QC_ORDER_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

export const getPackingOrderList =
  (search_value, inputs, page, type) => async (dispatch) => {
    console.log(inputs,'inputs');
    try {
      dispatch({
        type: PACKING_ORDER_LIST_REQUEST,
      });

      if (!search_value) {
        search_value = "";
      }

      if (!page) {
        page = 0;
      }

      const filter = {
        order_no: inputs.order_no || "",
        order_item_status: inputs.order_item_status || "",
        order_item_job_work_id: inputs.order_item_job_work_id || "",
        order_port_no_and_name: inputs.order_port_no_and_name || "",
        order_item_factory_name: inputs.order_item_factory_name || "",
        qc_status: inputs.qc_status || "",
        order_item_status: inputs.order_item_status || "",
        packing_create_date: inputs?.packing_create_date || "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to || "",
        order_est_delivery_date_from:
          inputs?.order_est_delivery_date_from || "",
      };

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      let data;
      const urlParams = new URLSearchParams({
        search: search_value,
        order_item_job_work_id: filter.order_item_job_work_id,
        order_item_status: filter.order_item_status,
        order_no: filter.order_no,
        order_port_no_and_name: filter.order_port_no_and_name,
        order_item_factory_name: filter.order_item_factory_name,
        qc_status: filter.qc_status,
        packing_create_date: filter.packing_create_date,
        order_item_status: filter.order_item_status,
        order_est_delivery_date_to: filter.order_est_delivery_date_to,
        order_est_delivery_date_from: filter.order_est_delivery_date_from,
        page: page,
      });

      if (type === "packingDone") {
        data = await axios.get(
          `${
            process.env.REACT_APP_URL
          }/api/packing/packing-done-list?${urlParams.toString()}`,
          config
        );
      }
      if (type === "packingPending") {
        data = await axios.get(
          `${
            process.env.REACT_APP_URL
          }/api/packing/packing-pending-list?${urlParams.toString()}`,
          config
        );
      }

      dispatch({
        type: PACKING_ORDER_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: PACKING_ORDER_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };
export const getShopPackingOrderList =
  (search_value, inputs, page, type) => async (dispatch) => {
    try {
      dispatch({
        type: SHOP_PACKING_ORDER_LIST_REQUEST,
      });

      if (!search_value) {
        search_value = "";
      }

      if (!page) {
        page = 0;
      }

      const filter = {
        shop_order_no: inputs.order_no || "",
        shop_order_item_size: inputs.shop_order_item_size || "",
        shop_order_item_name: inputs.shop_order_item_name || "",
        shop_order_port_no_and_name: inputs.order_port_no_and_name || "",
        shop_order_packing_create_date: inputs?.packing_create_date || "",
        shop_order_est_delivery_date_to:
          inputs?.order_est_delivery_date_to || "",
        shop_order_est_delivery_date_from:
          inputs?.order_est_delivery_date_from || "",
      };

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      let data;
      const urlParams = new URLSearchParams({
        search: search_value,
        shop_order_no: filter.shop_order_no,
        shop_order_item_name: filter.shop_order_item_name,
        shop_order_item_size: filter.shop_order_item_size,
        shop_order_port_no_and_name: filter.shop_order_port_no_and_name,
        shop_order_packing_create_date: filter.shop_order_packing_create_date,
        shop_order_est_delivery_date_to: filter.shop_order_est_delivery_date_to,
        shop_order_est_delivery_date_from:
          filter.shop_order_est_delivery_date_from,
        page: page,
      });

      if (type === "shopPackingDone") {
        data = await axios.get(
          `${
            process.env.REACT_APP_URL
          }/api/shop-packing/shop-packing-done-list?${urlParams.toString()}`,
          config
        );
      }
      if (type === "shopPackingPending") {
        data = await axios.get(
          `${
            process.env.REACT_APP_URL
          }/api/shop-packing/shop-packing-pending-list?${urlParams.toString()}`,
          config
        );
      }

      dispatch({
        type: SHOP_PACKING_ORDER_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: SHOP_PACKING_ORDER_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

// dispatch complete
export const getAllCompleteDispatch =
  (search_value, inputs, page) => async (dispatch) => {
    console.log(inputs,'inputs');
    try {
      if (!search_value) {
        search_value = "";
      }

      const filter = {
        order_item_name: inputs.order_item_name || "",
        order_no: inputs.order_no || "",
        order_item_job_work_id: inputs.order_item_job_work_id || "",
        order_port_no_and_name: inputs.order_port_no_and_name || "",
        order_item_factory_name: inputs.order_item_factory_name || "",
        order_item_status: inputs.order_item_status || "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to || "",
        order_est_delivery_date_from:
          inputs?.order_est_delivery_date_from || "",
      };

      dispatch({ type: ALL_DISPATCH_COMPLETE_LIST_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        order_item_name: filter.order_item_name,
        order_no: filter.order_no,
        order_item_job_work_id: filter.order_item_job_work_id,
        order_port_no_and_name: filter.order_port_no_and_name,
        order_est_delivery_date_to: filter.order_est_delivery_date_to,
        order_est_delivery_date_from: filter.order_est_delivery_date_from,
        order_item_factory_name: filter.order_item_factory_name,
        order_item_status: filter.order_item_status,
        page: page,
      });

      const data = await axios.get(
        `${
          process.env.REACT_APP_URL
        }/api/dispatch/list-completed?${urlParams.toString()}`,
        config
      );

      dispatch({
        type: ALL_DISPATCH_COMPLETE_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_DISPATCH_COMPLETE_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

// dispatch partial

export const getAllPartialDispatch =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }

      const filter = {
        order_item_name: inputs.order_item_name || "",
        order_no: inputs.order_no || "",
        order_item_job_work_id: inputs.order_item_job_work_id || "",
        order_port_no_and_name: inputs.order_port_no_and_name || "",
        order_item_factory_name: inputs.order_item_factory_name || "",
        order_item_status: inputs.order_item_status || "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to || "",
        order_est_delivery_date_from:
          inputs?.order_est_delivery_date_from || "",
      };

      dispatch({ type: ALL_DISPATCH_PARTIAL_LIST_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        order_item_name: filter.order_item_name,
        order_no: filter.order_no,
        order_item_job_work_id: filter.order_item_job_work_id,
        order_est_delivery_date_to: filter.order_est_delivery_date_to,
        order_est_delivery_date_from: filter.order_est_delivery_date_from,
        order_port_no_and_name: filter.order_port_no_and_name,
        order_item_factory_name: filter.order_item_factory_name,
        order_item_status: filter.order_item_status,
        page: page,
      });

      const data = await axios.get(
        `${
          process.env.REACT_APP_URL
        }/api/dispatch/list-partial?${urlParams.toString()}`,
        config
      );

      dispatch({
        type: ALL_DISPATCH_PARTIAL_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_DISPATCH_PARTIAL_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

// dispatch outForDelivery
export const getAllOutForDeliveryDispatch =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }

      const filter = {
        order_item_name: inputs.order_item_name || "",
        order_no: inputs.order_no || "",
        order_item_job_work_id: inputs.order_item_job_work_id || "",
        order_port_no_and_name: inputs.order_port_no_and_name || "",
        order_item_factory_name: inputs.order_item_factory_name || "",
        order_item_status: inputs.order_item_status || "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to || "",
        order_est_delivery_date_from:
          inputs?.order_est_delivery_date_from || "",
        dispatch_id: inputs?.dispatch_id || "",
        dispatch_shipment_no: inputs?.dispatch_shipment_no || "",
      };

      dispatch({ type: ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        order_item_name: filter.order_item_name,
        order_no: filter.order_no,
        dispatch_id: filter.dispatch_id,
        order_item_job_work_id: filter.order_item_job_work_id,
        dispatch_shipment_no: filter.dispatch_shipment_no,
        order_port_no_and_name: filter.order_port_no_and_name,
        order_est_delivery_date_to: filter.order_est_delivery_date_to,
        order_est_delivery_date_from: filter.order_est_delivery_date_from,
        order_item_factory_name: filter.order_item_factory_name,
        order_item_status: filter.order_item_status,
        page: page,
      });

      const data = await axios.get(
        `${
          process.env.REACT_APP_URL
        }/api/dispatch/get-out-for-delivery-list?${urlParams.toString()}`,
        config
      );

      dispatch({
        type: ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

// dispatch Delivered
export const getAllDeliveredDispatch =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }

      const filter = {
        order_item_name: inputs.order_item_name || "",
        order_no: inputs.order_no || "",
        order_item_job_work_id: inputs.order_item_job_work_id || "",
        order_port_no_and_name: inputs.order_port_no_and_name || "",
        order_item_factory_name: inputs.order_item_factory_name || "",
        order_item_status: inputs.order_item_status || "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to || "",
        order_est_delivery_date_from:
          inputs?.order_est_delivery_date_from || "",
        dispatch_id: inputs?.dispatch_id || "",
        dispatch_shipment_no: inputs?.dispatch_shipment_no || "",
      };

      dispatch({ type: ALL_DISPATCH_DELIVERED_LIST_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        order_item_name: filter.order_item_name,
        dispatch_id: filter.dispatch_id,
        order_no: filter.order_no,
        order_item_job_work_id: filter.order_item_job_work_id,
        order_est_delivery_date_to: filter.order_est_delivery_date_to,
        dispatch_shipment_no: filter.dispatch_shipment_no,
        order_est_delivery_date_from: filter.order_est_delivery_date_from,
        order_port_no_and_name: filter.order_port_no_and_name,
        order_item_factory_name: filter.order_item_factory_name,
        order_item_status: filter.order_item_status,
        page: page,
      });

      const data = await axios.get(
        `${
          process.env.REACT_APP_URL
        }/api/dispatch/get-delivery-list?${urlParams.toString()}`,
        config
      );

      dispatch({
        type: ALL_DISPATCH_DELIVERED_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_DISPATCH_DELIVERED_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

// Shop Orders
export const getAllShopOrders =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }
      const filter = {
        order_no: inputs.order_no ? inputs.order_no : "",
        order_port_no_and_name: inputs.order_port_no_and_name
          ? inputs.order_port_no_and_name
          : "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to
          ? inputs?.order_est_delivery_date_to
          : "",
        order_est_delivery_date_from: inputs?.order_est_delivery_date_from
          ? inputs?.order_est_delivery_date_from
          : "",
        dispatch_shipment_no: inputs?.dispatch_shipment_no
          ? inputs?.dispatch_shipment_no
          : "",
      };
      dispatch({ type: ALL_SHOP_ORDER_LIST_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/shop-order/list-shop-order?search=${search_value}&shop_order_no=${filter.order_no}&shop_order_port_no_and_name=${filter.order_port_no_and_name}&shop_order_item_est_delivery_date_to=${filter.order_est_delivery_date_to}&shop_order_item_est_delivery_date_from=${filter.order_est_delivery_date_from}&dispatch_shipment_no=${filter.dispatch_shipment_no}&page=${page}`,
        config
      );

      dispatch({
        type: ALL_SHOP_ORDER_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_SHOP_ORDER_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };
export const getAllShopOutForDeliveryDispatch =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }
      const filter = {
        order_no: inputs.order_no ? inputs.order_no : "",
        order_port_no_and_name: inputs.order_port_no_and_name
          ? inputs.order_port_no_and_name
          : "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to
          ? inputs?.order_est_delivery_date_to
          : "",
        order_est_delivery_date_from: inputs?.order_est_delivery_date_from
          ? inputs?.order_est_delivery_date_from
          : "",
        dispatch_id: inputs?.dispatch_id ? inputs?.dispatch_id : "",
        dispatch_shipment_no: inputs?.dispatch_shipment_no
          ? inputs?.dispatch_shipment_no
          : "",
      };
      dispatch({ type: ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/shop-order-dispatch/get-out-for-delivery-list?search=${search_value}&shop_order_no=${filter.order_no}&shop_order_port_no_and_name=${filter.order_port_no_and_name}&shop_order_est_delivery_date_to=${filter.order_est_delivery_date_to}&shop_order_est_delivery_date_from=${filter.order_est_delivery_date_from}&shop_order_dispatch_id=${filter.dispatch_id}&dispatch_shipment_no=${filter.dispatch_shipment_no}&page=${page}`,
        config
      );
      // console.log(data);
      dispatch({
        type: ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

export const getAllShopDeliveredDispatch =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }
      const filter = {
        order_no: inputs.order_no ? inputs.order_no : "",
        order_port_no_and_name: inputs.order_port_no_and_name
          ? inputs.order_port_no_and_name
          : "",
        order_est_delivery_date_to: inputs?.order_est_delivery_date_to
          ? inputs?.order_est_delivery_date_to
          : "",
        order_est_delivery_date_from: inputs?.order_est_delivery_date_from
          ? inputs?.order_est_delivery_date_from
          : "",
        dispatch_id: inputs?.dispatch_id ? inputs?.dispatch_id : "",
        dispatch_shipment_no: inputs?.dispatch_shipment_no
          ? inputs?.dispatch_shipment_no
          : "",
      };
      dispatch({ type: ALL_SHOP_ORDER_DELIVERED_LIST_REQUEST });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/shop-order-dispatch/get-delivery-list?search=${search_value}&shop_order_no=${filter.order_no}&shop_order_est_delivery_date_to=${filter.order_est_delivery_date_to}&shop_order_est_delivery_date_from=${filter.order_est_delivery_date_from}&shop_order_port_no_and_name=${filter.order_port_no_and_name}&shop_order_dispatch_id=${filter.dispatch_id}&dispatch_shipment_no=${filter.dispatch_shipment_no}&page=${page}`,
        config
      );
      // console.log(data);
      dispatch({
        type: ALL_SHOP_ORDER_DELIVERED_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_SHOP_ORDER_DELIVERED_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };

export const getAllOrdersCreated =
  (search_value, inputs, page) => async (dispatch) => {
    try {
      if (!search_value) {
        search_value = "";
      }

      const filter = {
        order_no: inputs.order_no || "",
        order_port_no_and_name: inputs.order_port_no_and_name || "",
        order_date: inputs.order_date || "",
        employee_id: inputs.employee_id || "",
      };

      // console.log(filter);
      dispatch({ type: ALL_ORDER_CREATED_LIST_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value,
        order_no: filter.order_no,
        order_date: filter.order_date,
        order_port_no_and_name: filter.order_port_no_and_name,
        employee_id: filter.employee_id,
        page: page,
      });

      const data = await axios.get(
        `${
          process.env.REACT_APP_URL
        }/api/order/list-created-order?${urlParams.toString()}`,
        config
      );

      // console.log(data);

      dispatch({
        type: ALL_ORDER_CREATED_LIST_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ORDER_CREATED_LIST_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };
