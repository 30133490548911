import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { getReceiveOrderList } from "app/redux/actions/orderAction";
import {
  displayDateFun
} from "app/utils/constants/functions";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReceiveOrderDetailsModal from "./receiveOrderDetails";
export default function ReceiveOrderListTable({
  searchTerm,
  setPage,
  page,
  inputs,
}) {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const { receiveOrder, TotalPage } = useSelector(
    (state) => state.orderReducer
  );
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = receiveOrder?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_sub_item_name") {
      aValue = a.order_sub_item_name.toLowerCase();
      bValue = b.order_sub_item_name.toLowerCase();
    } else if (orderBy == "order_item_factory_name") {
      aValue = a.order_item_factory_name.toLowerCase();
      bValue = b.order_item_factory_name.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getReceiveOrderList(searchTerm, inputs, newPage));
    // Replace this with your actual pagination logic
  };

  const handleCollectionDate = async (e, row) => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      await axios.patch(
        `${process.env.REACT_APP_URL}/api/production/edit-collection-date?order_items_id=${row.order_items_id}`,
        {
          order_item_collection_date: e.target.value,
        },
        config
      );
    } catch (error) {
      alert("error");
    }
  };

  const handleNavigate = (row) => {
    navigate("/dashboard/order/receiveOrder", { state: row });
  };
  const handleInfoIconClick = (rowData) => {
    setSelectedRowData(rowData);
    setInfoModalOpen(true);
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Port No & Name
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "105px",
                verticalAlign: "middle",
                px: 1,
              }}
            >
              <TableSortLabel
                active={orderBy === "order_no"}
                direction={order}
                onClick={() => handleSort("order_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Order No.
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "95px",
                verticalAlign: "middle",
                px: 1,
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_no"}
                direction={order}
                onClick={() => handleSort("order_item_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Item No.
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: "145px",
                verticalAlign: "middle",
                px: 1,
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_name"}
                direction={order}
                onClick={() => handleSort("order_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Item Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
                px: 1,
              }}
            >
              <TableSortLabel
                active={orderBy === "order_sub_item_name"}
                direction={order}
                onClick={() => handleSort("order_sub_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Sub Item Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "110px",
                verticalAlign: "middle",
                px: 1,
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_job_work_id"}
                direction={order}
                onClick={() => handleSort("order_item_job_work_id")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Job Work ID
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "125px",
                verticalAlign: "middle",
                color: "white",
                px: 1,
              }}
            >
              Order Details
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "125px",
                verticalAlign: "middle",
                px: 1,
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_factory_name"}
                direction={order}
                onClick={() => handleSort("order_item_factory_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Factory
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Status
            </TableCell>
            {permissions?.receiver_order_create == true && (
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "95px",
                  verticalAlign: "middle",
                  color: "white",
                  px: 1,
                }}
              >
                Action
              </TableCell>
            )}
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "115px",
                verticalAlign: "middle",
                color: "white",
                px: 1,
              }}
            >
              Order Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
                color: "white",
                px: 1,
              }}
            >
              Job Work Issued Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Collection Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_port_no_and_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row.order_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row.order_item_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row.order_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row.order_sub_item_name ? row.order_sub_item_name : "N/A"}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row.order_item_job_work_id}
              </TableCell>
              <TableCell sx={{ textAlign: "left", pl: "2.6%" }}>
                <InfoIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleInfoIconClick(row)}
                />
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row.order_item_factory_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_item_status}
              </TableCell>
              {permissions?.receiver_order_create == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    textDecoration: "underline",
                    cursor: "pointer",
                    px: 0.5,
                  }}
                >
                  <Button size="small" onClick={() => handleNavigate(row)}>
                    Receive
                  </Button>
                </TableCell>
              )}
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {displayDateFun(row.order_item_create_date)}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {displayDateFun(row.order_item_job_work_issued_date)}
              </TableCell>
              <TableCell>
                <TextField
                  disabled={
                    permissions?.receiver_order_edit == true ? false : true
                  }
                  defaultValue={row.order_item_collection_date}
                  size="small"
                  id="datetime-local"
                  type="datetime-local"
                  sx={{ width: 195 }}
                  onChange={(e) => {
                    handleCollectionDate(e, row);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1} // Replace with the actual total number of pages
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
      <ReceiveOrderDetailsModal
        open={isInfoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        rowData={selectedRowData}
      />
    </TableContainer>
  );
}
