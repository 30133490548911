import axios from "axios";

export const downloadShopPackingDoneReport = async (inputs) => {
  if (inputs.order_no == undefined) {
    inputs.order_no = "";
  }
  if (inputs.order_port_no_and_name == undefined) {
    inputs.order_port_no_and_name = "";
  }
  if (inputs.shop_order_item_name == undefined) {
    inputs.shop_order_item_name = "";
  }
  if (inputs.shop_order_item_size == undefined) {
    inputs.shop_order_item_size = "";
  }
  if (inputs.packing_create_date == undefined) {
    inputs.packing_create_date = "";
  }
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/shop-packing/shop-packing-done-list-excel?shop_order_no=${inputs.order_no}&shop_order_port_no_and_name=${inputs.order_port_no_and_name}&shop_order_item_size=${inputs.shop_order_item_size}&shop_order_item_name=${inputs.shop_order_item_name}&shop_order_packing_create_date=${inputs.packing_create_date}`,
      config
    );
    return data?.data?.result;
  } catch (error) {
    console.log(error);
    return error?.response?.data?.result;
  }
};
