import Div from "@jumbo/shared/Div";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { getAddonsItemNameList } from "app/services/apis/ListApi/addOnsItemNameList";
import { getGstListNoPem } from "app/services/apis/ListApi/gst";
import { getUnitListDropDown } from "app/services/apis/ListApi/unitList";
import { getRatePerMeter } from "app/services/apis/Purchase Order/getRatePerMeter";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const FinishedGoodsTable = ({ po_details }) => {
  const { state } = useLocation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [grandTotal, setGrandTotal] = useState(
    state?.grand_total ? state?.grand_total : 0
  );
  const [unitList, setUnitList] = useState([]);
  const [gstList, setGstList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);
  const navigate = useNavigate();

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const getRpmByItemName = async (e, i) => {
    // Create a copy of itemDetails to modify
    const updatedItemDetails = [...itemDetails];

    //  ratepermeter api
    const res = await getRatePerMeter(
      "",
      po_details.supplier_master_id,
      e.target.value
    );
    // console.log(res);
    if (res.length > 0) {
      // Update the corresponding field in the specific row
      updatedItemDetails[i] = {
        purchase_item_name: e.target.value,
        rate_per_unit: res[0]?.rate_per_unit,
        quantity: 0,
        unit_name: "",
      };
      setItemDetails(updatedItemDetails); // Update itemDetails
    }
  };
  const [itemDetails, setItemDetails] = useState(
    state?.purchase_items_details
      ? state?.purchase_items_details
      : [
          {
            purchase_item_name: "", // Set your initial values for each field here
            rate_per_unit: "",
            quantity: "",
            unit_master_id: "",
            amount: "",
            gst: "",
            gst_percentage: "",
            total_amount: "",
            purchase_size: null,
            cgst: "",
            sgst: "",
            igst: "",
          },
        ]
  );
  const addEmptyRow = () => {
    const emptyRow = {
      purchase_item_name: "", // Set your initial values for each field here
      rate_per_unit: "",
      quantity: "",
      unit_master_id: "",
      amount: "",
      gst: "",
      gst_percentage: "",
      total_amount: "",
      purchase_size: null,
      cgst: "",
      sgst: "",
      igst: "",
    };
    setItemDetails([...itemDetails, emptyRow]);
  };

  const handleTextFieldChange = (e, rowIndex) => {
    const { name, value } = e.target;

    // Create a copy of itemDetails to modify
    const updatedItemDetails = [...itemDetails];
    // Update the corresponding field in the specific row
    updatedItemDetails[rowIndex] = {
      ...updatedItemDetails[rowIndex],
      [name]: value,
    };
    // Update the state with the modified array
    setItemDetails(updatedItemDetails);
  };
  // console.log(itemDetails);
  const handleAmount = (rate, quantity, rowIndex, e) => {
    console.log();
    const updatedItemDetails = [...itemDetails];
    let equalValue =
      po_details?.supplier_state == "Maharashtra"
        ? updatedItemDetails[rowIndex].gst_percentage / 2
        : 0;
    // console.log(equalValue);

    // Update the corresponding field in the specific row
    updatedItemDetails[rowIndex] = {
      ...updatedItemDetails[rowIndex],
      amount: Number(rate) * Number(quantity),
      quantity: Number(quantity),
      rate_per_unit: Number(rate),
      gst:
        (Number(rate) *
          Number(quantity) *
          updatedItemDetails[rowIndex].gst_percentage) /
        100,
      gst_percentage: updatedItemDetails[rowIndex].gst_percentage,
      total_amount:
        Number(rate) * Number(quantity) +
        (Number(rate) *
          Number(quantity) *
          updatedItemDetails[rowIndex].gst_percentage) /
          100,
    };

    setItemDetails(updatedItemDetails);
    const grandTotal = updatedItemDetails?.reduce((accumulator, item) => {
      return accumulator + (parseFloat(item?.total_amount) || 0);
    }, 0);
    if (grandTotal > 0) {
      setGrandTotal(grandTotal?.toFixed(2));
    }
  };

  const handleGST = (gst_percentage, amount, rowIndex) => {
    const updatedItemDetails = [...itemDetails];
    let equalValue =
      po_details?.supplier_state == "Maharashtra" ? gst_percentage / 2 : 0;
    // console.log(equalValue);
    // console.log(updatedItemDetails[rowIndex].gst_percentage);

    // Update the corresponding field in the specific row
    updatedItemDetails[rowIndex] = {
      ...updatedItemDetails[rowIndex],
      gst: (Number(amount) * Number(gst_percentage)) / 100,
      gst_percentage: gst_percentage,
      total_amount:
        Number(amount) + (Number(amount) * Number(gst_percentage)) / 100,
      sgst: equalValue > 0 ? equalValue : 0,
      cgst: equalValue > 0 ? equalValue : 0,
      igst: equalValue == 0 ? gst_percentage : 0,
    };

    setItemDetails(updatedItemDetails);
    const grandTotal = updatedItemDetails.reduce((accumulator, item) => {
      return accumulator + (parseFloat(item?.total_amount) || 0);
    }, 0);
    if (grandTotal > 0) {
      setGrandTotal(grandTotal?.toFixed(2));
    }
  };

  const handleRemoveItem = (index) => {
    // Create a copy of itemDetails and remove the item at the specified index
    const updatedItemDetails = [...itemDetails];
    updatedItemDetails.splice(index, 1);
    setItemDetails(updatedItemDetails);
    const grandTotal = updatedItemDetails?.reduce((accumulator, item) => {
      return accumulator + (parseFloat(item?.total_amount) || 0);
    }, 0);

    if (grandTotal > 0) {
      setGrandTotal(grandTotal?.toFixed(2));
    }
  };

  useEffect(async () => {
    setUnitList(await getUnitListDropDown());
    setGstList(await getGstListNoPem());
    setItemNameList(await getAddonsItemNameList());
  }, []);

  useEffect(() => {
    localStorage.setItem("tableData", JSON.stringify(itemDetails));
    localStorage.setItem("totalAmt", grandTotal);
  }, [itemDetails]);

  return (
    <Div>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: "600" }}>
        Items Details
      </Typography>

      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",

                  color: "white",
                }}
              >
                Item Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",

                  color: "white",
                }}
              >
                Size
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",

                  color: "white",
                }}
              >
                Rate Per Unit
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",

                  color: "white",
                }}
              >
                Quantity
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",

                  color: "white",
                }}
              >
                Units
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",

                  color: "white",
                }}
              >
                Amount
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",

                  color: "white",
                }}
              >
                GST %
              </TableCell>
              {po_details?.supplier_state == "Maharashtra" ? (
                <>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      color: "white",
                    }}
                  >
                    CGST%
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      color: "white",
                    }}
                  >
                    SGST%
                  </TableCell>
                </>
              ) : (
                <TableCell
                  sx={{
                    textAlign: "left",
                    color: "white",
                  }}
                >
                  IGST%
                </TableCell>
              )}
              <TableCell
                sx={{
                  textAlign: "left",

                  color: "white",
                }}
              >
                GST Value
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",

                  color: "white",
                }}
              >
                Total Amount
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",

                  color: "white",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {itemDetails?.map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                    width: "100px",
                  }}
                >
                  <Select
                    value={row?.purchase_item_name || ""}
                    name="purchase_item_name"
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                      getRpmByItemName(e, i);
                    }}
                    size="small"
                    sx={{ width: "100px" }}
                  >
                    {itemNameList &&
                      itemNameList.length > 0 &&
                      itemNameList.map((item, index) => (
                        <MenuItem key={index} value={item?.addons_name}>
                          {item?.addons_name}
                        </MenuItem>
                      ))}
                  </Select>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                    width: "100px",
                  }}
                >
                  <TextField
                    size="small"
                    sx={{ width: "100px" }}
                    name="purchase_size"
                    value={row?.purchase_size}
                    onChange={(e) => handleTextFieldChange(e, i)}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                    width: "100px",
                  }}
                >
                  <TextField
                    type="number"
                    size="small"
                    name="rate_per_unit"
                    sx={{ width: "100px" }}
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                      handleAmount(e.target.value, row?.quantity, i, e);
                    }}
                    value={row?.rate_per_unit ? row?.rate_per_unit : ""}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                    width: "100px",
                  }}
                >
                  <TextField
                    type="number"
                    size="small"
                    sx={{ width: "110px" }}
                    name="quantity"
                    value={row?.quantity || ""}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (
                        newValue === "" ||
                        // (newValue >= 1 && !isNaN(newValue))
                        !isNaN(newValue)
                      ) {
                        handleTextFieldChange(e, i);
                        handleAmount(row?.rate_per_unit, newValue, i, e);
                      }
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                    width: "100px",
                  }}
                >
                  <Select
                    size="small"
                    sx={{ width: "100px" }}
                    name="unit_name"
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                    }}
                    value={row?.unit_name}
                  >
                    {unitList?.map((item) => {
                      return (
                        <MenuItem value={item?.unit_name}>
                          {item?.unit_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                    width: "100px",
                  }}
                >
                  <TextField
                    sx={{ width: "100px" }}
                    size="small"
                    name="amount"
                    disabled={true}
                    value={row.amount}
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                    width: "140px",
                  }}
                >
                  <Select
                    size="small"
                    name="gst_percentage"
                    sx={{ width: "100px" }}
                    value={row?.gst_percentage}
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                      handleGST(e.target.value, row.amount, i);
                    }}
                    fullWidth
                  >
                    {gstList?.map((gst) => {
                      return <MenuItem value={gst}>{gst}%</MenuItem>;
                    })}
                  </Select>
                </TableCell>
                {po_details?.supplier_state == "Maharashtra" ? (
                  <>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        width: "100px",
                      }}
                    >
                      <TextField
                        size="small"
                        sx={{ width: "100px" }}
                        name="cgst"
                        disabled={true}
                        value={row.cgst}
                        onChange={(e) => {
                          handleTextFieldChange(e, i);
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        width: "100px",
                      }}
                    >
                      <TextField
                        size="small"
                        sx={{ width: "100px" }}
                        name="sgst"
                        disabled={true}
                        value={row?.sgst}
                        onChange={(e) => {
                          handleTextFieldChange(e, i);
                        }}
                      />
                    </TableCell>
                  </>
                ) : (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontWeight: "600",
                      width: "100px",
                    }}
                  >
                    <TextField
                      size="small"
                      sx={{ width: "100px" }}
                      name="igst"
                      disabled={true}
                      value={row.igst}
                      onChange={(e) => {
                        handleTextFieldChange(e, i);
                      }}
                    />
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                    width: "100px",
                  }}
                >
                  <TextField
                    sx={{ width: "100px" }}
                    name="gst"
                    size="small"
                    disabled={true}
                    value={row?.gst}
                    onChange={(e) => {
                      handleTextFieldChange(e, i);
                    }}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                    width: "100px",
                  }}
                >
                  <TextField
                    sx={{ width: "100px" }}
                    size="small"
                    name="total_amount"
                    disabled={true}
                    value={row?.total_amount}
                  />
                </TableCell>

                <TableCell>
                  <DeleteIcon
                    sx={{ width: "50px", cursor: "pointer", color: "red" }}
                    onClick={() => handleRemoveItem(i)}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                colSpan={po_details?.supplier_state == "Maharashtra" ? 10 : 9}
                sx={{
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                Grand Total
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  fontWeight: "600",
                  pl: 4,
                }}
              >
                {`₹${grandTotal}`}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h3" sx={{ mt: 2 }}>
        (Above Prices are Exclusive of Discount)
      </Typography>
      <Button variant="contained" onClick={addEmptyRow} sx={{ mt: 2 }}>
        Add More Item
      </Button>
    </Div>
  );
};

export default FinishedGoodsTable;
