import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { currentdate, dateFun } from "app/utils/constants/functions";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CompleteTable from "../Tables/complete";
import PartialTable from "../Tables/partial";
import OutForDeliveryTable from "../Tables/outForDelivery";
import DeliveryTable from "../Tables/delivered";
import { getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import { getOrderNoList } from "app/services/apis/ListApi/orderNoList";
import { getjobWorkIdList } from "app/services/apis/ListApi/jobWorkIdList";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";
import { getQcOrderList } from "app/services/apis/ListApi/qcOrderStatusList";
import {
  getAllCompleteDispatch,
  getAllDeliveredDispatch,
  getAllOutForDeliveryDispatch,
  getAllPartialDispatch,
  getAllShopDeliveredDispatch,
  getAllShopOrders,
  getAllShopOutForDeliveryDispatch,
} from "app/redux/actions/orderAction";
import { useDispatch } from "react-redux";
import { getDispatchIdList } from "app/services/apis/ListApi/dispatchIdList";
import ShopOrderTable from "../Shop Order Table/shopOrderTable";
import ShopOutForDeliveryTable from "../Shop Order Table/shopOutForDelivery";
import ShopDeliveryTable from "../Shop Order Table/shopDelivered";
import { getShipmentNo } from "app/services/apis/ListApi/shipmentNoList";
import { downloadOutForDeliveryReport } from "app/services/apis/ReportApis/Dispatch/OutForDeliveryReports";
import { downloadDeliveredReport } from "app/services/apis/ReportApis/Dispatch/DeliveredReports";
import { getShopOrderShipmentNo } from "app/services/apis/ListApi/shopOrderShipmentNo";
import { downloadPartialReport } from "app/services/apis/ReportApis/Dispatch/PartialReports";
import { downloadCompleteReport } from "app/services/apis/ReportApis/Dispatch/CompleteReports";
import { downloadShopOrderReport } from "app/services/apis/ReportApis/ShopOrder/OrderReport";
import { downloadShopOrderDeliveredReport } from "app/services/apis/ReportApis/ShopOrder/DeliveredReport";
import { downloadShopOrderOutForDeliveryReport } from "app/services/apis/ReportApis/ShopOrder/OutForDeliveryReport";

const DispatchOrderList = () => {
  const [inputs, setInputs] = useState({});
  console.log(inputs,'inputs');
  const [searchTerm, setSearchTerm] = useState("");
  const [nav, setNav] = useState("Complete");
  const [value, setValue] = React.useState(0);
  const [portNoNameList, setPortNoNameList] = useState([]);
  const [orderNoList, setOrderNoList] = useState([]);
  const [jobWorkidList, setJobWorkidList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [qcStatusList, setQcStatusList] = useState([]);
  const [dispatchIdList, setDispatchIdList] = useState([]);
  const [shipmentNoList, setShipmentNoList] = useState([]);

  const [orderType, setOrderType] = useState("Normal Order");
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFilter = () => {
    setPage(1);
    if (nav == "Complete") {
      dispatch(getAllCompleteDispatch("", inputs, 1));
    } else if (nav == "Partial") {
      dispatch(getAllPartialDispatch("", inputs, 1));
    } else if (nav == "Out For Delivery") {
      dispatch(getAllOutForDeliveryDispatch("", inputs, 1));
    } else if (nav == "Delivered") {
      dispatch(getAllDeliveredDispatch("", inputs, 1));
    } else if (nav == "Shop Order") {
      dispatch(getAllShopOrders("", inputs, 1));
    } else if (nav == "Shop Out For Delivery") {
      dispatch(getAllShopOutForDeliveryDispatch("", inputs, 1));
    } else if (nav == "Shop Delivered") {
      dispatch(getAllShopDeliveredDispatch("", inputs, 1));
    }
  };

  const handleClear = () => {
    setPage(1);
    if (nav == "Complete") {
      dispatch(getAllCompleteDispatch("", "", 1));
    } else if (nav == "Partial") {
      dispatch(getAllPartialDispatch("", "", 1));
    } else if (nav == "Out For Delivery") {
      dispatch(getAllOutForDeliveryDispatch("", "", 1));
    } else if (nav == "Delivered") {
      dispatch(getAllDeliveredDispatch("", "", 1));
    } else if (nav == "Shop Order") {
      dispatch(getAllShopOrders("", "", 1));
    } else if (nav == "Shop Out For Delivery") {
      dispatch(getAllShopOutForDeliveryDispatch("", "", 1));
    } else if (nav == "Shop Delivered") {
      dispatch(getAllShopDeliveredDispatch("", "", 1));
    }
    setInputs({
      order_port_no_and_name: null,
      order_item_job_work_id: null,
      order_item_factory_name: null,
      order_item_status: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
      dispatch_shipment_no: null,
    });
    setResetAutocomplete((prev) => !prev);
  };

  const handleSearch = (value) => {
    if (nav == "Complete") {
      dispatch(getAllCompleteDispatch(value, "", ""));
    } else if (nav == "Partial") {
      dispatch(getAllPartialDispatch(value, "", ""));
    } else if (nav == "Out For Delivery") {
      dispatch(getAllOutForDeliveryDispatch(value, "", ""));
    } else if (nav == "Delivered") {
      dispatch(getAllDeliveredDispatch(value, "", ""));
    } else if (nav == "Shop Order") {
      dispatch(getAllShopOrders(value, "", ""));
    } else if (nav == "Shop Order") {
      dispatch(getAllShopOrders(value, "", ""));
    } else if (nav == "Shop Out For Delivery") {
      dispatch(getAllShopOutForDeliveryDispatch(value, "", ""));
    } else if (nav == "Shop Delivered") {
      dispatch(getAllShopDeliveredDispatch(value, "", ""));
    }
  };

  useEffect(() => {
    if (nav == "Complete") {
      dispatch(getAllCompleteDispatch("", "", 1));
    } else if (nav == "Partial") {
      dispatch(getAllPartialDispatch("", "", 1));
    } else if (nav == "Out For Delivery") {
      dispatch(getAllOutForDeliveryDispatch("", "", 1));
    } else if (nav == "Delivered") {
      dispatch(getAllDeliveredDispatch("", "", 1));
    } else if (nav == "Shop Order") {
      dispatch(getAllShopOrders("", "", 1));
    } else if (nav == "Shop Out For Delivery") {
      dispatch(getAllShopOutForDeliveryDispatch("", "", "", 1));
    } else if (nav == "Shop Delivered") {
      dispatch(getAllShopDeliveredDispatch("", "", "", 1));
    }
    setInputs({
      order_port_no_and_name: null,
      order_item_job_work_id: null,
      order_item_factory_name: null,
      order_item_status: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
    });
    setResetAutocomplete((prev) => !prev);
  }, [nav]);

  // navs and tab functionality
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    setPage(1);
    setValue(newValue);
  };

  useEffect(async () => {
    setPortNoNameList(await getPortListNoPerm());
    setJobWorkidList(await getjobWorkIdList());
    setFactoryList(await getFactoryNameList());
    setQcStatusList(await getQcOrderList());
    if (orderType == "Normal Order") {
      setShipmentNoList(await getShipmentNo());
    } else {
      setShipmentNoList(await getShopOrderShipmentNo());
    }
  }, [orderType]);

  useEffect(async () => {
    console.log(orderType,'orderTypeorderType');
    if (orderType == "Normal Order") {
      setOrderNoList(await getOrderNoList());
      setDispatchIdList(await getDispatchIdList());
    } else {
      setDispatchIdList(await getDispatchIdList("shop"));
      setOrderNoList(await getOrderNoList("shop"));
    }
    setInputs({
      order_port_no_and_name: null,
      order_item_job_work_id: null,
      order_item_factory_name: null,
      order_item_status: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
    });
    setResetAutocomplete((prev) => !prev);
  }, [orderType]);
  return (
    <Div>
      <Typography variant="h1" sx={{ mt: -4 }}>
        Dispatch Order
      </Typography>

      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Port No & Name</Typography>
          <Autocomplete
            freeSolo
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={portNoNameList || []}
            value={inputs.order_port_no_and_name}
            getOptionLabel={(option) => option}
            onInputChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_port_no_and_name: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>

        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Order No</Typography>
          <Autocomplete
            freeSolo
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={orderNoList || []}
            value={inputs.order_no}
            getOptionLabel={(option) => option}
            onInputChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_no: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>

        {orderType != "Shop Order" && (
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Job Work ID</Typography>
            <Autocomplete
              freeSolo
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={jobWorkidList || []}
              value={inputs.order_item_job_work_id}
              getOptionLabel={(option) => option}
              onInputChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_item_job_work_id: newValue ? newValue : null,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>
        )}
        {nav != "Out For Delivery" &&
          nav != "Delivered" &&
          orderType != "Shop Order" && (
            <Div sx={{ width: "23%" }}>
              <Typography variant="h5">Factory</Typography>
              <Autocomplete
                freeSolo
                key={resetAutocomplete ? "reset1" : "normal1"}
                size="small"
                id="tags-standard"
                options={factoryList || []}
                defaultValue={inputs.order_item_factory_name}
                getOptionLabel={(option) => option?.factory_name}
                onInputChange={(e, newValue) => {
                  setInputs((values) => ({
                    ...values,
                    order_item_factory_name: newValue?.factory_name
                      ? newValue?.factory_name
                      : null,
                  }));
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.factory_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </Div>
          )}
        {orderType != "Shop Order" && nav != "Delivered" && (
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Status</Typography>
            <Select
              size="small"
              sx={{ width: "100%" }}
              name="order_item_status"
              value={inputs.order_item_status || ""}
              onChange={handleChange}
            >
              {qcStatusList?.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Div>
        )}
        {(nav == "Out For Delivery" ||
          nav == "Delivered" ||
          nav == "Shop Out For Delivery" ||
          nav == "Shop Delivered") && (
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Dispatch Id</Typography>
            <Autocomplete
              freeSolo
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={dispatchIdList || []}
              value={inputs.dispatch_id}
              getOptionLabel={(option) => option}
              onInputChange={(e, newValue) => {
                inputs.dispatch_id = newValue ? newValue : null; // Set to null when newValue is null
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>
        )}
        {(nav == "Out For Delivery" ||
          nav == "Delivered" ||
          orderType == "Shop Order") && (
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Shipment No</Typography>
            <Autocomplete
              freeSolo
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="dispatch_shipment_no"
              options={shipmentNoList?.result || []}
              value={inputs.dispatch_shipment_no}
              getOptionLabel={(option) => option}
              onInputChange={(e, newValue) => {
                inputs.dispatch_shipment_no = newValue ? newValue : null; // Set to null when newValue is null
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>
        )}

        <Div>
          <Typography variant="h5">Delivery Date Range</Typography>
          <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "45%" }}>
                <DatePicker
                  // maxDate={dayjs()}
                  key={resetAutocomplete ? "reset1" : "normal1"}
                  format="DD/MM/YY"
                  value={inputs?.order_est_delivery_date_from}
                  error={!inputs?.order_est_delivery_date_from}
                  onChange={(newDate) =>
                    (inputs.order_est_delivery_date_from = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "45%" }}>
                <DatePicker
                  // maxDate={dayjs()}
                  key={resetAutocomplete ? "reset1" : "normal1"}
                  format="DD/MM/YY"
                  value={inputs?.order_est_delivery_date_to}
                  error={!inputs?.order_est_delivery_date_to}
                  onChange={(newDate) =>
                    (inputs.order_est_delivery_date_to = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
          </Div>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center", mt: 4 }}>
        <Button
          size="small"
          variant="outlined"
          sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
          onClick={handleFilter}
        >
          Apply
        </Button>
        <Button
          size="small"
          variant="outlined"
          sx={{ p: 1, pl: 4, pr: 4 }}
          onClick={handleClear}
        >
          Clear
        </Button>
      </Div>

      {/* search */}
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          mt: 3.5,
        }}
      >
        <Div sx={{ display: "flex", columnGap: 1 }}>
          <Div>
            <TextField
              width="23px"
              size="small"
              id="search"
              type="search"
              label="Search"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handleSearch(e.target.value);
              }}
              sx={{ width: 300, mt: "auto" }}
              InputProps={{
                endAdornment: (
                  <Div sx={{ cursor: "pointer" }}>
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  </Div>
                ),
              }}
            />
          </Div>
          <Div sx={{ width: "30%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Order Type</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  setOrderType(e.target.value);
                  if (e.target.value == "Normal Order") {
                    setNav("Complete");
                    setValue(0);
                  } else {
                    setNav("Shop Order");
                    setValue(0);
                  }
                }}
                value={orderType}
                label="Order Type"
                size="small"
              >
                <MenuItem value={"Normal Order"}>{"Normal Order"}</MenuItem>
                <MenuItem value={"Shop Order"}>{"Shop Order"}</MenuItem>
              </Select>
            </FormControl>
          </Div>
        </Div>
        {orderType == "Normal Order" ? (
          <Div sx={{ display: "flex", gap: 2 }}>
            {(nav == "Complete" ||
              nav == "Partial" ||
              nav == "Out For Delivery" ||
              nav == "Delivered") && (
              <Button
                // disabled={qcOrder?.length == 0}
                variant="contained"
                onClick={async () => {
                  if (nav == "Complete") {
                    const data = await downloadCompleteReport(inputs);
                    window.open(data);
                  }
                  if (nav == "Partial") {
                    const data = await downloadPartialReport(inputs);
                    window.open(data);
                  }
                  if (nav == "Out For Delivery") {
                    const data = await downloadOutForDeliveryReport(inputs);
                    window.open(data);
                  }
                  if (nav == "Delivered") {
                    const data = await downloadDeliveredReport(inputs);
                    window.open(data);
                  }
                }}
              >
                Export CSV
              </Button>
            )}
            {permissions?.dispatch_order_create == true && (
              <Div>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ p: 1, pl: 4, pr: 4 }}
                  onClick={() => navigate("/dashboard/order/createDispatch")}
                >
                  Create New Dispatch
                </Button>
              </Div>
            )}
          </Div>
        ) : (
          <Div sx={{ display: "flex", gap: 2 }}>
            {(nav == "Shop Order" ||
              nav == "Shop Out For Delivery" ||
              nav == "Shop Delivered") && (
              <Button
                // disabled={qcOrder?.length == 0}
                variant="contained"
                onClick={async () => {
                  if (nav == "Shop Order") {
                    const data = await downloadShopOrderReport(inputs);
                    window.open(data);
                  }

                  if (nav == "Shop Out For Delivery") {
                    const data = await downloadShopOrderOutForDeliveryReport(
                      inputs
                    );
                    window.open(data);
                  }
                  if (nav == "Shop Delivered") {
                    const data = await downloadShopOrderDeliveredReport(inputs);
                    window.open(data);
                  }
                }}
              >
                Export CSV
              </Button>
            )}
            {permissions?.dispatch_order_create == true && (
              <Div>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ p: 1, pl: 4, pr: 4 }}
                  onClick={() => navigate("/dashboard/order/createDispatch")}
                >
                  Create New Dispatch
                </Button>
              </Div>
            )}
          </Div>
        )}
      </Div>
      {orderType == "Normal Order" ? (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab
                label="Complete"
                {...a11yProps(0)}
                onClick={() => setNav("Complete")}
              />
              <Tab
                label="Partial"
                {...a11yProps(1)}
                onClick={() => setNav("Partial")}
              />
              <Tab
                label="Out For Delivery"
                {...a11yProps(2)}
                onClick={() => setNav("Out For Delivery")}
              />
              <Tab
                label="Delivered"
                {...a11yProps(3)}
                onClick={() => setNav("Delivered")}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <CompleteTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
              inputs={inputs}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <PartialTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
              inputs={inputs}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <OutForDeliveryTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
              inputs={inputs}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <DeliveryTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
              inputs={inputs}
            />
          </CustomTabPanel>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab
                label="Order"
                {...a11yProps(0)}
                onClick={() => setNav("Shop Order")}
              />
              <Tab
                label="Out For Delivery"
                {...a11yProps(1)}
                onClick={() => setNav("Shop Out For Delivery")}
              />
              <Tab
                label="Delivered"
                {...a11yProps(2)}
                onClick={() => setNav("Shop Delivered")}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <ShopOrderTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
              inputs={inputs}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ShopOutForDeliveryTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
              inputs={inputs}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ShopDeliveryTable
              searchTerm={searchTerm}
              setPage={setPage}
              page={page}
              inputs={inputs}
            />
          </CustomTabPanel>
        </Box>
      )}
    </Div>
  );
};

export default DispatchOrderList;
