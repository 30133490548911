import Div from "@jumbo/shared/Div";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./QcImagesCarousel.css"; // Import a custom CSS file for styling
import { GlassMagnifier } from "react-image-magnifiers";

const QcImagesModal = ({ open, onClose, rowData, type }) => {
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "100%",
          maxHeight: "100%"
        }
      }}
      open={open}
      onClose={onClose}
      maxWidth="xl" // Adjust maxWidth as needed
      // fullWidth={false} // Set fullWidth to false
      sx={{ mx: "auto", display: "flex", flexDirection: "column" }} // Centering the dialog content
    >
      {/* {console.log(rowData, "jefsefef")} */}
      <DialogTitle sx={{ fontWeight: "700", alignSelf: "center" }}>Images</DialogTitle>
      <DialogContent sx={{ flex: "1", display: "flex", justifyContent: "center" }}>
        <Div
          sx={{ display: "flex", flexWrap: "wrap", columnGap: 4, rowGap: 2 }}
        >
          <Carousel width={700} showThumbs={false} className="custom-carousel">
            {rowData == null
              ? []
              : (type == "image"
                  ? rowData?.order_item_image
                  : rowData?.order_item_catalogue_image
                )?.map((imageUrl, index) => (
                  <Div
                    key={index}
                    sx={{
                      objectFit: "contain",
                      height: "100vh",
                      width: "70%",
                    }}
                  >
                    <GlassMagnifier
                      height={"50%"}
                      width={"50%"}
                      magnifierSize={300}
                      imageSrc={`${process.env.REACT_APP_URL}/${imageUrl}`}
                    />
                  </Div>
                ))}
          </Carousel>
        </Div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QcImagesModal;
