import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

const ReceiveOrderDetailsModal = ({ open, onClose, rowData }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      {/* {console.log(rowData)} */}
      <DialogTitle sx={{ fontWeight: "700" }}>Order Details</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item No.
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "140px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                SubItem Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Code
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Qty
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Image
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{rowData?.order_item_no}</TableCell>
              <TableCell>{rowData?.order_item_name}</TableCell>
              <TableCell>
                {rowData?.order_sub_item_name == ""
                  ? "-"
                  : rowData?.order_sub_item_name}
              </TableCell>
              <TableCell>{rowData?.order_item_kc_fabric_code}</TableCell>
              <TableCell>{rowData?.order_item_fabric_required}</TableCell>

              <TableCell>
                {/* {console.log(rowData)} */}
                <img
                  width={"100px"}
                  height={"100px"}
                  src={`${process.env.REACT_APP_URL}/${
                    rowData?.fabric_image != null ? rowData?.fabric_image : ""
                  }`} // Replace 'image_url' with the actual field containing the image URL in your data
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {rowData?.order_item_addOns && (
          <>
            <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
              Addons:
            </Typography>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Send To Factory
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData?.order_item_addOns?.map((row, i) => (
                  <>
                    <TableRow key={i}>
                      <TableCell>{row?.itemName}</TableCell>
                      <TableCell>{row?.quantity}</TableCell>
                      <TableCell>{row?.status}</TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </>
        )}

        {rowData?.addons_items?.length > 0 && (
          <>
            <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
              Addons:
            </Typography>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Send To Factory
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData?.addons_items?.map((row, i) => (
                  <>
                    <TableRow key={i}>
                      <TableCell>{row?.itemName}</TableCell>
                      <TableCell>{row?.addons_quantity}</TableCell>
                      <TableCell>{row?.addons_status}</TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReceiveOrderDetailsModal;
