import {
  ALL_COSTING_MASTER_SUCCESS,
  ALL_PRODUCT_MASTER_SUCCESS,
  ALL_SUPPLIER_MASTER_SUCCESS,
  LOAD_COSTING_MASTER_FAIL,
  LOAD_COSTING_MASTER_REQUEST,
  LOAD_PRODUCT_MASTER_FAIL,
  LOAD_PRODUCT_MASTER_REQUEST,
  ALL_ADD_ON_MASTER_FAIL,
  ALL_ADD_ON_MASTER_REQUEST,
  ALL_ADD_ON_MASTER_SUCCESS,
  ALL_FACTORY_MASTER_FAIL,
  ALL_FACTORY_MASTER_REQUEST,
  ALL_FACTORY_MASTER_SUCCESS,
  ALL_PORT_MASTER_FAIL,
  ALL_PORT_MASTER_REQUEST,
  ALL_PORT_MASTER_SUCCESS,
  ALL_UNIT_MASTER_FAIL,
  ALL_UNIT_MASTER_REQUEST,
  ALL_UNIT_MASTER_SUCCESS,
  LOAD_SUPPLIER_MASTER_FAIL,
  LOAD_SUPPLIER_MASTER_REQUEST,
  ALL_FABRIC_MASTER_REQUEST,
  ALL_FABRIC_MASTER_SUCCESS,
  ALL_FABRIC_MASTER_FAIL,
  ALL_GST_MASTER_REQUEST,
  ALL_GST_MASTER_SUCCESS,
  ALL_GST_MASTER_FAIL,
  ALL_FABRIC_COLOR_MASTER_REQUEST,
  ALL_FABRIC_COLOR_MASTER_SUCCESS,
  ALL_FABRIC_COLOR_MASTER_FAIL,
} from "app/utils/constants/masterConstants";

const INIT_STATE = {
  loading: false,
  error: null,
  supplierMaster: [],
  costingMaster: [],
  productMaster: [],
  portMaster: [],
  addOnMaster: [],
  unitMaster: [],
  gstMaster: [],
  fabricMaster: [],
  fabricColorMaster: [],
  TotalPage: null,
};

export const masterReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_SUPPLIER_MASTER_REQUEST:
      return {
        loading: true,
      };
    case ALL_SUPPLIER_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case LOAD_SUPPLIER_MASTER_FAIL:
      return {
        loading: false,
        supplierMaster: [],
        error: action.payload,
      };

    case LOAD_COSTING_MASTER_REQUEST:
      return {
        loading: true,
      };
    case ALL_COSTING_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        costingMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case LOAD_COSTING_MASTER_FAIL:
      return {
        loading: false,
        costingMaster: [],
        error: action.payload,
      };

    case LOAD_PRODUCT_MASTER_REQUEST:
      return {
        loading: true,
      };
    case ALL_PRODUCT_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        productMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case LOAD_PRODUCT_MASTER_FAIL:
      return {
        loading: false,
        productMaster: [],
        error: action.payload,
      };

    //port master
    case ALL_PORT_MASTER_REQUEST:
      return {
        loading: true,
      };
    case ALL_PORT_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        portMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_PORT_MASTER_FAIL:
      return {
        loading: false,
        portMaster: [],
        error: action.payload,
      };
    //add-on master
    case ALL_ADD_ON_MASTER_REQUEST:
      return {
        loading: true,
      };
    case ALL_ADD_ON_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        addOnMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ADD_ON_MASTER_FAIL:
      return {
        loading: false,
        addOnMaster: [],
        error: action.payload,
      };

    //unit master
    case ALL_UNIT_MASTER_REQUEST:
      return {
        loading: true,
      };
    case ALL_UNIT_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        unitMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_UNIT_MASTER_FAIL:
      return {
        loading: false,
        unitMaster: [],
        error: action.payload,
      };

    //gst master
    case ALL_GST_MASTER_REQUEST:
      return {
        loading: true,
      };
    case ALL_GST_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        gstMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_GST_MASTER_FAIL:
      return {
        loading: false,
        gstMaster: [],
        error: action.payload,
      };

    //fabricColor master
    case ALL_FABRIC_COLOR_MASTER_REQUEST:
      return {
        loading: true,
      };
    case ALL_FABRIC_COLOR_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        fabricColorMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_FABRIC_COLOR_MASTER_FAIL:
      return {
        loading: false,
        fabricColorMaster: [],
        error: action.payload,
      };

    //factory master
    case ALL_FACTORY_MASTER_REQUEST:
      return {
        loading: true,
      };
    case ALL_FACTORY_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        factoryMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_FACTORY_MASTER_FAIL:
      return {
        loading: false,
        factoryMaster: [],
        error: action.payload,
      };

    //fabric master
    case ALL_FABRIC_MASTER_REQUEST:
      return {
        loading: true,
      };
    case ALL_FABRIC_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        fabricMaster: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_FABRIC_MASTER_FAIL:
      return {
        loading: false,
        fabricMaster: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
