import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PrintIcon from "@mui/icons-material/Print";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { getAllCosting } from "app/redux/actions/masterAction";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./costing.css";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function ListCostingTable({ searchTerm, setPage, page }) {
  const { costingMaster, TotalPage ,loading} = useSelector(
    (state) => state.masterReducer
  );
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = costingMaster?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "kc_fabric_code") {
      aValue = a.kc_fabric_code.toLowerCase();
      bValue = b.kc_fabric_code.toLowerCase();
    } else if (orderBy == "fabric_master.supplier_fabric_code") {
      aValue = a.fabric_master.supplier_fabric_code.toLowerCase();
      bValue = b.fabric_master.supplier_fabric_code.toLowerCase();
    } else if (orderBy == "supplier_city") {
      aValue = a.supplier_city.toLowerCase();
      bValue = b.supplier_city.toLowerCase();
    } else if (orderBy == "supplier_state") {
      aValue = a.supplier_state.toLowerCase();
      bValue = b.supplier_state.toLowerCase();
    } else if (orderBy == "supplier_contact_person_name") {
      aValue = a.supplier_contact_person_name.toLowerCase();
      bValue = b.supplier_contact_person_name.toLowerCase();
    } else if (orderBy == "email_id") {
      aValue = a.email_id.toLowerCase();
      bValue = b.email_id.toLowerCase();
    } else if (orderBy == "supplier_pan_no") {
      aValue = a.supplier_pan_no.toLowerCase();
      bValue = b.supplier_pan_no.toLowerCase();
    } else if (orderBy == "supplier_gst_no") {
      aValue = a.supplier_gst_no.toLowerCase();
      bValue = b.supplier_gst_no.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/master/costing/edit", { state: menuItem.data });
        break;
      case "printTag":
        printKCTag(menuItem.data);
        break;
    }
  };

  const printKCTag = (data) => {
    
          navigate("/dashboard/master/costing/printtag", {
            state: {
              data: data,
            },
          });
   
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllCosting(searchTerm, newPage));
  };

  return (
    <>      {loading && <FullScreenLoader />}
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell>
              <TableSortLabel
                active={orderBy === "kc_fabric_code"}
                direction={order}
                onClick={() => handleSort("kc_fabric_code")}
                sx={{
                  //   maxWidth: "70px",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Kennys Fabric Code
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "100px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === "fabric_master.supplier_fabric_code"}
                direction={order}
                onClick={() => handleSort("fabric_master.supplier_fabric_code")}
                sx={{
                  //   maxWidth: "70px",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Supplier Fabric Code
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "mrp"}
                direction={order}
                onClick={() => handleSort("mrp")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                MRP
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "100px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "cost"}
                direction={order}
                onClick={() => handleSort("cost")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Cost
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "job_work_cost"}
                direction={order}
                onClick={() => handleSort("job_work_cost")}
                sx={{
                  minWidth: "60px",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Job Work Cost
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",

                maxWidth: "180px",
              }}
            >
              <TableSortLabel
                active={orderBy === "freight"}
                direction={order}
                onClick={() => handleSort("freight")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                  maxWidth: "180px",
                }}
              >
                Freight
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",

                maxWidth: "150px",
              }}
            >
              <TableSortLabel
                active={orderBy === "profit_percentage"}
                direction={order}
                onClick={() => handleSort("profit_percentage")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                  maxWidth: "150px",
                }}
              >
                Profit Percent
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "usd_rate"}
                direction={order}
                onClick={() => handleSort("usd_rate")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                USD Rate
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "usd_sale_price"}
                direction={order}
                onClick={() => handleSort("usd_sale_price")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                USD Sale Price
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Color Code
            </TableCell>
            {permissions?.catalogue_master_edit == true && (
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>
                {row.kc_fabric_code}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.fabric_master.supplier_fabric_code}
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "180px",
                  wordWrap: "break-word",
                }}
              >
                {row.mrp}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {row.cost}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.job_work_cost}
              </TableCell>
              <TableCell>{row.freight}</TableCell>

              <TableCell sx={{ textAlign: "left" }}>
                {row.profit_percentage}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>{row.usd_rate}</TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "120px",
                  wordWrap: "break-word",
                }}
              >
                {row.usd_sale_price}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.fabric_master.fabric_color
                  .map((item) => {
                    return item;
                  })
                  .join(", ")}
              </TableCell>
              {permissions?.catalogue_master_edit == true && (
                <TableCell sx={{ textAlign: "left" }}>
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={[
                      {
                        icon: <EditIcon />,
                        title: "Edit Costing Details",
                        action: "edit",
                        data: row,
                      },
                      {
                        icon: <PrintIcon />,
                        title: "Print Tag",
                        action: "printTag",
                        data: row,
                      },
                    ]}
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1}
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
    </TableContainer>
    </>
  );
}
