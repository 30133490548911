import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ShopOrderDetailTable from "./shopOrderDetailsTable";
import { useEffect } from "react";
import axios from "axios";

const ShopOrderDetailInfoModal = ({
  open,
  onClose,
  rowData,
  type,
  renderApi,
  setRenderApi,
}) => {
  const [data, setData] = useState([]);
  useEffect(async () => {
    if (type == "shopOrder") {
      try {
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };

        const data = await axios.get(
          `${process.env.REACT_APP_URL}/api/shop-order/dispatch-list-based-on-item_id?id=${rowData?.shop_order_items_id}`,
          config
        );
        setData(data.data.result);
      } catch (error) {
        // console.log(error);
      }
    } else {
      setData(rowData);
    }
  }, [renderApi, rowData]);
  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <DialogTitle sx={{ fontWeight: "700" }}>Dispatch Details</DialogTitle>
      <DialogContent>
        <ShopOrderDetailTable itemDetails={data} type={type} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            if (type == "shopOrder") {
              setRenderApi(!renderApi);
            }
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShopOrderDetailInfoModal;
