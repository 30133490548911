import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Checkbox,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { getShopPackingOrderList } from "app/redux/actions/orderAction";
import { updateShopPackingStatus } from "app/services/apis/updatePackingStatus";
import { displayDateFun } from "app/utils/constants/functions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import FullScreenLoader from "app/components/ListingPageLoader";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";

const ShopPackingPendingListTable = ({ searchTerm, setPage, page, inputs }) => {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [addPacking, setAddPacking] = useState([]);
  const { shopPacking, TotalPage, loading } = useSelector(
    (state) => state.orderReducer
  );
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Swal = useSwalWrapper();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // Replace this with your actual pagination logic
    dispatch(
      getShopPackingOrderList(searchTerm, inputs, newPage, "shopPackingPending")
    );
  };
  useEffect(() => {
    // Load state from localStorage when component mounts
    const savedGroup = localStorage.getItem("addShopPacking");
    if (savedGroup) {
      setAddPacking(JSON.parse(savedGroup));
    }
  }, []);

  useEffect(() => {
    // Save state to localStorage whenever addPacking changes
    localStorage.setItem("addShopPacking", JSON.stringify(addPacking));
  }, [addPacking]);

  // const handleItemAction = async (menuItem) => {
  //   const data = {
  //     shop_order_items_id: menuItem.data.shop_order_items_id,
  //     packing_status: 1,
  //   };

  //   switch (menuItem.action) {
  //     case "changeStatus":
  //       // Show confirmation dialog
  //       const confirmation = await Swal.fire({
  //         title: "Are you sure?",
  //         text: "Do you want to change the shopPacking status?",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonText: "Yes, change it!",
  //         cancelButtonText: "No, cancel!",
  //       });

  //       if (confirmation.isConfirmed) {
  //         // If user confirms, proceed with updating the shopPacking status
  //         const res = await updateShopPackingStatus(data);

  //         if (res?.status == 200) {
  //           Swal.fire({ icon: "success", title: "shopPacking Done successfully" });
  //           dispatch(
  //             getShopPackingOrderList(
  //               searchTerm,
  //               inputs,
  //               1,
  //               "shopPackingPending"
  //             )
  //           );
  //           navigate("/dashboard/order/packing");
  //         } else {
  //           Swal.fire({ icon: "error", title: res.data.message });
  //         }
  //       }
  //       break;
  //     // Add other cases if needed
  //   }
  // };
  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = shopPacking?.sort((a, b) => {
    let aValue;
    let bValue;
    if (orderBy == "shop_order_item_name") {
      aValue = a.shop_order_item_name.toLowerCase();
      bValue = b.shop_order_item_name.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });
  const handleCheckbox = (e, row) => {
    if (e.target.checked == true) {
      const selectedArr = [...addPacking];
      const data = {
        shop_order_items_id: row.shop_order_items_id,
      };
      selectedArr.push(data);
      setAddPacking(selectedArr);
    } else {
      const selectedArr = [...addPacking];
      const deletedIds = selectedArr.filter(
        (ele) => ele.shop_order_items_id != row?.shop_order_items_id
      );
      setAddPacking(deletedIds);
    }
  };

  const handleAddPacking = async () => {
    try {
      setIsLoading(true);

      const data = { item_details: addPacking };

      const res = await updateShopPackingStatus(data);
      console.log(res, "res");
      if (res?.status === 200) {
        Swal.fire({ icon: "success", title: "Packing Done successfully" });
        dispatch(
          getShopPackingOrderList(searchTerm, inputs, 1, "shopPackingPending")
        );
        setAddPacking([]);
        navigate("/dashboard/order/packing");
      } else {
        Swal.fire({
          icon: "error",
          title: res.data.message || "Unknown error occurred",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error occurred",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {" "}
      {loading && <FullScreenLoader />}
      {addPacking?.length > 0 && permissions?.packing_order_create == true && (
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
            bgcolor: "#202020",
            p: 2,
            borderRadius: "5px",
          }}
        >
          <Typography variant="h5" sx={{ color: "white", mt: 1 }}>
            {addPacking?.length} Item Selected
          </Typography>
          <Div sx={{ display: "flex", columnGap: 3 }}>
            <LoadingButton
              variant="contained"
              onClick={() => handleAddPacking()}
            >
              Send To Packing
            </LoadingButton>
          </Div>
        </Div>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              {permissions?.packing_order_create && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "50px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  <Checkbox
                    onClick={(event) => {
                      if (event?.target?.checked) {
                        const allRawIds = shopPacking?.map((ele) => ({
                          shop_order_items_id: ele.shop_order_items_id,
                        }));
                        const newItems = allRawIds.filter(
                          (newItem) =>
                            !addPacking.some(
                              (existingItem) =>
                                existingItem.shop_order_items_id ===
                                newItem.shop_order_items_id
                            )
                        );
                        setAddPacking([...addPacking, ...newItems]);
                      } else {
                        setAddPacking(
                          addPacking.filter(
                            (item) =>
                              !shopPacking.some(
                                (packItem) =>
                                  packItem.shop_order_items_id ===
                                  item.shop_order_items_id
                              )
                          )
                        );
                      }
                    }}
                    color="primary"
                    checked={
                      addPacking?.length > 0 &&
                      shopPacking
                        ?.map((item) => item.shop_order_items_id)
                        ?.every((id) =>
                          addPacking?.some(
                            (groupItem) => groupItem.shop_order_items_id === id
                          )
                        )
                    }
                  />
                </TableCell>
              )}
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_port_no_and_name"}
                  direction={order}
                  onClick={() => handleSort("shop_order_port_no_and_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Port No & Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_no"}
                  direction={order}
                  onClick={() => handleSort("shop_order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Order No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_item_no"}
                  direction={order}
                  onClick={() => handleSort("shop_order_item_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_item_name"}
                  direction={order}
                  onClick={() => handleSort("shop_order_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_item_size"}
                  direction={order}
                  onClick={() => handleSort("shop_order_item_size")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Size
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_item_require_quantity"}
                  direction={order}
                  onClick={() => handleSort("shop_order_item_require_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Required Qty
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_salesman_name"}
                  direction={order}
                  onClick={() => handleSort("shop_salesman_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sales Person
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "170px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Exp. Delivery Date
              </TableCell>{" "}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                {permissions?.packing_order_create && (
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    <Checkbox
                      onClick={(event) => handleCheckbox(event, row)}
                      color="primary"
                      checked={addPacking?.some(
                        (ele) =>
                          ele.shop_order_items_id === row.shop_order_items_id
                      )}
                    />
                  </TableCell>
                )}
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.shop_order_port_no_and_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_item_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.shop_order_item_size ? row?.shop_order_item_size : "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.shop_order_item_require_quantity
                    ? row.shop_order_item_require_quantity
                    : "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_salesman_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.shop_order_date)}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.shop_order_item_est_delivery_date)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
};

export default ShopPackingPendingListTable;
