// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* QcImagesCarousel.css */

/* Increase the height of the Carousel */
.custom-carousel .carousel {
  height: 90vh; /* Adjust the height as needed */
 
}

/* Styling for individual carousel items (slides) */
.custom-carousel .carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styling for the caption */
.custom-carousel .carousel .legend {
  background-color: rgba(0, 0, 0, 0.7); /* Caption background color */
  color: #fff; /* Caption text color */
  padding: 10px;
  text-align: center;
  border-radius: 0;
  bottom: 0;
}

/* Styling for the control buttons (arrows) */
.custom-carousel .carousel .control-prev.control-arrow,
.custom-carousel .carousel .control-next.control-arrow {
  background-color: rgba(0, 0, 0, 0.7); /* Arrow button background color */
  color: #fff; /* Arrow button text color */
  border-radius: 0;
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  top: calc(50% - 20px); /* Vertically center the arrows */
}

/* Styling for the control buttons on hover (optional) */
.custom-carousel .carousel .control-prev.control-arrow:hover,
.custom-carousel .carousel .control-next.control-arrow:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

/* Customize other styles as per your design preferences */
`, "",{"version":3,"sources":["webpack://./src/app/pages/Order/QC/EditQc/QcImagesCarousel.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB,wCAAwC;AACxC;EACE,YAAY,EAAE,gCAAgC;;AAEhD;;AAEA,mDAAmD;AACnD;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA,4BAA4B;AAC5B;EACE,oCAAoC,EAAE,6BAA6B;EACnE,WAAW,EAAE,uBAAuB;EACpC,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,SAAS;AACX;;AAEA,6CAA6C;AAC7C;;EAEE,oCAAoC,EAAE,kCAAkC;EACxE,WAAW,EAAE,4BAA4B;EACzC,gBAAgB;EAChB,WAAW,EAAE,+BAA+B;EAC5C,YAAY,EAAE,gCAAgC;EAC9C,qBAAqB,EAAE,iCAAiC;AAC1D;;AAEA,wDAAwD;AACxD;;EAEE,oCAAoC;AACtC;;AAEA,0DAA0D","sourcesContent":["/* QcImagesCarousel.css */\n\n/* Increase the height of the Carousel */\n.custom-carousel .carousel {\n  height: 90vh; /* Adjust the height as needed */\n \n}\n\n/* Styling for individual carousel items (slides) */\n.custom-carousel .carousel .slide {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n/* Styling for the caption */\n.custom-carousel .carousel .legend {\n  background-color: rgba(0, 0, 0, 0.7); /* Caption background color */\n  color: #fff; /* Caption text color */\n  padding: 10px;\n  text-align: center;\n  border-radius: 0;\n  bottom: 0;\n}\n\n/* Styling for the control buttons (arrows) */\n.custom-carousel .carousel .control-prev.control-arrow,\n.custom-carousel .carousel .control-next.control-arrow {\n  background-color: rgba(0, 0, 0, 0.7); /* Arrow button background color */\n  color: #fff; /* Arrow button text color */\n  border-radius: 0;\n  width: 40px; /* Adjust the width as needed */\n  height: 40px; /* Adjust the height as needed */\n  top: calc(50% - 20px); /* Vertically center the arrows */\n}\n\n/* Styling for the control buttons on hover (optional) */\n.custom-carousel .carousel .control-prev.control-arrow:hover,\n.custom-carousel .carousel .control-next.control-arrow:hover {\n  background-color: rgba(0, 0, 0, 0.9);\n}\n\n/* Customize other styles as per your design preferences */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
