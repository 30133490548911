import Div from "@jumbo/shared/Div/Div";
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getAllPurchase } from "app/redux/actions/purchaseAction";
import { getKcFabricCodeList } from "app/services/apis/ListApi/kcFabricCodeList";
import { getPurchaseOrderList } from "app/services/apis/ListApi/purchaseOrderList";
import { getSuppliersListDropDown } from "app/services/apis/ListApi/supplierList";
import { downloadPurchaseReport } from "app/services/apis/ReportApis/PurchaseReport";
import { displayDateFun } from "app/utils/constants/functions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const PurchaseFilter = () => {
  const { allpurchase, TotalPage, loading } = useSelector(
    (state) => state.purchaseReducer
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [suppliersList, setSuppliersList] = useState([]);
  const [status, setStatus] = useState("");
  const [item_status, setItemStatus] = useState("");
  const [kc_code, setKCCode] = useState("");
  const [po_no, setPoNo] = useState("");
  const [kcFabricCodeList, setKcFabricCodeList] = useState([]);
  const [purchaseOrderNoList, setPurchaseOrderNoList] = useState([]);
  const [supplierId, setSupplierId] = useState("");
  const [poDate, setPoDate] = useState("");
  const [estimateDate, setEstimateDate] = useState("");
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(getAllPurchase());
    setPurchaseOrderNoList(await getPurchaseOrderList());
    setKcFabricCodeList(await getKcFabricCodeList());
    setSuppliersList(await getSuppliersListDropDown());
  }, []);
  const handleFilter = () => {
    // console.log(po_no);
    dispatch(
      getAllPurchase(
        "",
        null,
        status,
        supplierId,
        poDate,
        estimateDate,
        item_status,
        kc_code,
        po_no
      )
    );
  };

  const handleClear = () => {
    setSearchTerm("");
    setStatus("");
    setEstimateDate("");
    setPoDate("");
    setSupplierId("");
    setItemStatus("");
    setKCCode("");
    setPoNo("");
    setResetAutocomplete((prev) => !prev);
    dispatch(getAllPurchase());
  };

  return (
    <>
      <Div
        sx={{
          display: "flex",
          justifyContent: "left",
          gap: 3,
          mt: 3,
          width: "100%",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Div sx={{ width: "20%" }}>
          <Typography variant="h5">PO No</Typography>

          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={purchaseOrderNoList || []}
            getOptionLabel={(option) => option.purchase_order_no}
            onChange={(e, newValue) => setPoNo(newValue.purchase_order_no)}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.purchase_order_no}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "20%" }}>
          <Typography variant="h5">Kennys Fabric Code</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={kcFabricCodeList || []}
            getOptionLabel={(option) => option.kc_fabric_code}
            onChange={(e, newValue) => setKCCode(newValue.kc_fabric_code)}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.kc_fabric_code}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "20%" }}>
          <Typography variant="h5">Supplier Name</Typography>
          <Autocomplete
            size="small"
            id="tags-standard"
            options={suppliersList || []}
            inputValue={supplierId}
            getOptionLabel={(option) => option.supplier_name}
            onChange={(e, newValue) =>
              newValue == null
                ? setSupplierId("")
                : setSupplierId(newValue.supplier_name)
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.supplier_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "20%" }}>
          <Typography variant="h5">Status</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            labelId="supplier_name"
            id="supplier_name"
            value={status}
            onChange={(event) => {
              setStatus(event.target.value);
            }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Partially Closed">Partially Closed</MenuItem>;
            <MenuItem value="Closed">Closed</MenuItem>;
            <MenuItem value="Cancelled">Cancelled</MenuItem>;
          </Select>
        </Div>

        <Div sx={{ width: "20%" }}>
          <Typography variant="h5">Item Status</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            labelId="item_status"
            id="item_status"
            value={item_status}
            onChange={(event) => {
              setItemStatus(event.target.value);
            }}
          >
            <MenuItem value="Not Received">Not Received</MenuItem>
            <MenuItem value="Partial Received">Partial Received</MenuItem>;
            <MenuItem value="Partial Cancelled">Partial Cancelled</MenuItem>;
            <MenuItem value="Cancelled">Cancelled</MenuItem>;
            <MenuItem value="Completed">Completed</MenuItem>;
          </Select>
        </Div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Div sx={{ width: "20%" }}>
            <Typography variant="h5">Purchase Order Date</Typography>
            <DatePicker
              key={resetAutocomplete ? "reset1" : "normal1"}
              format="DD/MM/YYYY"
              onChange={(date) => {
                setPoDate(displayDateFun(date));
              }}
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  padding: 1,
                },
              }}
            />
          </Div>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Div sx={{ width: "20%" }}>
            <Typography variant="h5">Est Delivery Date</Typography>
            <DatePicker
              key={resetAutocomplete ? "reset1" : "normal1"}
              // defaultValue={dayjs(currentdate)}
              // value={estimateDate}
              format="DD-MM-YYYY"
              onChange={(date) => {
                setEstimateDate(displayDateFun(date));
              }}
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  padding: 1,
                },
              }}
            />
          </Div>
        </LocalizationProvider>
      </Div>

      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          justifyContent: "space-between",
        }}
      >
        <Div>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Div>
        <Button
          disabled={allpurchase?.length == 0}
          variant="contained"
          onClick={async () => {
            const data = await downloadPurchaseReport(
              status,
              supplierId,
              poDate,
              estimateDate,
              item_status,
              kc_code,
              po_no
            );
            window.open(data);
          }}
        >
          Export CSV
        </Button>
      </Div>
    </>
  );
};

export default PurchaseFilter;
