import Div from "@jumbo/shared/Div";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  currentdate,
  dateFun,
  displayDateFun,
} from "app/utils/constants/functions";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import { LoadingButton } from "@mui/lab";
import ShopItemsTable from "./shopItemsTable";
import { addShopOrder } from "app/services/apis/ShopOrderApis/addShopOrder";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function AddNewShopOrder() {
  const [portNoNameList, setPortNoNameList] = useState();
  const [cancelLoader, setCancelLoader] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState();
  const navigate = useNavigate();

  useEffect(async () => {
    setPortNoNameList(await getPortListNoPerm());
    setOrderData({
      shop_order_no: "",
      shop_order_port_no_and_name: "",
      shop_order_client_name: "",
      shop_order_date: dateFun(currentdate),
      shop_order_est_delivery_date: dateFun(currentdate),
      shop_salesman_name: "",
      shop_order_remarks: "",
      shop_items: [],
    });
  }, []);

  const handleSubmitOrder = async () => {
    setLoading(true);
    const items = orderData?.shop_items?.map((item, i) => {
      delete item["sizeList"];
      return { ...item, shop_order_item_no: i + 1 };
    });
    const submitOrder = await addShopOrder({ ...orderData, shop_items: items });
    if (submitOrder.status == false) {
      Swal.fire(submitOrder?.message, "Try Again", "error");
      setLoading(false);
    } else {
      Swal.fire("Shop Order Added Successfully !", "Successful", "success");
      setLoading(false);
      navigate("/dashboard/order/admin");
    }
  };

  const handleCancelOrder = () => {
    Swal.fire({
      title: "Are you sure you want to cancel?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/dashboard/order/admin");
      }
    });
  };

  return (
    <Div>
      <Typography variant="h1" sx={{ mt: -4, mb: 3 }}>
        Shop Order
      </Typography>
      <Div>
        <Div sx={{ display: "flex", flexWrap: "wrap", gap: 5, mb: 2 }}>
          <Div sx={{ width: "15%" }}>
            <Typography variant="h5">Order No.</Typography>
            <TextField
              size="small"
              name="shop_order_no"
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  shop_order_no: e.target.value,
                });
              }}
              sx={{ width: "100%" }}
            ></TextField>
          </Div>
          <Div sx={{ width: "15%" }}>
            <Typography variant="h5">Port No. & Name</Typography>
            <Select
              size="small"
              sx={{ width: "100%" }}
              name="shop_order_port_no_and_name"
              //   value={inputs.order_port_no_and_name || ""}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  shop_order_port_no_and_name: e.target.value,
                });
              }}
            >
              {portNoNameList &&
                portNoNameList.length &&
                portNoNameList?.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
            </Select>
          </Div>
          <Div sx={{ width: "15%" }}>
            <Typography variant="h5">Client Name</Typography>
            <TextField
              size="small"
              name="shop_order_client_name"
              //   value={inputs.order_client_name}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  shop_order_client_name: e.target.value,
                });
              }}
              sx={{ width: "100%" }}
            ></TextField>
          </Div>
          <Div sx={{ width: "15%" }}>
            <Typography variant="h5">Sales Person Name</Typography>
            <TextField
              size="small"
              name="shop_salesman_name"
              //   value={inputs.salesman_name}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  shop_salesman_name: e.target.value,
                });
              }}
              sx={{ width: "100%" }}
            ></TextField>
          </Div>
          <Div sx={{ width: "18%" }}>
            <Typography variant="h5">Order Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  "&.MuiTextField-root": {
                    height: "39px",
                    width: "100%",
                    flexDirection: "unset",
                  },
                }}
                // maxDate={dayjs()}
                format="DD/MM/YY"
                value={dayjs(currentdate)}
                name="shop_order_date"
                onChange={(date) => {
                  setOrderData({
                    ...orderData,
                    shop_order_date: dateFun(date),
                  });
                }}
              />
            </LocalizationProvider>
          </Div>
        </Div>
        <ShopItemsTable setOrderData={setOrderData} orderData={orderData} />
        <Div>
          <Typography variant="h3" sx={{ mt: 3 }}>
            Remarks:-
          </Typography>
          <TextField
            name="shop_order_remarks"
            // value={inputs.order_remarks}
            multiline
            onChange={(e) => {
              setOrderData({
                ...orderData,
                shop_order_remarks: e.target.value,
              });
            }}
            fullWidth
          ></TextField>
        </Div>
        <Div sx={{ width: "18%", mt: 4 }}>
          <Typography variant="h5">Est. Delivery Date</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                "&.MuiTextField-root": {
                  height: "39px",
                  width: "100%",
                  flexDirection: "unset",
                },
              }}
              format="DD/MM/YY"
              value={dayjs(currentdate)}
              // minDate={dayjs()}
              name="shop_order_est_delivery_date"
              onChange={(date) => {
                setOrderData({
                  ...orderData,
                  shop_order_est_delivery_date: dateFun(date),
                });
              }}
            />
          </LocalizationProvider>
        </Div>
      </Div>
      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 4,
        }}
      >
        <LoadingButton
          loading={cancelLoader}
          variant="outlined"
          onClick={handleCancelOrder}
        >
          Cancel
        </LoadingButton>

        <LoadingButton
          variant="contained"
          sx={{ width: "100px" }}
          onClick={handleSubmitOrder}
          loading={isLoading}
        >
          Save
        </LoadingButton>
      </Div>
    </Div>
  );
}
