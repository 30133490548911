import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import React from "react";
import AlterationTable from "./alterationTable";

const AlterationChallan = () => {
  return (
    <Div>
      <Typography variant="h1" sx={{ mt: -4, mb: 4 }}>
        Alteration Challan
      </Typography>
      <AlterationTable />
    </Div>
  );
};

export default AlterationChallan;
