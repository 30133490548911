import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { Button, Typography } from "@mui/material";
import FormTextField from "app/components/InputField/FormTextField";
import { updateUnit } from "app/services/apis/updateUnit";
import Swal from "sweetalert2";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { addCatalogue } from "app/services/apis/addCatalogue";

export default function AddCatalogue() {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);

  const data = {
    catalogue_folder_name: state?.catalogue_folder_name
      ? state?.catalogue_folder_name
      : "",
    catalogue_image_icon: state?.catalogue_image_icon
      ? state?.catalogue_image_icon
      : "",
  };
  const validationSchema = yup.object({
    catalogue_folder_name: yup
      .string("Enter Folder Name")
      .required("Folder Name is required"),
    catalogue_image_icon: yup
      .mixed()
      .test("fileType", "Invalid file type", (value) => {
        // Check if a file is selected
        if (!value) {
          return false;
        }

        // Get the file type
        const fileType = value.type;

        // Define the allowed image file types
        const allowedImageTypes = [
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/gif",
        ];

        // Check if the file type is allowed
        return allowedImageTypes.includes(fileType);
      })
      .required("Image file is required"),
  });

  const onUserSave = async (values) => {
    // console.log(values);
    const formdata = new FormData();
    formdata.append("catalogue_image_icon", values.catalogue_image_icon);
    formdata.append("catalogue_folder_name", values.catalogue_folder_name);

    setSubmitting(true);
    if (pathname == "/dashboard/master/catalogue/edit") {
      const data = await updateUnit(values, state.id);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Catalogue Edited Successfully",
          text: "",
        });
        navigate("/dashboard/master/catalogue");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addCatalogue(formdata);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Catalogue Added Successfully",
          text: "",
        });
        navigate("/dashboard/master/catalogue");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/dashboard/master/catalogue/add"
          ? "Add New Catalogue"
          : "Edit Catalogue"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={data}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                  }}
                >
                  <FormTextField
                    name="catalogue_folder_name"
                    label="Folder Name"
                  />

                  <Div>
                    <Typography variant="h5">Catalogue Image</Typography>

                    <input
                      type="file"
                      id="catalogue_image_icon"
                      name={"catalogue_image_icon"}
                      onChange={(e) =>
                        setFieldValue("catalogue_image_icon", e.target.files[0])
                      }
                      sx={{
                        width: "100%",
                        fontSize: "1rem",
                      }}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"catalogue_image_icon"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/master/catalogue");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
