import Div from "@jumbo/shared/Div";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import axios from "axios";
import { BookCover } from "book-cover-3d";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ListCatalogueFolders = ({ searchTerm }) => {
  const [catalogueList, setCatalogueList] = useState([]);
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [TotalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(async () => {
    try {
      setLoading(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/catalogue-master/list-catalogue-master?catalogue_folder_name=${searchTerm}&page=${page}`,
        config
      );
      if (data?.status == 200) {
        setLoading(false);
        setCatalogueList(data.data.result);
        setTotalPage(data.data.totalPages);
      }
    } catch (error) {
      // console.log(error.response);
    }
  }, [searchTerm, page]);

  const handleInfoIconClick = (rowData) => {
    navigate(`/dashboard/master/catalogue/${rowData?.catalogue_folder_name}`);
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/dashboard/master/catalogue/edit", { state: menuItem?.data });
        break;
    }
  };

  return (
    <Div>
      <>
        {" "}
        {loading && <FullScreenLoader />}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",

                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Catalogues
                </TableCell>

                {/* {permissions?.catalogue_master_edit == true && (
                <TableCell
                  sx={{
                    textAlign: "left",

                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Edit
                </TableCell>
              )} */}
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      columnGap: 5,
                      rowGap: 5,
                      mt: 5,
                      ml: 5,
                    }}
                  >
                    {catalogueList?.map((folder) => (
                      <Div>
                        <BookCover
                          rotate={27}
                          rotateHover={5}
                          height={250}
                          width={180}
                        >
                          <img
                            style={{ cursor: "pointer", objectFit: "cover" }}
                            src={`${process.env.REACT_APP_URL}/${folder.catalogue_image_icon}`}
                            onClick={() => handleInfoIconClick(folder)}
                          />
                        </BookCover>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: 600, mt: 5, textAlign: "center" }}
                        >
                          {folder.catalogue_folder_name}
                        </Typography>{" "}
                      </Div>
                    ))}
                  </TableCell>

                  {/* {permissions?.catalogue_master_edit == true && (
                    <TableCell sx={{ textAlign: "left" }}>
                      <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                          {
                            icon: <EditIcon />,
                            title: "Edit Catalogue",
                            action: "edit",
                            data: folder,
                          },
                        ]}
                        onClickCallback={handleItemAction}
                      />
                    </TableCell>
                  )} */}
                </TableRow>
              </>
            </TableBody>
          </Table>
          <Pagination
            count={TotalPage || 1}
            page={page}
            onChange={handleChangePage}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
            }}
          />
        </TableContainer>
      </>
    </Div>
  );
};

export default ListCatalogueFolders;
