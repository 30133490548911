import Div from "@jumbo/shared/Div";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListPortTable from "./portTable";
import { useDispatch } from "react-redux";
import { getAllPorts } from "app/redux/actions/masterAction";

export default function ListPort() {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllPorts(value, 1));
  };
  useEffect(() => {
    dispatch(getAllPorts());
  }, []);
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Port Master</Typography>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        {permissions?.port_master_create == true && (
          <Div>
            <Button
              variant="contained"
              sx={{ p: 1, pl: 4, pr: 4 }}
              onClick={() => navigate("/master/port/add")}
            >
              Add New Port
            </Button>
          </Div>
        )}
      </Div>
      <ListPortTable searchTerm={searchTerm} setPage={setPage} page={page} />
    </Div>
  );
}
