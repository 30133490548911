import InfoIcon from "@mui/icons-material/Info";
import {
  Chip,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { getPackingOrderList } from "app/redux/actions/orderAction";
import { displayDateFun } from "app/utils/constants/functions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PakingOrderDetailsModal from "./packingOrderModal";
import FullScreenLoader from "app/components/ListingPageLoader";

const PakingDoneListTable = ({ searchTerm, setPage, page, inputs }) => {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { packing, TotalPage,loading } = useSelector((state) => state.orderReducer);


  const dispatch = useDispatch();


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getPackingOrderList(searchTerm, inputs, newPage, "packingDone"));
  };

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = packing?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_sub_item_name") {
      aValue = a.order_sub_item_name.toLowerCase();
      bValue = b.order_sub_item_name.toLowerCase();
    } else if (orderBy == "order_item_factory_name") {
      aValue = a.order_item_factory_name.toLowerCase();
      bValue = b.order_item_factory_name.toLowerCase();
    } else if (orderBy == "order_item_qc_status") {
      aValue = a.order_item_qc_status.toLowerCase();
      bValue = b.order_item_qc_status.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleInfoIconClick = (rowData) => {
    setSelectedRowData(rowData);
    setInfoModalOpen(true);
  };

  return (
    <>      {loading && <FullScreenLoader />}

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Port No & Name
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",
                px: 1,
              }}
            >
              <TableSortLabel
                active={orderBy === "order_no"}
                direction={order}
                onClick={() => handleSort("order_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Order No.
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_no"}
                direction={order}
                onClick={() => handleSort("order_item_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Item No.
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "115px",
                px: 1,
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_name"}
                direction={order}
                onClick={() => handleSort("order_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Item Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "125px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_sub_item_name"}
                direction={order}
                onClick={() => handleSort("order_sub_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Sub Item Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "125px",
                verticalAlign: "middle",
                px: 1,
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_job_work_id"}
                direction={order}
                onClick={() => handleSort("order_item_job_work_id")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Job Work ID
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: "105px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Order Details
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_factory_name"}
                direction={order}
                onClick={() => handleSort("order_item_factory_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Factory
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Order Status
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Item Status
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                width: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "packing_status"}
                direction={order}
                onClick={() => handleSort("packing_status")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Paking Status
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_status"}
                direction={order}
                onClick={() => handleSort("order_item_status")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                QC Status
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "qc_details_create_date "}
                direction={order}
                onClick={() => handleSort("qc_details_create_date ")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                QC Date
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "115px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Order Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "115px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Packing Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.order_port_no_and_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row?.order_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.order_item_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row?.order_item_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.order_sub_item_name || "-"}
              </TableCell>
              <TableCell sx={{ textAlign: "left", px: 1 }}>
                {row?.order_item_job_work_id || "-"}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                <InfoIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleInfoIconClick(row)}
                />
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.order_item_factory_name}
              </TableCell>
              {/* <TableCell sx={{ textAlign: "left" }}>
                {row?.order_status}
              </TableCell> */}

              <TableCell>
                <Chip
                  color={
                    row?.order_status === "Completed" ? "success" : "warning"
                  }
                  size={"small"}
                  label={row?.order_status}
                />
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.order_item_status}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>Done</TableCell>

              <TableCell sx={{ textAlign: "left" }}>
                {row?.qc_status != null ? row?.qc_status : "-"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {displayDateFun(row?.qc_details_create_date)}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {displayDateFun(row?.order_date)}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {displayDateFun(row?.packing_create_date)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1}
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
      <PakingOrderDetailsModal
        open={isInfoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        rowData={selectedRowData}
      />
    </TableContainer>
    </>

  );
};

export default PakingDoneListTable;
