import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  currentdate,
  dateFun,
  displayDateFun,
} from "app/utils/constants/functions";
import { getAllShopOutForDeliveryDispatch } from "app/redux/actions/orderAction";
import { updateExpDeliveryDate } from "app/services/apis/OrderApis/expDeliveryDate";
import axios from "axios";
import ShopOrderDetailInfoModal from "./Modal/shopOrderModal";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function ShopOutForDeliveryTable({
  searchTerm,
  setPage,
  page,
  inputs,
}) {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");

  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { shopOutForDelivery, error, TotalPage, loading } = useSelector(
    (state) => state.orderReducer
  );
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = shopOutForDelivery?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "shop_order_transport_type") {
      aValue = a.shop_order_transport_type.toLowerCase();
      bValue = b.shop_order_transport_type.toLowerCase();
    } else if (orderBy == "shop_order_item_name") {
      aValue = a.shop_order_item_name.toLowerCase();
      bValue = b.shop_order_item_name.toLowerCase();
    } else if (orderBy == "shop_order_item_size") {
      aValue = a?.shop_order_item_size?.toLowerCase();
      bValue = b?.shop_order_item_size?.toLowerCase();
    } else if (orderBy == "shop_salesman_name") {
      aValue = a.shop_salesman_name.toLowerCase();
      bValue = b.shop_salesman_name.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllShopOutForDeliveryDispatch(searchTerm, inputs, newPage));
  };

  const handleInfoIconClick = (rowData) => {
    setSelectedRowData(rowData);
    setInfoModalOpen(true);
  };

  const updateDeliveryDate = async (id, date) => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/api/shop-order-dispatch/ready-for-delivery?id=${id}`,
        { shop_order_dispatch_delivery_date: dateFun(date) },
        config
      );
      if (res?.status == 200) {
        dispatch(getAllShopOutForDeliveryDispatch("", "", 1));
      }
    } catch (error) {
      // console.log(error);
    }
  };
  //   useEffect(() => {
  //     if (error == "Not Wastage Inventory found") {
  //       Swal.fire({ icon: "error", title: "No Result Found" });
  //     }
  //   }, [error]);

  return (
    <>
      {" "}
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Port No & Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                {/* <TableSortLabel
                active={orderBy === "shop_order_no"}
                direction={order}
                onClick={() => handleSort("shop_order_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              > */}
                Order No
                {/* </TableSortLabel> */}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_item_name"}
                  direction={order}
                  onClick={() => handleSort("shop_order_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_item_no"}
                  direction={order}
                  onClick={() => handleSort("shop_order_item_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_item_size"}
                  direction={order}
                  onClick={() => handleSort("shop_order_item_size")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Size
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "dispatch_shipment_no"}
                  direction={order}
                  onClick={() => handleSort("dispatch_shipment_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Shipment No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_dispatch_id"}
                  direction={order}
                  onClick={() => handleSort("shop_order_dispatch_id")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Dispatch ID
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_ordered_dispatch_quantity"}
                  direction={order}
                  onClick={() => handleSort("shop_ordered_dispatch_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Dispatch Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_transport_type"}
                  direction={order}
                  onClick={() => handleSort("shop_order_transport_type")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Transportation Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_order_awb_bl_no"}
                  direction={order}
                  onClick={() => handleSort("shop_order_awb_bl_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  AWB/ BL No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Dispatch Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "shop_salesman_name"}
                  direction={order}
                  onClick={() => handleSort("shop_salesman_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sales Person
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "170px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Exp. Delivery Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "170px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Delivery Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_port_no_and_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_item_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.shop_order_item_size ? row?.shop_order_item_size : "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.dispatch_shipment_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_dispatch_id}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_ordered_dispatch_quantity}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_transport_type}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.shop_order_awb_bl_no ? row.shop_order_awb_bl_no : "-"}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "100px",
                  }}
                >
                  <InfoIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleInfoIconClick(row)}
                  />
                </TableCell>
                <TableCell>{row.shop_salesman_name}</TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.shop_order_date)}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={
                        permissions.dispatch_order_edit == true ? false : true
                      }
                      format="DD/MM/YY"
                      // minDate={dayjs()}
                      defaultValue={dayjs(
                        row.shop_order_dispatch_est_delivery_date
                      )}
                      onChange={async (date) => {
                        const data = await updateExpDeliveryDate(
                          row.shop_order_dispatch_main_id,
                          date,
                          "shop"
                        );
                        if (data?.status == true) {
                          Swal.fire({
                            icon: "success",
                            title: "Expected Delivery Date Changed",
                          });
                        }
                      }}
                      sx={{
                        width: "100%",
                        "&.MuiTextField-root": {
                          height: "39px",
                          flexDirection: "unset",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={
                        permissions.dispatch_order_edit == true ? false : true
                      }
                      format="DD/MM/YY"
                      // minDate={dayjs()}
                      defaultValue={dayjs(currentdate)}
                      onChange={(date) => {
                        Swal.fire({
                          title:
                            "Do you want to change Item status to Delivered?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Save",
                          denyButtonText: "No, cancel!",
                          confirmButtonClass: "btn btn-success",
                          cancelButtonClass: "btn btn-danger",
                        }).then((result) => {
                          if (result?.isConfirmed) {
                            updateDeliveryDate(row.dispatch_details_id, date);
                            Swal.fire("Saved!", "", "success");
                          } else if (result?.isDenied) {
                            Swal.fire("Item not Delivered !", "", "info");
                          }
                        });
                      }}
                    />
                  </LocalizationProvider>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
        <ShopOrderDetailInfoModal
          open={isInfoModalOpen}
          onClose={() => {
            setInfoModalOpen(false);
          }}
          rowData={selectedRowData}
        />
      </TableContainer>
    </>
  );
}
