import Div from "@jumbo/shared/Div";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { getKcFabricCodeUniqList } from "app/services/apis/ListApi/kcFabricCodeList";
import { getTakaNoList } from "app/services/apis/ListApi/takaNoList";
import axios from "axios";
import { useEffect, useState } from "react";

const RawStockModal = ({ open, onClose }) => {
  const [kcFabricCode, setKcFabricCode] = useState([]);
  const [takaNo, setTakaNo] = useState([]);
  const [inputs, setInputs] = useState(null);

  const handleSave = async () => {
    try {
      const kcFabricCode = inputs?.kc_fabric_code || "";
      const takaNo = inputs?.taka_no || "";
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api/raw-inventory/list-raw-inventory-stock-download-excel?kc_fabric_code=${kcFabricCode}&taka_no=${takaNo}`
      );
      onClose();
      window.open(res?.data?.result);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setKcFabricCode(await getKcFabricCodeUniqList());
      setTakaNo(await getTakaNoList(inputs?.kc_fabric_code));
    };
    fetchData();
  }, [inputs, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Div sx={{ display: "inline-flex", justifyContent: "space-between" }}>
        <DialogTitle sx={{ fontWeight: "700" }}>
          Select KC Fabric Code & Taka No
        </DialogTitle>
      </Div>
      <DialogContent>
        <Div sx={{ display: "inline-flex", width: "100%", marginTop: 2 }}>
          <Div sx={{ width: "50%" }}>
            <Typography fontWeight="bold" variant="h5">
              KC Fabric Code
            </Typography>
            <Autocomplete
              size="small"
              id="kc-fabric-code-autocomplete"
              options={kcFabricCode || []}
              getOptionLabel={(option) => option?.kc_fabric_code}
              onChange={(e, newValue) => {
                typeof newValue === "object"
                  ? setInputs({
                      ...inputs,
                      kc_fabric_code: newValue?.kc_fabric_code || "",
                    })
                  : setInputs({
                      ...inputs,
                      kc_fabric_code: newValue || "",
                    });
              }}
              onInputChange={(e, newInputValue) => {
                setInputs({
                  ...inputs,
                  kc_fabric_code: newInputValue,
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.kc_fabric_code}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="All" />
              )}
            />
          </Div>
          <Div sx={{ width: "50%", ml: 2 }}>
            <Typography fontWeight="bold" variant="h5">
              Taka No
            </Typography>
            <Autocomplete
              disabled={!inputs?.kc_fabric_code}
              size="small"
              id="taka-no-autocomplete"
              options={takaNo || []}
              getOptionLabel={(option) => option?.taka_no}
              onChange={(e, newValue) => {
                typeof newValue === "object"
                  ? setInputs({
                      ...inputs,
                      taka_no: newValue?.taka_no || "",
                    })
                  : setInputs({
                      ...inputs,
                      taka_no: newValue || "",
                    });
              }}
              onInputChange={(e, newInputValue) => {
                setInputs({
                  ...inputs,
                  taka_no: newInputValue,
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.taka_no}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="All" />
              )}
            />
          </Div>
        </Div>
      </DialogContent>

      <DialogActions>
        <Div sx={{ display: "flex", columnGap: 2 }}>
          <Button
            size="small"
            onClick={handleSave}
            color="primary"
            variant="contained"
          >
            Export cSV
          </Button>

          <Button
            size="small"
            onClick={onClose}
            color="error"
            variant="contained"
          >
            Close
          </Button>
        </Div>
      </DialogActions>
    </Dialog>
  );
};

export default RawStockModal;
