import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const NormalAddonsModal = ({
  open,
  onClose,
  rowData,
  itemDetails,
  setItemDetails,
  index,
}) => {
  const [addonsList, setAddonsList] = useState();
  // console.log(addonsList);
  const handleSelect = (e, data, rowIndex) => {
    if (e.target.value == "") {
      const updatedItemDetails = [...addonsList];
      updatedItemDetails[rowIndex] = {};
      setAddonsList(updatedItemDetails);
    } else {
      const updatedItemDetails = [...addonsList];
      updatedItemDetails[rowIndex] = {
        ...rowData?.addons_items[rowIndex],
        dispatch_quantity: Number(e.target.value),
      };
      setAddonsList(updatedItemDetails);
    }
  };

  useEffect(() => {
    // console.log(itemDetails[index]?.addons_items);
    if (itemDetails[index]?.dispatch_addons_items) {
      setAddonsList(itemDetails[index]?.dispatch_addons_items);
      // console.log(1);
    } else if (rowData?.addons_items) {
      // console.log(2);
      setAddonsList(rowData?.addons_items);
    } else {
      // console.log(3);
      setAddonsList([]);
    }
  }, [open]);

  useEffect(() => {
    if (itemDetails[index] != undefined) {
      const updateData = [...itemDetails];
      updateData[index] = {
        ...updateData[index],
        dispatch_addons_items: addonsList,
      };
      setItemDetails(updateData);
    }
  }, [addonsList]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <DialogTitle sx={{ fontWeight: "700" }}>AddOns</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item No.
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Job Work Id
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item Size
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Available Qty
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "140px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Req Qty
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Dispatch Qty
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData?.addons_items.map((row, i) => {
              return (
                <TableRow>
                  <TableCell sx={{ textAlign: "left" }}>{i + 1}</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {rowData?.order_item_job_work_id}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.itemName}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.item_size ? row?.item_size : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.addons_available_quantity}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.addons_quantity}
                  </TableCell>

                  <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      type="number"
                      value={addonsList[i]?.dispatch_quantity || ""}
                      //   sx={{ width: "60%" }}
                      inputProps={{
                        max: row?.addons_available_quantity,
                        min: 1,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);
                        if (
                          isNaN(inputValue) ||
                          inputValue > row?.addons_available_quantity
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        handleSelect(e, row, i);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NormalAddonsModal;
