import React, { useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Div from "@jumbo/shared/Div";
import { useState } from "react";
import { dateFun } from "app/utils/constants/functions";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import Swal from "sweetalert2";
import { getDetailsByOrderNo } from "app/services/apis/Inventory/getDetailsByOrderNo";

export default function ManuallyIssueTable() {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState([]);
  const { state } = useLocation();
  const { menuItemData, type } = state;
  const [rawMaterial, setRawMaterials] = useState({
    raw_manually_order_date: "",
    raw_manually_order_no: "",
    raw_manually_order_item_no: "",
    raw_manually_order_item_name: "",
    raw_manually_order_sub_item_name: "",
    raw_manually_factory_name: "",
    raw_manually_available_quantity: menuItemData?.taka_reserve_quantity,
    raw_manually_issue_quantity: "",
    taka_id: menuItemData.id,
    raw_manually_taka_no: menuItemData.taka_no,
    raw_manually_taka_rack_details: menuItemData.raw_rack_details,
  });
  const [finishGoods, setFinishGoods] = useState({
    finished_manually_order_date: "",
    finished_manually_order_no: "",
    finished_manually_order_item_no: "",
    finished_manually_order_item_name: menuItemData.finished_item_name,
    finished_manually_factory_name: "",
    finished_manually_available_quantity:
      menuItemData?.finished_item_reserve_quantity,
    finished_manually_issue_quantity: "",
    finished_item_details_id: menuItemData.id,
    manually_finished_item_no: menuItemData.finished_item_no,
    manually_finished_rack_details: menuItemData.finished_rack_details,
  });
  // console.log(menuItemData);
  const handleSubmit = async () => {
    if (type == "Raw Material") {
      try {
        setLoader(true);
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const res = await axios.patch(
          `${process.env.REACT_APP_URL}/api/raw-inventory/raw-manual-issue`,
          rawMaterial,
          config
        );
        // console.log(res);
        if (res?.status == 200) {
          Swal.fire({ icon: "success", title: "Issued" });
          navigate("/dashboard/inventory");
        }
      } catch (error) {
        alert("Something Went Wrong");
      } finally {
        setLoader(false);
      }
    } else {
      try {
        setLoader(true);
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const res = await axios.patch(
          `${process.env.REACT_APP_URL}/api/finished-good-inventory/finished-manual-issue`,
          finishGoods,
          config
        );
        // console.log(res);
        if (res?.status == 200) {
          Swal.fire({ icon: "success", title: "Issued" });
          navigate("/dashboard/inventory");
        }
      } catch (error) {
        alert("Something Went Wrong");
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(async () => {
    const data = await getDetailsByOrderNo(
      rawMaterial?.raw_manually_order_no ||
        finishGoods?.finished_manually_order_no
    );
    // console.log(data);
    setOrderDetails(data?.data?.result);
  }, [
    rawMaterial?.raw_manually_order_no,
    finishGoods?.finished_manually_order_no,
  ]);

  return (
    <>
      <Typography variant="h1" sx={{ mt: -4, mb: 5 }}>
        Manually Issue
      </Typography>

      {type == "Raw Material" ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "200px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Order Date
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Order No.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item No.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "80px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Sub Item Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "80px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Factory
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "40px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Available Qty
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "40px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Issue Qty
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "left",
                    pl: 2,
                    width: "100px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Div sx={{ width: "100%" }}>
                      <DatePicker
                        defaultValue={rawMaterial?.raw_manually_order_date}
                        onChange={(date) => {
                          setRawMaterials({
                            ...rawMaterial,
                            raw_manually_order_date: dateFun(date),
                          });
                        }}
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                      />
                    </Div>
                  </LocalizationProvider>
                </TableCell>
                <TableCell sx={{ textAlign: "left", maxWidth: "50px" }}>
                  <TextField
                    size="small"
                    name="raw_manually_order_no"
                    sx={{ maxWidth: "100px" }}
                    value={rawMaterial?.raw_manually_order_no}
                    onChange={(e) => {
                      setRawMaterials({
                        ...rawMaterial,
                        raw_manually_order_no: e.target.value,
                      });
                    }}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <Select
                    size="small"
                    value={rawMaterial.raw_manually_order_item_no}
                    sx={{ width: "100%" }}
                    name="raw_manually_order_item_no"
                    onChange={(e) => {
                      setRawMaterials({
                        ...rawMaterial,
                        raw_manually_order_item_no: e.target.value,
                      });
                    }}
                  >
                    {orderDetails?.map((item) => {
                      return (
                        <MenuItem value={item.order_item_no}>
                          {item.order_item_no}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  <Select
                    size="small"
                    value={rawMaterial.raw_manually_order_item_name}
                    sx={{ width: "100%" }}
                    name="raw_manually_order_item_name"
                    onChange={(e) => {
                      setRawMaterials({
                        ...rawMaterial,
                        raw_manually_order_item_name: e.target.value,
                      });
                    }}
                  >
                    {orderDetails
                      ?.filter((item) => {
                        return (
                          item.order_item_no ===
                          rawMaterial.raw_manually_order_item_no
                        );
                      })
                      .map((item) => {
                        return (
                          <MenuItem value={item.order_item_name}>
                            {item.order_item_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <Select
                    size="small"
                    value={rawMaterial.raw_manually_order_sub_item_name}
                    sx={{ width: "100%" }}
                    name="Sub_item_name"
                    onChange={(e) => {
                      setRawMaterials({
                        ...rawMaterial,
                        raw_manually_order_sub_item_name: e.target.value,
                      });
                    }}
                  >
                    {orderDetails
                      ?.filter((item) => {
                        return (
                          item.order_item_name ===
                          rawMaterial.raw_manually_order_item_name
                        );
                      })
                      .map((item) => {
                        return (
                          <MenuItem value={item.order_sub_item_name}>
                            {item.order_sub_item_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <Select
                    size="small"
                    value={rawMaterial.raw_manually_factory_name}
                    sx={{ width: "100%" }}
                    name="raw_manually_factory_name"
                    onChange={(e) => {
                      setRawMaterials({
                        ...rawMaterial,
                        raw_manually_factory_name: e.target.value,
                      });
                    }}
                  >
                    {orderDetails?.map((item) => {
                      return (
                        <MenuItem value={item.order_item_factory_name}>
                          {item.order_item_factory_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <Typography>
                    {rawMaterial.raw_manually_available_quantity}
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <TextField
                    type="number"
                    size="small"
                    name="issued_qty"
                    value={rawMaterial.raw_manually_issue_quantity}
                    onChange={(e) => {
                      setRawMaterials({
                        ...rawMaterial,
                        raw_manually_issue_quantity: Number(e.target.value),
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "200px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Order Date
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Order No.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item No.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item Name
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "80px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Factory
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "40px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Available Qty
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "40px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Issue Qty
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "left",
                    pl: 2,
                    width: "100px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Div sx={{ width: "100%" }}>
                      <DatePicker
                        defaultValue={finishGoods?.finished_manually_order_date}
                        onChange={(date) => {
                          setFinishGoods({
                            ...finishGoods,
                            finished_manually_order_date: dateFun(date),
                          });
                        }}
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                      />
                    </Div>
                  </LocalizationProvider>
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <TextField
                    size="small"
                    name="finished_manually_order_no"
                    value={finishGoods?.finished_manually_order_no}
                    onChange={(e) => {
                      setFinishGoods({
                        ...finishGoods,
                        finished_manually_order_no: e.target.value,
                      });
                    }}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {/* <TextField
                    size="small"
                    name="finished_manually_order_item_no"
                    value={finishGoods.finished_manually_order_item_no}
                    onChange={(e) => {
                      setFinishGoods({
                        ...finishGoods,
                        finished_manually_order_item_no: e.target.value,
                      });
                    }}
                  /> */}

                  <Select
                    size="small"
                    value={finishGoods.finished_manually_order_item_no}
                    sx={{ width: "100%" }}
                    name="finished_manually_order_item_no"
                    onChange={(e) => {
                      setFinishGoods({
                        ...finishGoods,
                        finished_manually_order_item_no: e.target.value,
                      });
                    }}
                  >
                    {orderDetails?.map((item) => {
                      return (
                        <MenuItem value={item.order_item_no}>
                          {item.order_item_no}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <Select
                    size="small"
                    value={finishGoods.finished_manually_order_item_name}
                    sx={{ width: "100%" }}
                    name="finished_manually_order_item_name"
                    onChange={(e) => {
                      setFinishGoods({
                        ...finishGoods,
                        finished_manually_order_item_name: e.target.value,
                      });
                    }}
                  >
                    {orderDetails
                      ?.filter((item) => {
                        return (
                          item.order_item_no ==
                          finishGoods.finished_manually_order_item_no
                        );
                      })
                      .map((item) => {
                        return (
                          <MenuItem value={item.order_item_name}>
                            {item.order_item_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <Select
                    size="small"
                    value={finishGoods?.finished_manually_factory_name}
                    sx={{ width: "100%" }}
                    name="finished_manually_factory_name"
                    onChange={(e) => {
                      setFinishGoods({
                        ...finishGoods,
                        finished_manually_factory_name: e.target.value,
                      });
                    }}
                  >
                    {orderDetails?.map((item) => {
                      return (
                        <MenuItem value={item.order_item_factory_name}>
                          {item.order_item_factory_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <Typography>
                    {finishGoods.finished_manually_available_quantity}
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  <TextField
                    size="small"
                    name="issued_qty"
                    type="number"
                    value={finishGoods.finished_manually_issue_quantity}
                    onChange={(e) => {
                      setFinishGoods({
                        ...finishGoods,
                        finished_manually_issue_quantity: Number(
                          e.target.value
                        ),
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 5,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/dashboard/inventory");
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={loader}
          variant="contained"
          onClick={handleSubmit}
          sx={{ width: "100px" }}
        >
          Issue
        </LoadingButton>
      </Div>
    </>
  );
}
