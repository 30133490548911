import axios from "axios";

export const downloadShopPackingPendingReport = async (inputs) => {
  if (inputs.order_no == undefined) {
    inputs.order_no = "";
  }
  if (inputs.order_item_status == undefined) {
    inputs.order_item_status = "";
  }
  if (inputs.order_port_no_and_name == undefined) {
    inputs.order_port_no_and_name = "";
  }
  if (inputs.shop_order_item_name == undefined) {
    inputs.shop_order_item_name = "";
  }
  if (inputs.shop_order_item_size == undefined) {
    inputs.shop_order_item_size = "";
  }

  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/shop-packing/shop-packing-pending-list-excel?shop_order_no=${inputs.order_no}&order_item_status=${inputs.order_item_status}&shop_order_port_no_and_name=${inputs.order_port_no_and_name}&shop_order_item_size=${inputs.shop_order_item_size}&shop_order_item_name=${inputs.shop_order_item_name}`,
      config
    );
    return data?.data?.result;
  } catch (error) {
    console.log(error);
    return error?.response?.data?.result;
  }
};
