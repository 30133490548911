import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getAllSales } from "app/redux/actions/salesAction";
import { CuttingListInfoModal } from "app/pages/Order/Cutter/List/cuttingListInfoModal";
import { getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import { getOrderNoList } from "app/services/apis/ListApi/orderNoList";
import { getItemNameList } from "app/services/apis/ListApi/itemNameList";
import { downloadSalesListReport } from "app/services/apis/ReportApis/Sales/SalesListReport";
import { displayDateFun } from "app/utils/constants/functions";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function ListSales() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isItemOpen, setIsItemOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const [inputs, setInputs] = useState({});
  const [orderNoList, setOrderNoList] = useState([]);
  const [portNoNameList, setPortNoNameList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };
  const { allSales, TotalPage, loading } = useSelector(
    (state) => state.salesReducer
  );
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const sortedData = allSales?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_sub_item_name") {
      aValue = a.order_sub_item_name.toLowerCase();
      bValue = b.order_sub_item_name.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllSales(value, "", 1));
  };

  const handleFilter = () => {
    setPage(1);
    dispatch(getAllSales("", inputs, 1));
  };

  const handleClearFilter = () => {
    dispatch(getAllSales("", "", 1));
    setInputs({});
    setResetAutocomplete((prev) => !prev);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
    dispatch(getAllSales(searchTerm, inputs, page));
  };

  useEffect(async () => {
    dispatch(getAllSales("", "", 1));
    setPortNoNameList(await getPortListNoPerm());
    setOrderNoList(await getOrderNoList());
    setItemNameList(await getItemNameList());
  }, []);

  return (
    <Div sx={{ mt: -2 }}>
      <Div sx={{ mt: -4 }}>
        <Typography variant="h1">Sales</Typography>
        <Div
          sx={{
            display: "flex",
            justifyContent: "left",
            gap: 3,
            mt: 3,
            width: "100%",
            //   flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Port No & Name</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={portNoNameList || []}
              value={inputs.order_port_no_and_name}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_port_no_and_name: newValue ? newValue : null,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Order No</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={orderNoList || []}
              value={inputs.order_no}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_no: newValue ? newValue : null,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Item Name</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={itemNameList || []}
              value={inputs.order_item_name}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_item_name: newValue ? newValue : null,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>
          <Button variant="outlined" sx={{ mt: 2.5 }} onClick={handleFilter}>
            Apply
          </Button>
          <Button
            variant="outlined"
            sx={{ mt: 2.5 }}
            onClick={handleClearFilter}
          >
            Clear
          </Button>
        </Div>
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearch(e.target.value);
            }}
            sx={{ width: 300, mb: 5, mt: 4 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
          <Button
            variant="contained"
            disabled={allSales?.length == 0}
            onClick={async () => {
              const data = await downloadSalesListReport(inputs);
              window.open(data);
            }}
          >
            Export CSV
          </Button>
        </Div>
      </Div>
      <>
        {" "}
        {loading && <FullScreenLoader />}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    minWidth: "140px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Port No & Name
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    width: "110px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "order_no"}
                    direction={order}
                    onClick={() => handleSort("order_no")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Order No.
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "order_item_name"}
                    direction={order}
                    onClick={() => handleSort("order_item_name")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Item Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    width: "130px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "order_sub_item_name"}
                    direction={order}
                    onClick={() => handleSort("order_sub_item_name")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Sub Item Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "order_item_job_work_id"}
                    direction={order}
                    onClick={() => handleSort("order_item_job_work_id")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Job Work ID
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "factory_name"}
                    direction={order}
                    onClick={() => handleSort("factory_name")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Factory Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "productions_invoice_no"}
                    direction={order}
                    onClick={() => handleSort("productions_invoice_no")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Factory Bill No.
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    minWidth: "120px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "productions_invoice_date"}
                    direction={order}
                    onClick={() => handleSort("productions_invoice_date")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Invoice Date
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "center",
                    width: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Order Details
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "selling_price_item_price"}
                    direction={order}
                    onClick={() => handleSort("selling_price_item_price")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Raw Manual Issue (QTY)
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "selling_price_item_price"}
                    direction={order}
                    onClick={() => handleSort("selling_price_item_price")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Raw Manual Issue (Rate)
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "selling_price_item_price"}
                    direction={order}
                    onClick={() => handleSort("selling_price_item_price")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Item Price (USD)
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "selling_price_item_price_in_INR"}
                    direction={order}
                    onClick={() =>
                      handleSort("selling_price_item_price_in_INR")
                    }
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Item Price (INR)
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "selling_price_addOns_price"}
                    direction={order}
                    onClick={() => handleSort("selling_price_addOns_price")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Add Ons Price
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "cost_price_suit_cover_cost"}
                    direction={order}
                    onClick={() => handleSort("cost_price_suit_cover_cost")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Suit Cover Price
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "selling_price_freight"}
                    direction={order}
                    onClick={() => handleSort("selling_price_freight")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Freight
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "selling_price_other_charges"}
                    direction={order}
                    onClick={() => handleSort("selling_price_other_charges")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Other Charges
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "total_selling_price"}
                    direction={order}
                    onClick={() => handleSort("total_selling_price")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Total (SP)
                  </TableSortLabel>
                </TableCell>
                {permissions?.sales_edit == true && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "95px",
                      verticalAlign: "middle",
                      color: "white",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      height: "58px",
                      zIndex: 1,
                      bgcolor: "#202020",
                    }}
                  >
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_port_no_and_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_sub_item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_item_job_work_id}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.factory_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.productions_invoice_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {displayDateFun(row?.productions_invoice_date)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", px: 1 }}>
                    <InfoIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsItemOpen(true);
                        setSelectedRowData(row);
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.raw_manually_issue_quantity
                      ? row?.raw_manually_issue_quantity
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.raw_cost_details && row?.raw_manually_issue_quantity
                      ? row?.raw_cost_details * row?.raw_manually_issue_quantity
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.selling_price_item_price
                      ? Math.round(row?.selling_price_item_price)
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.selling_price_item_price_in_INR
                      ? Math.round(row?.selling_price_item_price_in_INR)
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.selling_price_addOns_price
                      ? row?.selling_price_addOns_price
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.cost_price_suit_cover_cost
                      ? row?.cost_price_suit_cover_cost
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.selling_price_freight
                      ? row?.selling_price_freight
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.selling_price_other_charges
                      ? row?.selling_price_other_charges
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.total_selling_price
                      ? Math.round(row?.total_selling_price)
                      : "-"}
                  </TableCell>
                  {permissions?.sales_edit == true && (
                    <TableCell
                      sx={{
                        textAlign: "left",
                        px: 1,
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        bgcolor: "white",
                      }}
                    >
                      <Typography
                        size="small"
                        fontWeight={600}
                        sx={{
                          cursor: "pointer",
                          ":hover": { color: "purple" },
                        }}
                        onClick={() => {
                          navigate("/dashboard/sales/edit", { state: row });
                        }}
                      >
                        Edit
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
            <CuttingListInfoModal
              type="normalOrder"
              open={isItemOpen}
              onClose={() => {
                setIsItemOpen(false);
              }}
              rowData={selectedRowData}
            />
          </Table>
          <Pagination
            count={TotalPage || 1} // Replace with the actual total number of pages
            page={page}
            onChange={handleChangePage}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
              p: 2,
            }}
          />
        </TableContainer>
      </>
    </Div>
  );
}
