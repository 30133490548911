//Raw Material
export const ALL_INVENTORY_RAW_MATERIAL_REQUEST =
  "ALL_INVENTORY_RAW_MATERIAL_REQUEST";
export const ALL_INVENTORY_RAW_MATERIAL_SUCCESS =
  "ALL_INVENTORY_RAW_MATERIAL_SUCCESS";
export const ALL_INVENTORY_RAW_MATERIAL_FAIL =
  "ALL_INVENTORY_RAW_MATERIAL_FAIL";

//Finished Goods
export const ALL_INVENTORY_FINISHED_GOODS_REQUEST =
  "ALL_INVENTORY_FINISHED_GOODS_REQUEST";
export const ALL_INVENTORY_FINISHED_GOODS_SUCCESS =
  "ALL_INVENTORY_FINISHED_GOODS_SUCCESS";
export const ALL_INVENTORY_FINISHED_GOODS_FAIL =
  "ALL_INVENTORY_FINISHED_GOODS_FAIL";

//Production
export const ALL_INVENTORY_PRODUCTION_REQUEST =
  "ALL_INVENTORY_PRODUCTION_REQUEST";
export const ALL_INVENTORY_PRODUCTION_SUCCESS =
  "ALL_INVENTORY_PRODUCTION_SUCCESS";
export const ALL_INVENTORY_PRODUCTION_FAIL = "ALL_INVENTORY_PRODUCTION_FAIL";

//Production HISTORY
export const ALL_INVENTORY_PRODUCTION_HISTORY_REQUEST =
  "ALL_INVENTORY_PRODUCTION_HISTORY_REQUEST";
export const ALL_INVENTORY_PRODUCTION_HISTORY_SUCCESS =
  "ALL_INVENTORY_PRODUCTION_HISTORY_SUCCESS";
export const ALL_INVENTORY_PRODUCTION_HISTORY_FAIL = "ALL_INVENTORY_PRODUCTION_HISTORY_FAIL";

//Wastage
export const ALL_INVENTORY_WASTAGE_REQUEST = "ALL_INVENTORY_WASTAGE_REQUEST";
export const ALL_INVENTORY_WASTAGE_SUCCESS = "ALL_INVENTORY_WASTAGE_SUCCESS";
export const ALL_INVENTORY_WASTAGE_FAIL = "ALL_INVENTORY_WASTAGE_FAIL";

//Raw Scrapped
export const ALL_INVENTORY_RAW_SCRAPPED_REQUEST = "ALL_INVENTORY_RAW_SCRAPPED_REQUEST";
export const ALL_INVENTORY_RAW_SCRAPPED_SUCCESS = "ALL_INVENTORY_RAW_SCRAPPED_SUCCESS";
export const ALL_INVENTORY_RAW_SCRAPPED_FAIL = "ALL_INVENTORY_RAW_SCRAPPED_FAIL";

//Finished Scrapped
export const ALL_INVENTORY_FINISHED_SCRAPPED_REQUEST = "ALL_INVENTORY_FINISHED_SCRAPPED_REQUEST";
export const ALL_INVENTORY_FINISHED_SCRAPPED_SUCCESS = "ALL_INVENTORY_FINISHED_SCRAPPED_SUCCESS";
export const ALL_INVENTORY_FINISHED_SCRAPPED_FAIL = "ALL_INVENTORY_FINISHED_SCRAPPED_FAIL";

//Addons
export const ALL_INVENTORY_ADDONS_REQUEST = "ALL_INVENTORY_ADDONS_REQUEST";
export const ALL_INVENTORY_ADDONS_SUCCESS = "ALL_INVENTORY_ADDONS_SUCCESS";
export const ALL_INVENTORY_ADDONS_FAIL = "ALL_INVENTORY_ADDONS_FAIL";

export const INVENTORY_PURCHASE_ITEM_REQUEST =
  "INVENTORY_PURCHASE_ITEM_REQUEST";
export const INVENTORY_PURCHASE_ITEM_SUCCESS =
  "INVENTORY_PURCHASE_ITEM_SUCCESS";
export const INVENTORY_PURCHASE_ITEM_FAIL = "INVENTORY_PURCHASE_ITEM_FAIL";
export const CLEAR_INVENTORY_PURCHASE_ITEM = "CLEAR_INVENTORY_PURCHASE_ITEM";

//Raw Material Manually Issued
export const ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_REQUEST =
  "ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_REQUEST";
export const ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_SUCCESS =
  "ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_SUCCESS";
export const ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_FAIL =
  "ALL_INVENTORY_RAW_MATERIAL_MANUALLY_ISSUED_FAIL";

//Finished Manually Issued
export const ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_REQUEST =
  "ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_REQUEST";
export const ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_SUCCESS =
  "ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_SUCCESS";
export const ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_FAIL =
  "ALL_INVENTORY_FINISHED_MANUALLY_ISSUED_FAIL";
