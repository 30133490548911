export const LOAD_PURCHASE_REQUEST = "LOAD_PURCHASE_REQUEST";
export const LOAD_PURCHASE_SUCCESS = "LOAD_PURCHASE_SUCCESS";
export const LOAD_PURCHASE_FAIL = "LOAD_PURCHASE_FAIL";

export const ALL_PURCHASE_REQUEST = "ALL_PURCHASE_REQUEST";
export const ALL_PURCHASE_SUCCESS = "ALL_PURCHASE_SUCCESS";
export const ALL_PURCHASE_FAIL = "ALL_PURCHASE_FAIL";

export const LOAD_PURCHASE_RETURN_REQUEST = "LOAD_PURCHASE_RETURN_REQUEST";
export const LOAD_PURCHASE_RETURN_SUCCESS = "LOAD_PURCHASE_RETURN_SUCCESS";
export const LOAD_PURCHASE_RETURN_FAIL = "LOAD_PURCHASE_RETURN_FAIL";

export const ALL_PURCHASE_RETURN_REQUEST = "ALL_PURCHASE_RETURN_REQUEST";
export const ALL_PURCHASE_RETURN_SUCCESS = "ALL_PURCHASE_RETURN_SUCCESS";
export const ALL_PURCHASE_RETURN_FAIL = "ALL_PURCHASE_RETURN_FAIL";
