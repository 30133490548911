import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { GlassMagnifier } from "react-image-magnifiers";

const FabricImageModal = ({ open, onClose, rowData }) => {
  // console.log(rowData, "dddddddddd");
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: "700" }}>
        {rowData?.kc_fabric_code || rowData?.order_item_kc_fabric_code}
      </DialogTitle>
      <DialogContent>
        {rowData && (
          <>
            <GlassMagnifier
              height={"100%"}
              width={"100%"}
              magnifierSize={300}
              imageSrc={`${process.env.REACT_APP_URL}/${
                rowData?.fabric_image || rowData?.image
              }`}
              // largeImageSrc="./large-image.jpg" // Optional
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FabricImageModal;
