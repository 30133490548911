/* eslint-disable default-case */
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function DraftTable() {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = React.useState("sr_no");
  const { draft, error,loading } = useSelector((state) => state.reportReducer);

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = draft?.sort((a, b) => {
    let aValue;
    let bValue;
    if (orderBy == "order_port_no_and_name") {
      aValue = a.order_port_no_and_name.toLowerCase();
      bValue = b.order_port_no_and_name.toLowerCase();
    } else if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_no") {
      aValue = a.order_no.toLowerCase();
      bValue = b.order_no.toLowerCase();
    } else if (orderBy == "order_sub_item_name") {
      aValue = a.order_sub_item_name.toLowerCase();
      bValue = b.order_sub_item_name.toLowerCase();
    } else if (orderBy === "sr_no") {
      // Use index for comparison
      aValue = draft.indexOf(a);
      bValue = draft.indexOf(b);
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }

    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  // useEffect(() => {
  //   if (error == "No order found") {
  //     Swal.fire({ icon: "error", title: "No Result Found" });
  //   }
  // }, []);

  return (
    <>      {loading && <FullScreenLoader />}
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell sx={{ textAlign: "left" }}>
              <TableSortLabel
                active={orderBy === "sr_no"}
                direction={order}
                onClick={() => handleSort("sr_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                S.NO
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_no"}
                direction={order}
                onClick={() => handleSort("order_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Order No
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_name"}
                direction={order}
                onClick={() => handleSort("order_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Product Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_sub_item_name"}
                direction={order}
                onClick={() => handleSort("order_sub_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Item Name
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_kc_fabric_code"}
                direction={order}
                onClick={() => handleSort("order_item_kc_fabric_code")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                KC Code
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_fabric_required"}
                direction={order}
                onClick={() => handleSort("order_item_fabric_required")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Req Mtr
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_port_no_and_name"}
                direction={order}
                onClick={() => handleSort("order_port_no_and_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Port Name & No
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData && sortedData.length > 0 ? (
            sortedData.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>{i + 1}</TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.order_item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.order_sub_item_name}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row?.order_item_kc_fabric_code}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.order_item_fabric_required}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.order_port_no_and_name}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={6}
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                Data not found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        count={0}
        hidePrevButton
        hideNextButton
        hideEllipsis
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
          height: "40px",
        }}
      />
    </TableContainer>
    </>
  );
}
