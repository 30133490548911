import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import PurchaseFinishInfoTable from "./purchaseFinishInfoTable";
import PurchaseRawInfoTable from "./purchaseRawInfoTable";

const PurchaseInfoModal = ({ open, onClose, rowData }) => {
  const { allpurchase } = useSelector((state) => state.purchaseReducer);
  let data = [{ purchase_order_type: "Raw Material" }];
  if (rowData) {
    data = allpurchase?.filter((item) => {
      return item.purchase_order_no === rowData?.purchase_order_no;
    });
    // console.log(data);
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <DialogTitle sx={{ fontWeight: "700" }}>PO Details</DialogTitle>
      <DialogContent>
        <Div sx={{ display: "flex", justifyContent: "space-between" }}>
          <Div>
            <Div sx={{ fontSize: "1rem" }}>
              <strong>PO No.:</strong> {rowData?.purchase_order_no}
            </Div>
            <Div sx={{ fontSize: "1rem" }}>
              <strong>PO Date:</strong> {rowData?.purchase_order_date}
            </Div>
            <Div sx={{ fontSize: "1rem" }}>
              <strong>Supplier Name:</strong>{" "}
              {rowData?.supplier_master?.supplier_name}
            </Div>
          </Div>
          <Div>
            <Div sx={{ fontSize: "1rem" }}>
              <strong>Est Delivery Date:</strong>{" "}
              {rowData?.purchase_estimate_delivery_date}
            </Div>
            <Div sx={{ fontSize: "1rem" }}>
              <strong>Status:</strong> {rowData?.purchase_status}
            </Div>
            <Div sx={{ fontSize: "1rem" }}>
              <strong>Type:</strong> {rowData?.purchase_order_type}
            </Div>
          </Div>
        </Div>

        <Div sx={{ mt: 3 }}>
          <Typography
            sx={{ fontSize: "1rem", fontWeight: "600", color: "black" }}
          >
            Item Details:
          </Typography>
        </Div>
        {data ? (
          data[0]?.purchase_order_type == "Raw Material" ? (
            <PurchaseRawInfoTable
              itemDetails={data[0]?.purchase_items_details}
              itemId={data[0]?.purchase_order_id}
            />
          ) : (
            <PurchaseFinishInfoTable
              itemDetails={data[0]?.purchase_items_details}
              itemId={data[0]?.purchase_order_id}
            />
          )
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size="1.5rem" />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PurchaseInfoModal;
