import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import InfoIcon from "@mui/icons-material/Info";
import {
  Checkbox,
  Chip,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { getPackingOrderList } from "app/redux/actions/orderAction";
import { updatePackingStatus } from "app/services/apis/updatePackingStatus";
import { displayDateFun } from "app/utils/constants/functions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PakingOrderDetailsModal from "./packingOrderModal";

import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import FullScreenLoader from "app/components/ListingPageLoader";

const PackingPendingListTable = ({ searchTerm, setPage, page, inputs }) => {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [addPacking, setAddPacking] = useState([]);

  const { packing, TotalPage, loading } = useSelector(
    (state) => state.orderReducer
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Swal = useSwalWrapper();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getPackingOrderList(searchTerm, inputs, newPage, "packingPending")
    );
  };
  useEffect(() => {
    // Load state from localStorage when component mounts
    const savedGroup = localStorage.getItem("addPacking");
    if (savedGroup) {
      setAddPacking(JSON.parse(savedGroup));
    }
  }, []);

  useEffect(() => {
    // Save state to localStorage whenever addPacking changes
    localStorage.setItem("addPacking", JSON.stringify(addPacking));
  }, [addPacking]);
  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = packing?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_sub_item_name") {
      aValue = a.order_sub_item_name.toLowerCase();
      bValue = b.order_sub_item_name.toLowerCase();
    } else if (orderBy == "order_item_factory_name") {
      aValue = a.order_item_factory_name.toLowerCase();
      bValue = b.order_item_factory_name.toLowerCase();
    } else if (orderBy == "order_item_qc_status") {
      aValue = a.order_item_qc_status.toLowerCase();
      bValue = b.order_item_qc_status.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleInfoIconClick = (rowData) => {
    setSelectedRowData(rowData);
    setInfoModalOpen(true);
  };

  const handleCheckbox = (e, row) => {
    if (e.target.checked == true) {
      console.log("0000000000000");
      const selectedArr = [...addPacking];
      const data = {
        order_items_id: row.order_items_id,
        qc_details_id: row.qc_details_id,
      };
      selectedArr.push(data);
      setAddPacking(selectedArr);
    } else {
      console.log("11111111111");
      const selectedArr = [...addPacking];
      const deletedIds = selectedArr.filter(
        (ele) => ele.qc_details_id != row?.qc_details_id
      );
      console.log(deletedIds, "deletedIds");
      setAddPacking(deletedIds);
    }
  };

  const handleAddPacking = async () => {
    try {
      setIsLoading(true);

      const data = { item_details: addPacking };

      const res = await updatePackingStatus(data);
      console.log(res, "res");
      if (res?.status === 200) {
        Swal.fire({ icon: "success", title: "Packing Done successfully" });
        dispatch(getPackingOrderList("", "", 1, "packingPending"));
        setAddPacking([]);
        navigate("/dashboard/order/packing");
      } else {
        Swal.fire({
          icon: "error",
          title: res.data.message || "Unknown error occurred",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error occurred",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {" "}
      {loading || isLoading ? <FullScreenLoader /> : null}
      {addPacking?.length > 0 && permissions?.packing_order_create == true && (
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
            bgcolor: "#202020",
            p: 2,
            borderRadius: "5px",
          }}
        >
          <Typography variant="h5" sx={{ color: "white", mt: 1 }}>
            {addPacking?.length} Item Selected
          </Typography>
          <Div sx={{ display: "flex", columnGap: 3 }}>
            <LoadingButton
              variant="contained"
              onClick={() => handleAddPacking()}
            >
              Send To Packing
            </LoadingButton>
          </Div>
        </Div>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              {permissions?.packing_order_create && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "50px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  <Checkbox
                    onClick={(event) => {
                      if (event?.target?.checked) {
                        const allRawIds = packing?.map((ele) => ({
                          order_items_id: ele.order_items_id,
                          qc_details_id: ele.qc_details_id,
                        }));
                        const newItems = allRawIds.filter(
                          (newItem) =>
                            !addPacking.some(
                              (existingItem) =>
                                existingItem.order_items_id ===
                                  newItem.order_items_id &&
                                existingItem.qc_details_id ===
                                  newItem.qc_details_id
                            )
                        );
                        setAddPacking([...addPacking, ...newItems]);
                      } else {
                        setAddPacking(
                          addPacking.filter(
                            (item) =>
                              !packing.some(
                                (packItem) =>
                                  packItem.qc_details_id === item.qc_details_id
                              )
                          )
                        );
                      }
                    }}
                    color="primary"
                    checked={
                      addPacking?.length > 0 &&
                      packing
                        ?.map((item) => item.qc_details_id)
                        ?.every((id) =>
                          addPacking?.some(
                            (groupItem) => groupItem.qc_details_id === id
                          )
                        )
                    }
                  />
                </TableCell>
              )}
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Port No & Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_no"}
                  direction={order}
                  onClick={() => handleSort("order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Order No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_no"}
                  direction={order}
                  onClick={() => handleSort("order_item_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  px: 1,
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_name"}
                  direction={order}
                  onClick={() => handleSort("order_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "125px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_sub_item_name"}
                  direction={order}
                  onClick={() => handleSort("order_sub_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sub Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "125px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_job_work_id"}
                  direction={order}
                  onClick={() => handleSort("order_item_job_work_id")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Job Work ID
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "105px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Partial & Complete Status
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_factory_name"}
                  direction={order}
                  onClick={() => handleSort("order_item_factory_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Factory
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item Status
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_status"}
                  direction={order}
                  onClick={() => handleSort("order_item_status")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  QC Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "qc_details_create_date "}
                  direction={order}
                  onClick={() => handleSort("qc_details_create_date ")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  QC Date
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                {permissions?.packing_order_create && (
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    <Checkbox
                      onClick={(event) => handleCheckbox(event, row)}
                      color="primary"
                      checked={addPacking?.some(
                        (ele) => ele.qc_details_id === row.qc_details_id
                      )}
                    />
                  </TableCell>
                )}

                <TableCell sx={{ textAlign: "left" }}>
                  {row.order_port_no_and_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order_item_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.order_item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order_sub_item_name || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.order_item_job_work_id || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <InfoIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleInfoIconClick(row)}
                  />
                </TableCell>

                <TableCell>
                  <Chip
                    color={
                      row?.partial_complete_status === "Completed"
                        ? "success"
                        : "warning"
                    }
                    size={"small"}
                    label={row?.partial_complete_status}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order_item_factory_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.order_item_status}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row.qc_status != null ? row.qc_status : "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.qc_details_create_date)}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.order_date)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
        <PakingOrderDetailsModal
          open={isInfoModalOpen}
          onClose={() => setInfoModalOpen(false)}
          rowData={selectedRowData}
        />
      </TableContainer>
    </>
  );
};

export default PackingPendingListTable;
