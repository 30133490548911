import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { currentdate, dateFun } from "app/utils/constants/functions";
import SearchIcon from "@mui/icons-material/Search";

import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useState } from "react";

import {
  getNewOrderList,
  getReceiveOrderListForCutting,
} from "app/redux/actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import NewOrderListTable from "./newOrderListTable";
import JobWorkIssuedTable from "./jobWorkIssuedTable";
import { getItemNameList } from "app/services/apis/ListApi/itemNameList";
import { getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import { getOrderNoList } from "app/services/apis/ListApi/orderNoList";
import { getjobWorkIdList } from "app/services/apis/ListApi/jobWorkIdList";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";
import { getItemNoList } from "app/services/apis/ListApi/itemNoList";
import { downloadNewOrderReport } from "app/services/apis/ReportApis/Cutting/NewOrderReport";
import { downloadJobWorkIssuedReport } from "app/services/apis/ReportApis/Cutting/JobWorkIssuedReport";

const CutterOrderList = () => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [tabValue, setTabValue] = useState("one");
  const [itemNameList, setItemNameList] = useState([]);
  const [orderNoList, setOrderNoList] = useState([]);
  const [jobWorkidList, setJobWorkidList] = useState([]);
  const [itemNoList, setItemNoList] = useState([]);
  const [portNoNameList, setPortNoNameList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const { receiveOrder } = useSelector((state) => state.orderReducer);
  const { cuttingOrder } = useSelector((state) => state.orderReducer);
  const [page, setPage] = useState(1);
  useEffect(async () => {
    setItemNameList(await getItemNameList());
    setPortNoNameList(await getPortListNoPerm());
    setOrderNoList(await getOrderNoList());
    setJobWorkidList(await getjobWorkIdList());
    setFactoryList(await getFactoryNameList());
    setItemNoList(await getItemNoList());
  }, []);

  const handleFilter = () => {
    setPage(1);
    if (tabValue == "two") {
      dispatch(getReceiveOrderListForCutting("", inputs, 1));
    } else {
      dispatch(getNewOrderList("", inputs, 1));
    }
  };

  const handleClear = () => {
    if (tabValue == "two") {
      dispatch(getReceiveOrderListForCutting("", "", 1));
    } else {
      dispatch(getNewOrderList("", "", 1));
    }
    setPage(1);
    setInputs({
      order_item_name: null,
      order_no: null,
      order_item_job_work_id: null,
      order_port_no_and_name: null,
      order_item_no: null,
      order_item_factory_name: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
      job_work_issued_date_from: null,
      job_work_issued_date_to: null,
    });
    setResetAutocomplete((prev) => !prev);
  };

  const handleSearch = (value) => {
    if (tabValue == "two") {
      dispatch(getReceiveOrderListForCutting(value, ""));
    } else {
      dispatch(getNewOrderList(value, ""));
    }
  };

  useEffect(() => {
    if (tabValue == "two") {
      dispatch(getReceiveOrderListForCutting("", "", 1));
    } else {
      dispatch(getNewOrderList("", "", 1));
    }
    setInputs({
      order_item_name: null,
      order_no: null,
      order_item_job_work_id: null,
      order_port_no_and_name: null,
      order_item_no: null,
      order_item_factory_name: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
      job_work_issued_date_from: null,
      job_work_issued_date_to: null,
    });
    setResetAutocomplete((prev) => !prev);
  }, [tabValue]);

  return (
    <Div>
      <Typography variant="h1" sx={{ mt: -4 }}>
        Cutter Order
      </Typography>
      <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Item Name</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={itemNameList || []}
            value={inputs.order_item_name}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_item_name: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>

        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Order No</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={orderNoList || []}
            value={inputs.order_no}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_no: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        {tabValue == "two" && (
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Job Work ID</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={jobWorkidList || []}
              value={inputs.order_item_job_work_id}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_item_job_work_id: newValue ? newValue : null,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>
        )}
        

        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Port No./Port Name</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={portNoNameList || []}
            value={inputs.order_port_no_and_name}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_port_no_and_name: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Item No</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={itemNoList || []}
            value={inputs.order_item_no}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_item_no: newValue ? newValue : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Factory</Typography>
          <Autocomplete
            key={resetAutocomplete ? "reset1" : "normal1"}
            size="small"
            id="tags-standard"
            options={factoryList || []}
            defaultValue={inputs?.order_item_factory_name}
            getOptionLabel={(option) => option?.factory_name}
            onChange={(e, newValue) => {
              setInputs((values) => ({
                ...values,
                order_item_factory_name: newValue?.factory_name
                  ? newValue?.factory_name
                  : null,
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.factory_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Div>

        <Div>
          <Typography variant="h5">Delivery Date Range</Typography>
          <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "45%" }}>
                <DatePicker
                  maxDate={dayjs()}
                  format="DD/MM/YYYY"
                  value={inputs?.order_est_delivery_date_from}
                  error={!inputs?.order_est_delivery_date_from}
                  onChange={(newDate) =>
                    (inputs.order_est_delivery_date_from = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "45%" }}>
                <DatePicker
                  maxDate={dayjs()}
                  format="DD/MM/YYYY"
                  value={inputs?.order_est_delivery_date_to}
                  error={!inputs?.order_est_delivery_date_to}
                  onChange={(newDate) =>
                    (inputs.order_est_delivery_date_to = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "&.MuiTextField-root": {
                      height: "39px",
                      flexDirection: "unset",
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
          </Div>
        </Div>
        {tabValue == "two" && (
        <Div>
          <Typography variant="h5">Jobwork Issued Date Range</Typography>
          <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "45%" }}>
                <DatePicker
                  maxDate={dayjs()}
                  key={resetAutocomplete ? "reset1" : "normal1"}
                  value={inputs?.job_work_issued_date_from}
                  error={!inputs?.job_work_issued_date_from}
                  // defaultValue={dayjs(currentdate)}
                  // value={estimateDate}
                  // value={inputs?.job_work_issued_date}
                  format="DD-MM-YYYY"
                  onChange={(newDate) =>
                    (inputs.job_work_issued_date_from = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-input": {
                      padding: 1,
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Div sx={{ width: "45%" }}>
                <DatePicker
                  maxDate={dayjs()}
                  key={resetAutocomplete ? "reset1" : "normal1"}
                  // defaultValue={dayjs(currentdate)}
                  // value={estimateDate}
                  // value={inputs?.job_work_issued_date}
                  value={inputs?.job_work_issued_date_to}
                  error={!inputs?.job_work_issued_date_to}
                  format="DD-MM-YYYY"
                  onChange={(newDate) =>
                    (inputs.job_work_issued_date_to = dateFun(newDate))
                  }
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-input": {
                      padding: 1,
                    },
                  }}
                />
              </Div>
            </LocalizationProvider>
          </Div>
        </Div>
                )}
        
      </Div>
      
      {/* apply and clear */}
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          justifyContent: "space-between",
        }}
      >
        <Div>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 1, pl: 4, pr: 4 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Div>

        <Button
          disabled={
            tabValue == "one"
              ? cuttingOrder?.length == 0
              : receiveOrder?.length == 0
          }
          variant="contained"
          onClick={async () => {
            if (tabValue == "one") {
              const data = await downloadNewOrderReport(inputs);
              window.open(data);
            } else {
              const data = await downloadJobWorkIssuedReport(inputs);
              window.open(data);
            }
          }}
        >
          Export CSV
        </Button>
      </Div>

      {/* search */}
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          mt: 3.5,
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          size="small"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mt: "auto" }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
      </Div>
      <Tabs
        sx={{ mb: 2 }}
        value={tabValue}
        onChange={(e, value) => {
          setTabValue(value);
          // console.log(value);
        }}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        <Tab value="one" label="New Order" />
        <Tab value="two" label="Job Work Issued" />
      </Tabs>
      {/* Table */}

      {tabValue == "two" ? (
        <JobWorkIssuedTable
          searchTerm={searchTerm}
          setPage={setPage}
          page={page}
          inputs={inputs}
        />
      ) : (
        <NewOrderListTable
          searchTerm={searchTerm}
          setPage={setPage}
          page={page}
          inputs={inputs}
        />
      )}
    </Div>
  );
};

export default CutterOrderList;
