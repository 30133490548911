import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { getAllOrders, getNewOrderList } from "app/redux/actions/orderAction";
import { getAvailableTaka } from "app/services/apis/ListApi/getAvailableTaka";
import axios from "axios";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import "../../../../pages/css/swal.css";
import Swal from "sweetalert2";
import { changeTaka } from "app/services/apis/changeTaka";

export const CuttingListInfoModal = ({
  open,
  onClose,
  rowData,
  page,
  type,
}) => {
  const { pathname } = useLocation();
  const [details, setDetails] = useState([]);
  const [scanValue, setScanValue] = useState("");
  const [takaList, setTakaList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [inputs, setInputs] = useState();
  const dispatch = useDispatch();
  const HandleScan = async () => {
    const fabric_data =
      details?.order_item_kc_fabric_code + details?.order_item_taka_no;
    if (scanValue == fabric_data.replace(/-/g, "")) {
      try {
        setLoader(true);
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const data = await axios.patch(
          `${process.env.REACT_APP_URL}/api/challan/scanner-code-verify?id=${
            details?.id || details?.order_items_id
          }`,
          { order_item_verify_scanner_code_status: true },
          config
        );
        if (data?.status == 200) {
          if (pathname == "/dashboard/order/admin") {
            dispatch(getAllOrders("", "", page));
          } else {
            dispatch(getNewOrderList("", "", page));
          }
          setScanValue("");
          onClose();
        }
        // console.log(data);
      } catch (error) {
        // console.log(error);
        // console.log(error.response);
      } finally {
        setLoader(false);
      }
    } else {
      setScanValue("");
    }
  };

  const handleTakaChange = async (newValue) => {
    setInputs((values) => ({
      required_quantity: newValue ? details?.order_item_fabric_required : null,
      new_taka_id: newValue ? newValue.id : null,
      old_taka_id: details?.taka_id ? details?.taka_id : null,
      item_id: details?.id
        ? details.id
        : details?.order_items_id
        ? details.order_items_id
        : null,
    }));
    const updateDetails = {
      required_quantity: newValue ? details?.order_item_fabric_required : null,
      new_taka_id: newValue ? newValue.id : null,
      old_taka_id: details?.taka_id ? details?.taka_id : null,
      item_id: details?.id
        ? details.id
        : details?.order_items_id
        ? details.order_items_id
        : null,
    };
    if (newValue?.id != null) {
      Swal.fire({
        title: `Are you sure you want to update the taka?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Update it!",
        customClass: {
          container: "popupImportant",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = await changeTaka(updateDetails);

          if (data?.status == 200) {
            if (pathname == "/dashboard/order/admin") {
              dispatch(getAllOrders("", "", page));
            } else {
              dispatch(getNewOrderList("", "", page));
            }
            // dispatch(getNewOrderList("", "", page));
            onClose();
          }

          Swal.fire({
            title: "Updated!",
            text: "Your Taka has been updated.",
            icon: "success",
            customClass: {
              container: "popupImportant",
            },
          });
        }
      });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (pathname === "/dashboard/order/cutter") {
        setDetails(rowData?.item);
        let data = await getAvailableTaka(
          rowData?.item?.order_item_kc_fabric_code,
          rowData?.item?.order_item_fabric_required
        );
        const existingTaka = {
          taka_no: rowData?.item?.order_item_taka_no,
          id: rowData?.item?.taka_id,
          raw_rack_details: rowData?.item?.order_item_rack_details,
          taka_reserve_quantity: rowData?.item?.order_item_fabric_required,
        };
        const isExistingTakaPresent = data.some(
          (item) => item.id === existingTaka.id
        );
        if (!isExistingTakaPresent) {
          setTakaList([...data, existingTaka]);
        } else {
          setTakaList(data);
        }
      } else if (pathname === "/dashboard/order/admin") {
        setDetails(rowData);
        let data = await getAvailableTaka(
          rowData?.order_item_kc_fabric_code,
          rowData?.order_item_fabric_required
        );
        const existingTaka = {
          taka_no: rowData?.order_item_taka_no,
          id: rowData?.taka_id,
          raw_rack_details: rowData?.order_item_rack_details,
          taka_reserve_quantity: rowData?.order_item_fabric_required,
        };
        const isExistingTakaPresent = data.some(
          (item) => item.id === existingTaka.id
        );
        if (!isExistingTakaPresent) {
          setTakaList([...data, existingTaka]);
        } else {
          setTakaList(data);
        }
      } else {
        setDetails(rowData);
      }
    };

    fetchData();
  }, [rowData, pathname]);
  // useEffect(async () => {
  //   if (pathname === "/dashboard/order/cutter") {
  //     setDetails(rowData?.item);
  //     console.log("vvvvv");
  //     if (pathname === "/dashboard/order/admin") {
  //       setDetails(rowData);
  //       let data = await getAvailableTaka(
  //         rowData?.order_item_kc_fabric_code,
  //         rowData?.order_item_fabric_required
  //       );
  //       const existingTaka = {
  //         taka_no: rowData?.order_item_taka_no,
  //         id: rowData?.taka_id,
  //         raw_rack_details: rowData?.order_item_rack_details,
  //         taka_reserve_quantity: rowData?.order_item_fabric_required,
  //       };
  //       const isExistingTakaPresent = data.some(
  //         (item) => item.id === existingTaka.id
  //       );

  //       if (!isExistingTakaPresent) {
  //         setTakaList([...data, existingTaka]);
  //       } else {
  //         setTakaList(data);
  //       }
  //     }
  //     let data = await getAvailableTaka(
  //       rowData?.item?.order_item_kc_fabric_code,
  //       rowData?.item?.order_item_fabric_required
  //     );
  //     const existingTaka = {
  //       taka_no: rowData?.item?.order_item_taka_no,
  //       id: rowData?.item?.taka_id,
  //       raw_rack_details: rowData?.item?.order_item_rack_details,
  //       taka_reserve_quantity: rowData?.item?.order_item_fabric_required,
  //     };

  //     // Check if the id of existingTaka is present in the data array
  //     const isExistingTakaPresent = data.some(
  //       (item) => item.id === existingTaka.id
  //     );

  //     if (!isExistingTakaPresent) {
  //       setTakaList([...data, existingTaka]);
  //     } else {
  //       setTakaList(data); // Set takaList to data without adding existingTaka
  //     }
  //   } else {
  //     setDetails(rowData);
  //   }
  // }, [rowData]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <DialogTitle sx={{ fontWeight: "700" }}>Order Detail</DialogTitle>
      <DialogContent>
        {details?.order_item_verify_scanner_code_status == 0 && (
          <Typography
            variant="h2"
            sx={{
              color: "red",
              textAlign: "center",
              mb: 2,

              animation: "blink 1s infinite",
              "@keyframes blink": {
                "0%": { opacity: 1 },
                "50%": { opacity: 0 },
                "100%": { opacity: 1 },
              },
            }}
          >
            Scanner is Active
          </Typography>
        )}
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item No.
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "140px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                SubItem Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Code
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Fabric Qty
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Rack Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Taka No.
              </TableCell>
              {type != "normalOrder" && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "80px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Scancode
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{details?.order_item_no}</TableCell>
              <TableCell>{details?.order_item_name}</TableCell>
              <TableCell>
                {details?.order_sub_item_name == ""
                  ? "-"
                  : details?.order_sub_item_name}
              </TableCell>
              <TableCell>{details?.order_item_kc_fabric_code}</TableCell>
              <TableCell>{details?.order_item_fabric_required}</TableCell>
              <TableCell>
                {details?.order_item_rack_details
                  ? details?.order_item_rack_details
                  : "-"}
              </TableCell>
              <TableCell>
                {details?.order_item_taka_no
                  ? details?.order_item_taka_no
                  : "-"}
              </TableCell>
              {console.log(inputs, "ggggggggggggggggggggggg")}
              {type != "normalOrder" && (
                <TableCell>
                  {scanValue != "" &&
                  details?.order_item_verify_scanner_code_status == 0 ? (
                    <LoadingButton
                      loading={loader}
                      onClick={HandleScan}
                      size="small"
                      variant="contained"
                    >
                      Verify
                    </LoadingButton>
                  ) : (
                    "Scan Code"
                  )}
                  {details?.order_item_verify_scanner_code_status == 1 && (
                    <Typography>Verified</Typography>
                  )}
                  <TextField
                    autoFocus
                    disabled={
                      details?.order_item_verify_scanner_code_status == 1
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setScanValue(e.target.value);
                    }}
                    sx={{
                      position: "absolute", // Position it absolutely
                      top: "-9999px", // Move it out of the viewport
                      left: "-9999px",
                    }}
                  />

                  {/* {console.log(scanValue)} */}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>

        {details?.order_item_addOns && (
          <>
            <Typography variant="h5" sx={{ mt: 3, fontWeight: 800 }}>
              Addons:
            </Typography>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Send To Factory
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details?.order_item_addOns?.map((row, i) => (
                  <>
                    <TableRow key={i}>
                      <TableCell>{row?.itemName?.toUpperCase()}</TableCell>
                      <TableCell>{row?.quantity}</TableCell>
                      <TableCell>{row?.status?.toUpperCase()}</TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </>
        )}

        {details?.dispatch_addons_items?.length > 0 && (
          <>
            <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
              Addons:
            </Typography>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Order Quantity
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Dispatch Quantity
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Send To Factory
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData?.dispatch_addons_items
                  ?.filter((ele) => ele.order_item_addons_id)
                  .map((row, i) => (
                    <>
                      <TableRow key={i}>
                        <TableCell>{row?.itemName}</TableCell>
                        <TableCell>{row?.addons_quantity}</TableCell>
                        <TableCell>{row?.dispatch_quantity}</TableCell>
                        <TableCell>
                          {row?.addons_status.toUpperCase()}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </>
        )}

        {details?.addons_items?.length > 0 && (
          <>
            <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
              Addons:
            </Typography>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    Send To Factory
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData?.addons_items
                  ?.filter((ele) => ele.order_item_addons_id)
                  .map((row, i) => (
                    <>
                      <TableRow key={i}>
                        <TableCell>{row?.itemName}</TableCell>
                        <TableCell>{row?.addons_quantity}</TableCell>
                        <TableCell>
                          {row?.addons_status.toUpperCase()}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </>
        )}

        {details?.order_item_verify_scanner_code_status == 0 && (
          <Div sx={{ width: "50%", mt: 3 }}>
            <Typography variant="h5">Update Taka:-</Typography>
            {console.log(takaList, "takaList")}
            <Autocomplete
              size="small"
              id="tags-standard"
              options={
                takaList?.filter((item) => {
                  return item.taka_no != details?.order_item_taka_no;
                }) || []
              }
              getOptionLabel={(option) => {
                if (typeof option === "object" && option !== null) {
                  return `Taka No:- ${option.taka_no} Rack:- ${option.raw_rack_details} Quantity:- ${option.taka_reserve_quantity}`;
                } else {
                  return option;
                }
              }}
              onChange={(e, newValue) => {
                handleTakaChange(newValue);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  Taka No:- {option.taka_no} | Rack:- {option.raw_rack_details}{" "}
                  | Quantity:- {option.taka_reserve_quantity}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>
        )}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={onClose} color="primary">
          Close
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};
