import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
export const ChallanMeasurement = ({ data }) => {
  // console.log(data);
  let state = data?.order_measurement?.filter(
    (item) =>
      item?.item_master_item_name ==
      (data?.item?.order_sub_item_name || data?.order_sub_item_name)
  );
  // console.log(state);
  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };
  return (
    <Div sx={{ mt: 5 }}>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 3,
        }}
      >
        <Typography variant="h3" sx={{ mt: "auto", fontWeight: "600" }}>
          Measurements:-
        </Typography>
      </Div>
      <Div sx={{ width: "100%" }}>
        <Div>
          {state?.length > 0 &&
            state?.map((item, index) => {
              const measurementKeys = Object.keys(
                item?.item_master_measurement
              );
              const chunkedKeys = chunkArray(measurementKeys, 5);

              return (
                <Div
                  sx={{
                    width: "100%",
                    mt: 3,
                    mb: 5,
                    border: "3px solid black",
                    p: 2,
                    borderRadius: "5px",
                    bgcolor: "#fbfef9",
                  }}
                  key={index}
                >
                  <Typography variant="h2" sx={{ textDecoration: "underline" }}>
                    {item.item_master_item_name}
                  </Typography>

                  {chunkedKeys.map((keys, tableIndex) => (
                    <Table key={tableIndex}>
                      <TableHead>
                        <TableRow
                          sx={{
                            bgcolor: "#202020",
                            color: "white",
                            width: "100%",
                          }}
                        >
                          {keys.map(
                            (key, i) =>
                              key && ( // Only render TableCell when key has a value
                                <TableCell
                                  key={i}
                                  sx={{
                                    textAlign: "left",
                                    width: `${100 / 5}%`, // Equal width for 5 keys
                                    color: "white",
                                    border: "2px solid white",
                                    borderRadius: "10px",
                                    padding: "5px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {key}
                                </TableCell>
                              )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          {Array.from({ length: 5 }).map((_, i) => (
                            <TableCell
                              key={i}
                              sx={{
                                maxWidth: "100% !important",
                              }}
                            >
                              {keys[i] && (
                                <TextField
                                  size="small"
                                  disabled
                                  name={keys[i]}
                                  value={
                                    item?.item_master_measurement[keys[i]] || ""
                                  }
                                  sx={{
                                    width: "100%",
                                    "& .Mui-disabled": {
                                      WebkitTextFillColor: "black !important", // Set your custom color for disabled state
                                    },
                                  }}
                                />
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
                </Div>
              );
            })}
        </Div>
      </Div>
    </Div>
  );
};
