import Div from "@jumbo/shared/Div";
import {
  Button,
  Checkbox,
  Chip,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import React, { useEffect, useState } from "react";
import { CuttingListInfoModal } from "../../Cutter/List/cuttingListInfoModal";
import ReceiveOrderDetailsModal from "../../Receive/List/receiveOrderDetails";
import dayjs from "dayjs";
import {
  currentdate,
  dateFun,
  displayDateFun,
} from "app/utils/constants/functions";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { getLatestDispatchId } from "app/services/apis/OrderApis/getLatestDispatchId";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import NormalAddonsModal from "./normalOrderAddons";
import FullScreenLoader from "app/components/ListingPageLoader";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CreateNewDispatch = () => {
  const [portNoNameList, setPortNoNameList] = useState([]);
  const [orderDeatailModal, setOrderDeatailModal] = useState("");
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [isAddonsModalOpen, setAddonsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [orderData, setOrderData] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [emptyAddons, setEmptyAddons] = useState([]);
  const [itemIndex, setItemIndex] = useState(null);
  const [selectedPort, setSelectedPort] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [dispatchId, setDispatchId] = useState(0);
  const [inputs, setInputs] = useState({
    dispatch_type: "Normal Order",
    dispatch_order_status: "",
    dispatch_port_no_and_name: "",
    dispatch_shipment_no: "",
    transport_type: "",
    awb_bl_no: "",
    dispatch_date: dateFun(currentdate),
    dispatch_box_no: "",
    dispatch_weight: "",
    dispatch_quantity: itemDetails.length,
    dispatch_est_delivery_date: dateFun(currentdate),
    dispatch_contact_person_name: "",
    dispatch_contact_person_phone_no: "",
  });
  const navigate = useNavigate();
  // console.log(orderData);
  const handleChange = (event) => {
    // console.log(event, "tttttttttttttt");
    const name = event.target.name;
    const value = event.target.value;
    if (name == "dispatch_port_no_and_name") {
      setOrderData([]);
      setSelectedPort(value);
    }
    if (name == "dispatch_type") {
      setSelectedPort("");
    }
    setInputs((values) => ({
      ...values,
      [name]: name == "dispatch_weight" ? parseFloat(value) : value,
    }));
  };

  const sortedData = orderData?.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleModal = (row, type, index) => {
    if (type == "addons") {
      setSelectedRowData(row);
      setItemIndex(index);
      setAddonsModalOpen(true);
    } else {
      if (row.order_item_status == "New Order") {
        setOrderDeatailModal("New Order");
        setSelectedRowData(row);
        setInfoModalOpen(true);
      } else {
        setOrderDeatailModal("");
        setSelectedRowData(row);
        setInfoModalOpen(true);
      }
    }
  };
  // console.log(itemDetails);
  const handleSelect = (e, data, rowIndex, type) => {
    if (type != "shop") {
      if (e.target.checked != true) {
        let updatedItemDetails = [...itemDetails];
        let removeItemIndex = updatedItemDetails?.findIndex((item) => {
          return item?.order_items_id == orderData[rowIndex]?.order_items_id;
        });
        updatedItemDetails[removeItemIndex] = undefined;

        setItemDetails(updatedItemDetails);
      } else {
        const updatedItemDetails = [...itemDetails];

        let finalupdatedItemDetails = {
          order_items_id: data.order_items_id,
          orders_id: data.orders_id,
          dispatch_addons_items:
            data.addons_items.length > 0
              ? data.addons_items.map((ele, i, arr) => {
                  return {
                    ...arr[i],
                    dispatch_quantity: 0,
                  };
                })
              : [],
        };
        setItemDetails([...updatedItemDetails, finalupdatedItemDetails]);
      }
    } else {
      if (e.target.value == "") {
        const updatedItemDetails = [...itemDetails];
        updatedItemDetails[rowIndex] = null;
        setItemDetails(updatedItemDetails);
      } else {
        const updatedItemDetails = [...itemDetails];
        updatedItemDetails[rowIndex] = {
          shop_order_items_id: data?.shop_order_items_id,
          shop_order_id: data?.shop_order_id,
          shop_order_item_size: data.shop_order_item_size,
          shop_order_item_name: data.shop_order_item_name,
          dispatch_quantity: Number(e.target.value),
          require_quantity: data?.shop_order_item_require_quantity,
          balance_quantity: data?.balance_quantity,
        };
        setItemDetails(updatedItemDetails);
      }
    }
  };

  const submitForm = async () => {
    let body = {};
    if (inputs?.dispatch_type == "Normal Order") {
      body = {
        ...inputs,
        dispatch_quantity: itemDetails.length,
        dispatch_id: dispatchId,
        dispatch_items: itemDetails
          .filter((item) => typeof item == "object" && item !== null)
          .map((item) => ({
            ...item,
            dispatch_addons_items: item?.dispatch_addons_items?.filter(
              (addon) => addon !== null
            ),
          })),
      };
    } else {
      body = {
        dispatch_type: inputs?.dispatch_type,
        shop_order_dispatch_port_no_and_name: inputs.dispatch_port_no_and_name,
        shop_order_transport_type: inputs.transport_type,
        shop_order_awb_bl_no: inputs.awb_bl_no,
        shop_order_dispatch_date: inputs.dispatch_date,
        shop_order_dispatch_box_no: inputs?.dispatch_box_no,
        shop_order_dispatch_weight: inputs.dispatch_weight,
        shop_order_dispatch_contact_person_name:
          inputs.dispatch_contact_person_name,
        shop_order_dispatch_contact_person_phone_no:
          inputs.dispatch_contact_person_phone_no,
        shop_order_dispatch_est_delivery_date:
          inputs.dispatch_est_delivery_date,
        shop_order_dispatch_quantity: itemDetails
          ?.filter((item) => item !== null)
          .reduce((a, item) => {
            return a + (item?.dispatch_quantity || 0);
          }, 0),
        shop_order_dispatch_id: dispatchId,
        dispatch_shipment_no: inputs.dispatch_shipment_no,
        dispatch_items: itemDetails.filter(
          (item) => typeof item == "object" && item !== null
        ),
      };
    }
    try {
      setSubmitLoader(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      if (inputs.dispatch_type == "Normal Order") {
        const data = await axios.post(
          `${process.env.REACT_APP_URL}/api/dispatch/add-dispatch`,
          body,
          config
        );
        if (data?.status == 200) {
          Swal.fire({ icon: "success", title: "Dispatch Created" });
          navigate("/dashboard/order/dispatch");
        }
      } else {
        const data = await axios.post(
          `${process.env.REACT_APP_URL}/api/shop-order-dispatch/add-shop-order-dispatch`,
          body,
          config
        );
        if (data?.status == 200) {
          Swal.fire({ icon: "success", title: "Dispatch Created" });
          navigate("/dashboard/order/dispatch");
        }
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: error?.response?.data?.message });
    } finally {
      setSubmitLoader(false);
    }
  };

  useEffect(async () => {
    setPortNoNameList(await getPortListNoPerm());
  }, []);

  useEffect(async () => {
    if (selectedPort != "") {
      setItemDetails([]);
      try {
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        if (inputs?.dispatch_type == "Normal Order") {
          const data = await axios.get(
            `${process.env.REACT_APP_URL}/api/dispatch/list-partial-and-completed?order_port_no_and_name=${selectedPort}`,
            config
          );
          if (data?.status == 200) {
            setOrderData(data.data.result);
          }
        } else {
          const data = await axios.get(
            `${process.env.REACT_APP_URL}/api/list-order/get-all-shop-order-status-in-dispatch?shop_order_port_no_and_name=${selectedPort}`,
            config
          );
          if (data?.status == 200) {
            setOrderData(data.data.result);
          }
        }
      } catch (error) {
        // console.log(error?.response?.data?.result);
      }
    }
  }, [selectedPort]);

  // console.log(itemDetails, "jtyh");

  const handleSearch = async (value) => {
    if (inputs?.dispatch_type == "Normal Order") {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/dispatch/list-partial-and-completed?order_port_no_and_name=${selectedPort}&search=${value}`,
        config
      );
      if (data?.status == 200) {
        // if (
        //   itemDetails.length > 0 &&
        //   !itemDetails.every((item) => item == undefined)
        // ) {
        //   const index = data.data.result.findIndex(item => item.order_items_id === );
        // }
        // console.log(data.data.result, "ressss");
        setOrderData(data.data.result);
      }
    }
  };

  useEffect(async () => {
    if (inputs.dispatch_type == "Normal Order") {
      setItemDetails([]);
      setDispatchId(await getLatestDispatchId());
    } else {
      setItemDetails([]);
      setDispatchId(await getLatestDispatchId("shop"));
    }
    setOrderData([]);
    setItemDetails([]);
    setInputs({ ...inputs, dispatch_port_no_and_name: "" });
  }, [inputs?.dispatch_type]);

  return (
    <Div>
      <Typography variant="h1" sx={{ mt: -4, mb: 3 }}>
        Create New Dispatch
      </Typography>
      <Div sx={{ display: "flex", columnGap: 5, mb: 5 }}>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Dispatch Type</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            name="dispatch_type"
            onChange={handleChange}
            value={inputs?.dispatch_type}
          >
            <MenuItem value={"Normal Order"}>{"Normal Order"}</MenuItem>
            <MenuItem value={"Shop Order"}>{"Shop Order"}</MenuItem>
          </Select>
        </Div>
        <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Select Port</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            name="dispatch_port_no_and_name"
            value={inputs?.dispatch_port_no_and_name}
            onChange={handleChange}
          >
            {portNoNameList?.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        </Div>
        {inputs?.dispatch_type == "Normal Order" &&
          inputs?.dispatch_port_no_and_name != "" && (
            <Div sx={{ mt: 3.3 }}>
              <TextField
                size="small"
                id="search"
                type="search"
                label="Search"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  handleSearch(e.target.value);
                }}
                sx={{ width: 300, mt: "auto" }}
                InputProps={{
                  endAdornment: (
                    <Div sx={{ cursor: "pointer" }}>
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    </Div>
                  ),
                }}
              />
            </Div>
          )}
        {/* <Div sx={{ width: "23%" }}>
          <Typography variant="h5">Order Status</Typography>
          <Select
            size="small"
            sx={{ width: "100%" }}
            name="dispatch_order_status"
            onChange={handleChange}
            value={inputs?.dispatch_order_status}
          >
            <MenuItem value={"Complete Order"}>{"Complete Order"}</MenuItem>
            <MenuItem value={"Partial Order"}>{"Partial Order"}</MenuItem>
          </Select>
        </Div> */}
      </Div>
      {orderData.length > 0 && (
        <>
          {inputs?.dispatch_type == "Normal Order" ? (
            <Div>
              <Div sx={{ width: "100%", bgcolor: "black" }}>
                <Typography
                  variant="h4"
                  sx={{
                    bgcolor: "#202020",
                    color: "white",
                    p: 1,
                    mb: "-2",
                  }}
                >
                  {inputs.dispatch_port_no_and_name}
                </Typography>
              </Div>

              <TableContainer component={Paper} sx={{ height: "50vh" }}>
                <Table>
                  <TableHead sx={{ position: "sticky", top: 0, zIndex: "2" }}>
                    <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "90px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Select
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "160px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "120px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Order No
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "120px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item No.
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "130px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "160px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Sub Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "135px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Job Work ID
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "120px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        AddOns
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "120px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Order Details
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "100px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Factory
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "130px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Order Date
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "170px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Exp. Delivery Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      height: "10vh",
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": {
                        width: "12px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#6b6b6b",
                        borderRadius: "2px",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "#d4d4d4",
                        borderRadius: "1px",
                      },
                    }}
                  >
                    {sortedData?.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          bgcolor:
                            emptyAddons?.includes(
                              row?.order_item_job_work_id
                            ) && "#ef233c !important",
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: "left",
                          }}
                        >
                          {/* {console.log(row)} */}
                          {/* {console.log(itemDetails)} */}
                          <Checkbox
                            {...label}
                            sx={{
                              color:
                                emptyAddons?.includes(
                                  row?.order_item_job_work_id
                                ) && "#FFFF !important",
                            }}
                            onChange={(e) => handleSelect(e, row, i)}
                            checked={itemDetails?.some(
                              (item) =>
                                item?.order_items_id === row?.order_items_id
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            color={
                              row?.order_status === "Completed"
                                ? "success"
                                : "warning"
                            }
                            size={"small"}
                            label={row?.order_status}
                          />
                        </TableCell>

                        {/* <TableCell>{row.order_status}</TableCell> */}
                        <TableCell
                          sx={{
                            textAlign: "left",
                            color:
                              emptyAddons?.includes(
                                row?.order_item_job_work_id
                              ) && "#FFFF !important",
                          }}
                        >
                          {row.order_no}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            color:
                              emptyAddons?.includes(
                                row?.order_item_job_work_id
                              ) && "#FFFF !important",
                          }}
                        >
                          {row.order_item_no}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            color:
                              emptyAddons?.includes(
                                row?.order_item_job_work_id
                              ) && "#FFFF !important",
                          }}
                        >
                          {row.order_item_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            color:
                              emptyAddons?.includes(
                                row?.order_item_job_work_id
                              ) && "#FFFF !important",
                          }}
                        >
                          {row.order_sub_item_name
                            ? row.order_sub_item_name
                            : "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            color:
                              emptyAddons?.includes(
                                row?.order_item_job_work_id
                              ) && "#FFFF !important",
                          }}
                        >
                          {row.order_item_job_work_id
                            ? row.order_item_job_work_id
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", pl: "3%" }}>
                          <ControlPointIcon
                            sx={{
                              cursor: "pointer",
                              color:
                                emptyAddons?.includes(
                                  row?.order_item_job_work_id
                                ) && "#FFFF !important",
                            }}
                            onClick={() => {
                              // console.log(itemDetails, row?.order_items_id);
                              if (
                                itemDetails?.some(
                                  (item) =>
                                    item &&
                                    item?.order_items_id === row?.order_items_id
                                )
                              ) {
                                handleModal(row, "addons", i);
                              } else {
                                return Swal.fire({
                                  icon: "warning",
                                  text: "Select Item",
                                });
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", pl: "3%" }}>
                          <InfoIcon
                            sx={{
                              cursor: "pointer",
                              color:
                                emptyAddons?.includes(
                                  row?.order_item_job_work_id
                                ) && "#FFFF !important",
                            }}
                            onClick={() => handleModal(row)}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            color:
                              emptyAddons?.includes(
                                row?.order_item_job_work_id
                              ) && "#FFFF !important",
                          }}
                        >
                          {row.order_item_factory_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            color:
                              emptyAddons?.includes(
                                row?.order_item_job_work_id
                              ) && "#FFFF !important",
                          }}
                        >
                          {displayDateFun(row.order_date)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              disabled
                              format="DD/MM/YY"
                              defaultValue={dayjs(row.order_est_delivery_date)}
                              sx={{
                                width: "100%",
                                "&.MuiTextField-root": {
                                  height: "39px",
                                  flexDirection: "unset",
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {orderDeatailModal == "New Order" ? (
                  <CuttingListInfoModal
                    open={isInfoModalOpen}
                    onClose={() => {
                      setInfoModalOpen(false);
                    }}
                    rowData={selectedRowData}
                  />
                ) : (
                  <ReceiveOrderDetailsModal
                    open={isInfoModalOpen}
                    onClose={() => setInfoModalOpen(false)}
                    rowData={selectedRowData}
                  />
                )}
                <NormalAddonsModal
                  itemDetails={itemDetails}
                  setItemDetails={setItemDetails}
                  index={itemIndex}
                  open={isAddonsModalOpen}
                  onClose={() => setAddonsModalOpen(false)}
                  rowData={selectedRowData}
                />
              </TableContainer>
            </Div>
          ) : (
            <Div>
              <Div sx={{ width: "100%", bgcolor: "black" }}>
                <Typography
                  variant="h4"
                  sx={{
                    bgcolor: "#202020",
                    color: "white",
                    p: 1,
                    mb: "-2",
                  }}
                >
                  {inputs.dispatch_port_no_and_name}
                </Typography>
              </Div>
              <TableContainer
                component={Paper}
                sx={{ height: "50vh", overflowY: "scroll" }}
              >
                <Table>
                  <TableHead sx={{ position: "sticky", top: 0, zIndex: "2" }}>
                    <TableRow
                      sx={{ bgcolor: "#202020", color: "white", width: "100%" }}
                    >
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Select
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Order No
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item No
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item Size
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item Unit
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "140px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Dispatch Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Required Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Balance Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Available Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item Remark
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Main Remark
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "7%",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Client Name
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "120px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Order Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData?.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell sx={{ textAlign: "left" }}>
                          <Checkbox
                            {...label}
                            checked={itemDetails[i] != null ? true : false}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row.shop_order_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row.shop_order_item_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row.shop_order_item_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row.shop_order_item_size
                            ? row.shop_order_item_size
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row.shop_order_unit}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          <TextField
                            size="small"
                            type="number"
                            sx={{ width: "70%" }}
                            inputProps={{ max: row?.balance_quantity, min: 0 }}
                            onKeyDown={(e) => {
                              const inputValue = parseInt(
                                e.target.value + e.key,
                                10
                              );
                              if (
                                isNaN(inputValue) ||
                                inputValue > row?.balance_quantity
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              handleSelect(e, row, i, "shop");
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row.shop_order_item_require_quantity}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row.balance_quantity}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row.available_quantity}
                        </TableCell>

                        <TableCell sx={{ textAlign: "left" }}>
                          <TextareaAutosize
                            contentEditable={false}
                            maxRows={4}
                            value={row.shop_order_item_remarks}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          <TextareaAutosize
                            contentEditable={false}
                            maxRows={4}
                            value={row.shop_order_remarks}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row.shop_order_client_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {displayDateFun(row.shop_order_date)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Div>
          )}

          <Div>
            <Div sx={{ display: "flex", columnGap: 3, mt: 3 }}>
              <Typography variant="h4">Dispatch Id :</Typography>
              <Typography variant="h4">{dispatchId}</Typography>
            </Div>

            <Div
              sx={{
                display: "flex",
                columnGap: 2,
                mt: 1,
                alignItems: "center",
              }}
            >
              <Typography variant="h4" sx={{ mt: 1 }}>
                Shipment No:
              </Typography>
              <TextField
                onChange={handleChange}
                size="small"
                value={inputs?.dispatch_shipment_no || ""}
                name="dispatch_shipment_no"
              />
            </Div>

            <Div
              sx={{
                mt: 2,
                display: "flex",
                columnGap: 5,
                rowGap: 2,
                flexWrap: "wrap",
              }}
            >
              <Div sx={{ width: "23%" }}>
                <Typography variant="h5">Transport Type</Typography>
                <Select
                  size="small"
                  sx={{ width: "100%" }}
                  name="transport_type"
                  onChange={handleChange}
                  value={inputs?.transport_type || ""}
                >
                  <MenuItem value={"Hand"}>{"Hand"}</MenuItem>
                  <MenuItem value={"Air"}>{"Air"}</MenuItem>
                  <MenuItem value={"Sea"}>{"Sea"}</MenuItem>
                  <MenuItem value={"DHL"}>{"DHL"}</MenuItem>
                </Select>
              </Div>
              <Div sx={{ width: "23%" }}>
                <Typography variant="h5">Box No</Typography>
                <TextField
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  value={inputs?.dispatch_box_no || ""}
                  name="dispatch_box_no"
                />
              </Div>
              <Div sx={{ width: "23%" }}>
                <Typography variant="h5">Weight (Kg)</Typography>
                <TextField
                  type="number"
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  value={inputs?.dispatch_weight || ""}
                  name="dispatch_weight"
                />
              </Div>
              {inputs?.dispatch_type == "Normal Order" ? (
                <Div sx={{ width: "23%" }}>
                  <Typography variant="h5">Quantity</Typography>
                  <TextField
                    onChange={handleChange}
                    disabled
                    size="small"
                    fullWidth
                    value={
                      itemDetails.filter((item) => {
                        return item != undefined;
                      }).length
                    }
                    name="dispatch_quantity"
                  />
                </Div>
              ) : (
                <Div sx={{ width: "23%" }}>
                  <Typography variant="h5">Quantity</Typography>
                  {/* {console.log(inputs, "rrrrrrrrrrrrrrrrrr")} */}
                  <TextField
                    // onChange={handleChange}
                    disabled
                    size="small"
                    fullWidth
                    value={itemDetails
                      ?.filter((item) => item !== null)
                      .reduce((a, item) => {
                        return a + (item?.dispatch_quantity || 0);
                      }, 0)}
                    name="dispatch_quantity"
                  />
                </Div>
              )}
              {inputs.transport_type == "Hand" ? (
                <>
                  <Div sx={{ width: "23%" }}>
                    <Typography variant="h5">Contact Person Name</Typography>
                    <TextField
                      onChange={handleChange}
                      size="small"
                      fullWidth
                      value={inputs?.dispatch_contact_person_name || ""}
                      name="dispatch_contact_person_name"
                    />
                  </Div>
                  <Div sx={{ width: "23%" }}>
                    <Typography variant="h5">Contact Person No.</Typography>
                    <TextField
                      onChange={handleChange}
                      size="small"
                      fullWidth
                      value={inputs?.dispatch_contact_person_phone_no || ""}
                      name="dispatch_contact_person_phone_no"
                    />
                  </Div>
                </>
              ) : (
                <Div sx={{ width: "23%" }}>
                  <Typography variant="h5">AWB / BL No.</Typography>
                  <TextField
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    value={inputs?.awb_bl_no || ""}
                    name="awb_bl_no"
                  />
                </Div>
              )}
            </Div>
            <Div sx={{ display: "flex", columnGap: 2, width: "40%", mt: 2 }}>
              <Div>
                <Typography variant="h5">Date of Dispatch</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YY"
                    // maxDate={dayjs()}
                    defaultValue={dayjs(inputs?.dispatch_date || currentdate)}
                    onChange={(date) => (inputs.dispatch_date = dateFun(date))}
                    sx={{
                      width: "100%",
                      "&.MuiTextField-root": {
                        height: "39px",
                        flexDirection: "unset",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Div>
              <Div>
                <Typography variant="h5">Est. Delivery Date</Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // minDate={dayjs()}
                    format="DD/MM/YY"
                    defaultValue={dayjs(inputs?.dispatch_est_delivery_date)}
                    onChange={(date) =>
                      (inputs.dispatch_est_delivery_date = dateFun(date))
                    }
                    sx={{
                      width: "100%",
                      "&.MuiTextField-root": {
                        height: "39px",
                        flexDirection: "unset",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Div>
            </Div>
          </Div>
        </>
      )}
      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 4,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to cancel?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboard/order/dispatch");
              }
            });
          }}
        >
          Cancel
        </Button>
        {/* {console.log(itemDetails)} */}
        <LoadingButton
          disabled={
            itemDetails.length > 0 &&
            itemDetails.filter((item) => item != undefined).length > 0
              ? false
              : true
          }
          variant="contained"
          sx={{ width: "100px" }}
          onClick={submitForm}
          loading={submitLoader}
        >
          Dispatch
        </LoadingButton>
      </Div>
    </Div>
  );
};

export default CreateNewDispatch;
