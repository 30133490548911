import Div from "@jumbo/shared/Div";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";
import { getProductList } from "app/services/apis/ListApi/getProductList";
import { getKcFabricCodeList } from "app/services/apis/ListApi/kcFabricCodeList";
import { getFabricAvailability } from "app/services/apis/OrderApis/checkFabricAvailability";
import React, { useEffect, useState } from "react";
import AddonsModal from "./addOnsModal";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { uploadImage } from "app/services/apis/UploadImagesApi";
import UploadCatalogue from "./uploadCatalogueModal";
import UploadImagesModal from "./uploadImagesModal";
import PreviewIcon from "@mui/icons-material/Preview";
import { getFactoryFabricDropdown } from "app/services/apis/ListApi/FactoryFabricList";
import { min } from "lodash";
import Swal from "sweetalert2";

const AddOrderTable = ({ setOrderData, gender, orderNo }) => {
  const [productList, setProductList] = useState([]);
  const [kfcList, setKFCList] = useState([]);
  const [factoryNameList, setFactoryNameList] = useState([]);
  const [prevSubItems, setPrevSubItems] = useState(0);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [loading, setLoading] = useState();
  const [index, setIndex] = useState();
  const [flag, setFlag] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const { orderItemsList } = useSelector((state) => state.orderReducer);
  const [selectedRow, setSelectedRow] = useState();
  const [indexNo, setIndexNo] = useState(null);
  const [openImage, setOpenImage] = useState(false);
  const [openUploadImg, setOpenUploadImg] = useState(false);
  const [loader, setLoader] = useState(false);
  const [itemDetails, setItemDetails] = useState([
    {
      sub_row: false,
      order_item_no: 1,
      order_item_name: "", // Set your initial values for each field here
      order_sub_item_name: "",
      order_item_kc_fabric_code: "",
      order_item_catalogue_image: [],
      order_item_image: [],
      order_item_taka_no: "",
      order_item_rack_details: "",
      taka_id: "",
      order_item_fabric_required: 1,
      inventory_status: null,
      order_item_draft_status: false,
      order_item_send_to_factory_status: false,
      order_item_draft_days: null,
      factory_master_id: "",
      order_item_addOns: [
        {
          itemName: "",
          quantity: "",
          status: "no",
          size: null,
        },
      ],
    },
  ]);

  const { pathname } = useLocation();

  const handleTextFieldChange = (e, rowIndex) => {
    const { name, value } = e.target;
    const updatedItemDetails = [...itemDetails];
    updatedItemDetails[rowIndex] = {
      ...updatedItemDetails[rowIndex],
      [name]: name == "order_item_fabric_required" ? parseFloat(value) : value,
    };
    setItemDetails(updatedItemDetails);
  };

  const handleRemoveItem = async (row, index) => {
    // Create a copy of itemDetails and remove the item at the specified index
    setRemoveLoader(true);

    if (row?.taka_id && row?.order_item_fabric_required) {
      const retriveTaka = {
        id: row?.taka_id,
        order_quantity: row?.order_item_fabric_required,
      };
      try {
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const res = await axios.patch(
          `${process.env.REACT_APP_URL}/api/order/retrieved-quantity`,
          {
            items:
              pathname == "/dashboard/order/editOrder"
                ? [retriveTaka]
                : [row.cancelDetails],
          },
          config
        );
      } catch (error) {
        if (error) {
          setRemoveLoader(false);
        }
      }
    }

    const updatedItemDetails = [...itemDetails];
    updatedItemDetails.splice(index, 1);
    setItemDetails(updatedItemDetails);
    setRemoveLoader(false);
  };

  const handleItemChange = (e, i) => {
    const sub_items = productList.filter((item) => {
      return item.item_name == e.target.value;
    });

    let addRowData;
    if (sub_items[0].sub_items?.length > 0) {
      addRowData = sub_items[0]?.sub_items?.map((item, i) => {
        // console.log(itemDetails[itemDetails.length - 1]?.order_item_no);
        return {
          sub_row: i > 0 ? true : false,
          order_item_no: itemDetails[itemDetails.length - 1].order_item_no
            ? itemDetails[itemDetails.length - 1].order_item_no
            : i + 1,
          order_item_name: e.target.value, // Set your initial values for each field here
          order_sub_item_name: item.sub_item_name,
          order_item_kc_fabric_code: "",
          order_item_fabric_required: 1,
          taka_id: "",
          inventory_status: null,
          order_item_draft_status: false,
          order_item_send_to_factory_status: false,
          order_item_draft_days: null,
          factory_master_id: null,
          order_item_remarks: "",
        };
      });
    } else {
      addRowData = [
        {
          order_item_no: sub_items[0].sub_items[0].order_item_no + 1,
          order_item_name: e.target.value, // Set your initial values for each field here
          order_sub_item_name: "",
          order_item_kc_fabric_code: "",
          order_item_fabric_required: 1,
          inventory_status: null,
          order_item_draft_status: false,
          order_item_send_to_factory_status: false,
          order_item_draft_days: null,
          factory_master_id: null,
          taka_id: "",
        },
      ];
    }
    const updatedData = [...itemDetails];
    if (prevSubItems == 0) {
      updatedData.splice(i, sub_items[0].sub_items?.length);
      setPrevSubItems(addRowData?.length);
    } else {
      updatedData.splice(i, prevSubItems);
      setPrevSubItems(addRowData?.length);
    }
    setItemDetails([...updatedData, ...addRowData]);
  };

  const handleAddRow = () => {
    let updatedItemDetails = [...itemDetails];
    const updated_data = updatedItemDetails?.map((item) => {
      return { ...item, sub_row: true };
    });
    // console.log(itemDetails[itemDetails.length - 1]?.order_item_no);
    setItemDetails([
      ...updated_data,
      {
        sub_row: false,
        order_item_no:
          itemDetails[itemDetails.length - 1]?.order_item_no != undefined
            ? itemDetails[itemDetails.length - 1].order_item_no + 1
            : 1,
        order_item_name: "", // Set your initial values for each field here
        order_sub_item_name: "",
        order_item_kc_fabric_code: "",
        order_item_catalogue_image: [],
        order_item_image: [],
        taka_id: "",
        order_item_taka_no: "",
        order_item_rack_details: "",
        order_item_fabric_required: 1,
        inventory_status: null,
        order_item_draft_status: false,
        order_item_send_to_factory_status: false,
        order_item_draft_days: null,
        factory_master_id: "",
        order_item_addOns: [
          {
            itemName: "",
            quantity: 0,
            status: "no",
          },
        ],
      },
    ]);
  };

  const handleCheck = async (row, i) => {
    console.log(row, "row");

    setLoading(true);
    const data = {
      order_quantity: row.order_item_fabric_required,
      kc_fabric_code: row.order_item_kc_fabric_code,
      item_name: row.order_item_name,
      sub_item_name: row.order_sub_item_name,
      item_no: row.order_item_no,
      order_no: orderNo,
    };

    const res = await getFabricAvailability(data, setLoading);

    if (res != undefined) {
      const updatedItemDetails = [...itemDetails];
      updatedItemDetails[i] = {
        ...updatedItemDetails[i],
        taka_id: res?.result?.id || "0",
        order_item_taka_no: res?.result?.taka_no,
        order_item_rack_details: res?.result?.raw_rack_details,
        inventory_status:
          data.kc_fabric_code == "Factory Fabric" ||
          data.kc_fabric_code == "Client Fabric"
            ? true
            : res?.message == "Available"
            ? true
            : false,
      };
      if (res?.result) {
        updatedItemDetails[i].cancelDetails = {
          id: res?.result?.id,
          order_quantity: row?.order_item_fabric_required,
        };
      }

      const cancelDetails = updatedItemDetails?.map(
        (item) => item.cancelDetails
      );
      const cancel = cancelDetails.filter((item) => item != undefined);
      localStorage.setItem("orderData", JSON.stringify(cancel));
      setItemDetails(updatedItemDetails);
      setLoading(false);
    } else {
      Swal.fire("Error", "Please Try Again", "error");
      setLoading(false);
    }
  };

  const handleEdit = async (row, i) => {
    setEditLoader(true);
    if (
      (row.inventory_status == true &&
        row.order_item_kc_fabric_code != "Factory Fabric") ||
      row.order_item_kc_fabric_code != "Client Fabric"
    ) {
      try {
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const res = await axios.patch(
          `${process.env.REACT_APP_URL}/api/order/retrieved-quantity`,
          { items: [row.cancelDetails] },
          config
        );
        if (res?.status == 200) {
          const updatedItemDetails = [...itemDetails];
          updatedItemDetails[i] = {
            ...updatedItemDetails[i],
            inventory_status: null,
          };
          setItemDetails(updatedItemDetails);
        }
      } catch (error) {
        if (error) {
          console.log(error?.response?.status, "rrrrrrrrr");
          if (error?.response?.status == undefined) {
            Swal.fire("Error", "Please Try Again", "error");
            setEditLoader(false);
            return;
          } else {
            const updatedItemDetails = [...itemDetails];
            updatedItemDetails[i] = {
              ...updatedItemDetails[i],
              inventory_status: null,
            };
            setItemDetails(updatedItemDetails);
            setEditLoader(false);
            return;
          }
        }
      }
    } else {
      const updatedItemDetails = [...itemDetails];
      updatedItemDetails[i] = {
        ...updatedItemDetails[i],
        inventory_status: null,
      };
      setItemDetails(updatedItemDetails);
    }
    setEditLoader(false);
  };

  const handleImage = async (e, i, type) => {
    const files = e.target.files; // This is a FileList object
    if (files.length === 0) {
      // No new files selected, do nothing
      return;
    }
    setLoader(true);
    const updatedItemDetails = [...itemDetails];
    const imageData = new FormData();
    for (let j = 0; j < files.length; j++) {
      imageData.append("image", files[j]);
    }
    try {
      const imageUrlRes = await uploadImage(imageData);
      updatedItemDetails[i] = {
        ...updatedItemDetails[i],
        order_item_image: imageUrlRes, // Use the response URL, if available
      };
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoader(false);
    }
    setItemDetails(updatedItemDetails);
  };

  const handleAddonsModal = (row, i) => {
    setInfoModalOpen(true);
    setSelectedRowData(row);
    setIndex(i);
    setFlag(!flag);
  };

  useEffect(async () => {
    setKFCList(await getFactoryFabricDropdown());
    setFactoryNameList(await getFactoryNameList());
  }, []);

  useEffect(async () => {
    // console.log(await getProductList(gender));
    setProductList(await getProductList(gender));
  }, [gender]);

  useEffect(() => {
    // Handle page unload (closing the tab/window)
    const handleUnload = async () => {
      const restoringInventory = JSON.parse(localStorage.getItem("orderData"));
      localStorage.removeItem("orderData");
      if (restoringInventory) {
        try {
          const config = {
            withCredentials: true,
            headers: {
              withCredentials: true,
            },
          };

          const res = await axios.patch(
            `${process.env.REACT_APP_URL}/api/order/retrieved-quantity`,
            { items: restoringInventory },
            config
          );
        } catch (error) {
          console.error(error);
          // Handle the error as needed
        }
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []); // Empty dependency array to run this effect only once on component mount

  useEffect(() => {
    // Handle page refresh
    const handleRefresh = async () => {
      const restoringInventory = JSON.parse(localStorage.getItem("orderData"));

      if (restoringInventory) {
        try {
          const config = {
            withCredentials: true,
            headers: {
              withCredentials: true,
            },
          };

          const res = await axios.patch(
            `${process.env.REACT_APP_URL}/api/order/retrieved-quantity`,
            { items: restoringInventory },
            config
          );
          if (res?.status === 200) {
            localStorage.removeItem("orderData");
          }
        } catch (error) {
          console.error(error);
          // Handle the error as needed
        }
      }
    };

    // Run the refresh handling logic when the component mounts
    handleRefresh();
  }, []); // Empty depe

  useEffect(() => {
    setOrderData(itemDetails);
  }, [itemDetails]);

  useEffect(() => {
    if (pathname == "/dashboard/order/replaceOrder") {
      const replaceOrderItemList = orderItemsList?.map((ele) => {
        return {
          ...ele,
          inventory_status: null,
          order_item_fabric_required: 0,
          // order_item_catalogue_image: [],
          // order_item_image: [],
          factory_master_id: 0,
        };
      });
      setItemDetails(replaceOrderItemList);
    } else if (orderItemsList?.length > 0) {
      setItemDetails(orderItemsList);
    }
  }, [orderItemsList]);
  // console.log(productList);
  return (
    <Div>
      <Typography variant="h3" sx={{ mt: "auto", fontWeight: "600" }}>
        Order Items:-
      </Typography>
      <TableContainer component={Paper} sx={{ width: "100%", mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  width: "100px",
                }}
              >
                Item No
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  width: "120px",
                }}
              >
                Item Name
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  width: "120px",
                }}
              >
                Sub Item
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  width: "260px",
                }}
              >
                Fabric Code
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  width: "200px",
                }}
              >
                Fabric Required
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  width: "200px",
                }}
              >
                Check Inventory
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  width: "120px",
                }}
              >
                Add Addons
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  width: "150px",
                }}
              >
                Catalog Selection
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  width: "150px",
                }}
              >
                Upload Image
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  width: "200px",
                }}
              >
                Factory
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                Remark
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  width: "200px",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemDetails?.map((row, i) => (
              <>
                <TableRow key={i}>
                  <TableCell>
                    {/* {row?.order_item_name == itemDetails[i]?.order_item_name &&
                      } */}
                    {row?.order_item_no}
                  </TableCell>
                  <TableCell>
                    <Select
                      sx={{ width: "200px" }}
                      value={row?.order_item_name || ""}
                      disabled={
                        pathname == "/dashboard/order/editOrder" ||
                        pathname == "/dashboard/order/replaceOrder"
                          ? i < orderItemsList?.length
                            ? true
                            : false
                          : itemDetails[i - 1]?.order_item_name ===
                            row?.order_item_name
                      }
                      size="small"
                      onChange={(e) => {
                        handleItemChange(e, i);
                      }}
                    >
                      {productList?.map((item) => {
                        // console.log(item);
                        return (
                          <MenuItem value={item?.item_name}>
                            {item?.item_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                  <TableCell>{row?.order_sub_item_name}</TableCell>
                  <TableCell>
                    <Autocomplete
                      style={{ width: "190px" }}
                      size="small"
                      id={i}
                      options={kfcList}
                      disabled={
                        (pathname == "/dashboard/order/editOrder" ||
                          pathname == "/dashboard/order/replaceOrder") &&
                        i < orderItemsList?.length
                          ? true
                          : false
                      }
                      value={row?.order_item_kc_fabric_code}
                      getOptionLabel={(option) =>
                        option?.kc_fabric_code ? option.kc_fabric_code : option
                      }
                      onChange={(e, newValue) => {
                        const updated_details = [...itemDetails];
                        updated_details[i] = {
                          ...updated_details[i],
                          factory_master_id: 0,
                          order_item_kc_fabric_code:
                            newValue == null ? "" : newValue.kc_fabric_code,
                        };
                        setItemDetails(updated_details);
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.kc_fabric_code}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      style={{ width: "90px" }}
                      inputProps={{ min: 1 }}
                      disabled={row.inventory_status == null ? false : true}
                      type="number"
                      size="small"
                      name="order_item_fabric_required"
                      value={row?.order_item_fabric_required}
                      onChange={(e) => {
                        handleTextFieldChange(e, i);
                      }}
                    ></TextField>
                    {row?.inventory_status != null && (
                      <LoadingButton
                        disabled={
                          pathname == "/dashboard/order/editOrder" &&
                          i < orderItemsList?.length
                            ? true
                            : false
                        }
                        fullWidth
                        loading={editLoader}
                        size="small"
                        variant="contained"
                        sx={{ mt: 1, fontSize: "10px" }}
                        onClick={() => {
                          handleEdit(row, i);
                        }}
                      >
                        Edit
                      </LoadingButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <Div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "600",
                      }}
                    >
                      {row?.inventory_status == true && "Available"}
                      {row?.inventory_status == false && "Unavailable"}
                      {row?.inventory_status == null && (
                        <LoadingButton
                          disabled={
                            row?.order_item_kc_fabric_code == "" &&
                            row?.order_item_fabric_required == ""
                              ? true
                              : false
                          }
                          loading={loading}
                          size="small"
                          variant="contained"
                          sx={{ ml: 2, fontSize: "10px" }}
                          onClick={() => {
                            handleCheck(row, i);
                          }}
                        >
                          Check
                        </LoadingButton>
                      )}
                    </Div>
                  </TableCell>
                  <TableCell>
                    <Div sx={{ display: "flex", justifyContent: "center" }}>
                      <AddCircleOutlineOutlined
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleAddonsModal(row, i)}
                      />
                    </Div>
                  </TableCell>
                  <TableCell>
                    {row?.order_item_catalogue_image != null ? (
                      <Typography>{`Images Selected: ${row?.order_item_catalogue_image?.length}`}</Typography>
                    ) : (
                      <Typography>Images Selected: 0</Typography>
                    )}
                    <label
                      htmlFor={`catalogueImageInput_${i}`} // Matching label with the unique ID
                      style={{
                        display: "block",
                        textAlign: "left",
                        marginTop: "10px",
                        width: "151px",
                      }}
                    >
                      <Button
                        // disabled={
                        //   pathname == "/dashboard/order/editOrder" &&
                        //   i < orderItemsList?.length
                        //     ? true
                        //     : false
                        // }
                        component="span"
                        size="small"
                        variant="contained"
                        sx={{ fontSize: "10px" }}
                        onClick={() => {
                          setSelectedRow(row);
                          setOpenImage(true);
                          setIndexNo(i);
                        }}
                      >
                        Upload Image
                      </Button>
                    </label>
                  </TableCell>
                  <TableCell>
                    <Div
                      sx={{
                        display: "flex",
                        alignItems: "end",
                      }}
                    >
                      <Div sx={{ width: "120px" }}>
                        {row?.order_item_image != null ? (
                          <Typography>{`Images Selected: ${row?.order_item_image?.length}`}</Typography>
                        ) : (
                          <Typography>{`Images Selected:0`}</Typography>
                        )}
                        <input
                          type="file"
                          multiple
                          accept=".jpg, .jpeg, .png"
                          id={`imageInput_${i}`} // Unique ID for regular image input
                          style={{ display: "none" }}
                          onChange={(e) => handleImage(e, i, "")}
                        />
                        <label
                          htmlFor={`imageInput_${i}`} // Matching label with the unique ID
                          style={{
                            display: "block",
                            textAlign: "left",
                            marginTop: "10px",
                            width: "151px",
                          }}
                        >
                          <LoadingButton
                            loading={loader}
                            component="span"
                            size="small"
                            variant="contained"
                            sx={{ fontSize: "10px" }}
                          >
                            Upload Image
                          </LoadingButton>
                        </label>
                      </Div>
                      <PreviewIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedRowData(row?.order_item_image);
                          setOpenUploadImg(true);
                        }}
                      />
                    </Div>
                  </TableCell>
                  <TableCell>
                    <Div>
                      <Select
                        disabled={
                          (row?.inventory_status == true &&
                            row?.order_item_send_to_factory_status != true) ||
                          row?.order_item_kc_fabric_code == "Factory Fabric" ||
                          row?.order_item_kc_fabric_code == "Client Fabric"
                            ? false
                            : true
                        }
                        size="small"
                        sx={{ width: "150px" }}
                        name="factory_master_id"
                        value={row?.factory_master_id || ""}
                        onChange={(e) => handleTextFieldChange(e, i)}
                      >
                        {factoryNameList &&
                          factoryNameList?.length &&
                          factoryNameList?.map((item) => {
                            return (
                              <MenuItem value={item?.id}>
                                {item?.factory_name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Div>
                  </TableCell>
                  <TableCell>
                    <TextField
                      disabled={
                        pathname == "/dashboard/order/editOrder" &&
                        i < orderItemsList?.length
                          ? true
                          : false
                      }
                      sx={{ width: "300px" }}
                      multiline
                      size="small"
                      name={"order_item_remarks"}
                      value={row?.order_item_remarks}
                      onChange={(e) => handleTextFieldChange(e, i)}
                    />
                  </TableCell>
                  <TableCell>
                    <LoadingButton
                      disabled={
                        // itemDetails?.length == 1 ||
                        pathname == "/dashboard/order/editOrder" &&
                        i < orderItemsList?.length
                          ? true
                          : false
                      }
                      loading={removeLoader}
                      size="small"
                      onClick={() => handleRemoveItem(row, i)}
                      variant="contained"
                      color="error"
                    >
                      Remove
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
        {isInfoModalOpen && (
          <AddonsModal
            open={isInfoModalOpen}
            onClose={() => setInfoModalOpen(false)}
            rowData={selectedRowData}
            index={index}
            setItemDetails={setItemDetails}
            itemDetails={itemDetails}
          />
        )}

        {openImage && (
          <UploadCatalogue
            open={openImage}
            onClose={() => setOpenImage(false)}
            itemDetails={itemDetails}
            setItemDetails={setItemDetails}
            index={indexNo}
          />
        )}
        {openUploadImg && (
          <UploadImagesModal
            open={openUploadImg}
            onClose={() => setOpenUploadImg(false)}
            itemDetails={itemDetails}
            rowData={selectedRowData}
            setItemDetails={setItemDetails}
          />
        )}
      </TableContainer>
      <Button variant="contained" sx={{ mt: 2 }} onClick={handleAddRow}>
        Add More Item
      </Button>
    </Div>
  );
};

export default AddOrderTable;
