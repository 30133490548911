import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import React from "react";
import EditQcPage from "./EditQcPage";


const QcEdit = () => {
  return (
    <Div>
      <Typography variant="h1" sx={{ mt: -4, mb: 4 }}>
        Edit Qc
      </Typography>
      <EditQcPage />
    </Div>
  );
};

export default QcEdit;
