import {
  ALL_DISPATCH_COMPLETE_LIST_FAIL,
  ALL_DISPATCH_COMPLETE_LIST_REQUEST,
  ALL_DISPATCH_COMPLETE_LIST_SUCCESS,
  ALL_DISPATCH_DELIVERED_LIST_FAIL,
  ALL_DISPATCH_DELIVERED_LIST_REQUEST,
  ALL_DISPATCH_DELIVERED_LIST_SUCCESS,
  ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_FAIL,
  ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_REQUEST,
  ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_SUCCESS,
  ALL_DISPATCH_PARTIAL_LIST_FAIL,
  ALL_DISPATCH_PARTIAL_LIST_REQUEST,
  ALL_DISPATCH_PARTIAL_LIST_SUCCESS,
  ALL_ORDER_CREATED_LIST_FAIL,
  ALL_ORDER_CREATED_LIST_REQUEST,
  ALL_ORDER_CREATED_LIST_SUCCESS,
  ALL_ORDER_ITEM_LIST_FAIL,
  ALL_ORDER_ITEM_LIST_REQUEST,
  ALL_ORDER_ITEM_LIST_SUCCESS,
  ALL_ORDER_LIST_FAIL,
  ALL_ORDER_LIST_REQUEST,
  ALL_ORDER_LIST_SUCCESS,
  ALL_SHOP_ORDER_DELIVERED_LIST_FAIL,
  ALL_SHOP_ORDER_DELIVERED_LIST_REQUEST,
  ALL_SHOP_ORDER_DELIVERED_LIST_SUCCESS,
  ALL_SHOP_ORDER_LIST_FAIL,
  ALL_SHOP_ORDER_LIST_REQUEST,
  ALL_SHOP_ORDER_LIST_SUCCESS,
  ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_FAIL,
  ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_REQUEST,
  ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_SUCCESS,
  CUTTING_ORDER_LIST_FAIL,
  CUTTING_ORDER_LIST_REQUEST,
  CUTTING_ORDER_LIST_SUCCESS,
  QC_ORDER_LIST_FAIL,
  QC_ORDER_LIST_REQUEST,
  QC_ORDER_LIST_SUCCESS,
  PACKING_ORDER_LIST_FAIL,
  PACKING_ORDER_LIST_REQUEST,
  PACKING_ORDER_LIST_SUCCESS,
  SHOP_PACKING_ORDER_LIST_FAIL,
  SHOP_PACKING_ORDER_LIST_REQUEST,
  SHOP_PACKING_ORDER_LIST_SUCCESS,
  RECEIVE_ORDER_LIST_FAIL,
  RECEIVE_ORDER_LIST_REQUEST,
  RECEIVE_ORDER_LIST_SUCCESS,
} from "app/utils/constants/orderConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  orderList: [],
  orderCreatedList: [],
  cuttingOrder: [],
  receiveOrder: [],
  qcOrder: [],
  orderItemsList: [],
  dispatchComplete: [],
  dispatchPartial: [],
  dispatchOutForDelivery: [],
  dispatchDelivered: [],
  shopOrders: [],
  packing: [],
  shopPacking: [],
  shopOutForDelivery: [],
  shopDelivered: [],
  TotalPage: null,
};

export const orderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case ALL_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orderList: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ORDER_LIST_FAIL:
      return {
        loading: false,
        orderList: [],
        error: action.payload,
      };

    case ALL_ORDER_CREATED_LIST_REQUEST:
      return {
        loading: true,
      };
    case ALL_ORDER_CREATED_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orderCreatedList: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ORDER_CREATED_LIST_FAIL:
      return {
        loading: false,
        orderCreatedList: [],
        error: action.payload,
      };

    case CUTTING_ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case CUTTING_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        cuttingOrder: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case CUTTING_ORDER_LIST_FAIL:
      return {
        loading: false,
        cuttingOrder: [],
        error: action.payload,
      };

    case RECEIVE_ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case RECEIVE_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        receiveOrder: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case RECEIVE_ORDER_LIST_FAIL:
      return {
        loading: false,
        receiveOrder: [],
        error: action.payload,
      };

    case QC_ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case QC_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        qcOrder: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case QC_ORDER_LIST_FAIL:
      return {
        loading: false,
        qcOrder: [],
        error: action.payload,
      };

    case PACKING_ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case PACKING_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        packing: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case PACKING_ORDER_LIST_FAIL:
      return {
        loading: false,
        packing: [],
        error: action.payload,
      };

    case SHOP_PACKING_ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case SHOP_PACKING_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        shopPacking: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case SHOP_PACKING_ORDER_LIST_FAIL:
      return {
        loading: false,
        shopPacking: [],
        error: action.payload,
      };

    case ALL_ORDER_ITEM_LIST_REQUEST:
      return {
        loading: true,
      };
    case ALL_ORDER_ITEM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orderItemsList: action.payload,
      };

    case ALL_ORDER_ITEM_LIST_FAIL:
      return {
        loading: false,
        orderItemsList: [],
        error: action.payload,
      };
    case ALL_DISPATCH_COMPLETE_LIST_REQUEST:
      return {
        loading: true,
      };
    case ALL_DISPATCH_COMPLETE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatchComplete: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_DISPATCH_COMPLETE_LIST_FAIL:
      return {
        loading: false,
        dispatchComplete: [],
        error: action.payload,
      };
    case ALL_DISPATCH_PARTIAL_LIST_REQUEST:
      return {
        loading: true,
      };
    case ALL_DISPATCH_PARTIAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatchPartial: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_DISPATCH_PARTIAL_LIST_FAIL:
      return {
        loading: false,
        dispatchPartial: [],
        error: action.payload,
      };
    case ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_REQUEST:
      return {
        loading: true,
      };
    case ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatchOutForDelivery: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_FAIL:
      return {
        loading: false,
        dispatchOutForDelivery: [],
        error: action.payload,
      };
    case ALL_DISPATCH_DELIVERED_LIST_REQUEST:
      return {
        loading: true,
      };
    case ALL_DISPATCH_DELIVERED_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatchDelivered: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_DISPATCH_DELIVERED_LIST_FAIL:
      return {
        loading: false,
        dispatchDelivered: [],
        error: action.payload,
      };

    // shop order
    case ALL_SHOP_ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case ALL_SHOP_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        shopOrders: action.payload.data,
        TotalPage: action.payload.totalPage,
      };
    case ALL_SHOP_ORDER_LIST_FAIL:
      return {
        loading: false,
        shopOrders: [],
        error: action.payload,
      };
    case ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_REQUEST:
      return {
        loading: true,
      };
    case ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        shopOutForDelivery: action.payload.data,
        TotalPage: action.payload.totalPage,
      };
    case ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_FAIL:
      return {
        loading: false,
        shopOutForDelivery: [],
        error: action.payload,
      };
    case ALL_SHOP_ORDER_DELIVERED_LIST_REQUEST:
      return {
        loading: true,
      };
    case ALL_SHOP_ORDER_DELIVERED_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        shopDelivered: action.payload.data,
        TotalPage: action.payload.totalPage,
      };
    case ALL_SHOP_ORDER_DELIVERED_LIST_FAIL:
      return {
        loading: false,
        shopDelivered: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
