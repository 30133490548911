import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
} from "@mui/material";
import { displayDateFun } from "app/utils/constants/functions";

export default function ShopOrderDetailTable({ itemDetails, type }) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "10%",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Dispatch ID
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "10%",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Transport Type
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "10%",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Box No
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "10%",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                AWB BL No.
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "10%",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Weight
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "10%",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                {type != "shopOrder" ? "Total Items" : "Dispatch Qty"}
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Person Name
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Person No
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "10%",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Dispatch Date
              </TableCell>
            </TableRow>
          </TableHead>
          {type != "shopOrder" ? (
            <TableBody>
              {console.log("2222222222222")}

              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "left",
                    pl: 2,
                  }}
                >
                  {itemDetails?.shop_order_dispatch_id}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {itemDetails?.shop_order_transport_type}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {itemDetails?.shop_order_dispatch_box_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {itemDetails?.shop_order_awb_bl_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {itemDetails?.shop_order_dispatch_weight}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {itemDetails?.shop_order_dispatch_quantity}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {itemDetails?.shop_order_dispatch_contact_person_name
                    ? itemDetails?.shop_order_dispatch_contact_person_name
                    : "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {itemDetails?.shop_order_dispatch_contact_person_phone_no
                    ? itemDetails?.shop_order_dispatch_contact_person_phone_no
                    : "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {itemDetails?.shop_order_dispatch_date
                    ? displayDateFun(itemDetails?.shop_order_dispatch_date)
                    : "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            Array.isArray(itemDetails) == true && (
              <TableBody>
                {itemDetails?.map((itemDetail, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          pl: 2,
                        }}
                      >
                        {itemDetail?.shop_order_dispatch_id}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {itemDetail?.shop_order_transport_type}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {itemDetail?.shop_order_dispatch_box_no}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {itemDetail?.shop_order_awb_bl_no}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {itemDetail?.shop_order_dispatch_weight}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {itemDetail?.shop_ordered_dispatch_quantity}
                      </TableCell>

                      <TableCell sx={{ textAlign: "left" }}>
                        {itemDetail?.shop_order_dispatch_contact_person_name
                          ? itemDetail?.shop_order_dispatch_contact_person_name
                          : "-"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {itemDetail?.shop_order_dispatch_contact_person_phone_no
                          ? itemDetail?.shop_order_dispatch_contact_person_phone_no
                          : "-"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {itemDetail?.shop_order_dispatch_date
                          ? displayDateFun(itemDetail?.shop_order_dispatch_date)
                          : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )
          )}
        </Table>
      </TableContainer>
    </>
  );
}
