import axios from "axios";

export const downloadInventoryProductionReport = async (inputs) => {
  console.log(inputs, "iiiiiiiii");

  if (inputs.order_item_name == undefined) {
    inputs.order_item_name = "";
  }
  if (inputs.order_no == undefined) {
    inputs.order_no = "";
  }
  if (inputs.order_port_no_and_name == undefined) {
    inputs.order_port_no_and_name = "";
  }
  if (inputs.productions_invoice_no == undefined) {
    inputs.productions_invoice_no = "";
  }
  if (inputs.order_item_job_work_id == undefined) {
    inputs.order_item_job_work_id = "";
  }
  if (inputs.order_item_factory_name == undefined) {
    inputs.order_item_factory_name = "";
  }
  if (inputs.order_item_status == undefined) {
    inputs.order_item_status = "";
  }
  if (inputs.order_est_delivery_date_from == undefined) {
    inputs.order_est_delivery_date_from = "";
  }
  if (inputs.order_est_delivery_date_to == undefined) {
    inputs.order_est_delivery_date_to = "";
  }

  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    // const data = await axios.get(
    //   `${
    //     process.env.REACT_APP_URL
    //   }/api/production/production-download-excel?order_no=${inputs.order_no}
    //     &order_item_job_work_id=${inputs.order_item_job_work_id}
    //     &productions_invoice_no=${inputs.productions_invoice_no}
    //     &order_item_name=${inputs.order_item_name.replace(/ /g, "+")}
    //   &order_item_factory_name=${inputs.order_item_factory_name.replace(
    //     / /g,
    //     "+"
    //   )}
    //   &order_item_status=${inputs.order_item_status}
    //   &order_port_no_and_name=${inputs.order_port_no_and_name}
    //   &order_est_delivery_date_from=${inputs.order_est_delivery_date_from}
    //   &order_est_delivery_date_to=${inputs.order_est_delivery_date_to}`,
    //   config
    // );
    // console.log(data, "data");

    // const queryString = Object.entries(inputs)
    //   .filter(
    //     ([key, value]) =>
    //       value && typeof value === "string" && value.trim() !== ""
    //   )
    //   .map(([key, value]) => `${key}=${value.trim().replace(/ /g, "+")}`)
    //   .join("&");

    const queryString = Object.entries(inputs)
      .filter(([key, value]) => {
        return (
          value &&
          ((typeof value === "string" && value.trim() !== "") ||
            typeof value === "number")
        );
      })
      .map(([key, value]) => {
        const formattedValue =
          typeof value === "number"
            ? value.toString()
            : encodeURIComponent(value.trim());
        return `${encodeURIComponent(key)}=${formattedValue}`;
      })
      .join("&");
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/production/production-download-excel?${queryString}`,
      config
    );

    return data?.data?.result;
  } catch (error) {
    console.log(error);
    return error?.response?.data?.message;
  }
};

export const downloadInventoryProductionHistoryReport = async (inputs) => {
  // console.log(inputs, "inputs");
  if (inputs.order_item_name == undefined) {
    inputs.order_item_name = "";
  }
  if (inputs.order_no == undefined) {
    inputs.order_no = "";
  }
  if (inputs.productions_invoice_no == undefined) {
    inputs.productions_invoice_no = "";
  }
  if (inputs.order_port_no_and_name == undefined) {
    inputs.order_port_no_and_name = "";
  }
  if (inputs.order_item_job_work_id == undefined) {
    inputs.order_item_job_work_id = "";
  }
  if (inputs.order_item_factory_name == undefined) {
    inputs.order_item_factory_name = "";
  }
  if (inputs.order_item_status == undefined) {
    inputs.order_item_status = "";
  }
  if (inputs.order_est_delivery_date_from == undefined) {
    inputs.order_est_delivery_date_from = "";
  }
  if (inputs.order_est_delivery_date_to == undefined) {
    inputs.order_est_delivery_date_to = "";
  }
  if (inputs.supplier_name == undefined) {
    inputs.supplier_name = "";
  }

  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    // const data = await axios.get(
    //   `${
    //     process.env.REACT_APP_URL
    //   }/api/production/production-history-download-excel?order_no=${
    //     inputs.order_no
    //   }&order_item_job_work_id=${
    //     inputs.order_item_job_work_id
    //   }&productions_invoice_no=${inputs.productions_invoice_no.replace(/ /g,"+")}
    //   &order_item_name=${inputs.order_item_name.replace(
    //     / /g,
    //     "+"
    //   )}&order_item_factory_name=${inputs.order_item_factory_name.replace(
    //     / /g,
    //     "+"
    //   )}&order_item_status=${
    //     inputs.order_item_status
    //   }&supplier_name=${inputs.supplier_name.replace(/ /g, "+")}`,
    //   config
    // );

    const queryString = Object.entries(inputs)
      .filter(([key, value]) => {
        return (
          value &&
          ((typeof value === "string" && value.trim() !== "") ||
            typeof value === "number")
        );
      })
      .map(([key, value]) => {
        const formattedValue =
          typeof value === "number"
            ? value.toString()
            : encodeURIComponent(value.trim());
        return `${encodeURIComponent(key)}=${formattedValue}`;
      })
      .join("&");

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/production/production-history-download-excel?${queryString}`,
      config
    );
    console.log(data, "data");
    return data?.data?.result;
  } catch (error) {
    console.log(error);
    return error?.response?.data?.result;
  }
};
